import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import OnDeleteIcon from '../../icons/OnDeleteIcon';
import OnEditIcon from '../../icons/OnEditIcon';
import OnFileDownloadIcon from '../../icons/OnFileDownloadIcon';
import {
    deleteParticipantDocument,
    downloadParticipantDocument,
    onDeleteParticipantDocument
} from '../../store/documentService';
import { selectCurrentParticipant } from '../../store/participantSelectors';
import { capitalize } from '../../utils/capitalize';
import { QUALITY, SUPERUSER } from '../../utils/userRoles';

import classes from '../../commonStyles/tables.module.css';

const DocumentsTableRow = ({ row, roles, onEdit, acceptedRoles, deleteRoles }) => {
    const dispatch = useDispatch();

    const [active, setActive] = useState(true);
    const [status, setStatus] = useState('');
    const [editRoles, setEditRoles] = useState(acceptedRoles);

    const currentParticipant = useSelector(selectCurrentParticipant);
    const { claimQueues, claimQueuesMetaData, contractConstants } = useSelector(
        (state) => state.entities.participantService
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    useEffect(() => {
        setActive(row.status.toUpperCase() === 'READY');
        let status = capitalize(row.status);
        if (row.status.toUpperCase() === 'QUARANTINED' || row.status.toUpperCase() === 'FAILED')
            status = 'FAILED';
        setStatus(status);
    }, [row]);

    useEffect(() => {
        if (
            !claimQueuesMetaData?.last ||
            claimQueues.length < 1 ||
            Object.keys(contractConstants).length < 1
        )
            return;
        if (contractConstants.activeStatusDocumentTypeIds.includes(row.typeId))
            setEditRoles([QUALITY, SUPERUSER]);
    }, [claimQueues, claimQueuesMetaData, contractConstants]);

    useEffect(() => {
        if (successMessage === `${row.filename} has been deleted`) {
            dispatch(onDeleteParticipantDocument(row.id));
        }
    }, [successMessage]);

    const onDownload = () =>
        dispatch(downloadParticipantDocument(currentParticipant.id, row.filename));

    const onDelete = () => dispatch(deleteParticipantDocument(currentParticipant.id, row.filename));

    return (
        <>
            <TableRow className={`${classes.basicRowStripes}`}>
                <TableCell>{row.filename}</TableCell>
                <TableCell>{row.typeName === 'x' ? ' - ' : row.typeName}</TableCell>
                <TableCell>{row.dateCreated === 'x' ? ' - ' : row.dateCreated}</TableCell>
                <TableCell>{row.time === 'x' ? ' - ' : row.time}</TableCell>
                <TableCell>{row.userName === 'z' ? ' - ' : row.userName}</TableCell>
                <TableCell>{`${row.dateModified === 'x' ? ' - ' : row.dateModified}`}</TableCell>
                <TableCell>{status}</TableCell>
                <TableCell align="right">
                    <OnFileDownloadIcon
                        roles={roles}
                        acceptedRoles={acceptedRoles}
                        active={active}
                        onDownload={onDownload}
                        participantId={currentParticipant.id}
                        filename={row.filename}
                    />
                </TableCell>
                <TableCell align="right">
                    <OnEditIcon
                        onEdit={() => onEdit(row)}
                        roles={roles}
                        active={active}
                        acceptedRoles={editRoles}
                    />
                </TableCell>
                <TableCell align="right">
                    <OnDeleteIcon
                        onDelete={onDelete}
                        roles={roles}
                        acceptedRoles={deleteRoles}
                        active={active}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default DocumentsTableRow;

DocumentsTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    deleteRoles: PropTypes.arrayOf(PropTypes.string),
    onEdit: PropTypes.func
};
