import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { selectActionPlanStatus } from '../../../store/dataSelectors';
import { loadActionPlanStatusDetails } from '../../../store/directusService';
import {
    selectCurrentParticipant,
    selectCurrentParticipantBarriers,
    selectCurrentParticipantEmployability
} from '../../../store/participantSelectors';
import { updateActionPlan } from '../../../store/participantService';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import DateSelect from '../../formElements/DateSelect';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import FormActions from '../../ui/formActions/FormActions';
import SmartDefs from '../../ui/notices/smartDefs/SmartDefs';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

import { validate } from './actionPlanValidations';
import { calcDays } from './calcDays';
import { configDisabled } from './disabledStatus';

import classes from './actionPlan.module.css';

const ACTION_DESCRIPTION_LIMIT = 750;
const ACTION_COMMENTS_LIMIT = 500;
const ActionForm = ({ row, onClose, roles }) => {
    const {
        register,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({});

    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const restrictedRoles = [QUALITY, SUPERUSER];
    const initialState = {
        id: '',
        participantId: '',
        mandatory: false,
        actionTitle: '',
        action: '',
        barrierId: '',
        actionStartDate: '',
        actionDueDate: '',
        actionOutcomeDate: '',
        totalDays: null,
        outcomeId: '',
        outcomeName: '',
        moduleId: null,
        comments: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [allowMandatoryActivities, setAllowMandatoryActivities] = useState(true);
    const [isClearBarrier, setIsClearBarrier] = useState('0');
    const [isClearOutcome, setIsClearOutcome] = useState('1');
    const [outcomes, setOutcomes] = useState([]);

    const initialDisabledState = {
        barrierId: false,
        mandatory: false,
        actionTitle: false,
        action: false,
        actionStartDate: true,
        actionDueDate: false,
        actionOutcomeDate: false,
        outcomeId: false,
        comments: false
    };

    const [disabled, setDisabled] = useState(initialDisabledState);
    const [currentParticipantBarriersWithNames, setCurrentParticipantBarriersWithNames] = useState(
        []
    );

    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentParticipantEmployability = useSelector(selectCurrentParticipantEmployability);
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const actionPlanStatus = useSelector(selectActionPlanStatus);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setIsClearBarrier(Math.random());
        setIsClearOutcome(Math.random());
    };

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipantBarriers.length < 1) return;

        setCurrentParticipantBarriersWithNames(
            currentParticipantBarriers.map((el) => ({
                ...el,
                name: getNameFromId(barrierNameDetails, el.barrierId)
            }))
        );
    }, [currentParticipantBarriers]);

    useEffect(() => {
        setDisabled(configDisabled(newEntry, roles, allowMandatoryActivities));
    }, [roles, newEntry]);

    useEffect(() => {
        if (!currentParticipant?.id) return;
        reset();
        setNewEntry((prev) => ({
            ...prev,
            ...row,
            participantId: currentParticipant.id,
            actionStartDate:
                row.actionStartDate === '1960-01-01' ? null : row.actionStartDate.slice(0, 10),
            actionDueDate:
                row.actionDueDate === '1960-01-01' ? null : row.actionDueDate.slice(0, 10),
            actionOutcomeDate:
                row.actionOutcomeDate === '1960-01-01' ? '' : row.actionOutcomeDate.slice(0, 10)
        }));
        if (!actionPlanStatus?.length) dispatch(loadActionPlanStatusDetails());
    }, [currentParticipant, row]);

    useEffect(() => {
        if (Object.keys(currentParticipantEmployability).length > 1) {
            setAllowMandatoryActivities(currentParticipantEmployability?.allowMandatoryActivities);
        }
    }, [currentParticipantEmployability]);

    useEffect(() => {
        if (!actionPlanStatus.length) return;
        setOutcomes(
            actionPlanStatus.filter((el) =>
                el.contract.some((entry) => entry.contract_id.id === currentParticipant.contractId)
            )
        );
    }, [actionPlanStatus]);

    useEffect(() => {
        if (newEntry.actionOutcomeDate) return;
        if (newEntry.outcomeId) {
            setNewEntry((prev) => ({
                ...prev,
                actionOutcomeDate: new Date().toISOString().slice(0, 10)
            }));
        }
    }, [newEntry.outcomeId]);

    useEffect(() => {
        if (successMessage === 'Participant action plan has been updated') clearForm();
    }, [successMessage]);

    // EVENT HANDLERS
    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        let disableDueDate = disabled.actionDueDate;
        let disableOutcomeDate = disabled.actionOutcomeDate;

        if (row.actionDueDate && row.actionDueDate.slice(0, 10) === newEntry.actionDueDate)
            disableDueDate = true;

        if (
            row.actionOutcomeDate &&
            row.actionOutcomeDate.slice(0, 10) === newEntry.actionOutcomeDate
        )
            disableOutcomeDate = true;
        clearErrors();

        const updatedDisabled = {
            ...disabled,
            actionDueDate: disableDueDate,
            actionOutcomeDate: disableOutcomeDate
        };
        const { valid, errors } = validate(updatedDisabled, newEntry);
        errors.forEach((el) => setError(el.error, el.detail));
        if (!valid) return;
        // eslint-disable-next-line
        const { outcomeName, totalDays, barrierName, ...rest } = newEntry;
        dispatch(updateActionPlan(rest));
        onFormExit();
    };

    // RENDER
    return (
        <div className={classes.actionFormSection}>
            <h3>Edit an action</h3>
            <form className={classes.actionForm} onSubmit={handleSubmit(onSubmit)}>
                {row?.moduleId ? (
                    <div className={classes.staticCopySet}>
                        <div className={classes.staticCopy}>
                            <h3>Is this a mandatory action?</h3>
                            {newEntry.mandatory ? 'Yes' : 'No'}
                        </div>

                        <div className={classes.staticCopy}>
                            <h3>What shall we call this action?</h3>
                            {newEntry.actionTitle}
                        </div>
                    </div>
                ) : (
                    <>
                        <NoYesRadioPicker
                            id="mandatory"
                            disabled={!hasRole(acceptedRoles, roles) || !allowMandatoryActivities}
                            radioButtonPick={newEntry.mandatory}
                            text={'Is this a mandatory action?'}
                            onChange={(val) =>
                                setNewEntry((prev) => ({ ...prev, mandatory: val }))
                            }></NoYesRadioPicker>

                        <LabelledTextField
                            label={'What shall we call this action?'}
                            id={'actionTitle'}
                            value={newEntry.actionTitle || ''}
                            placeholder={'Enter name'}
                            mandatory={true}
                            disabled={
                                newEntry.outcomeId
                                    ? !hasRole(restrictedRoles, roles)
                                    : !hasRole(acceptedRoles, roles)
                            }
                            error={errors.actionTitle}
                            {...register('actionTitle')}
                            onChange={(e) => {
                                clearErrors('actionTitle');
                                setNewEntry((prev) => ({ ...prev, actionTitle: e.target.value }));
                            }}
                        />
                    </>
                )}
                <DDLOptionPicker
                    label={'Which barrier does this action align to?'}
                    id={'barrier'}
                    key={isClearBarrier}
                    disabled={
                        newEntry.outcomeId
                            ? !hasRole(restrictedRoles, roles)
                            : !hasRole(acceptedRoles, roles)
                    }
                    menuItems={currentParticipantBarriersWithNames || []}
                    chosenName={newEntry.barrierName}
                    chosenId={newEntry.barrierId}
                    onChange={(chosenId) => {
                        clearErrors('barrierId');
                        setNewEntry((prev) => ({ ...prev, barrierId: chosenId }));
                    }}></DDLOptionPicker>

                {row?.moduleId ? (
                    <div className={classes.staticCopyAction}>
                        <h3>Action</h3>
                        {newEntry.action}
                    </div>
                ) : (
                    <>
                        <div className={classes.smartDefsWrapper}>
                            <div className={classes.heading}>
                                What is the action?<sup>*</sup>
                            </div>
                            <SmartDefs />
                        </div>

                        <LabelledTextField
                            label={'Action'}
                            id={'action'}
                            mandatory={true}
                            disabled={
                                newEntry.outcomeId
                                    ? !hasRole(restrictedRoles, roles)
                                    : !hasRole(acceptedRoles, roles)
                            }
                            multiline
                            rows={5}
                            value={newEntry.action}
                            placeholder={'Enter action description'}
                            counter={'true'}
                            helperText={
                                `${newEntry.action.length}` + '/' + ACTION_DESCRIPTION_LIMIT
                            }
                            inputProps={{ maxLength: ACTION_DESCRIPTION_LIMIT }}
                            error={errors.action}
                            {...register('action')}
                            onChange={(e) => {
                                clearErrors('action');
                                setNewEntry((prev) => ({ ...prev, action: e.target.value }));
                            }}
                        />
                    </>
                )}

                <div className={classes.twoCol}>
                    <div>
                        <DateSelect
                            value={newEntry.actionStartDate}
                            label="Action start date"
                            disabled={true}
                        />
                    </div>
                    <div>
                        <DateSelect
                            value={newEntry.actionDueDate}
                            label="Action due date"
                            disabled={
                                newEntry.outcomeId
                                    ? !hasRole(restrictedRoles, roles)
                                    : !hasRole(acceptedRoles, roles)
                            }
                            error={errors.actionDueDate}
                            onDateChange={(res) => {
                                clearErrors('actionDueDate');
                                setNewEntry((prev) => ({ ...prev, actionDueDate: res }));
                            }}
                        />
                    </div>
                </div>

                <div className={classes.completion}>
                    <h3>Action completion information</h3>

                    <div className={classes.twoCol}>
                        <div>
                            <DateSelect
                                label="Action outcome date"
                                disabled={!hasRole(restrictedRoles, roles) || !newEntry.outcomeId}
                                isDefault={false}
                                value={newEntry.actionOutcomeDate}
                                error={errors.actionOutcomeDate}
                                onDateChange={(res) => {
                                    clearErrors('actionOutcomeDate');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        actionOutcomeDate: res
                                    }));
                                }}
                            />
                        </div>

                        <div className={classes.inputSet}>
                            <label>Total days to complete</label>
                            <div className={classes.total}>
                                {' '}
                                {calcDays(actionPlanStatus, newEntry)}{' '}
                            </div>
                        </div>
                    </div>
                    <DDLOptionPicker
                        label={'Outcome'}
                        id={'outcome'}
                        key={isClearOutcome}
                        disabled={
                            row.outcomeId
                                ? !hasRole(restrictedRoles, roles)
                                : !hasRole(acceptedRoles, roles)
                        }
                        menuItems={outcomes || []}
                        chosenName={newEntry.outcomeName}
                        chosenId={newEntry.outcomeId}
                        onChange={(chosenId) =>
                            setNewEntry((prev) => ({ ...prev, outcomeId: chosenId }))
                        }></DDLOptionPicker>

                    <div className={classes.comments}>
                        <LabelledTextField
                            label="Comments"
                            subLabel="Use this area for comments surrounding this action"
                            id="comments"
                            disabled={!hasRole(acceptedRoles, roles)}
                            multiline
                            rows={5}
                            value={newEntry.comments || ''}
                            placeholder="Enter here"
                            counter={'true'}
                            helperText={
                                newEntry?.comments?.length
                                    ? `${newEntry?.comments?.length}/${ACTION_COMMENTS_LIMIT}`
                                    : ''
                            }
                            inputProps={{ maxLength: ACTION_COMMENTS_LIMIT }}
                            error={errors.comments}
                            {...register('comments')}
                            onChange={(e) => {
                                clearErrors('comments');
                                setNewEntry((prev) => ({ ...prev, comments: e.target.value }));
                            }}
                        />
                    </div>
                </div>
                <FormActions onClose={onSubmit} onCancel={onFormExit} btnText="Update Action" />
            </form>
        </div>
    );
};

export default ActionForm;

ActionForm.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string)
};
