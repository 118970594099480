import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadClaimQueueActionStatusDetails } from '../../../store/directusService';
import { selectUsers } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { formatDateTime } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';

import card from '../claimStyles/statusActionHistory.module.css';

const ActionHistory = ({ history }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const users = useSelector(selectUsers);
    const claimQueueActionStatusDetails = useSelector(
        (state) => state.entities.directusService.claimQueueActionStatusDetails
    );

    // USE EFFECTS
    useEffect(() => {
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (history.userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === history.userId);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(history.userId));
            } else dispatch(loadUser(history.userId));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === history.userId);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    // RENDER
    return (
        <div className={card.cardView}>
            <div className={card.banner}>
                <span>Email: {emailAddress} </span>
                <span>
                    Date: {history.date ? formatDateTime(history.date).substring(0, 10) : ' - '}
                </span>
                <span>
                    Time: {history.date ? formatDateTime(history.date).substring(19, 12) : ' - '}{' '}
                </span>
                <span>
                    New Action:{' '}
                    {getNameFromId(claimQueueActionStatusDetails, history.actionStatusId)}
                </span>
            </div>

            <div className={card.content}>
                <p>
                    <span className={card.label}>
                        {' '}
                        Notes: {` ${history?.actionNotes ? history.actionNotes : ' - '}`}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default ActionHistory;

ActionHistory.propTypes = {
    history: PropTypes.object
};
