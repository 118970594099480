import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import OnFileDownloadIcon from '../../../icons/OnFileDownloadIcon';
import {
    loadClaimEventStatuses,
    loadClaimEventStatusReasons,
    loadClaimEventTypes,
    loadClaimQueueActionStatusDetails,
    loadContractDetails
} from '../../../store/directusService';
import { clearClaimQueues, searchClaimQueue } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';
import { downloadCsv } from '../../../utils/downloadCSV';
import { clearKeys, trimEntries } from '../../../utils/objectUtils';
import { stableSort } from '../../../utils/sortFunctions';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import DateRangePicker from '../../formElements/DateRangePicker';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import SearchOnEnter from '../../search/SearchOnEnter';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ActionEditor from './ActionEditor';
import StatusEditor from './StatusEditor';
import ValidationTableRow from './ValidationTableRow';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import spinner from '../../../commonStyles/loadingSpinner.module.css';
import local from '../claimStyles/validations.module.css';

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const sortOrder = 'asc';
const headCells = [
    { id: 'ptCode', numeric: false, label: 'ID', sortable: true },
    { id: 'poNumber', numeric: false, label: 'PO Number', sortable: true },
    { id: 'eventName', numeric: false, label: 'Event Type' },
    { id: 'eventDate', numeric: false, label: 'Event Date', sortable: true },
    { id: 'lastStatusUpdated', numeric: false, label: 'Last Updated', sortable: true },
    { id: 'statusName', numeric: false, label: 'Status' },
    { id: 'actionStatus', numeric: false, label: 'Action Status' },
    { id: 'edit', numeric: false, label: '' }
];

const ValidationTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [catId, setCatId] = useState('');
    const [rows, setRows] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [rowMetaData, setRowMetaData] = useState({
        order: sortOrder,
        orderBy: 'lastStatusUpdated',
        page: 0,
        rowsPerPage: 100
    });

    const [filters, setFilters] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [preparingCSVForDownload, setPreparingCSVForDownload] = useState(false);
    const [csvReadyToDownload, setCSVReadyToDownload] = useState(false);

    const [isStatusEditorOpen, setIsStatusEditorOpen] = useState(false);
    const [statusRow, setStatusRow] = useState({});
    const [selectedStatusId, setSelectedStatusId] = useState('');

    const [keys, setKeys] = useState({
        claimEventStatus: '0',
        claimEventType: '1',
        contract: '2'
    });

    const [isOpenActionModal, setIsOpenActionModal] = useState(false);
    const [formType, setFormType] = useState('create');
    const [selectedRow, setSelectedRow] = useState({});
    const [openId, setOpenId] = useState('');
    const csvHeaderFields =
        'PO Number,PT ID,Contract,Claim Status,Event Type,Event Date,Last Updated';

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const {
        claimEventStatuses,
        claimEventStatusReasons,
        claimEventTypes,
        contractDetails,
        claimQueueActionStatusDetails
    } = useSelector((state) => state.entities.directusService);
    const { claimQueues, claimQueuesMetaData, loadingClaimQueuesSearch } = useSelector(
        (state) => state.entities.participantService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearClaimQueues());
        setKeys(clearKeys(keys));
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        claimEventStatusReasons?.length < 1 && dispatch(loadClaimEventStatusReasons());
        contractDetails?.length < 1 && dispatch(loadContractDetails());
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (claimQueueActionStatusDetails?.length < 1) return;
        setCatId(
            claimQueueActionStatusDetails.find((el) => el.name === 'Corrective Action Taken')?.id
        );
    }, [claimQueueActionStatusDetails]);

    useEffect(() => {
        if (Object.keys(filters).length !== 0) {
            dispatch(
                searchClaimQueue(filters, 0, loadPageSize, rowMetaData.orderBy, rowMetaData.order)
            );
        } else dispatch(clearClaimQueues());
    }, [filters]);

    useEffect(() => {
        if (csvReadyToDownload && claimQueues.length > 0) {
            startDownload();
        }
    }, [claimQueues, csvReadyToDownload]);

    useEffect(() => {
        let updatedRows = [];
        if (claimQueues.length > 0) {
            updatedRows = claimQueues.map((el) => generateClaim(el));
        }
        setRows(updatedRows);
    }, [claimQueues]);

    useEffect(() => {
        if (csvReadyToDownload && claimQueues.length > 0) {
            startDownload();
        }
    }, [claimQueues, csvReadyToDownload]);

    useEffect(() => {
        if (successMessage === `Claim queue has been updated`) {
            dispatch(
                searchClaimQueue(filters, 0, loadPageSize, rowMetaData.orderBy, rowMetaData.order)
            );
        }
    }, [successMessage]);

    // HELPER FUNCTIONS
    const generateClaim = (el) => ({
        ...el,
        eventName: getNameFromId(claimEventTypes, el.eventTypeId),
        statusName: getNameFromId(claimEventStatuses, el.statusId),
        actionStatus: el?.actionStatusId
            ? claimQueueActionStatusDetails?.find((entry) => entry.id === el.actionStatusId)?.name
            : 'zz'
    });

    const startDownload = () => {
        setCSVReadyToDownload(false);
        const newRows = claimQueues.map((el) => generateClaim(el));
        const name = `Live_Queue_Report_${format(new Date(), `dd_MMM_yyyy_HH_mm`)}`;
        const values = newRows.map((el) => [
            el.poNumber || '',
            el.ptCode,
            getNameFromId(contractDetails, filters.contractId),
            el.statusName,
            el.eventName,
            reverseFormatDate(el.eventDate),
            reverseFormatDate(el.lastStatusUpdated)
        ]);
        downloadCsv(csvHeaderFields, values, name);
        setPreparingCSVForDownload(false);
    };

    const loadingError = () => {
        if (claimEventStatuses?.length < 1) return 'No claim event statuses';
        if (claimEventTypes?.length < 1) return 'No claim event types';
        if (contractDetails?.length < 1) return 'No contract details';
    };

    // EVENT HANDLERS
    const onFetchMissingPagesForDownload = () => {
        setPreparingCSVForDownload(true);
        claimQueues.forEach((el, i) => {
            if (el.length === 0) {
                Object.keys(filters).length !== 0 &&
                    dispatch(
                        searchClaimQueue(
                            filters,
                            i,
                            claimQueuesMetaData.size,
                            rowMetaData.orderBy,
                            rowMetaData.order
                        )
                    );
            }
        });
        setCSVReadyToDownload(true);
    };

    const onUpdateFilters = (key, value) => {
        if (value === filters[key]) return;
        if (!value) {
            setFilters(trimEntries([key], filters));
        } else setFilters((prev) => ({ ...prev, [key]: value }));
    };

    const onSearchDateRange = (searchRange) => {
        if (!searchRange) {
            if ('lastStatusUpdatedFrom' in filters && 'lastStatusUpdatedTo' in filters) {
                setFilters(trimEntries(['lastStatusUpdatedFrom', 'lastStatusUpdatedTo'], filters));
            }
        } else {
            const inDate = parse(searchRange.substring(0, 10), 'dd/MM/yyyy', new Date());
            const startDate = format(inDate, 'yyyy-MM-dd');
            const outDate = parse(searchRange.substring(13), 'dd/MM/yyyy', new Date());
            const endDate = format(outDate, 'yyyy-MM-dd');
            setFilters((prev) => ({
                ...prev,
                lastStatusUpdatedFrom: startDate,
                lastStatusUpdatedTo: endDate
            }));
        }
    };

    const onUpdateStatusIds = (chosenId) => {
        chosenId ? onUpdateFilters('statusIds', [chosenId]) : onUpdateFilters('statusIds', null);
        setSelectedStatusId(chosenId);
    };

    const onCreateAction = (row) => {
        setSelectedRow(row);
        setIsOpenActionModal(true);
        setFormType('create');
    };

    const onEditAction = (row) => {
        setSelectedRow(row);
        setIsOpenActionModal(true);
        setFormType('edit');
    };

    const onActionModalCancel = () => {
        setIsOpenActionModal(false);
        setSelectedRow({});
    };

    const onUpdate = () => {
        setIsOpenActionModal(false);
        setSelectedRow({});
    };

    const onTermChange = (searchRes) => {
        if (!searchRes && filters.code) onUpdateFilters('code', '');
        setSearchTerm(searchRes);
    };

    const onSearch = () => {
        if (!searchTerm) {
            setTotalResults(claimQueuesMetaData.totalElements);
            return;
        }
        setTotalResults(1);
        onUpdateFilters('code', searchTerm);
    };

    const onToggleDropdown = (id) => setOpenId(id);

    const onStatusChangeEditor = (isOpen) => {
        setIsStatusEditorOpen(isOpen);
    };

    const onStatusRowChange = (row) => {
        setStatusRow(row);
        setIsStatusEditorOpen(true);
    };

    // RENDER
    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <ValidationTableRow
                key={el.id}
                row={el}
                acceptedRoles={acceptedRoles}
                toggleDropdown={onToggleDropdown}
                openId={openId}
                onStatusRowChange={onStatusRowChange}
                onCreateAction={onCreateAction}
                onEditAction={onEditAction}
            />
        ));
    };

    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <div className={app.pageContainer}>
            <div className={local.headerBar}>
                <div className={app.sectionHeading}>Validation Queue</div>
                <div>
                    <label className={local.label} htmlFor="note">
                        Export to CSV
                    </label>
                    {preparingCSVForDownload ? (
                        <div className={local.loadingSpinnerWrapper}>
                            <div className={spinner.ddlLoader}>
                                <div className={spinner.loader} />
                            </div>
                        </div>
                    ) : (
                        <OnFileDownloadIcon
                            roles={loggedInUser.roles}
                            acceptedRoles={acceptedRoles}
                            active={
                                hasRole(acceptedRoles, loggedInUser.roles) &&
                                'contractId' in filters &&
                                'lastStatusUpdatedFrom' in filters &&
                                'lastStatusUpdatedTo' in filters &&
                                claimQueues?.length > 0
                            }
                            onDownload={onFetchMissingPagesForDownload}
                            align="center"
                        />
                    )}
                </div>
            </div>
            <form className={form.form} data-testid="form_start_validation_table_management">
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'eventType'}
                            key={keys.claimEventType}
                            label={'Event Type'}
                            placeholder="Select Event Type"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            menuItems={claimEventTypes || []}
                            selectedId={filters?.eventTypeId || ''}
                            selected={
                                claimEventTypes.find((el) => el.id === filters?.eventTypeId) || {}
                            }
                            onChange={(chosenId) => onUpdateFilters('eventTypeId', chosenId)}
                        />
                        <DateRangePicker
                            id="lastUpdated"
                            label="Last Updated"
                            onSearch={onSearchDateRange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'contractId'}
                            key={keys.contract}
                            label={'Contract'}
                            placeholder="Select Contract"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            menuItems={contractDetails || []}
                            selectedId={filters?.contractId || ''}
                            selected={
                                contractDetails.find((el) => el.id === filters?.contractId) || {}
                            }
                            onChange={(chosenId) => onUpdateFilters('contractId', chosenId)}
                        />
                        <SingleSelect
                            id={'statusIds'}
                            key={keys.claimEventStatus}
                            label={'Status'}
                            placeholder="Select Status"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            menuItems={claimEventStatuses || []}
                            selectedId={selectedStatusId || ''}
                            selected={
                                claimEventStatuses.find((el) => el.id === selectedStatusId) || {}
                            }
                            onChange={(chosenId) => onUpdateStatusIds(chosenId)}
                        />

                        <RadioButtons
                            id="correctiveActivityTakenOnly"
                            label="Corrective Activity Taken Only"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            value={filters.actionStatusId || false}
                            onChange={(option) =>
                                onUpdateFilters('actionStatusId', option ? !!catId : false)
                            }
                        />
                    </div>
                </div>
            </form>

            <div>
                <div className={local.claimsToolBar} data-testid="search_field">
                    <label className={form.formLabel}>Search PT ID & PO Number</label>
                    <SearchOnEnter
                        searchTerm={searchTerm}
                        placeholder="Search ID and PO..."
                        autocomplete="off"
                        search={onSearch}
                        onChange={onTermChange}
                    />
                </div>
                <StatusEditor
                    open={isStatusEditorOpen}
                    onStatusChangeEditor={onStatusChangeEditor}
                    statusRow={statusRow}
                    claimEventStatuses={claimEventStatuses}
                    claimEventStatusReasons={claimEventStatusReasons}
                />
            </div>

            {rows.length < 1 ? (
                <div>No claims found</div>
            ) : (
                <div>
                    <ResultsTable
                        defaultOrderBy={rowMetaData.orderBy}
                        defaultRowsPerPage={rowMetaData.rowsPerPage}
                        headCells={headCells}
                        loadResults={searchClaimQueue}
                        passRowMetaDataUp={setRowMetaData}
                        apiParams={filters}
                        tableRows={createRows()}
                        totalResults={totalResults}
                        metaData={claimQueuesMetaData}
                        loadingValues={loadingClaimQueuesSearch}
                        loadPageSize={loadPageSize}
                    />
                </div>
            )}

            {isOpenActionModal && (
                <ActionEditor
                    roles={loggedInUser.roles}
                    formType={formType}
                    isOpen={isOpenActionModal}
                    selectedRow={selectedRow}
                    onCancel={onActionModalCancel}
                    onUpdate={onUpdate}
                />
            )}
        </div>
    );
};

export default ValidationTableManagement;
