import { createSlice } from '@reduxjs/toolkit';

import { getUserURL } from '../api/commonHTTP';
import { DEFAULT_PAGE_LOAD_SIZE } from '../api/pagination';
import { getEmptyPaginationState } from '../utils/objectUtils';

import { apiCallBegan } from './api';

const accessToken = localStorage.getItem('accessToken');

const slice = createSlice({
    name: 'userService',
    initialState: {
        loggedInUser: {
            // List of roles this user can have. Hard-code initial values of these
            // for testing until user can log in.
            //
            // When the user can log in this will be filled with the users roles etc.
            //
            // Roles are defined in UserRoles
            roles: [], // this gets sent from the payload during login
            // Set this when there is a user logged in
            userName: '', // this gets sent from the payload during login
            firstName: '', // this gets sent from the payload during login
            lastName: '', // this gets sent from the payload during login
            currentParticipant: {
                id: '',
                name: ''
            },
            // Index to current environment.
            // eslint-disable-next-line no-undef
            environment: process.env.REACT_APP_ENVIRONMENT
        },
        loggedInUserDetails: {},
        accessToken: accessToken ? accessToken : '',
        idToken: '',
        token: '',
        allTeams: [],
        currentTeam: {},
        currentUser: {},
        errorMessage: '',
        otherTeamsForService: [],
        teams: [],
        teamsForService: [],
        userTypes: [],
        users: [],
        usersMetaData: {},
        usersById: {},
        usersByLoggedInUserServiceIds: [],
        loadingUsersByLoggedInUserServiceIds: false,
        usersByLoggedInUserServiceIdsMetaData: getEmptyPaginationState(),
        usersBySearch: [],
        usersBySearchMetaData: getEmptyPaginationState(),
        usersForComms: [],
        usersForForms: [],
        usersForPools: [],
        usersForVacancies: [],
        usersForVacanciesMetaData: {},
        loadingUsersForVacancies: false,
        usersMinimalDetails: [],
        successMessage: ''
    },
    reducers: {
        loginSuccess: (state, action) => {
            const { accessToken, refreshToken, ...rest } = action.payload;
            state.errorMessage = '';
            state.accessToken = accessToken;
            state.refreshToken = refreshToken;
            state.loggedInUser = { ...state.loggedInUser, ...rest };
        },

        logoutSuccess: (state) => {
            state.accessToken = '';
            state.refreshToken = '';
            // eslint-disable-next-line no-undef
            state.loggedInUser = { environment: process.env.REACT_APP_ENVIRONMENT };
        },

        allTeamsLoaded: (state, action) => {
            state.allTeams = action.payload.content;
        },

        teamsLoaded: (state, action) => {
            state.teams = action.payload;
        },

        clearOtherTeamsForService: (state) => {
            state.otherTeamsForService = [];
        },

        clearTeamsForService: (state) => {
            state.teamsForService = [];
        },

        clearUsersMinimalDetails: (state) => {
            state.usersMinimalDetails = [];
        },

        clearUsersBySearch: (state) => {
            state.usersBySearch = [];
            state.usersBySearchMetaData = getEmptyPaginationState();
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload;
        },

        otherTeamsForServiceLoaded: (state, action) => {
            state.otherTeamsForService = action.payload;
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        },

        teamCreated: (state, action) => {
            state.currentTeam = action.payload;
        },

        teamsForServiceLoaded: (state, action) => {
            state.teamsForService = action.payload;
        },

        userCreated: (state, action) => {
            state.currentUser = action.payload;
        },

        userUpdated: (state, action) => {
            const filteredUsers = state.users.filter((el) => el.id !== action.payload.id);
            state.users = [...filteredUsers, action.payload];
        },

        userLoaded: (state, action) => {
            state.currentUser = action.payload;
            const users = state.users.filter((el) => el.id !== action.payload.id);
            state.users = [...users, action.payload];
        },

        userByIdLoaded: (state, action) => {
            state.usersById[action.payload.id] = action.payload;
        },

        userForFormsLoaded: (state, action) => {
            const usersForForms = state.usersForForms.filter((el) => el.id !== action.payload.id);
            state.usersForForms = [...usersForForms, action.payload];
        },

        loggedInUserLoaded: (state, action) => {
            state.loggedInUserDetails = action.payload;
        },

        usersByLoggedInUserServiceIdsLoading: (state) => {
            state.loadingUsersByLoggedInUserServiceIds = true;
        },

        usersByLoggedInUserServiceIdsLoaded: (state, action) => {
            state.loadingUsersByLoggedInUserServiceIds = false;
            const { content, ...rest } = action.payload;
            if (rest.first) {
                state.usersByLoggedInUserServiceIds = content;
                state.usersForComms = content;
                state.usersForForms = content;
                state.usersForPools = content;
                state.usersForVacancies = content;
            } else {
                state.usersByLoggedInUserServiceIds = [
                    ...state.usersByLoggedInUserServiceIds,
                    ...content
                ];
                state.usersForComms = [...state.usersForComms, ...content];
                state.usersForForms = [...state.usersForForms, ...content];
                state.usersForPools = [...state.usersForPools, ...content];
                state.usersForVacancies = [...state.usersForVacancies, ...content];
            }
            state.usersByLoggedInUserServiceIdsMetaData = rest;
        },

        usersBySearchLoaded: (state, action) => {
            const { content, ...rest } = action.payload;
            state.usersBySearch = rest.first ? content : [...state.usersBySearch, ...content];
            state.usersBySearchMetaData = rest;
        },

        usersLoaded: (state, action) => {
            const { content, ...rest } = action.payload;
            state.users = rest.first ? content : [...state.users, ...content];
            state.usersMetaData = rest;
        },

        usersMinimalDetailsLoaded: (state, action) => {
            state.usersMinimalDetails = action.payload;
        },

        usersForCommsLoaded: (state, action) => {
            state.usersForComms =
                'content' in action.payload ? action.payload.content : action.payload;
        },

        usersForFormsLoaded: (state, action) => {
            state.usersForForms =
                'content' in action.payload ? action.payload.content : action.payload;
        },

        usersForVacanciesLoading: (state) => {
            state.loadingUsersForVacancies = true;
        },

        usersForVacanciesLoaded: (state, action) => {
            state.loadingUsersForVacancies = false;
            const { content, ...rest } = action.payload;
            // There will already be entries from the loggedInUsers ServiceIds
            state.usersForVacancies = [...state.usersForVacancies, ...content];
            state.usersForVacanciesMetaData = rest;
        },

        userTypesLoaded: (state, action) => {
            state.userTypes = action.payload;
        },

        userRolesSet: (state, action) => {
            state.loggedInUser.roles = action.payload;
        },

        idTokenSet: (state, action) => {
            state.idToken = action.payload;
        }
    }
});

// ActionCreators. These are commands for things which are about to happen
// so 'loadTeams' (command) rather than 'teamsLoaded' (event that has happened)

export const login = (data) =>
    apiCallBegan({
        // eslint-disable-next-line no-undef
        url: process.env.REACT_APP_USER_SERVICE_URL + '/rest/login',
        method: 'post',
        data,
        onSuccess: loginSuccess.type,
        onError: errorOccurred.type
    });

export const loadUserAttributes = () =>
    apiCallBegan({
        // eslint-disable-next-line no-undef
        url: process.env.REACT_APP_USER_SERVICE_URL + '/rest/user-attributes',
        onSuccess: loginSuccess.type,
        onError: errorOccurred.type
    });

export const logOut = (data) =>
    apiCallBegan({
        // eslint-disable-next-line no-undef
        url: process.env.REACT_APP_USER_SERVICE_URL + '/rest/logOff',
        method: 'post',
        data,
        onSuccess: logoutSuccess().type,
        onError: errorOccurred.type
    });

export const createTeam = (name, data) =>
    apiCallBegan({
        url: getUserURL() + 'rest/team',
        method: 'post',
        data,
        onSuccess: teamCreated.type,
        successMessage: `Created team ${name}`,
        onError: errorOccurred.type
    });

export const createUser = (data) =>
    apiCallBegan({
        url: getUserURL() + 'rest/user',
        method: 'post',
        data,
        onSuccess: userCreated.type,
        successMessage: `Created user for email address ${data.emailAddress}`,
        onError: errorOccurred.type
    });

export const updateUser = (data) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/${data.id}`,
        method: 'put',
        data,
        onSuccess: userUpdated.type,
        successMessage: `Updated user ${data.emailAddress}`,
        onError: errorOccurred.type
    });

export const loadUser = (userId) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/${userId}`,
        onSuccess: userLoaded.type,
        onError: errorOccurred.type
    });

export const loadUserById = (userId) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/${userId}`,
        onSuccess: userByIdLoaded.type,
        onError: errorOccurred.type
    });

export const loadLoggedInUser = (userId) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/${userId}`,
        onSuccess: loggedInUserLoaded.type,
        onError: errorOccurred.type
    });

export const loadUsersForComms = (serviceIds) =>
    apiCallBegan({
        url: getUserURL() + 'rest/user/search',
        method: 'post',
        data: { serviceIds },
        onSuccess: usersForCommsLoaded.type,
        successMessage: `Users loaded`,
        onError: errorOccurred.type
    });

export const loadUserForForms = (userId) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/${userId}`,
        onSuccess: userForFormsLoaded.type,
        successMessage: `Users for forms loaded`,
        onError: errorOccurred.type
    });

export const loadUsersForForms = (serviceIds) =>
    apiCallBegan({
        url: getUserURL() + 'rest/user/search',
        method: 'post',
        data: { serviceIds },
        onSuccess: usersForFormsLoaded.type,
        successMessage: `Users for forms loaded`,
        onError: errorOccurred.type
    });

export const searchUsersByLoggedInUserServiceIds = (
    serviceIds,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE
) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/search?page=${page}&size=${size}`,
        method: 'post',
        data: { serviceIds },
        onStart: usersByLoggedInUserServiceIdsLoading.type,
        onSuccess: usersByLoggedInUserServiceIdsLoaded.type,
        successMessage: `Users loaded`,
        onError: errorOccurred.type
    });

export const searchUsersForVacancies = (data, page = 0, size = DEFAULT_PAGE_LOAD_SIZE) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/search?page=${page}&size=${size}`,
        method: 'post',
        data,
        onStart: usersForVacanciesLoading.type,
        onSuccess: usersForVacanciesLoaded.type,
        successMessage: `Users for vacancies loaded`,
        onError: errorOccurred.type
    });

export const loadUsersByServiceIds = (serviceIds) =>
    apiCallBegan({
        url: getUserURL() + 'rest/user/search',
        method: 'post',
        data: { serviceIds },
        onSuccess: usersLoaded.type,
        successMessage: `Users loaded`,
        onError: errorOccurred.type
    });

export const loadUsersByUserIds = (userIds, page = 0, size = DEFAULT_PAGE_LOAD_SIZE) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/search?page=${page}&size=${size}`,
        method: 'post',
        data: { userIds },
        onSuccess: usersLoaded.type,
        successMessage: `Users loaded`,
        onError: errorOccurred.type
    });

export const loadUsersBySearch = (data, page = 0, size = DEFAULT_PAGE_LOAD_SIZE) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/search?page=${page}&size=${size}`,
        method: 'post',
        data,
        onSuccess: usersBySearchLoaded.type,
        successMessage: `Users loaded`,
        onError: errorOccurred.type
    });

export const loadUsersBySimpleSearch = (term, page = 0, size = DEFAULT_PAGE_LOAD_SIZE) =>
    apiCallBegan({
        url: getUserURL() + `rest/user/simple-search/${term}?page=${page}&size=${size}`,
        method: 'get',
        onSuccess: usersLoaded.type,
        successMessage: `Users loaded`,
        onError: errorOccurred.type
    });

export const loadUsersMinimalDetails = (userIds) =>
    apiCallBegan({
        url: getUserURL() + 'rest/user/minimalDetails',
        method: 'post',
        data: userIds,
        onSuccess: usersMinimalDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadUserTypes = () =>
    apiCallBegan({
        url: getUserURL() + 'rest/user/types',
        onSuccess: userTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadAllTeams = () =>
    apiCallBegan({
        url: getUserURL() + 'rest/team',
        onSuccess: allTeamsLoaded.type,
        onError: errorOccurred.type
    });

export const searchTeams = (data) =>
    apiCallBegan({
        url: getUserURL() + 'rest/team/search',
        data,
        method: 'post',
        onSuccess: teamsLoaded.type,
        onError: errorOccurred.type
    });

export const loadTeamsForService = (data) =>
    apiCallBegan({
        url: getUserURL() + 'rest/team/search',
        data,
        method: 'post',
        onSuccess: teamsForServiceLoaded.type,
        onError: errorOccurred.type
    });

export const loadOtherTeamsForService = (data) =>
    apiCallBegan({
        url: getUserURL() + 'rest/team/search',
        data,
        method: 'post',
        onSuccess: otherTeamsForServiceLoaded.type,
        onError: errorOccurred.type
    });

export const saveAccessToken = (accessToken) => {
    localStorage.setItem('accessToken', accessToken);
};

export const setIdToken = (accessToken) => idTokenSet(accessToken);

export const getAccessToken = () => {
    return localStorage.getItem('accessToken');
};
export const clearLocalStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('currentParticipantId');
};

export const {
    clearTeamsForService,
    clearUsersMinimalDetails,
    clearUsersBySearch,
    clearOtherTeamsForService,
    userRolesSet
} = slice.actions;

export const { logoutSuccess } = slice.actions;

const {
    errorOccurred,
    otherTeamsForServiceLoaded,
    idTokenSet,
    allTeamsLoaded,
    teamsLoaded,
    teamsForServiceLoaded,
    loginSuccess,
    loggedInUserLoaded,
    teamCreated,
    userCreated,
    userUpdated,
    userLoaded,
    userByIdLoaded,
    usersByLoggedInUserServiceIdsLoaded,
    usersByLoggedInUserServiceIdsLoading,
    usersBySearchLoaded,
    userForFormsLoaded,
    usersLoaded,
    usersMinimalDetailsLoaded,
    usersForCommsLoaded,
    usersForFormsLoaded,
    usersForVacanciesLoading,
    usersForVacanciesLoaded,
    userTypesLoaded
} = slice.actions;
export default slice.reducer;
