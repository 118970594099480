import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogTitle } from '@mui/material';

import {
    loadParticipantByPtCode,
    searchClaimEvents,
    updateClaimQueue
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { getNameFromId } from '../../../utils/directusFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';
import TextAreaField from '../../formElements/TextAreaField';

import { initialErrorState, NOTE_CHAR_LIMIT, validate } from './validateStatusEditor';

import form from '../../../commonStyles/formStyles.module.css';
import local from '../claimStyles/validationModal.module.css';

const StatusEditor = ({
    open,
    onStatusChangeEditor,
    statusRow,
    claimEventStatuses,
    claimEventStatusReasons
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const initialState = {
        id: null,
        statusId: null,
        reasonId: null,
        notes: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [claimEventStatusesTrimmed, setClaimEventStatusesTrimmed] = useState([]);
    const [keys, setKeys] = useState({
        statusId: '0',
        reasonId: '1'
    });

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { claimEvent, currentParticipant } = useSelector(
        (state) => state.entities.participantService
    );

    // USE EFFECTS
    useEffect(() => {
        if ('ptCode' in statusRow) dispatch(loadParticipantByPtCode(statusRow.ptCode));
        setKeys(clearKeys(keys));
    }, [statusRow]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length > 0) {
            dispatch(
                searchClaimEvents({
                    eligibilityGroupId: currentParticipant.eligibilityGroupId,
                    claimTypeId: statusRow.eventTypeId
                })
            );
        }
    }, [currentParticipant]);

    useEffect(() => {
        if (Object.keys(claimEvent)?.length > 0) {
            const eventStatuses = claimEvent.claimTypeStatuses.find(
                (entry) => entry.statusId === statusRow.statusId
            );
            if (eventStatuses) {
                const eventToStatusIds = eventStatuses.claimTypeToStatusIds;
                setClaimEventStatusesTrimmed(
                    claimEventStatuses.filter((el) => eventToStatusIds.some((v) => v === el.id))
                );
            }
        }
    }, [claimEvent]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    // HELPER FNS
    const clearData = () => {
        setNewEntry(initialState);
        setErrors(initialErrorState);
        setKeys(clearKeys(keys));
        onStatusChangeEditor(false);
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onInputUpdate = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onStatusUpdate = (value) => {
        clearError('statusId');
        'reasonId' in errors && clearError('reasonId');
        setNewEntry((prev) => ({ ...prev, statusId: value }));
    };

    const onCancel = () => clearData();

    const onSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(newEntry, claimEventStatuses);
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;
        const payload = { ...newEntry, id: statusRow.id };
        dispatch(updateClaimQueue(payload));
        clearData();
    };

    // RENDER
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={onCancel}
                aria-labelledby="form-dialog-title"
                fullWidth={true}>
                <DialogTitle id="form-dialog-title">Update Status</DialogTitle>
                <div className={local.modalContent}>
                    <form
                        onSubmit={onSubmit}
                        className={form.formWrapper}
                        data-testid="update_status_form_start">
                        <SingleSelect
                            id={'statusId'}
                            key={keys.statusId}
                            label={'Status'}
                            placeholder="Select Status"
                            mandatory={true}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            menuItems={claimEventStatusesTrimmed || []}
                            selectedId={newEntry.statusId || ''}
                            selected={
                                claimEventStatusesTrimmed.find(
                                    (el) => el.id === newEntry?.statusId
                                ) || {}
                            }
                            error={errors.statusId}
                            onChange={(chosenId) => onStatusUpdate(chosenId)}
                        />
                        <SingleSelect
                            id={'reasonId'}
                            key={keys.reasonId}
                            label={'Reason'}
                            placeholder="Select Reason"
                            mandatory={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                getNameFromId(
                                    claimEventStatuses,
                                    newEntry.statusId
                                ).toLowerCase() === 'fail'
                            }
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                getNameFromId(
                                    claimEventStatuses,
                                    newEntry.statusId
                                ).toLowerCase() !== 'fail'
                            }
                            menuItems={claimEventStatusReasons || []}
                            selectedId={newEntry.reasonId || ''}
                            selected={
                                claimEventStatusReasons.find(
                                    (el) => el.id === newEntry?.reasonId
                                ) || {}
                            }
                            error={errors.reasonId}
                            onChange={(chosenId) => onInputUpdate('reasonId', chosenId)}
                        />
                        <TextAreaField
                            id="notes"
                            label="Notes"
                            placeholder={'Reversion notes'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            maxLength={NOTE_CHAR_LIMIT}
                            rows={5}
                            value={newEntry.notes || ''}
                            count={`${newEntry.notes.length}/${NOTE_CHAR_LIMIT}`}
                            error={errors.notes}
                            onChange={(e) => onInputUpdate('notes', e.target.value)}
                        />

                        <div className={local.actionButtons}>
                            <Button
                                id="statusEditorUpdateButton"
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                content="Update"
                            />
                            <Button
                                id="statusEditorCancelButton"
                                type="button"
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                content="Cancel"
                                onClick={onCancel}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

StatusEditor.propTypes = {
    open: PropTypes.bool.isRequired,
    onStatusChangeEditor: PropTypes.func,
    statusRow: PropTypes.object,
    claimEventStatuses: PropTypes.array,
    claimEventStatusReasons: PropTypes.array
};

export default StatusEditor;
