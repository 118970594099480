import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import {
    createAppointment,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../store/calendarService';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadLocationsForServices,
    loadServiceDetailsByService
} from '../../store/directusService';
import { selectCurrentParticipantsName } from '../../store/participantSelectors';
import {
    createAppointmentAttendanceDetail,
    loadParticipantEmployability
} from '../../store/participantService';
import { clearUsersBySearch, loadUsersBySearch } from '../../store/userService';
import { SAVE } from '../../utils/uiConstants';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import DateSelect from '../formElements/DateSelect';
import SingleSelect from '../formElements/SingleSelect';
import AppointmentLocationSelect from '../scheduling/components/AppointmentLocationSelect';
import AppointmentTitleSelect from '../scheduling/components/AppointmentTitleSelect';
import MandatoryActivitiesSwitch from '../scheduling/components/MandatoryActivitiesSwitch';
import StartAppointmentButton from '../scheduling/components/StartAppointmentButton';
import StaticParticipant from '../scheduling/components/StaticParticipant';
import TimeSelect from '../scheduling/components/TimeSelect';
import { updateLocations, validateUpdates } from '../scheduling/utils/appointmentSchedulerUtils';
import Panel from '../ui/panel/Panel';

import '../scheduling/styles/appointmentSchedulerPanel.css';

const ParticipantAppointmentCreate = ({ newAppointment, currentCalendarOwner }) => {
    const dispatch = useDispatch();

    // LOCAL STATE

    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        mandatory: false,
        appointmentType: '',
        service: '',
        location: '',
        participant: ''
    };

    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [editable, setEditable] = useState(false);
    const [advisers, setAdvisers] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [newEntry, setNewEntry] = useState(initialState);
    const [allowParticipantMandatoryActivities, setAllowParticipantMandatoryActivities] =
        useState(false);
    const [showStartAppointment, setShowStartAppointment] = useState(false);
    const [disableStartAppointmentButton, setDisableStartAppointmentButton] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    // STORE STATE
    const {
        usersBySearch: users,
        usersBySearchMetaData,
        loggedInUser: { roles }
    } = useSelector((state) => state.entities.userService);
    const { currentParticipant, currentParticipantEmployability } = useSelector(
        (state) => state.entities.participantService
    );
    const currentParticipantsName = useSelector(selectCurrentParticipantsName);
    const {
        locationsForServices,
        serviceDetails: services,
        appointmentTypeDetailsForContracts: appointmentTypes
    } = useSelector((state) => state.entities.directusService);

    const { openAppointmentSchedulerPanel, newAppointment: newParticipantAppointment } =
        useSelector((state) => state.entities.calendarService);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // FNS

    const clearForm = () => {
        setNewEntry(initialState);
    };

    // USE EFFECTS

    useEffect(() => {
        dispatch(clearUsersBySearch());
        if (!currentCalendarOwner || Object.keys(currentCalendarOwner).length < 1) return;

        const { serviceId } = currentParticipant;
        if (!serviceId) return;

        if (appointmentTypes?.length < 1)
            dispatch(loadAppointmentTypeDetailsByContractIds([currentParticipant.contractId]));
        if (!services?.find((el) => el.id === serviceId))
            dispatch(loadServiceDetailsByService([serviceId]));
        if (locationsForServices?.length < 1) dispatch(loadLocationsForServices([serviceId]));
        dispatch(loadParticipantEmployability(currentParticipant.id));

        const canEdit = hasRole(acceptedRoles, roles);
        setEditable(canEdit);
    }, []);

    useEffect(() => {
        if (
            appointmentTypes?.length < 1 ||
            services?.length < 1 ||
            locationsForServices?.length < 1
        )
            return;
        const { serviceId } = currentParticipant;
        const currentServiceName = services?.find((el) => el.id === serviceId)?.name || '';
        const updatedLocations = updateLocations(locationsForServices, serviceId);
        setFilteredLocations(updatedLocations);

        setNewEntry({
            ...newEntry,
            serviceId,
            userId: currentCalendarOwner.id,
            startTime: newAppointment.startTime,
            endTime: newAppointment.endTime,
            date: newAppointment.date,
            service: currentServiceName,
            participant: currentParticipantsName,
            participantId: currentParticipant.id
        });
    }, [appointmentTypes, locationsForServices, services]);

    useEffect(() => {
        if (Object.keys(currentParticipantEmployability).length > 1) {
            setAllowParticipantMandatoryActivities(
                currentParticipantEmployability?.allowMandatoryActivities
            );
        }
    }, [currentParticipantEmployability]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter(
            (el) =>
                el.active && el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < usersBySearchMetaData.totalElements) {
            onLoadMoreAdvisers();
            return;
        }
        if (!updatedAdvisers.some((el) => el.id === currentCalendarOwner.id)) {
            // Put current calendar owner at the top of dropdown if it's not in the updated advisers array
            updatedAdvisers = [currentCalendarOwner, ...updatedAdvisers];
        }
        setAdvisers(addFullNameToArray(updatedAdvisers));
    }, [users]);

    useEffect(() => {
        if (
            successMessage === `Appointment ${newEntry.appointmentType} has been created` ||
            successMessage === `Appointment slot ${newEntry.appointmentType} has been created`
        ) {
            setIsSaveButtonDisabled(false);
            if (newEntry.participantId && !newEntry.appointmentSlots) {
                setShowStartAppointment(true);
                setDisableStartAppointmentButton(false);
                if (newParticipantAppointment?.id) {
                    const payload = {
                        participantId: newParticipantAppointment.participantId,
                        calendarServiceAppointmentId: newParticipantAppointment.id
                    };
                    dispatch(createAppointmentAttendanceDetail(payload));
                }
            } else onPanelClose();
        }
    }, [successMessage, newParticipantAppointment]);

    // HELPER FNS
    const onPanelClose = () => {
        clearForm();
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
    };

    const onUpdateAdviser = (chosenId) => {
        if (!chosenId) return;
        setNewEntry((prev) => ({
            ...prev,
            userId: chosenId
        }));
        setDisableStartAppointmentButton(true);
    };

    const onUpdate = (update) => {
        setNewEntry((prev) => ({ ...prev, ...update }));
        setDisableStartAppointmentButton(true);
    };

    const onInputUpdate = (key, value) => {
        setNewEntry((prev) => ({ ...prev, [key]: value }));
        setDisableStartAppointmentButton(true);
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const valid = validateUpdates(newEntry, dispatch);
        if (!valid) return;
        setIsSaveButtonDisabled(true);
        if (valid) {
            const {
                /* eslint-disable no-unused-vars */
                id,
                appointmentType,
                service,
                location,
                participant,
                ...rest
            } = newEntry;
            dispatch(createAppointment(newEntry.appointmentType, rest));
        }
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < usersBySearchMetaData.totalElements) {
            dispatch(
                loadUsersBySearch(
                    { serviceIds: [currentParticipant.serviceId] },
                    !users.length ? 0 : usersBySearchMetaData.number + 1
                )
            );
        }
    };

    // RENDER

    return (
        <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
            <div className="appointment-scheduler">
                <form onSubmit={onSubmit}>
                    <AppointmentTitleSelect
                        appointmentTypes={appointmentTypes}
                        appointmentType={newEntry.appointmentType}
                        onTitleChange={onUpdate}
                    />

                    <div className="input-set">
                        <label htmlFor="service">Service</label>
                        <div className="static">{newEntry.service}</div>
                    </div>

                    <div className="input-set">
                        <SingleSelect
                            id={'adviserId'}
                            label={'Adviser'}
                            placeholder="Select Adviser..."
                            disabled={!newEntry.service || !editable}
                            menuItems={advisers}
                            selectedId={newEntry.userId || ''}
                            selected={
                                newEntry.userId
                                    ? advisers?.find((el) => el.id === newEntry.userId) || {}
                                    : {}
                            }
                            onChange={onUpdateAdviser}
                            onLoadMoreItems={onLoadMoreAdvisers}
                            moreItemsToLoad={
                                !usersBySearchMetaData?.totalElements ||
                                users.length < usersBySearchMetaData?.totalElements
                            }
                        />
                    </div>

                    <AppointmentLocationSelect
                        locations={filteredLocations}
                        locationName={newEntry.location}
                        onLocationChange={onUpdate}
                        disabled={!newEntry.service}
                    />

                    <TimeSelect
                        startTime={newEntry.startTime}
                        endTime={newEntry.endTime}
                        onTimeChange={onUpdate}
                    />
                    <DateSelect
                        value={newEntry.date}
                        onDateChange={(date) => onInputUpdate('date', date)}
                    />

                    <div className="last-group">
                        <>
                            <StaticParticipant
                                participant={currentParticipant}
                                onNav={onPanelClose}
                            />

                            <MandatoryActivitiesSwitch
                                checked={newEntry.mandatory}
                                onSwitch={onUpdate}
                                disabled={
                                    !allowParticipantMandatoryActivities && !newEntry.mandatory
                                }
                            />
                        </>

                        <div className="appointmentSaveBtn">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={isSaveButtonDisabled}
                                data-testid="testIdSubmitButton">
                                {SAVE}
                            </Button>
                        </div>
                        <StartAppointmentButton
                            show={showStartAppointment}
                            onClick={onPanelClose}
                            disabled={!editable || disableStartAppointmentButton}
                            appointment={{
                                ...newParticipantAppointment,
                                appointmentType: newEntry.appointmentType
                            }}
                            participant={currentParticipant}
                        />
                    </div>
                </form>
            </div>
        </Panel>
    );
};

export default ParticipantAppointmentCreate;

ParticipantAppointmentCreate.propTypes = {
    newAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object
};
