import { createSelector } from '@reduxjs/toolkit';

const selectParticipantsEntries = (state) => state.entities.participantService.participants;

const selectParticipantsSearchEntries = (state) =>
    state.entities.participantService.participantsSearch;

const selectFormEntry = (state) => state.entities.participantService.currentForm;

const selectFormContractsEntries = (state) => state.entities.participantService.formContracts;

const selectFormsParticipantEntries = (state) => state.entities.participantService.formsParticipant;

const selectFormsEntries = (state) => state.entities.participantService.forms;

const selectQuestionnairesEntries = (state) => state.entities.participantService.questionnaires;

const selectQuestionnaireContractsEntries = (state) =>
    state.entities.participantService.questionnaireContracts;

const selectQuestionnaireEntry = (state) => state.entities.participantService.currentQuestionnaire;

const selectQuestionnaireAnswersEntries = (state) =>
    state.entities.participantService.questionnaireAnswers;

const selectRecentParticipantsEntries = (state) =>
    state.entities.participantService.recentParticipants;
const selectCurrentParticipantEntry = (state) =>
    state.entities.participantService.currentParticipant;

const selectParticipantActionPlanEntries = (state) =>
    state.entities.participantService.participantActionPlans;

const selectParticipantAssetRequestEntries = (state) =>
    state.entities.participantService.participantAssetRequests;

const selectParticipantDocumentErrorEntries = (state) =>
    state.entities.documentService.participantDocumentErrors;

const selectCurrentParticipantEmployabilityEntry = (state) =>
    state.entities.participantService.currentParticipantEmployability;

const selectParticipantFinancialRequestEntries = (state) =>
    state.entities.participantService.participantFinancialRequests;

const selectCurrentParticipantBarrierEntries = (state) =>
    state.entities.participantService.currentParticipantBarriers;

const selectReferralHistory = (state) => state.entities.participantService.referralHistory;

export const selectFormContracts = createSelector(
    selectFormContractsEntries,
    (formContracts) => formContracts
);

export const selectCurrentForm = createSelector(selectFormEntry, (currentForm) => currentForm);

export const selectForms = createSelector(selectFormsEntries, (forms) => forms);

export const selectFormsParticipant = createSelector(
    selectFormsParticipantEntries,
    (formsParticipant) => formsParticipant
);

export const selectQuestionnaireContracts = createSelector(
    selectQuestionnaireContractsEntries,
    (questionnaireContracts) => questionnaireContracts
);

export const selectCurrentQuestionnaire = createSelector(
    selectQuestionnaireEntry,
    (currentQuestionnaire) => currentQuestionnaire
);

export const selectQuestionnaires = createSelector(
    selectQuestionnairesEntries,
    (questionnaires) => questionnaires
);

export const selectQuestionnaireAnswers = createSelector(
    selectQuestionnaireAnswersEntries,
    (questionnaireAnswers) => questionnaireAnswers
);

const selectSmokingQuitAttemptsEntries = (state) =>
    state.entities.participantService.smokingQuitAttempts;

export const selectCurrentParticipant = createSelector(
    selectCurrentParticipantEntry,
    (currentParticipant) => currentParticipant
);

export const selectParticipants = createSelector(
    selectParticipantsEntries,
    (participants) => participants
);

export const selectRecentParticipants = createSelector(
    selectRecentParticipantsEntries,
    (recentParticipants) => recentParticipants
);

export const selectCurrentParticipantsName = createSelector(
    selectCurrentParticipant,
    (currentParticipant) => `${currentParticipant.firstName} ${currentParticipant.lastName}`
);

export const selectParticipantActionPlans = createSelector(
    selectParticipantActionPlanEntries,
    (participantActionPlans) => participantActionPlans
);

export const selectParticipantAssetRequests = createSelector(
    selectParticipantAssetRequestEntries,
    (participantAssetRequests) => participantAssetRequests
);

export const selectParticipantDocumentErrors = createSelector(
    selectParticipantDocumentErrorEntries,
    (participantDocumentErrors) => participantDocumentErrors
);

export const selectCurrentParticipantEmployability = createSelector(
    selectCurrentParticipantEmployabilityEntry,
    (currentParticipantEmployability) => currentParticipantEmployability
);

export const selectParticipantFinancialRequests = createSelector(
    selectParticipantFinancialRequestEntries,
    (participantFinancialRequests) => participantFinancialRequests
);

export const selectCurrentParticipantBarriers = createSelector(
    selectCurrentParticipantBarrierEntries,
    (currentParticipantBarriers) => currentParticipantBarriers
);

export const selectParticipantsSearchResults = createSelector(
    selectParticipantsSearchEntries,
    (participantsSearch) => participantsSearch
);

export const selectIsSecondNatureReferralAttemptSuccessful = createSelector(
    selectReferralHistory,
    (referralHistory) => referralHistory.some((el) => el.responseCode === 201)
);

export const selectSmokingQuitAttempts = createSelector(
    selectSmokingQuitAttemptsEntries,
    (smokingQuitAttempts) => {
        const entries = smokingQuitAttempts.map((el) => ({
            ...el,
            parsedDate: Date.parse(el.quitDate)
        }));
        return entries.sort((a, b) => b.parsedDate - a.parsedDate);
    }
);
