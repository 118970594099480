export const stableSort = (rows, orderBy, order) => {
    if (rows.length < 1) return [];

    const onSort =
        order === 'desc'
            ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
            : (a, b) => -(b[orderBy] > a[orderBy] ? 1 : -1);

    const stabilizedThis = rows.map((el, i) => [el, i]);
    stabilizedThis.sort((a, b) => {
        const order = onSort(a[0], b[0]);
        return order !== 0 ? order : a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};
