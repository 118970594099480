import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectUsers } from '../../../store/userSelectors';
import { loadUser } from '../../../store/userService';
import { formatDateTime } from '../../../utils/dateFunctions';
import { getNameFromId } from '../../../utils/directusFunctions';

import card from '../claimStyles/statusActionHistory.module.css';

const StatusHistory = ({ history }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const users = useSelector(selectUsers);
    const { claimEventStatuses, claimEventStatusReasons } = useSelector(
        (state) => state.entities.directusService
    );

    // USE EFFECTS
    useEffect(() => {
        if (history.userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === history.userId);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(history.userId));
            } else dispatch(loadUser(history.userId));
        }
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === history.userId);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    // RENDER
    return (
        <div className={card.cardView}>
            <div className={card.banner}>
                <span>Email: {emailAddress} </span>
                <span>Date: {formatDateTime(history.date).substring(0, 10) || ' - '}</span>
                <span>Time: {formatDateTime(history.date).substring(19, 12) || ' - '} </span>
                <span>New Status: {getNameFromId(claimEventStatuses, history.statusId)}</span>
            </div>

            <div className={card.content}>
                <p>
                    <span className={card.label}>
                        Reason:{' '}
                        {` ${getNameFromId(claimEventStatusReasons, history.reasonId) || 'N/A'}`}
                    </span>
                </p>
                <p>
                    <span className={card.label}> Notes: {` ${history.notes || ' - '}`}</span>
                </p>
            </div>
        </div>
    );
};

export default StatusHistory;

StatusHistory.propTypes = {
    history: PropTypes.object
};
