import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogTitle } from '@mui/material';

import { setSuccessMessage } from '../../../store/formsState';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { hasRole, PRAP, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import TextAreaField from '../../formElements/TextAreaField';

import { NOTE_CHAR_LIMIT, validate } from './validateStatusRevertEditor';

import form from '../../../commonStyles/formStyles.module.css';
import local from '../claimStyles/validationModal.module.css';

const StatusRevertEditor = ({
    isOpen,
    onStatusRevertEditorChange,
    revertedRow,
    onStatusRevertUpdated
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const [notes, setNotes] = useState('');
    const [errors, setErrors] = useState({});

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // HELPER FNS
    const clearData = () => {
        setNotes('');
        setErrors({});
        onStatusRevertEditorChange(false);
    };

    // EVENT HANDLERS
    const onNotesChange = (e) => {
        setErrors({});
        setNotes(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(notes);
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;
        dispatch(setSuccessMessage(`Reversion notes added.`));
        onStatusRevertUpdated({ ...revertedRow, notes });
        clearData();
    };

    // RENDER
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={clearData}
                aria-labelledby="form-dialog-title"
                fullWidth={true}>
                <DialogTitle id="form-dialog-title">Claim Status Reversion</DialogTitle>

                <div className={local.modalContent}>
                    <form onSubmit={onSubmit} className={form.formWrapper}>
                        <TextAreaField
                            id="notes"
                            label="Reversion Notes"
                            placeholder={'Reversion notes'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            maxLength={NOTE_CHAR_LIMIT}
                            rows={5}
                            value={notes || ''}
                            count={`${notes.length}/${NOTE_CHAR_LIMIT}`}
                            error={errors.notes}
                            onChange={onNotesChange}
                        />

                        <div className={local.actionButtons}>
                            <Button
                                id="statusEditorUpdateButton"
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                content="Update"
                            />
                            <Button
                                id="revertStatusEditorCancelButton"
                                type="button"
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                content="Cancel"
                                onClick={clearData}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

StatusRevertEditor.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onStatusRevertEditorChange: PropTypes.func,
    revertedRow: PropTypes.object,
    onStatusRevertUpdated: PropTypes.func
};

export default StatusRevertEditor;
