import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button, Divider, Link, Stack, TextField } from '@mui/material';

import { loadJobSectorDetails, loadVacancyStageDetails } from '../../../../store/directusService';
import { setErrorMessage } from '../../../../store/formsState';
import { loadBusinessRecord, updateVacancy } from '../../../../store/recruitmentService';
import { selectLoggedInUser, selectUsersById } from '../../../../store/userSelectors';
import { loadUserById, searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { SCREEN_SETTINGS } from '../../../../themes/theme';
import { getFutureDate } from '../../../../utils/dateFunctions';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import * as validate from '../../../../validation/validation';
import SingleSelect from '../../../formElements/SingleSelect';
import IconError from '../../../IconError';
import FormHeader from '../../../layout/FormHeader';
import LabelledPostcodeText from '../../../ui/editors/LabelledPostcodeText';
import LabelledTextField from '../../../ui/editors/LabelledTextField';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import DDLOptionPicker from '../../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../../ui/pickers/NoYesRadioPicker';

import app from '../../../../app.module.css';
import form from '../../../../commonStyles/formStyles.module.css';

const SHIFT_PATTERN_LENGTH = 250;
const MAX_DESCRIPTION_LENGTH = 1000;
const MAX_REQUIREMENTS_LENGTH = 250;
const PAY_RATE_FROM_GREATER_THAN_PAY_RATE_TO =
    'Pay rate from must be less than or equal to pay rate to';
const VACANCY_CLOSING_DATE_INVALID = 'Vacancy closing date must be future date';
const APPLICANT_START_DATE_INVALID = 'Applicant start date must be future date';
const EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT = 'Expected weekly hours must be 48 or less';
// const EMPTY_STAGING_NOT_ALLOWED = 'Empty staging not allowed';
const POSITIONS_GREATER_THAN_ZERO = 'Number of positions must be 1 or more';

const EditVacancy = () => {
    const {
        clearErrors,
        handleSubmit,
        register,
        setValue,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        title: null,
        ownerId: null,
        sectorId: null,
        hiringManagerId: null,
        numberOfPositions: null,
        payRateFrom: null,
        payRateTo: null,
        shiftPatternWorkingHours: '',
        expectedWeeklyHours: null,
        vacancyClosingDate: null,
        anticipatedStartDate: null,
        remote: false,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        city: null,
        postcode: null,
        description: '',
        requirement: '',
        inactive: null,
        stageIds: []
    };
    const businessOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [businessOwners, setBusinessOwners] = useState([]);
    const [hiringManagers, setHiringManagers] = useState([]);
    const [shiftPattern, setShiftPattern] = useState('');
    const [description, setDescription] = useState('');
    const [requirements, setRequirements] = useState('');

    const [isClearSelectedOwner, setIsClearSelectedOwner] = useState('0');
    const [isClearSelectedJobSector, setIsClearSelectedJobSector] = useState('1');
    const [isClearSelectedHiringManager, setIsClearSelectedHiringManager] = useState('2');
    const [isClearSelectedVacancyStage, setIsClearSelectedVacancyStage] = useState('3');

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { jobSectorDetails, vacancyStageDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentBusiness, currentVacancy } = useSelector(
        (state) => state.entities.recruitmentService
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const usersById = useSelector(selectUsersById);
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    const onLoadMoreBusinessOwners = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    const handleVacancyTitleChange = (e) => {
        setNewEntry((prev) => ({ ...prev, title: e.target.value }));
    };

    const handleOwnerChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, ownerId: chosenId }));
    };

    const handleJobSectorChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, sectorId: chosenId }));
    };

    const handlePositionsChange = (e) => {
        setNewEntry((prev) => ({ ...prev, numberOfPositions: e.target.value }));
    };

    const handlePayRateFromChange = (e) => {
        setNewEntry((prev) => ({ ...prev, payRateFrom: e.target.value }));
    };

    const handlePayRateToChange = (e) => {
        setNewEntry((prev) => ({ ...prev, payRateTo: e.target.value }));
    };

    const handleExpectedWeeklyHoursChange = (e) => {
        setNewEntry((prev) => ({ ...prev, expectedWeeklyHours: e.target.value }));
    };

    const handleShiftPatternChange = (e) => {
        setNewEntry((prev) => ({ ...prev, shiftPatternWorkingHours: e.target.value }));
        setShiftPattern(e.target.value || '');
    };

    const handleHiringManagerChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, hiringManagerId: chosenId }));
    };

    const handleVacancyClosingDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            vacancyClosingDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleAnticipatedStartDateChange = (e) => {
        setNewEntry((prev) => ({
            ...prev,
            anticipatedStartDate: e.target.value === '' ? null : e.target.value
        }));
    };

    const handleRemoteChange = (option) => {
        setNewEntry((prev) => ({
            ...prev,
            remote: option
        }));
        if (option) {
            setValue('remoteWorking', option, {
                shouldValidate: false
            });

            clearErrors('addressLine1', 'city', 'postcode');
            setNewEntry((prev) => ({
                ...prev,
                addressLine1: null,
                addressLine2: null,
                addressLine3: null,
                city: null,
                postcode: null
            }));
        } else {
            setValue('remoteWorking', option, {
                shouldValidate: true
            });
        }
    };

    const handleAddress1Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine1: e.target.value }));
    };

    const handleAddress2Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine2: e.target.value }));
    };

    const handleAddress3Change = (e) => {
        setNewEntry((prev) => ({ ...prev, addressLine3: e.target.value }));
    };

    const handleCityChange = (e) => {
        setNewEntry((prev) => ({ ...prev, city: e.target.value }));
    };

    const handlePostcodeChange = (e) => {
        setNewEntry((prev) => ({ ...prev, postcode: e.target.value }));
    };

    const handleDescriptionChange = (e) => {
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
        setDescription(e.target.value);
    };

    const handleRequirementsChange = (e) => {
        setNewEntry((prev) => ({ ...prev, requirement: e.target.value }));
        setRequirements(e.target.value);
    };

    const getHiringManagerName = (id) => {
        if (!id || id === '') {
            return '';
        }
        return hiringManagers.find((item) => item.id === id)?.name || '';
    };

    const clearData = () => {
        setNewEntry(initialState);
        setIsClearSelectedOwner(Math.random());
        setIsClearSelectedJobSector(Math.random());
        setIsClearSelectedHiringManager(Math.random());
        setIsClearSelectedVacancyStage(Math.random());
    };

    const onNavigate = () => {
        clearData();
        navigate('/recruitment_management', {
            state: { accordionPanel: state?.accordionPanel || 'pooling' }
        });
    };

    // HELPER FNS
    const setBusinessOwnersArray = (businessOwners) => {
        const updatedBusinessOwners = addEmailAddressAsNameToArray(businessOwners);
        setBusinessOwners(updatedBusinessOwners);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadJobSectorDetails());
        dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!('id' in loggedInUser)) return;
        handleOwnerChange(loggedInUser.id);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (!currentVacancy) return;

        dispatch(loadBusinessRecord(currentVacancy.businessRecordId));
        setNewEntry(currentVacancy);
        setDescription(currentVacancy.description);
        setRequirements(currentVacancy.requirement);
        setShiftPattern(currentVacancy.shiftPatternWorkingHours);
        setNewEntry((prev) => ({
            ...prev,
            vacancyClosingDate: currentVacancy.vacancyClosingDate.substring(0, 10)
        }));
        if (currentVacancy?.anticipatedStartDate) {
            setNewEntry((prev) => ({
                ...prev,
                anticipatedStartDate: currentVacancy.anticipatedStartDate.substring(0, 10)
            }));
        }
        if (currentVacancy?.ownerId) {
            const initialBusinessOwner =
                usersById[currentVacancy.ownerId] ||
                users.find((el) => el.id === currentVacancy.ownerId);
            if (!initialBusinessOwner) {
                dispatch(loadUserById(currentVacancy.ownerId));
            } else {
                setBusinessOwnersArray([initialBusinessOwner]);
            }
        }

        // Force Yup validation on pre-populated fields
        setValue('title', currentVacancy.title, {
            shouldValidate: true
        });
        setValue('managerId', currentVacancy.hiringManagerId, {
            shouldValidate: true
        });
        setValue('sectorId', currentVacancy.sectorId, {
            shouldValidate: true
        });
        setValue('positions', currentVacancy.numberOfPositions, {
            shouldValidate: true
        });
        setValue('payRateFrom', currentVacancy.payRateFrom, {
            shouldValidate: true
        });
        setValue('payRateTo', currentVacancy.payRateTo, {
            shouldValidate: true
        });
        setValue('vacancyClosingDate', currentVacancy.vacancyClosingDate.substring(0, 10), {
            shouldValidate: true
        });
        setValue('address3', currentVacancy.address3, {
            shouldValidate: true
        });
        if (!currentVacancy.remote) {
            setValue('address1', currentVacancy.address1, {
                shouldValidate: true
            });
            setValue('city', currentVacancy.city, {
                shouldValidate: true
            });
            setValue('postcode', currentVacancy.postcode, {
                shouldValidate: true
            });
        } else {
            handleRemoteChange(currentVacancy.remote);
        }
    }, [currentVacancy]);

    useEffect(() => {
        if (!currentBusiness?.ownerId) return;

        setValue('ownerId', currentBusiness.ownerId, {
            shouldValidate: true
        });
        setHiringManagers(
            currentBusiness?.contactCards?.map((el) => ({
                id: el.id,
                name: `${el.firstName} ${el.lastName} ( ${el.officeNumber} )`
            }))
        );
    }, [currentBusiness]);

    useEffect(() => {
        if (!users.length) return;
        let updatedBusinessOwners = users.filter((el) =>
            el.userTypes?.find((entry) => businessOwnerRoles.includes(entry.role))
        );
        if (!updatedBusinessOwners.length && users.length < totalUsers) {
            onLoadMoreBusinessOwners();
            return;
        }
        if (newEntry.ownerId && !updatedBusinessOwners.some((el) => el.id === newEntry.ownerId)) {
            // Put selected business owner at the top of dropdown if it's not in the updated business owners array
            const selectedAdvisor = businessOwners.find((el) => el.id === newEntry.ownerId);
            updatedBusinessOwners = [selectedAdvisor, ...updatedBusinessOwners];
        }
        setBusinessOwnersArray(updatedBusinessOwners);
    }, [users]);

    useEffect(() => {
        if (newEntry.ownerId && usersById[newEntry.ownerId]) {
            setBusinessOwnersArray([usersById[newEntry.ownerId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (successMessage.startsWith('Vacancy has been updated')) onNavigate();
    }, [successMessage]);

    const onSubmit = () => {
        if (!newEntry.ownerId) {
            dispatch(setErrorMessage(`You must select a business record owner.`));
        } else if (newEntry.numberOfPositions && newEntry.numberOfPositions === '0') {
            dispatch(setErrorMessage(POSITIONS_GREATER_THAN_ZERO));
        } else if (parseFloat(newEntry.payRateTo) < parseFloat(newEntry.payRateFrom)) {
            dispatch(setErrorMessage(PAY_RATE_FROM_GREATER_THAN_PAY_RATE_TO));
        } else if (
            newEntry.payRateFrom === '0' ||
            newEntry.payRateFrom === '0.0' ||
            newEntry.payRateFrom === '0.00'
        ) {
            dispatch(setErrorMessage('Pay rate from cannot be zero'));
        } else if (
            newEntry.payRateTo === '0' ||
            newEntry.payRateTo === '0.0' ||
            newEntry.payRateTo === '0.00'
        ) {
            dispatch(setErrorMessage('Pay rate to cannot be zero'));
        } else if (
            newEntry.expectedWeeklyHours > '' &&
            48 < parseInt(newEntry.expectedWeeklyHours)
        ) {
            dispatch(setErrorMessage(EXPECTED_HOURS_GREATER_THAN_FORTY_EIGHT));
        } else {
            dispatch(updateVacancy(newEntry));
        }
    };

    const onCancel = () => onNavigate();

    if (jobSectorDetails?.length < 1) return <LoadingSpinner content="No job sector found" />;
    if (vacancyStageDetails?.length < 1) return <LoadingSpinner content="No vacancy stage found" />;

    return (
        <div className={form.formWrapper}>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form_start" className={form.form}>
                <FormHeader text={'Edit Vacancy'}></FormHeader>
                {/* This is needed for remote working validations */}
                <div style={{ width: '0vh', height: '0vh', overflow: 'hidden' }}>
                    <input
                        id="remoteWorking"
                        name="remoteWorking"
                        value={newEntry.remote || false}
                        {...register('remoteWorking')}></input>
                </div>
                <h2 className={app.sectionHeading}>Vacancy Overview:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Vacancy Title'}
                            id={'title'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.title || ''}
                            placeholder={'Enter vacancy title'}
                            error={errors.title}
                            {...register('title')}
                            onChange={handleVacancyTitleChange}
                        />
                        <SingleSelect
                            id={'ownerId'}
                            key={isClearSelectedOwner}
                            label={'Vacancy Owner'}
                            placeholder="Select Vacancy Owner..."
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            menuItems={businessOwners}
                            selectedId={newEntry.ownerId}
                            selected={
                                businessOwners?.find((el) => el.id === newEntry.ownerId) || {}
                            }
                            error={errors.ownerId}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleOwnerChange(e);
                                }
                            }}
                            onLoadMoreItems={onLoadMoreBusinessOwners}
                            moreItemsToLoad={!totalUsers || users.length < totalUsers}
                        />
                        <LabelledTextField
                            label={'Business Record'}
                            id={'title'}
                            disabled={true}
                            value={currentBusiness.name || ''}
                        />
                        <DDLOptionPicker
                            label={'Hiring Manager'}
                            id={'managerId'}
                            key={isClearSelectedHiringManager}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            menuItems={hiringManagers || []}
                            chosenName={
                                newEntry.hiringManagerId
                                    ? getHiringManagerName(newEntry.hiringManagerId)
                                    : ''
                            }
                            chosenId={newEntry.hiringManagerId || ''}
                            error={errors.managerId}
                            {...register('managerId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleHiringManagerChange(e);
                                }
                            }}
                        />
                        <DDLOptionPicker
                            label={'Job Sector'}
                            id={'sectorId'}
                            key={isClearSelectedJobSector}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            menuItems={jobSectorDetails}
                            chosenName={
                                newEntry.sectorId
                                    ? getNameFromId(jobSectorDetails, newEntry.sectorId)
                                    : ''
                            }
                            chosenId={newEntry.sectorId || ''}
                            error={errors.sectorId}
                            {...register('sectorId')}
                            onChange={(e) => {
                                if (e !== null && e !== undefined) {
                                    handleJobSectorChange(e);
                                }
                            }}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Number of Positions'}
                            id={'positions'}
                            type="number"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.numberOfPositions || ''}
                            placeholder={'Enter number of Positions'}
                            error={errors.positions}
                            {...register('positions')}
                            onChange={handlePositionsChange}
                        />
                        <LabelledTextField
                            label={'Description'}
                            id={'description'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            multiline
                            rows={5}
                            value={description}
                            placeholder={'Enter job description'}
                            counter={'true'}
                            helperText={
                                description?.length
                                    ? `${description.length}/${MAX_DESCRIPTION_LENGTH}`
                                    : `0/${MAX_DESCRIPTION_LENGTH}`
                            }
                            inputProps={{
                                maxLength: MAX_DESCRIPTION_LENGTH
                            }}
                            onChange={handleDescriptionChange}
                        />
                        <LabelledTextField
                            label={'Requirements'}
                            id={'requirements'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            multiline
                            rows={5}
                            value={requirements}
                            placeholder={'Enter job requirements'}
                            counter={'true'}
                            helperText={
                                requirements?.length
                                    ? `${requirements.length}/${MAX_REQUIREMENTS_LENGTH}`
                                    : `0/${MAX_REQUIREMENTS_LENGTH}`
                            }
                            inputProps={{
                                maxLength: MAX_REQUIREMENTS_LENGTH
                            }}
                            onChange={handleRequirementsChange}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Vacancy Detail:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Hourly Pay Rate - From'}
                            id={'payRateFrom'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.payRateFrom || ''}
                            placeholder={'Enter hourly pay rate from'}
                            error={errors.payRateFrom}
                            {...register('payRateFrom')}
                            onChange={handlePayRateFromChange}
                        />
                        <LabelledTextField
                            label={'Hourly Pay Rate - To'}
                            id={'payRateTo'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={true}
                            value={newEntry.payRateTo || ''}
                            placeholder={'Enter hourly pay rate to'}
                            error={errors.payRateTo}
                            {...register('payRateTo')}
                            onChange={handlePayRateToChange}
                        />
                        <TextField
                            label="Vacancy Closing Date *"
                            id="vacancyClosingDate"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            type="date"
                            value={newEntry.vacancyClosingDate || ''}
                            sx={{ width: 220, marginBottom: '50px', marginRight: '20px' }}
                            InputLabelProps={{ shrink: true }}
                            {...register('vacancyClosingDate')}
                            onChange={handleVacancyClosingDateChange}
                        />
                        <TextField
                            label="Applicant Start Date"
                            id="anticipatedStartDate"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            type="date"
                            value={newEntry.anticipatedStartDate || ''}
                            sx={{ width: 220 }}
                            InputLabelProps={{ shrink: true }}
                            {...register('anticipatedStartDate')}
                            onChange={handleAnticipatedStartDateChange}
                        />
                        <IconError
                            text={errors.vacancyClosingDate || errors.anticipatedStartDate || null}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <LabelledTextField
                            label={'Expected Weekly Hours'}
                            id={'expectedWeeklyHours'}
                            type="number"
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            size="small"
                            value={newEntry.expectedWeeklyHours || ''}
                            placeholder={'Enter maximum'}
                            error={errors.expectedWeeklyHours}
                            {...register('expectedWeeklyHours')}
                            onChange={handleExpectedWeeklyHoursChange}
                        />
                        <LabelledTextField
                            label={'Shift Patterns/Working Hours'}
                            id={'shiftPattern'}
                            disabled={
                                !hasRole(
                                    [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                    loggedInUser.roles
                                )
                            }
                            mandatory={false}
                            multiline
                            rows={5}
                            value={shiftPattern}
                            placeholder={'Enter shift patterns/working hours description'}
                            counter={'true'}
                            helperText={
                                shiftPattern?.length
                                    ? `${shiftPattern.length}/${SHIFT_PATTERN_LENGTH}`
                                    : `0/${SHIFT_PATTERN_LENGTH}`
                            }
                            inputProps={{
                                maxLength: SHIFT_PATTERN_LENGTH
                            }}
                            onChange={handleShiftPatternChange}
                        />
                    </div>
                </div>
                <br />
                <br />
                <h2 className={app.sectionHeading}>Address:</h2>
                <NoYesRadioPicker
                    disabled={
                        !hasRole(
                            [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                            loggedInUser.roles
                        )
                    }
                    text={'Remote?'}
                    radioButtonPick={newEntry.remote || false}
                    onChange={handleRemoteChange}
                />
                <div hidden={newEntry.remote}>
                    <div className={form.formSection}>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'Address Line 1'}
                                id={'address1'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                mandatory={true}
                                value={newEntry.addressLine1 || ''}
                                placeholder={'Enter address line 1'}
                                error={errors.address1}
                                {...register('address1')}
                                onChange={handleAddress1Change}
                            />
                            <LabelledTextField
                                label={'Address Line 2'}
                                id={'address2'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                value={newEntry.addressLine2 || ''}
                                placeholder={'Enter address line 2'}
                                error={errors.address2}
                                {...register('address2')}
                                onChange={handleAddress2Change}
                            />
                            <LabelledTextField
                                label={'Address Line 3'}
                                id={'address3'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                value={newEntry.addressLine3 || ''}
                                placeholder={'Enter address line 3'}
                                error={errors.address3}
                                {...register('address3')}
                                onChange={handleAddress3Change}
                            />
                        </div>
                        <div className={form.formColumn}>
                            <LabelledTextField
                                label={'City'}
                                id={'city'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                mandatory={true}
                                value={newEntry.city || ''}
                                placeholder={'Enter city'}
                                error={errors.city}
                                {...register('city')}
                                onChange={handleCityChange}
                            />
                            <LabelledPostcodeText
                                label={'Postcode'}
                                id={'postcode'}
                                disabled={
                                    !hasRole(
                                        [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                        loggedInUser.roles
                                    )
                                }
                                mandatory={true}
                                placeholder={'Enter Postcode'}
                                value={newEntry.postcode || ''}
                                name="postcode"
                                error={errors.postcode}
                                {...register('postcode')}
                                onChange={handlePostcodeChange}
                            />
                        </div>
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Application Process:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        {currentVacancy.stageIds.map((el, i) => (
                            <div key={i}>
                                <div className={form.inputSet}>
                                    <div className={form.inputSetInput}>
                                        <DDLOptionPicker
                                            label={
                                                currentVacancy.stageIds.length === i + 1
                                                    ? 'Final Stage'
                                                    : 'Stage ' + `${i + 1}`
                                            }
                                            id={'stageId'}
                                            name={'stageId'}
                                            key={isClearSelectedVacancyStage}
                                            disabled={true}
                                            chosenName={getNameFromId(vacancyStageDetails, el)}
                                            chosenId={el || ''}
                                            menuItems={vacancyStageDetails}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={form.formColumn}></div>
                </div>
                <br />
                <Divider sx={{ marginTop: '40px', marginBottom: '20px' }} />
                <Stack direction="row" spacing={4}>
                    <Button
                        disabled={
                            !hasRole(
                                [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER],
                                loggedInUser.roles
                            )
                        }
                        type="submit"
                        color="primary"
                        variant="contained"
                        data-testid="testIdSubmitButton1"
                        endIcon={<KeyboardArrowRightIcon />}>
                        {'Update Vacancy'}
                    </Button>
                    <Link
                        label="Cancel X"
                        underline="always"
                        variant="body1"
                        color="primary"
                        onClick={() => onCancel()}
                        sx={{
                            paddingTop: SCREEN_SETTINGS.margin.large,
                            cursor: 'pointer'
                        }}>
                        Cancel X
                    </Link>
                </Stack>
                <br />
                <p>{}</p>
            </form>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    title: Yup.string()
        .min(1, 'Vacancy title must be at least one character')
        .max(100, 'Vacancy title must be 100 characters or less'),
    sectorId: Yup.string().required('Please select a job sector.'),
    managerId: Yup.string().required('Please select a hiring manager.'),
    positions: Yup.string()
        .min(1, 'Number of positions must be 1 or more')
        .max(3, 'Number of positions must be 999 or less')
        .matches(validate.INTEGER_REGEXP, {
            excludeEmptyString: true,
            message: 'Please enter a valid integer'
        }),
    payRateFrom: Yup.string()
        .required('Please enter a valid From pay rate')
        .nullable()
        .min(0, 'From pay rate limit cannot be negative')
        .max(999.99, 'From pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid From pay rate'),
    payRateTo: Yup.string()
        .required('Please enter a valid To pay rate')
        .nullable()
        .min(0, 'To pay rate limit cannot be negative')
        .max(999.99, 'To pay rate limit must be 999.99 or less')
        .matches(validate.PAY_REGEXP, 'Invalid To pay rate'),
    vacancyClosingDate: Yup.string()
        .required('Please select a vacancy closing date')
        .nullable()
        .test('vacancyClosingDate', VACANCY_CLOSING_DATE_INVALID, function (vacancyClosingDate) {
            return getFutureDate(vacancyClosingDate) === true;
        }),
    anticipatedStartDate: Yup.string()
        .nullable()
        .test(
            'anticipatedStartDate',
            APPLICANT_START_DATE_INVALID,
            function (anticipatedStartDate) {
                return getFutureDate(anticipatedStartDate) === true;
            }
        ),
    expectedWeeklyHours: Yup.string()
        .max(2, 'Expected weekly hours must be 48 or less')
        .matches(validate.INTEGER_REGEXP, {
            excludeEmptyString: true,
            message: 'Please enter a valid integer'
        }),
    remoteWorking: Yup.boolean(),
    address1: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () =>
            Yup.string()
                .min(1, 'Address line 1 must be at least one character')
                .max(100, 'Address line 1 must be 100 characters or less')
    }),
    address2: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () => Yup.string().max(100, 'Address line 2 must be 100 characters or less')
    }),
    address3: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () => Yup.string().max(100, 'Address line 3 must be 100 characters or less')
    }),
    city: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () =>
            Yup.string()
                .min(1, 'City must be at least one character')
                .max(30, 'City must be 30 characters or less')
    }),
    postcode: Yup.string().when('remoteWorking', {
        is: (remoteWorking) => remoteWorking === false,
        then: () =>
            Yup.string().matches(validate.POSTCODE_REGEXP, {
                excludeEmptyString: false,
                message: 'Please enter a valid postcode'
            })
    })
});

export default EditVacancy;
