import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import {
    searchCoursesByCodeOrName,
    searchCoursesByLoggedInUser
} from '../../../store/courseService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SearchOnEnter from '../../search/SearchOnEnter';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CourseInstanceTableRow from './CourseInstanceTableRow';

import classes from '../courseStyles/courseToolbar.module.css';

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const headCells = [
    { id: 'code', numeric: false, label: 'ID', sortable: true },
    { id: 'name', numeric: false, label: 'Course Name', sortable: true },
    { id: 'warning', numeric: true, label: 'Missing Course Info' },
    { id: 'endDate', numeric: true, label: 'End Date', sortable: true },
    { id: 'startDate', numeric: true, label: 'Start Date', sortable: true },
    { id: 'edit', numeric: true, label: '' }
];

const CourseInstanceManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [internalCourses, setInternalCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [coursesLoaded, setCoursesLoaded] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [rowMetaData, setRowMetaData] = useState({
        order: 'desc',
        orderBy: 'endDate',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });
    const [openId, setOpenId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const { usersCourses, usersCoursesMetaData, usersCoursesByCodeOrName } = useSelector(
        (state) => state.entities.courseService
    );

    // USE EFFECTS
    useEffect(() => {
        if (!loggedInUser || !('id' in loggedInUser)) return;
        if (usersCourses?.length < 1)
            dispatch(searchCoursesByLoggedInUser({ userId: loggedInUser.id }));
        else setCoursesLoaded(true);
    }, [loggedInUser]);

    useEffect(() => {
        if (!coursesLoaded) return;
        const internalCourses = usersCourses
            .filter((course) => course.courseProvider === 'internal')
            .map((el) => ({
                ...el,
                startDate: !el.startDate ? '1960-01-01' : el.startDate,
                endDate: !el.endDate ? '1960-01-01' : el.endDate
            }));

        if (internalCourses.length < 1 && !usersCoursesMetaData.last)
            dispatch(
                searchCoursesByLoggedInUser(
                    { userId: loggedInUser.id },
                    usersCoursesMetaData.number + 1
                )
            );
        else setCoursesLoaded(false);

        setInternalCourses(internalCourses);
        setRows(internalCourses);
    }, [usersCourses, coursesLoaded]);

    useEffect(() => {
        setTotalResults(usersCoursesMetaData.totalElements);
    }, [usersCoursesMetaData]);

    useEffect(() => {
        if (!coursesLoaded || !searchTerm) return;
        if (usersCoursesByCodeOrName.length < 1) setRows([]);
        setCoursesLoaded(false);
    }, [usersCoursesByCodeOrName, coursesLoaded]);

    useEffect(() => {
        if (
            successMessage === 'Course records by logged in user have been loaded' ||
            successMessage === 'Course search record has been loaded' ||
            successMessage.includes(`Module details have been updated for module`)
        )
            setCoursesLoaded(true);
    }, [successMessage]);

    useEffect(() => {
        if (!searchTerm) {
            setRows(internalCourses);
            setTotalResults(usersCoursesMetaData.totalElements);
        }
    }, [searchTerm]);

    // HELPER FNS
    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <CourseInstanceTableRow
                key={el.id}
                row={el}
                roles={acceptedRoles}
                toggleDropdown={onToggleDropdown}
                openId={openId}
            />
        ));
    };

    // EVENT HANDLERS
    const onTermChange = (value) => setSearchTerm(value);

    const onSearch = () => {
        if (!searchTerm) {
            setTotalResults(usersCoursesMetaData.totalElements);
            return;
        }
        setTotalResults(1);
        if (internalCourses.length) {
            const filtered = internalCourses.filter(
                (el) =>
                    el.name.toLowerCase() === searchTerm.toLowerCase() ||
                    el.code.toLowerCase() === searchTerm.toLowerCase()
            );
            filtered.length
                ? setRows(filtered)
                : dispatch(
                      searchCoursesByCodeOrName({
                          userId: loggedInUser.id,
                          term: searchTerm
                      })
                  );
        }
    };

    const onToggleDropdown = (id) => setOpenId(id);

    const onSearchAllActiveTemplates = () => navigate('/all_active_course_templates');

    return (
        <>
            <div className={classes.coursesToolBar}>
                <SearchOnEnter
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                    search={onSearch}
                    onChange={onTermChange}
                />
                <Button
                    id="SearchAllActiveTemplatesButton"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    content="Search All Active Course Templates"
                    icon={true}
                    onClick={onSearchAllActiveTemplates}
                />
            </div>

            {rows.length < 1 ? (
                <LoadingSpinner content="No courses found" />
            ) : (
                <ResultsTable
                    defaultOrderBy={'endDate'}
                    sortOrder={'desc'}
                    headCells={headCells}
                    loadResults={searchCoursesByLoggedInUser}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={{ userId: loggedInUser.id }}
                    tableRows={createRows()}
                    totalResults={totalResults}
                    metaData={usersCoursesMetaData}
                    loadPageSize={loadPageSize}
                />
            )}
        </>
    );
};

CourseInstanceManagement.propTypes = {
    isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default CourseInstanceManagement;
