import React from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '@mui/material';

import Button from '../../../formElements/Button';

import local from './confirmPrompt.module.css';

const ConfirmPrompt = ({ isOpen, onCancel, onConfirm, content, customClass = '' }) => {
    return (
        <Dialog onClose={onCancel} open={isOpen}>
            <div className={local.confirmModal}>
                <div className={`${local.modalContent} ${customClass}`}>{content}</div>
                <div className={local.modalBtns}>
                    <div className={local.modalBtn} onClick={onCancel}>
                        <Button id="deleteModalNoButton" content="No" />
                    </div>
                    <div className={local.modalBtn} onClick={onConfirm}>
                        <Button id="deleteModalYesButton" content="Yes" />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmPrompt;

ConfirmPrompt.propTypes = {
    onCancel: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    content: PropTypes.any,
    customClass: PropTypes.string
};
