import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';

import { selectAppointmentTypes, selectServices } from '../../store/dataSelectors';
import {
    loadAppointmentTypeDetails,
    loadServiceDetailsByService
} from '../../store/directusService';
import { selectLoggedInUser, selectUserRoles } from '../../store/userSelectors';
import { loadTeamsForService, searchUsersByLoggedInUserServiceIds } from '../../store/userService';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../utils/userRoles';

import AppointmentSlotSwitch from './components/AppointmentSlotSwitch';
import SchedulerAdviserPanel from './components/SchedulerAdviserPanel';
import SchedulerSelector from './components/SchedulerSelector';
import TimeView from './components/TimeView';

import './styles/schedulingAssistant.css';
import './styles/appointmentSchedulerPanel.css';
import '../../commonStyles/pills-selection-list.css';
import classes from '../../app.module.css';

const SchedulingAssistant = () => {
    const dispatch = useDispatch();

    const allowedAdviserRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [service, setService] = useState({});
    const [teamsList, setTeamsList] = useState([]);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [isAppointmentSlots, setIsAppointmentSlots] = useState(false);
    const [advisers, setAdvisers] = useState([]);
    const [selectedAdviserIds, setSelectedAdviserIds] = useState([]);
    const [activeAdviserId, setActiveAdviserId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const roles = useSelector(selectUserRoles);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const teamsForService = useSelector((state) => state.entities.userService.teamsForService);
    const appointmentTypes = useSelector(selectAppointmentTypes);
    const services = useSelector(selectServices);

    // USE EFFECTS

    useEffect(() => {
        if (!appointmentTypes?.length) dispatch(loadAppointmentTypeDetails());
    }, []);

    useEffect(() => {
        if (!('serviceIds' in loggedInUser)) return;
        dispatch(loadServiceDetailsByService(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    useEffect(() => {
        setTeamsList(teamsForService);
    }, [teamsForService]);

    useEffect(() => {
        if (!users.length) return;
        if (users.length < totalUsers) {
            onLoadMoreAdvisers();
            return;
        }
        let advisers;
        if (selectedTeams.length) {
            const teams = selectedTeams.map((el) => el.id);
            advisers = users.filter((el) =>
                [el.primaryTeam?.id, ...el.otherTeams.map((entry) => entry.id)]
                    .filter((id) => id !== undefined)
                    .some((entry) => teams.includes(entry))
            );
        } else {
            advisers = users.filter((el) =>
                [el.primaryService, ...el.otherServices].includes(service.id)
            );
        }
        setAdvisers(addFullNameToArray(advisers));
        setSelectedAdviserIds([]);
    }, [selectedTeams, service.id, users]);

    // EVENT HANDLERS
    const onServiceUpdate = (updatedService) => {
        setService(updatedService);
        const teamPayload = {
            name: null,
            serviceIds: [updatedService.id],
            costCode: null
        };
        setTeamsList([]);
        setSelectedTeams([]);
        dispatch(loadTeamsForService(teamPayload));
    };

    const onAddTeam = (newTeam) => {
        if (!newTeam || selectedTeams.find((el) => el.id === newTeam.id)) return;
        setSelectedTeams((prev) => [...prev, newTeam]);
    };

    const onRemove = (id) => {
        setSelectedTeams(selectedTeams.filter((el) => el.id !== id));
        const isEntry = teamsList.length ? teamsList.some((el) => el.id === id) : false;
        !isEntry && setTeamsList([...teamsList, teamsForService.find((el) => el.id === id)]);
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    return (
        <div className={classes.container}>
            <div className="appointment-scheduler">
                <div className="scheduling-topbar">
                    <div className="inputs">
                        <div className="input">
                            <SchedulerSelector
                                id="service"
                                heading="Service"
                                listItems={services}
                                currentName={service?.name}
                                currentId={service?.id}
                                onUpdate={onServiceUpdate}
                                disabled={!hasRole(allowedAdviserRoles, roles)}
                            />
                        </div>
                        <div className="input">
                            <SchedulerSelector
                                id="team"
                                heading="Team"
                                listItems={teamsList}
                                currentName={selectedTeams[selectedTeams.length - 1]?.name}
                                onUpdate={onAddTeam}
                                disabled={!service?.id || !hasRole(allowedAdviserRoles, roles)}
                            />
                        </div>
                    </div>
                    <div className="switch">
                        <AppointmentSlotSwitch
                            checked={isAppointmentSlots}
                            onChange={(update) => setIsAppointmentSlots(update.appointmentSlots)}
                            disabled={!hasRole(allowedAdviserRoles, roles)}
                        />
                    </div>
                </div>
                <div className="selected-lists">
                    <div className="selected-teams">
                        {selectedTeams.length > 0 && (
                            <>
                                Selected teams:
                                <div className="selection-list">
                                    {selectedTeams.map((el) => (
                                        <span key={el.id} className="selected-list-item">
                                            <span
                                                className="clear-icon"
                                                onClick={() => onRemove(el.id)}>
                                                <ClearIcon />
                                            </span>
                                            {el.name}
                                        </span>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="timeview-content-container">
                    <div className="scheduling-sidebar">
                        <SchedulerAdviserPanel
                            advisers={advisers}
                            selectedAdviserIds={selectedAdviserIds}
                            setSelectedAdviserIds={setSelectedAdviserIds}
                            activeAdviserId={activeAdviserId}
                            setActiveAdviserId={setActiveAdviserId}
                            onLoadMoreAdvisers={onLoadMoreAdvisers}
                            moreAdvisersToLoad={!totalUsers || users.length < totalUsers}
                            disabled={!service?.id}
                        />
                    </div>
                    <div className="timeview-calendar-content">
                        <TimeView
                            selectedAdvisers={selectedAdviserIds.map((el) =>
                                advisers.find((entry) => entry.id === el)
                            )}
                            selectedAdviserId={activeAdviserId}
                            isAppointmentSlots={isAppointmentSlots}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchedulingAssistant;
