// This is a slice of state for the Static Data Service.
import { createSlice } from '@reduxjs/toolkit';

import { getDirectusURL } from '../api/commonHTTP';

import { apiCallDirectusBegan, apiSeqAdd, apiSeqDelete } from './api';

const slice = createSlice({
    name: 'directusService',
    initialState: {
        actionPlanStatusDetails: [],
        appointmentTypeDetailsForContracts: [],
        appointmentTypeDetails: [],
        appointmentTypes: [],
        assetRequestRequestTypeDetails: [],
        assetRequestOrderTypeDetails: [],
        assetRequestAssetDetails: [],
        assetRequestStatusDetails: [],
        attendanceDetails: [],
        attractionDetails: [],
        attractionDetailDetails: [],
        barrierNameDetails: [],
        benefitCodeDetails: [],
        bloodPressureMeasurementTypes: [],
        bloodTestEvents: [],
        bloodTestSources: [],
        businessSectorDetails: [],
        callOutcomes: [],
        claimEventStatuses: [],
        claimEventStatusReasons: [],
        claimEventTypes: [],
        claimQueueActionStatusDetails: [],
        claimStatusUploaderTypes: [],
        communicationOptOutTypes: [],
        communicationTypes: [],
        comorbidityDetails: [],
        contractDetails: [],
        contractDetailsForContractId: {},
        contractTypes: [],
        courseTemplateHealthDetails: [],
        darPriorities: [],
        darRequestTypes: [],
        darStatuses: [],
        dataSetDetails: [],
        disabilityDetails: [],
        documentTypeIdsAndNames: [],
        documentTypeIdsAndNamesForContract: [],
        documentTypesForContracts: [],
        documentTypes: [],
        driversLicenceDetails: [],
        employmentTypeDetails: [],
        ethnicityDetails: [],
        externalCourseProviderDetails: [],
        financialPaymentMethodDetails: [],
        financialPaymentMethodTypeDetails: [],
        financialRequestReasonDetails: [],
        financialRequestStatusDetails: [],
        financialRequestTypeDetails: [],
        firstLanguageDetails: [],
        formStatusDetails: [],
        formTypes: [],
        frequencyDetails: [],
        genderDetails: [],
        gpDetails: [],
        hoursPerWeekDetails: [],
        housingDetails: [],
        interventionTypes: [],
        jobCentreDetails: [],
        jobReadinessDetails: [],
        jobSectorDetails: [],
        keySoftSkillsDetails: [],
        locationDetails: [],
        locationsForServices: [],
        locationIdsAndNames: [],
        maritalStatusDetails: [],
        maximumTravelDistanceDetails: [],
        mentalHealthDetails: [],
        modStatusDetails: [],
        nonSeriousEventTypes: [],
        occThemes: [],
        ownPlacementDetails: [],
        participantStatusDetails: [],
        participantStatusReasonDetails: [],
        placementEmploymentTypeDetails: [],
        placementStatusDetails: [],
        poolStatusDetails: [],
        preferredPronounDetails: [],
        provisionStatusDetails: [],
        qualificationTypeDetails: [],
        questionnaireTypes: [],
        readinessToChangeDetails: [],
        reasonForLeavingDetails: [],
        referralSourceDetails: [],
        religionDetails: [],
        rightToWorkDocTypes: [],
        seriousEventTypes: [],
        serviceDetails: [],
        serviceDetailsForContractId: [],
        signPostingTypes: [],
        signPostingOrganisations: [],
        smokingCoResults: [],
        smokingSelfReportedQuits: [],
        smokingTypes: [],
        smokingUsingECigarettes: [],
        timelineStatusDetails: [],
        timelineTypeDetails: [],
        timeUnemployedDetails: [],
        transportMethodDetails: [],
        uniqueIdentifierTypes: [],
        uploaderTypes: [],
        vacancyRejectionReasonsDetails: [],
        vacancyStageDetails: [],
        wellbeingDetails: [],
        workConditionalityGroupDetails: [],
        workConfidence: [],
        successMessage: '',
        errorMessage: '',
        origin: '',
        attractionDetailsLoading: false,
        attractionUpdatePending: false,
        gpDetailsLoading: false,
        gpUpdatePending: false,
        jobCentreDetailsLoading: false,
        job_centreUpdatePending: false,
        placementEmploymentTypeDetailsLoading: false,
        placementStatusDetailsLoading: false
    },
    reducers: {
        clearAppointmentType: (state) => {
            state.appointmentTypeDetails = [];
            state.appointmentTypes = [];
        },

        clearAssetRequestAssetDetails: (state) => {
            state.assetRequestAssetDetails = [];
        },

        clearAssetRequestRequestTypeDetails: (state) => {
            state.assetRequestRequestTypeDetails = [];
        },

        clearAssetRequestOrderTypeDetails: (state) => {
            state.assetRequestOrderTypeDetails = [];
        },

        clearAttendance: (state) => {
            state.attendanceDetails = [];
        },

        clearAttraction: (state) => {
            state.attractionDetails = [];
        },

        clearAttractionDetail: (state) => {
            state.attractionDetailDetails = [];
        },

        clearBarrierName: (state) => {
            state.barrierNameDetails = [];
        },

        clearBenefitCode: (state) => {
            state.benefitCodeDetails = [];
        },

        clearBusinessSector: (state) => {
            state.businessSectorDetails = [];
        },

        clearComorbidity: (state) => {
            state.comorbidityDetails = [];
        },

        clearContractDetails: (state) => {
            state.contractDetails = [];
        },

        clearCourseTemplateHealth: (state) => {
            state.courseTemplateHealthDetails = [];
        },

        clearDisability: (state) => {
            state.disabilityDetails = [];
        },

        clearDriversLicence: (state) => {
            state.driversLicenceDetails = [];
        },

        clearEmployment: (state) => {
            state.employmentTypeDetails = [];
        },

        clearEthnicity: (state) => {
            state.ethnicityDetails = [];
        },

        clearExternalCourseProvider: (state) => {
            state.ethnicityDetails = [];
        },

        clearFinancialPaymentMethod: (state) => {
            state.financialPaymentMethodDetails = [];
        },

        clearFinancialPaymentMethodType: (state) => {
            state.financialPaymentMethodTypeDetails = [];
        },

        clearFinancialRequestStatus: (state) => {
            state.financialRequestStatusDetails = [];
        },

        clearFinancialRequestType: (state) => {
            state.financialRequestTypeDetails = [];
        },

        clearFinancialRequestReason: (state) => {
            state.financialRequestReasonDetails = [];
        },

        clearFirstLanguage: (state) => {
            state.firstLanguageDetails = [];
        },

        clearFrequency: (state) => {
            state.frequencyDetails = [];
        },

        clearGenders: (state) => {
            state.genderDetails = [];
        },

        clearGp: (state) => {
            state.gpDetails = [];
        },

        clearHousing: (state) => {
            state.housingDetails = [];
        },

        clearJobCentre: (state) => {
            state.jobCentreDetails = [];
        },

        clearJobReadiness: (state) => {
            state.jobReadinessDetails = [];
        },

        clearJobSector: (state) => {
            state.jobSectorDetails = [];
        },

        clearKeySoftSkills: (state) => {
            state.keySoftSkillsDetails = [];
        },

        clearLocationsForServices: (state) => {
            state.locationsForServices = [];
        },

        clearMaritalStatus: (state) => {
            state.maritalStatusDetails = [];
        },

        clearMaximumTravelDistance: (state) => {
            state.maximumTravelDistanceDetails = [];
        },

        clearMentalHealth: (state) => {
            state.mentalhealthDetails = [];
        },

        clearModStatus: (state) => {
            state.modStatusDetails = [];
        },

        clearPlacementEmploymentType: (state) => {
            state.placementEmploymentTypeDetails = [];
        },

        clearQualificationType: (state) => {
            state.qualificationTypeDetails = [];
        },

        clearReadinessToChange: (state) => {
            state.readinessToChangeDetails = [];
        },

        ClearReasonForLeaving: (state) => {
            state.reasonForLeavingDetails = [];
        },

        clearReferralSource: (state) => {
            state.referralSourceDetails = [];
        },

        clearReligion: (state) => {
            state.religionDetails = [];
        },

        clearRightToWorkDocType: (state) => {
            state.rightToWorkDocTypes = [];
        },

        clearServiceDetails: (state) => {
            state.serviceDetails = [];
        },

        clearSmokingTypes: (state) => {
            state.smokingTypes = [];
        },

        clearTimelineStatus: (state) => {
            state.timelineStatusDetails = [];
        },

        clearTimelineType: (state) => {
            state.timelineTypeDetails = [];
        },

        clearTimeUnemployed: (state) => {
            state.timeUnemployedDetails = [];
        },

        clearTransportMethod: (state) => {
            state.transportMethodDetails = [];
        },

        clearWorkConditionalityGroup: (state) => {
            state.workConditionalityGroupDetails = [];
        },

        clearWorkHistory: (state) => {
            state.workHistoryDetails = [];
        },

        actionPlanStatusDetailsLoading: (state) => {
            state.actionPlanStatusDetails = [];
        },

        actionPlanStatusDetailsLoaded: (state, action) => {
            state.actionPlanStatusDetails = action.payload.data;
        },

        appointmentTypeDetailsLoaded: (state, action) => {
            state.appointmentTypeDetails = action.payload.data;
            state.appointmentTypes = action.payload.data;
        },

        appointmentTypeDetailsForContractsLoaded: (state, action) => {
            state.appointmentTypeDetailsForContracts = action.payload.data;
        },

        assetRequestAssetDetailsLoaded: (state, action) => {
            state.assetRequestAssetDetails = action.payload.data;
        },

        assetRequestOrderTypeDetailsLoaded: (state, action) => {
            state.assetRequestOrderTypeDetails = action.payload.data;
        },

        assetRequestStatusDetailsLoaded: (state, action) => {
            state.assetRequestStatusDetails = action.payload.data;
        },

        assetRequestRequestTypeDetailsLoaded: (state, action) => {
            state.assetRequestRequestTypeDetails = action.payload.data;
        },

        attendanceDetailsLoaded: (state, action) => {
            state.attendanceDetails = action.payload.data;
        },

        attractionDetailsLoading: (state) => {
            state.attractionDetailsLoading = true;
        },

        attractionDetailsLoaded: (state, action) => {
            state.attractionDetailsLoading = false;
            state.attractionDetails = action.payload.data;
        },

        attractionDetailDetailsLoaded: (state, action) => {
            // remove duplicate names
            state.attractionDetailDetails = action.payload.data.filter(
                (el, i, arr) => arr.findIndex((entry) => entry.name === el.name) === i
            );
        },

        barrierNameDetailsLoaded: (state, action) => {
            const sortedBarriers = action.payload.data;
            state.barrierNameDetails = sortedBarriers.sort((a, b) => a.name.localeCompare(b.name));
        },

        benefitCodeDetailsLoaded: (state, action) => {
            state.benefitCodeDetails = action.payload.data;
        },

        bloodPressureMeasurementTypesLoaded: (state, action) => {
            state.bloodPressureMeasurementTypes = action.payload.data;
        },

        bloodTestEventsLoading: (state) => {
            state.bloodTestEvents = [];
        },

        bloodTestEventsLoaded: (state, action) => {
            state.bloodTestEvents = action.payload.data;
        },

        bloodTestSourcesLoading: (state) => {
            state.bloodTestSources = [];
        },

        bloodTestSourcesLoaded: (state, action) => {
            state.bloodTestSources = action.payload.data;
        },

        businessSectorDetailsLoaded: (state, action) => {
            state.businessSectorDetails = action.payload.data;
        },

        callOutcomesLoaded: (state, action) => {
            state.callOutcomes = action.payload.data;
        },

        claimEventStatusesLoaded: (state, action) => {
            state.claimEventStatuses = action.payload.data;
        },

        claimEventStatusReasonsLoaded: (state, action) => {
            state.claimEventStatusReasons = action.payload.data;
        },

        claimEventTypesLoaded: (state, action) => {
            state.claimEventTypes = action.payload.data;
        },

        claimQueueActionStatusDetailsLoaded: (state, action) => {
            state.claimQueueActionStatusDetails = action.payload.data;
        },

        claimStatusUploaderTypesLoaded: (state, action) => {
            state.claimStatusUploaderTypes = action.payload.data;
        },

        communicationOptOutTypesLoaded: (state, action) => {
            state.communicationOptOutTypes = action.payload.data;
        },

        communicationTypesLoaded: (state, action) => {
            state.communicationTypes = action.payload.data;
        },

        comorbidityDetailsLoaded: (state, action) => {
            state.comorbidityDetails = action.payload.data;
        },

        contractDetailsLoaded: (state, action) => {
            state.contractDetails = action.payload.data;
        },

        contractDetailsCreated: (state, action) => {
            state.contractDetails = [...state.contractDetails, action.payload.data];
        },

        contractDetailsForContractIdLoaded: (state, action) => {
            if (action.payload.data?.length)
                state.contractDetailsForContractId = action.payload.data[0];
        },

        contractTypesLoaded: (state, action) => {
            state.contractTypes = action.payload.data;
        },

        courseTemplateHealthDetailsLoaded: (state, action) => {
            state.courseTemplateHealthDetails = action.payload.data;
        },

        darPrioritiesLoaded: (state, action) => {
            state.darPriorities = action.payload.data;
        },

        darRequestTypesLoaded: (state, action) => {
            state.darRequestTypes = action.payload.data;
        },

        darStatusesLoaded: (state, action) => {
            state.darStatuses = action.payload.data;
        },

        dataSetDetailsLoaded: (state, action) => {
            state.dataSetDetails = action.payload.data;
        },

        disabilityDetailsLoaded: (state, action) => {
            state.disabilityDetails = action.payload.data;
        },

        documentTypesLoading: (state) => {
            state.documentTypes = [];
        },

        documentTypesLoaded: (state, action) => {
            state.documentTypes = action.payload.data;
        },

        documentTypeIdsAndNamesLoaded: (state, action) => {
            state.documentTypeIdsAndNames = action.payload.data;
        },

        documentTypeIdsAndNamesForContractLoaded: (state, action) => {
            const docs = action.payload.data;
            state.documentTypeIdsAndNamesForContract = docs.sort((a, b) =>
                a.name.localeCompare(b.name)
            );
        },

        driversLicenceDetailsLoaded: (state, action) => {
            state.driversLicenceDetails = action.payload.data;
        },

        employmentTypeDetailsLoaded: (state, action) => {
            state.employmentTypeDetails = action.payload.data;
        },

        ethnicityDetailsLoaded: (state, action) => {
            state.ethnicityDetails = action.payload.data;
        },

        nonSeriousEventTypesLoaded: (state, action) => {
            state.nonSeriousEventTypes = action.payload.data;
        },

        seriousEventTypesLoaded: (state, action) => {
            state.seriousEventTypes = action.payload.data;
        },

        externalCourseProviderDetailsLoaded: (state, action) => {
            state.externalCourseProviderDetails = action.payload.data;
        },

        financialPaymentMethodDetailsLoaded: (state, action) => {
            state.financialPaymentMethodDetails = action.payload.data;
        },

        financialPaymentMethodTypeDetailsLoaded: (state, action) => {
            state.financialPaymentMethodTypeDetails = action.payload.data;
        },

        financialRequestReasonDetailsLoaded: (state, action) => {
            state.financialRequestReasonDetails = action.payload.data;
        },

        financialRequestStatusDetailsLoaded: (state, action) => {
            state.financialRequestStatusDetails = action.payload.data;
        },

        financialRequestTypeDetailsLoaded: (state, action) => {
            state.financialRequestTypeDetails = action.payload.data;
        },

        firstLanguageDetailsLoaded: (state, action) => {
            state.firstLanguageDetails = action.payload.data;
        },

        formStatusDetailsLoaded: (state, action) => {
            state.formStatusDetails = action.payload.data;
        },

        formTypesLoaded: (state, action) => {
            state.formTypes = action.payload.data;
        },

        frequencyDetailsLoaded: (state, action) => {
            state.frequencyDetails = action.payload.data;
        },

        genderDetailsLoading: (state) => {
            state.genderDetails = [];
        },

        genderDetailsLoaded: (state, action) => {
            state.genderDetails = action.payload.data;
        },

        gpDetailsLoading: (state) => {
            state.gpDetailsLoading = true;
        },

        gpDetailsLoaded: (state, action) => {
            state.gpDetailsLoading = false;
            state.gpDetails =
                state.gpDetails.length <= 2000
                    ? [...state.gpDetails, ...action.payload.data]
                    : action.payload.data;
        },

        hoursPerWeekDetailsLoaded: (state, action) => {
            state.hoursPerWeekDetails = action.payload.data;
        },

        housingDetailsLoaded: (state, action) => {
            state.housingDetails = action.payload.data;
        },

        interventionTypesLoaded: (state, action) => {
            state.interventionTypes = action.payload.data;
        },

        jobReadinessDetailsLoaded: (state, action) => {
            state.jobReadinessDetails = action.payload.data;
        },

        jobCentreDetailsLoading: (state) => {
            state.jobCentreDetailsLoading = true;
        },

        jobCentreDetailsLoaded: (state, action) => {
            state.jobCentreDetailsLoading = false;
            state.jobCentreDetails = action.payload.data;
        },

        jobSectorDetailsLoading: (state) => {
            state.jobSectorDetails = [];
        },

        jobSectorDetailsLoaded: (state, action) => {
            state.jobSectorDetails = action.payload.data;
        },

        keySoftSkillsDetailsLoaded: (state, action) => {
            state.keySoftSkillsDetails = action.payload.data;
        },

        locationLoaded: (state, action) => {
            const newLocations = Array.isArray(state.locationDetails) ? state.locationDetails : [];
            const locationDetails = newLocations.filter(
                (el) => el.id !== action.payload.data[0].id
            );
            state.locationDetails = [...locationDetails, action.payload.data[0]];
        },

        locationDetailsLoaded: (state, action) => {
            state.locationDetails =
                state.locationDetails.length < 2000
                    ? [...state.locationDetails, ...action.payload.data]
                    : action.payload.data;
        },

        locationsForServicesLoaded: (state, action) => {
            state.locationsForServices = action.payload.data;
        },

        locationIdsAndNamesLoaded: (state, action) => {
            state.locationIdsAndNames = action.payload.data;
        },

        maritalStatusDetailsLoaded: (state, action) => {
            state.maritalStatusDetails = action.payload.data;
        },

        maximumTravelDistanceDetailsLoaded: (state, action) => {
            state.maximumTravelDistanceDetails = action.payload.data;
        },

        mentalHealthDetailsLoaded: (state, action) => {
            state.mentalHealthDetails = action.payload.data;
        },

        modStatusDetailsLoaded: (state, action) => {
            state.modStatusDetails = action.payload.data;
        },

        occThemeDetailsLoaded: (state, action) => {
            state.occThemes = action.payload.data;
        },

        ownPlacementDetailsLoaded: (state, action) => {
            state.ownPlacementDetails = action.payload.data;
        },

        ownPlacementDetailsLoading: (state) => {
            state.ownPlacementDetails = [];
        },

        participantStatusDetailsLoaded: (state, action) => {
            state.participantStatusDetails = action.payload.data;
        },

        participantStatusDetailsLoading: (state) => {
            state.participantStatusDetails = [];
        },

        participantStatusReasonDetailsLoaded: (state, action) => {
            state.participantStatusReasonDetails = action.payload.data;
        },

        participantStatusReasonDetailsLoading: (state) => {
            state.participantStatusReasonDetails = [];
        },

        placementEmploymentTypeDetailsLoaded: (state, action) => {
            state.placementEmploymentTypeDetails = action.payload.data;
        },

        placementEmploymentTypeDetailsLoading: (state) => {
            state.placementEmploymentTypeDetails = [];
        },

        placementStatusDetailsLoaded: (state, action) => {
            state.placementStatusDetails = action.payload.data;
        },

        placementStatusDetailsLoading: (state) => {
            state.placementStatusDetails = [];
        },

        poolAcceptedRejectionReasonsDetailsLoaded: (state, action) => {
            state.poolAcceptedRejectionReasonsDetails = action.payload.data;
        },

        poolAcceptedRejectionReasonsDetailsLoading: (state) => {
            state.poolAcceptedRejectionReasonsDetails = [];
        },

        poolRequestedRejectionReasonsDetailsLoaded: (state, action) => {
            state.poolRequestedRejectionReasonsDetails = action.payload.data;
        },

        poolRequestedRejectionReasonsDetailsLoading: (state) => {
            state.poolRequestedRejectionReasonsDetails = [];
        },

        poolStatusDetailsLoaded: (state, action) => {
            state.poolStatusDetails = action.payload.data;
        },

        poolStatusDetailsLoading: (state) => {
            state.poolStatusDetails = [];
        },

        preferredPronounDetailsLoaded: (state, action) => {
            state.preferredPronounDetails = action.payload.data;
        },

        provisionStatusDetailsLoaded: (state, action) => {
            state.provisionStatusDetails = action.payload.data;
        },

        qualificationTypeDetailsLoaded: (state, action) => {
            state.qualificationTypeDetails = action.payload.data;
        },

        questionnaireTypesLoaded: (state, action) => {
            state.questionnaireTypes = action.payload.data;
        },

        readinessToChangeDetailsLoaded: (state, action) => {
            state.readinessToChangeDetails = action.payload.data;
        },

        reasonForLeavingDetailsLoaded: (state, action) => {
            state.reasonForLeavingDetails = action.payload.data;
        },

        referralSourceDetailsLoading: (state) => {
            state.referralSourceDetails = [];
        },

        referralSourceDetailsLoaded: (state, action) => {
            state.referralSourceDetails = action.payload.data;
            state.errorMessage = '';
        },

        religionDetailsLoaded: (state, action) => {
            state.religionDetails = action.payload.data;
        },

        rightToWorkDocTypesLoaded: (state, action) => {
            state.rightToWorkDocTypes = action.payload.data;
        },

        serviceDetailsCreated: (state, action) => {
            state.serviceDetails = [...state.serviceDetails, action.payload.data];
        },

        serviceDetailsForContractIdLoaded: (state, action) => {
            state.serviceDetailsForContractId = action.payload.data;
        },

        serviceDetailsLoaded: (state, action) => {
            state.serviceDetails = action.payload.data;
        },

        signPostingOrganisationsLoaded: (state, action) => {
            state.signPostingOrganisations = action.payload.data;
        },

        signPostingTypesLoaded: (state, action) => {
            state.signPostingTypes = action.payload.data;
        },

        smokingCoResultsLoaded: (state, action) => {
            state.smokingCoResults = action.payload.data;
        },

        smokingSelfReportedQuitsLoaded: (state, action) => {
            state.smokingSelfReportedQuits = action.payload.data;
        },

        smokingTypesLoaded: (state, action) => {
            state.smokingTypes = action.payload.data;
        },

        smokingUsingECigarettesLoaded: (state, action) => {
            state.smokingUsingECigarettes = action.payload.data;
        },

        timelineStatusDetailsLoaded: (state, action) => {
            state.timelineStatusDetails = action.payload.data;
        },

        timelineTypeDetailsLoaded: (state, action) => {
            state.timelineTypeDetails = action.payload.data;
        },

        timeUnemployedDetailsLoaded: (state, action) => {
            state.timeUnemployedDetails = action.payload.data;
        },

        transportMethodDetailsLoaded: (state, action) => {
            state.transportMethodDetails = action.payload.data;
        },

        // Get contract types
        uniqueIdentifierTypesLoaded: (state, action) => {
            state.uniqueIdentifierTypes = action.payload.data;
        },

        uploaderTypesLoaded: (state, action) => {
            state.uploaderTypes = action.payload.data;
        },

        vacancyRejectionReasonsDetailsLoaded: (state, action) => {
            state.vacancyRejectionReasonsDetails = action.payload.data;
        },

        vacancyRejectionReasonsDetailsLoading: (state) => {
            state.vacancyRejectionReasonsDetails = [];
        },

        vacancyStageDetailsLoaded: (state, action) => {
            state.vacancyStageDetails = action.payload.data;
        },

        wellbeingDetailsLoading: (state) => {
            state.wellbeingDetails = [];
        },

        wellbeingDetailsLoaded: (state, action) => {
            state.wellbeingDetails = action.payload.data;
        },

        workConditionalityGroupDetailsLoaded: (state, action) => {
            state.workConditionalityGroupDetails = action.payload.data;
        },

        workConfidenceLoaded: (state, action) => {
            state.workConfidence = action.payload.data;
        },

        updateCollectionPending: (state, action) => {
            state[`${action.payload.name}UpdatePending`] = true;
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload.message;
            state.origin = action.payload.origin;
            state.successMessage = '';
            state.gpDetailsLoading = false;
            state.gpUpdatePending = false;
            state.attractionDetailsLoading = false;
            state.attractionUpdatePending = false;
            state.jobCentreDetailsLoading = false;
            state.job_centreUpdatePending = false;
        },

        errorMessageSet: (state, action) => {
            state.errorMessage = action.payload;
            state.successMessage = '';
        },

        successMessageSet: (state) => {
            state.errorMessage = '';
            state.attractionUpdatePending = false;
            state.gpUpdatePending = false;
            state.job_centreUpdatePending = false;
        }
    }
});

const contractFilter = '?fields=*.*.*&filter[contract][contract_id][id][_in]=';
const contractFilterForServices = '?fields=*.*.*&filter[contract][id][_in]=';
const serviceFilter = '?fields=*.*.*&filter[service][service_id][id][_in]=';
const nameAndIdAndContractContractFilter = `?fields=name,id,contract.*&filter[contract][contract_id][id][_in]=`;
export const nameAndIdAndServiceServiceFilter = `?fields=name,id,service.*&filter[service][service_id][id][_in]=`;
const filterByIds = '?fields=*.*.*&filter[id][_in]=';

export const createContract = (data) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/contract`,
        method: 'post',
        data,
        onSuccess: contractDetailsCreated.type,
        successMessage: `Contract details for ${data.name} have been added`,
        onError: errorOccurred.type
    });

export const addContractsToCollection = (name, id, data, message) =>
    apiSeqAdd({
        url: getDirectusURL() + `items/${name}_contract`,
        method: 'post',
        data,
        onStartData: { name },
        onStart: updateCollectionPending.type,
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const removeContractsFromCollection = (name, data, message) =>
    apiSeqDelete({
        url: getDirectusURL() + `items/${name}_contract`,
        method: 'delete',
        data: { keys: data },
        onStartData: { name },
        onStart: updateCollectionPending.type,
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const addServicesToCollection = (name, id, data, message) =>
    apiSeqAdd({
        url: getDirectusURL() + `items/${name}_service`,
        method: 'post',
        data,
        onStartData: { name },
        onStart: updateCollectionPending.type,
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const removeServicesFromCollection = (name, data, message) =>
    apiSeqDelete({
        url: getDirectusURL() + `items/${name}_service`,
        method: 'delete',
        data: { keys: data },
        onStartData: { name },
        onStart: updateCollectionPending.type,
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const addGenericServiceRow = (collection, id, data, message) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/${collection}_service`,
        method: 'post',
        data,
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const createReferralSource = (name, data) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/referral_source`,
        method: 'post',
        data,
        onSuccess: referralSourceDetailsLoaded.type,
        successMessage: `Referral source details for ${name} have been added`,
        onError: errorOccurred.type,
        origin: 'createReferralSource'
    });

export const createService = (data) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/service`,
        method: 'post',
        data,
        onSuccess: serviceDetailsCreated.type,
        successMessage: `Service details for ${data.name} have been added`,
        onError: errorOccurred.type
    });

export const deleteGenericRow = (collection, id, message) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/${collection}_contract/${id}`,
        method: 'delete',
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const deleteServiceGenericRow = (collection, id, message) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/${collection}_service/${id}`,
        method: 'delete',
        onSuccess: successMessageSet.type,
        successMessage: message,
        onError: errorOccurred.type
    });

export const loadActionPlanStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/action_plan_status`,
        onStart: actionPlanStatusDetailsLoading.type,
        onSuccess: actionPlanStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadActionPlanStatusDetailsByContractId = (id) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/action_plan_status` +
            encodeURIComponent('?filter[contract][_eq]=') +
            id,
        onSuccess: actionPlanStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAppointmentTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/calendar_appointment_type`,
        onSuccess: appointmentTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAppointmentTypeDetailsByContractIds = (contractIds) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/calendar_appointment_type` +
            encodeURIComponent(contractFilter + `${contractIds}`),
        onSuccess: appointmentTypeDetailsForContractsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAssetRequestAssetDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/asset_request_asset_type`,
        onSuccess: assetRequestAssetDetailsLoaded.type,
        onError: errorOccurred.type
    });
export const loadAssetRequestOrderTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/request_order_type`,
        onSuccess: assetRequestOrderTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });
export const loadAssetRequestRequestTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/asset_request_request_type`,
        onSuccess: assetRequestRequestTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAssetRequestStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/financial_request_status`,
        onSuccess: assetRequestStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAttendanceDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/attendance`,
        onSuccess: attendanceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAttractionDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/attraction`,
        onStart: attractionDetailsLoading.type,
        onSuccess: attractionDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAttractionDetailDetailsByAttraction = (attractionId) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/attraction_detail` +
            encodeURIComponent(
                '?limit=-1&fields=*.*.*&filter[attraction][attraction_id][id][_in]=' + attractionId
            ),
        onSuccess: attractionDetailDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadBarrierNameDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/barrier_name?fields=*.*.*&limit=1000`,
        onSuccess: barrierNameDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadBarrierNameDetailsByContract = (contractIds) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/barrier_name` +
            encodeURIComponent(contractFilter + `${contractIds}`) +
            `?fields=*.*.*&limit=1000`,
        onSuccess: barrierNameDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadBarrierNameDetailsByNameAndContract = (barrierNames, contractId) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/barrier_name` +
            encodeURIComponent('?filter[name][_in]=' + barrierNames) +
            encodeURIComponent(
                '&fields=*.*.*&filter[contract][contract_id][id][_eq]=' + contractId
            ),
        onSuccess: barrierNameDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadBenefitCodeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/benefit_code`,
        onSuccess: benefitCodeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadBloodPressureMeasurementTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/blood_pressure_measurement_type`,
        onSuccess: bloodPressureMeasurementTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadBloodTestEvents = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/blood_test_event`,
        onStart: bloodTestEventsLoading.type,
        onSuccess: bloodTestEventsLoaded.type,
        onError: errorOccurred.type
    });

export const loadBloodTestSources = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/blood_test_source`,
        onStart: bloodTestSourcesLoading.type,
        onSuccess: bloodTestSourcesLoaded.type,
        onError: errorOccurred.type
    });

export const loadBusinessSectorDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/business_sector`,
        onSuccess: businessSectorDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadCallOutcomes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/call_outcome`,
        onSuccess: callOutcomesLoaded.type,
        onError: errorOccurred.type
    });

export const loadClaimEventStatuses = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/claim_event_status`,
        onSuccess: claimEventStatusesLoaded.type,
        onError: errorOccurred.type
    });

export const loadClaimEventStatusReasons = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/claim_event_status_reason`,
        onSuccess: claimEventStatusReasonsLoaded.type,
        onError: errorOccurred.type
    });

export const loadClaimEventTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/claim_event_type`,
        onSuccess: claimEventTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadClaimQueueActionStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/claim_queue_action_status`,
        onSuccess: claimQueueActionStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadClaimStatusUploaderTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/claim_status_uploader_type`,
        onSuccess: claimStatusUploaderTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadCommunicationOptOutTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/communication_opt_out_type`,
        onSuccess: communicationOptOutTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadCommunicationTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/communication_type`,
        onSuccess: communicationTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadComorbidityDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/comorbidity`,
        onSuccess: comorbidityDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadContractDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/contract`,
        onSuccess: contractDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadContractDetailsForContractId = (id) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() + `items/contract` + encodeURIComponent('?filter[id][_eq]=') + `${id}`,
        onSuccess: contractDetailsForContractIdLoaded.type,
        onError: errorOccurred.type
    });

export const loadContractDetailsForContractIds = (ids) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/contract` + encodeURIComponent(filterByIds + ids),
        onSuccess: contractDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadContractTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/contract_type`,
        onSuccess: contractTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadCourseTemplateHealthDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/course_template_health`,
        onSuccess: courseTemplateHealthDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadDarPriorities = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/dar_priority`,
        onSuccess: darPrioritiesLoaded.type,
        onError: errorOccurred.type
    });

export const loadDarRequestTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/dar_request_type`,
        onSuccess: darRequestTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadDarStatuses = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/dar_status`,
        onSuccess: darStatusesLoaded.type,
        onError: errorOccurred.type
    });

export const loadDataSetDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/data_set`,
        onSuccess: dataSetDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadDisabilityDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/disability`,
        onSuccess: disabilityDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadDocumentTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/document_type?fields=*.*.*&limit=1000`,
        onStart: documentTypesLoading.type,
        onSuccess: documentTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadDocumentTypesForContractId = (id) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/document_type` +
            encodeURIComponent(contractFilter + id) +
            `?fields=*.*.*&limit=1000`,
        onStart: documentTypesLoading.type,
        onSuccess: documentTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadDocumentTypeIdsAndNames = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/document_type?fields=id,name&limit=-1`,
        onSuccess: documentTypeIdsAndNamesLoaded.type,
        onError: errorOccurred.type
    });

export const loadDocumentTypeIdsAndNamesForContract = (contractId) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/document_type` +
            encodeURIComponent(nameAndIdAndContractContractFilter + contractId) +
            `?fields=*.*.*&limit=-1`,
        onSuccess: documentTypeIdsAndNamesForContractLoaded.type,
        onError: errorOccurred.type
    });

export const loadDriversLicenceDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/drivers_licence`,
        onSuccess: driversLicenceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadEmploymentTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/employment_type`,
        onSuccess: employmentTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadEthnicityDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/ethnicity`,
        onSuccess: ethnicityDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadNonSeriousEventTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/non_serious_event_type`,
        onSuccess: nonSeriousEventTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadSeriousEventTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/serious_event_type`,
        onSuccess: seriousEventTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadExternalCourseProviderDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/external_course_provider`,
        onSuccess: externalCourseProviderDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadAllExternalCourseProviderDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/external_course_provider?limit=-1`,
        onSuccess: externalCourseProviderDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFinancialPaymentMethodDetailsByPaymentMethodType = (paymentMethodTypeId) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/financial_payment_method` +
            encodeURIComponent(
                '?fields=*.*.*&filter[financial_payment_method_type][financial_payment_method_type_id][_eq]=' +
                    `${paymentMethodTypeId}`
            ),
        onSuccess: financialPaymentMethodDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFinancialPaymentMethodTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/financial_payment_method_type`,
        onSuccess: financialPaymentMethodTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFinancialRequestReasonDetailsByRequestType = (requestTypeId) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/financial_request_reason` +
            encodeURIComponent(
                '?fields=*.*.*&filter[financial_request_type][financial_request_type_id][_eq]=' +
                    `${requestTypeId}`
            ),
        onSuccess: financialRequestReasonDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFinancialRequestStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/financial_request_status`,
        onSuccess: financialRequestStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFinancialRequestTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/financial_request_type`,
        onSuccess: financialRequestTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFirstLanguageDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/first_language`,
        onSuccess: firstLanguageDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFormStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/form_status`,
        onSuccess: formStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadFormTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/form_type`,
        onSuccess: formTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadFrequencyDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/frequency`,
        onSuccess: frequencyDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadGenderDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/gender`,
        onStart: genderDetailsLoading.type,
        onSuccess: genderDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadGpDetails = (page = 1) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/gp?fields=*.*.*&page=${page}&limit=1000`, // `items/gp?fields=*.*.*&limit=2097`
        onStart: gpDetailsLoading.type,
        onSuccess: gpDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadHoursPerWeekDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/hours_per_week`,
        onSuccess: hoursPerWeekDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadHousingDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/housing`,
        onSuccess: housingDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadInterventionTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/intervention_type`,
        onSuccess: interventionTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadJobCentreDetails = (page = 1) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/job_centre?fields=*.*.*&page=${page}&limit=1500`,
        onStart: jobCentreDetailsLoading.type,
        onSuccess: jobCentreDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadJobReadinessDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/job_readiness`,
        onSuccess: jobReadinessDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadJobSectorDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/job_sector`,
        onStart: jobSectorDetailsLoading.type,
        onSuccess: jobSectorDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadKeySoftSkillsDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/key_soft_skills`,
        onSuccess: keySoftSkillsDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadLocation = (locationId) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/location` +
            encodeURIComponent('?filter[id][_eq]=') +
            `${locationId}`,
        onSuccess: locationLoaded.type,
        onError: errorOccurred.type
    });

export const loadLocationIdsAndNames = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/location?fields=id,name&limit=-1`,
        onSuccess: locationIdsAndNamesLoaded.type,
        onError: errorOccurred.type
    });

export const loadLocationsForServices = (serviceIds) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/location` +
            encodeURIComponent(nameAndIdAndServiceServiceFilter + serviceIds) +
            `?fields=*.*.*&limit=-1`,
        onSuccess: locationsForServicesLoaded.type,
        onError: errorOccurred.type
    });

export const loadLocationsByServices = (ids) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/location` +
            encodeURIComponent(serviceFilter + ids) +
            `?limit=-1`,
        onSuccess: locationsForServicesLoaded.type,
        onError: errorOccurred.type
    });

export const loadMaritalStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/marital_status`,
        onSuccess: maritalStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadMaximumTravelDistanceDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/maximum_travel_distance`,
        onSuccess: maximumTravelDistanceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadMentalHealthDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/mental_health`,
        onSuccess: mentalHealthDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadModStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/mod_status`,
        onSuccess: modStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadOccThemeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/occ_theme`,
        onSuccess: occThemeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadOwnPlacementDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/own_placement`,
        onStart: ownPlacementDetailsLoading.type,
        onSuccess: ownPlacementDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadParticipantStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/participant_status`,
        onStart: participantStatusDetailsLoading.type,
        onSuccess: participantStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadParticipantStatusReasonDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/participant_status_reason?limit=-1&sort=name`,
        onStart: participantStatusReasonDetailsLoading.type,
        onSuccess: participantStatusReasonDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadPlacementEmploymentTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/placement_employment_type`,
        onStart: placementEmploymentTypeDetailsLoading.type,
        onSuccess: placementEmploymentTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadPlacementStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/placement_status`,
        onStart: placementStatusDetailsLoading.type,
        onSuccess: placementStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadPoolAcceptedRejectionReasonsDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/pool_accepted_rejection_reasons`,
        onStart: poolAcceptedRejectionReasonsDetailsLoading.type,
        onSuccess: poolAcceptedRejectionReasonsDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadPoolRequestedRejectionReasonsDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/pool_requested_rejection_reasons`,
        onStart: poolRequestedRejectionReasonsDetailsLoading.type,
        onSuccess: poolRequestedRejectionReasonsDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadPoolStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/pool_status`,
        onStart: poolStatusDetailsLoading.type,
        onSuccess: poolStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadPreferredPronounDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/preferred_pronoun`,
        onSuccess: preferredPronounDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadProvisionStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/provision_status`,
        onSuccess: provisionStatusDetailsLoaded().type,
        onError: errorOccurred.type
    });

export const loadQualificationTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/qualification_type`,
        onSuccess: qualificationTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadQuestionnaireTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/questionnaire_type`,
        onSuccess: questionnaireTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadReadinessToChangeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/readiness_to_change`,
        onSuccess: readinessToChangeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadRightToWorkDocTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/right_to_work_document_type`,
        onSuccess: rightToWorkDocTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadReasonForLeavingDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/reason_for_leaving`,
        onSuccess: reasonForLeavingDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadReferralSourceDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/referral_source`,
        onStart: referralSourceDetailsLoading.type,
        onSuccess: referralSourceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadReligionDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/religion`,
        onSuccess: religionDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadServiceDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/service`,
        onSuccess: serviceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadServiceDetailsByContract = (contractIds) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/service` +
            encodeURIComponent(contractFilterForServices + contractIds),
        onSuccess: serviceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadServiceDetailsByService = (serviceIds) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/service` + encodeURIComponent(filterByIds + serviceIds),
        onSuccess: serviceDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadServiceDetailsForContractId = (id) =>
    apiCallDirectusBegan({
        url:
            getDirectusURL() +
            `items/service` +
            encodeURIComponent('?filter[contract][_eq]=') +
            `${id}`,
        onSuccess: serviceDetailsForContractIdLoaded.type,
        onError: errorOccurred.type
    });

export const loadSignPostingTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/signposting_type`,
        onSuccess: signPostingTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadSignPostingOrganisations = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/signposting_organisation`,
        onSuccess: signPostingOrganisationsLoaded.type,
        onError: errorOccurred.type
    });

export const loadSmokingCoResults = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/smoking_co_result`,
        onSuccess: smokingCoResultsLoaded.type,
        onError: errorOccurred.type
    });

export const loadSmokingSelfReportedQuits = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/smoking_self_reported_quit`,
        onSuccess: smokingSelfReportedQuitsLoaded.type,
        onError: errorOccurred.type
    });

export const loadSmokingTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/smoking_type`,
        onSuccess: smokingTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadSmokingUsingECigarettes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/smoking_using_e_cigarette`,
        onSuccess: smokingUsingECigarettesLoaded.type,
        onError: errorOccurred.type
    });

export const loadTimelineTypeDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/timeline_type`,
        onSuccess: timelineTypeDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadTimelineStatusDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/timeline_status`,
        onSuccess: timelineStatusDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadTimeUnemployedDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/time_unemployed`,
        onSuccess: timeUnemployedDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadTransportMethodDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/transport_method`,
        onSuccess: transportMethodDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadUniqueIdentifierTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/unique_identifier_type`,
        onSuccess: uniqueIdentifierTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadUploaderTypes = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/uploader_type`,
        onSuccess: uploaderTypesLoaded.type,
        onError: errorOccurred.type
    });

export const loadVacancyRejectionReasonsDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/vacancy_rejection_reasons`,
        onStart: vacancyRejectionReasonsDetailsLoading.type,
        onSuccess: vacancyRejectionReasonsDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadVacancyStageDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/vacancy_stage_collection`,
        onSuccess: vacancyStageDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadWellbeingDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/wellbeing`,
        onStart: wellbeingDetailsLoading.type,
        onSuccess: wellbeingDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadWellbeingDetailsForContractId = (id) =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/wellbeing` + encodeURIComponent(contractFilter + id),
        onStart: wellbeingDetailsLoading.type,
        onSuccess: wellbeingDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadWorkConditionalityGroupDetails = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/work_conditionality_group`,
        onSuccess: workConditionalityGroupDetailsLoaded.type,
        onError: errorOccurred.type
    });

export const loadWorkConfidence = () =>
    apiCallDirectusBegan({
        url: getDirectusURL() + `items/work_confidence`,
        onSuccess: workConfidenceLoaded.type,
        onError: errorOccurred.type
    });

export const setSuccessMessage = (message) => successMessageSet(message);
export const setErrorMessage = (message) => errorMessageSet(message);

// Put selectors for getting data from state here
// Selectors take state and return computed state

// Action reducers called directly without http
export const {
    clearAppointmentType,
    clearAttendance,
    clearAssetRequestAssetDetails,
    clearAssetRequestRequestTypeDetails,
    clearAssetRequestOrderTypeDetails,
    clearAttraction,
    clearAttractionDetail,
    clearBarrierName,
    clearBenefitCode,
    clearBusinessSector,
    clearComorbidity,
    clearContractDetails,
    clearCourseTemplateHealth,
    clearDisability,
    clearDriversLicence,
    clearEmployment,
    clearEthnicity,
    clearExternalCourseProvider,
    clearFinancialPaymentMethod,
    clearFinancialPaymentMethodType,
    clearFinancialRequestStatus,
    clearFinancialRequestReason,
    clearFinancialRequestType,
    clearFirstLanguage,
    clearFrequency,
    clearGenders,
    clearGp,
    clearHousing,
    clearJobReadiness,
    clearJobCentre,
    clearJobSector,
    clearKeySoftSkills,
    clearLocationsForServices,
    clearMaritalStatus,
    clearMaximumTravelDistance,
    clearMentalHealth,
    clearModStatus,
    clearPlacementEmploymentType,
    ClearQualificationType,
    clearReadinessToChange,
    clearReasonForLeaving,
    clearReferralSource,
    clearReligion,
    clearRightToWorkDocType,
    clearServiceDetails,
    clearSmokingTypes,
    clearTimelineStatus,
    clearTimelineType,
    clearTimeUnemployed,
    clearTransportMethod,
    clearWorkConditionalityGroup,
    clearWorkHistory
} = slice.actions;

// why can't components call the fn directly from state - when it's not an api req ?
// Used internally
const {
    successMessageSet,
    errorMessageSet,
    errorOccurred,
    actionPlanStatusDetailsLoading,
    actionPlanStatusDetailsLoaded,
    appointmentTypeDetailsForContractsLoaded,
    appointmentTypeDetailsLoaded,
    assetRequestAssetDetailsLoaded,
    assetRequestOrderTypeDetailsLoaded,
    assetRequestStatusDetailsLoaded,
    assetRequestRequestTypeDetailsLoaded,
    attendanceDetailsLoaded,
    attractionDetailsLoading,
    attractionDetailsLoaded,
    attractionDetailDetailsLoaded,
    barrierNameDetailsLoaded,
    benefitCodeDetailsLoaded,
    bloodPressureMeasurementTypesLoaded,
    bloodTestEventsLoading,
    bloodTestEventsLoaded,
    bloodTestSourcesLoading,
    bloodTestSourcesLoaded,
    businessSectorDetailsLoaded,
    callOutcomesLoaded,
    claimEventStatusesLoaded,
    claimEventStatusReasonsLoaded,
    claimEventTypesLoaded,
    claimQueueActionStatusDetailsLoaded,
    claimStatusUploaderTypesLoaded,
    communicationOptOutTypesLoaded,
    communicationTypesLoaded,
    comorbidityDetailsLoaded,
    contractDetailsCreated,
    contractDetailsLoaded,
    contractDetailsForContractIdLoaded,
    contractTypesLoaded,
    courseTemplateHealthDetailsLoaded,
    darPrioritiesLoaded,
    darRequestTypesLoaded,
    darStatusesLoaded,
    dataSetDetailsLoaded,
    disabilityDetailsLoaded,
    documentTypeIdsAndNamesLoaded,
    documentTypeIdsAndNamesForContractLoaded,
    documentTypesLoading,
    documentTypesLoaded,
    driversLicenceDetailsLoaded,
    employmentTypeDetailsLoaded,
    ethnicityDetailsLoaded,
    nonSeriousEventTypesLoaded,
    seriousEventTypesLoaded,
    externalCourseProviderDetailsLoaded,
    financialPaymentMethodDetailsLoaded,
    financialPaymentMethodTypeDetailsLoaded,
    financialRequestReasonDetailsLoaded,
    financialRequestStatusDetailsLoaded,
    financialRequestTypeDetailsLoaded,
    firstLanguageDetailsLoaded,
    formStatusDetailsLoaded,
    formTypesLoaded,
    frequencyDetailsLoaded,
    genderDetailsLoading,
    genderDetailsLoaded,
    gpDetailsLoading,
    gpDetailsLoaded,
    hoursPerWeekDetailsLoaded,
    housingDetailsLoaded,
    interventionTypesLoaded,
    jobReadinessDetailsLoaded,
    jobCentreDetailsLoading,
    jobCentreDetailsLoaded,
    jobSectorDetailsLoading,
    jobSectorDetailsLoaded,
    keySoftSkillsDetailsLoaded,
    locationLoaded,
    locationIdsAndNamesLoaded,
    locationsForServicesLoaded,
    maritalStatusDetailsLoaded,
    maximumTravelDistanceDetailsLoaded,
    mentalHealthDetailsLoaded,
    modStatusDetailsLoaded,
    occThemeDetailsLoaded,
    ownPlacementDetailsLoaded,
    ownPlacementDetailsLoading,
    participantStatusDetailsLoaded,
    participantStatusDetailsLoading,
    participantStatusReasonDetailsLoaded,
    participantStatusReasonDetailsLoading,
    placementEmploymentTypeDetailsLoaded,
    placementEmploymentTypeDetailsLoading,
    placementStatusDetailsLoaded,
    placementStatusDetailsLoading,
    poolStatusDetailsLoading,
    poolStatusDetailsLoaded,
    poolAcceptedRejectionReasonsDetailsLoading,
    poolAcceptedRejectionReasonsDetailsLoaded,
    poolRequestedRejectionReasonsDetailsLoading,
    poolRequestedRejectionReasonsDetailsLoaded,
    preferredPronounDetailsLoaded,
    provisionStatusDetailsLoaded,
    qualificationTypeDetailsLoaded,
    questionnaireTypesLoaded,
    readinessToChangeDetailsLoaded,
    reasonForLeavingDetailsLoaded,
    referralSourceDetailsLoading,
    referralSourceDetailsLoaded,
    religionDetailsLoaded,
    rightToWorkDocTypesLoaded,
    serviceDetailsCreated,
    serviceDetailsForContractIdLoaded,
    serviceDetailsLoaded,
    signPostingTypesLoaded,
    signPostingOrganisationsLoaded,
    smokingCoResultsLoaded,
    smokingSelfReportedQuitsLoaded,
    smokingTypesLoaded,
    smokingUsingECigarettesLoaded,
    timelineStatusDetailsLoaded,
    timelineTypeDetailsLoaded,
    timeUnemployedDetailsLoaded,
    transportMethodDetailsLoaded,
    uniqueIdentifierTypesLoaded,
    uploaderTypesLoaded,
    updateCollectionPending,
    vacancyRejectionReasonsDetailsLoaded,
    vacancyRejectionReasonsDetailsLoading,
    vacancyStageDetailsLoaded,
    wellbeingDetailsLoading,
    wellbeingDetailsLoaded,
    workConditionalityGroupDetailsLoaded,
    workConfidenceLoaded
} = slice.actions;

export default slice.reducer;
