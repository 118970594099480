import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { loadCommunicationTemplates } from '../../../store/communicationService';
import { loadFormStatusDetails, loadFormTypes } from '../../../store/directusService';
import { selectCurrentForm, selectCurrentParticipant } from '../../../store/participantSelectors';
import { loadForm, loadFormContractByContract, loadForms } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { loadUser, loadUsersByServiceIds } from '../../../store/userService';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ConductUCD603DoubtForm from './ConductUCD603DoubtForm';
import ConductWHPER1ExitForm from './ConductWHPER1ExitForm';

import classes from './formStyles/form.module.css';

const MandatoryForm = () => {
    // HOOKS;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    // LOCAL STATE
    const [formId, setFormId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentForm = useSelector(selectCurrentForm);
    const formStatusDetails = useSelector(
        (state) => state.entities.directusService.formStatusDetails
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadCommunicationTemplates());
        dispatch(loadFormTypes());
        dispatch(loadFormStatusDetails());
        dispatch(loadForms());
        dispatch(loadFormContractByContract(currentParticipant.contractId));
    }, []);

    useEffect(() => {
        if (state && 'formId' in state) {
            setFormId(state.formId);
        }
    }, [state]);

    useEffect(() => {
        if (formId) {
            dispatch(loadForm(formId));
        }
    }, [formId]);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser)?.length < 1) return;
        dispatch(loadUser(loggedInUser.id));
        if ('serviceIds' in loggedInUser)
            loggedInUser.serviceIds.length > 0 &&
                dispatch(loadUsersByServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser.id]);

    // HELPER FNS
    const loadingError = () => {
        if (Object.keys(currentForm)?.length < 1) return 'No current form found';
        if (formStatusDetails?.length < 1) return 'No form status details';
    };

    // EVENT HANDLERS
    const onComplete = () => {
        navigate('/edit_participant');
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={classes.forms}>
            {currentForm.name.includes('UCD603') ? (
                <ConductUCD603DoubtForm
                    formId={formId}
                    onClose={onComplete}
                    roles={loggedInUser.roles}
                />
            ) : currentForm.name.includes('WHPER1') ? (
                <ConductWHPER1ExitForm
                    formId={formId}
                    onClose={onComplete}
                    roles={loggedInUser.roles}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default MandatoryForm;
