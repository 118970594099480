// This is a slice of state for the Document Service.
import { createSlice } from '@reduxjs/toolkit';

import { getDocumentURL, getParticipantURL } from '../api/commonHTTP';
import { DEFAULT_PAGE_LOAD_SIZE } from '../api/pagination';
import { getEmptyPaginationState } from '../utils/objectUtils';

import { apiCallBegan, apiDownload, apiUpload } from './api';

const slice = createSlice({
    name: 'documentService',
    initialState: {
        documentUploadReturnData: [],
        participantDocuments: [],
        participantDocumentsMetaData: getEmptyPaginationState(),
        participantDocumentErrors: [],
        loadingParticipantDocumentDetails: false,
        uploadErrorFiles: [],
        uploadErrors: [],
        errors: [],
        errorMessage: '',
        successMessage: ''
    },
    reducers: {
        clearParticipantDocuments: (state) => {
            state.participantDocuments = [];
        },

        clearUploadErrors: (state) => {
            state.uploadErrors = [];
            state.uploadErrorFiles = [];
            state.participantDocumentErrors = [];
        },

        documentAdded: (state, action) => {
            state.documentUploadReturnData = action.payload;
            if (!Array.isArray(action.payload)) return;
            const errors = action.payload?.filter((el) => el.errorCode);
            state.participantDocumentErrors = [...state.participantDocumentErrors, ...errors];
            state.uploadErrors = [...state.uploadErrors, ...errors];
        },

        addDocumentFailure: (state, action) => {
            state.uploadErrors = action.error;
        },

        addBatchDocumentFailure: (state, action) => {
            state.uploadErrorFiles = [...state.uploadErrorFiles, action.error];
        },

        participantDocumentDetailsLoading: (state) => {
            state.loadingParticipantDocumentDetails = true;
        },

        participantDocumentDetailsLoaded: (state, action) => {
            state.loadingParticipantDocumentDetails = false;
            const { content, ...rest } = action.payload;
            state.participantDocuments = rest.first
                ? content
                : [...state.participantDocuments, ...content];
            state.participantDocumentsMetaData = rest;
        },

        participantDocumentDetailsLoadingFailed: (state, action) => {
            state.loadingParticipantDocumentDetails = false;
            state.errorMessage = action.payload;
        },

        participantDocumentAdded: (state, action) => {
            state.documentUploadReturnData = action.payload;
            const errors = action.payload.filter((el) => el.errorCode);
            state.participantDocumentErrors = [...state.participantDocumentErrors, ...errors];
        },

        participantDocumentDetailsAdded: (state, action) => {
            const { content, ...rest } = action.payload;
            state.participantDocuments = [...state.participantDocuments, ...content];
            state.participantDocumentsMetaData = {
                ...state.participantDocumentsMetaData,
                totalElements:
                    state.participantDocumentsMetaData.totalElements + rest.totalElements,
                numberOfElements:
                    state.participantDocumentsMetaData.numberOfElements + rest.numberOfElements
            };
        },

        participantDocumentDetailsUpdated: (state, action) => {
            state.participantDocuments = state.participantDocuments.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        participantDocumentDeleted: (state, action) => {
            state.participantDocuments = state.participantDocuments.filter(
                (el) => el.id !== action.payload
            );
        },

        fileUploadErrorEntryDeleted: (state, action) => {
            state.participantDocumentErrors = state.participantDocumentErrors.filter(
                (el) => el.filename !== action.payload
            );
        },

        deleteFileUploadErrorEntries: (state) => {
            state.participantDocumentErrors = [];
        },

        errorOccurred: (state, action) => {
            state.errorMessage = action.payload;
        },

        errorMessageSet: (state, action) => {
            state.errorMessage = action.payload;
            state.successMessage = '';
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        }
    }
});

export const addClaimQueueProceedCsv = (data, successMessage) =>
    apiUpload({
        url: getParticipantURL() + `rest/csv/claim-queue/proceed`,
        method: 'post',
        data,
        onSuccess: documentAdded.type,
        successMessage,
        onError: addDocumentFailure.type
    });

export const addClaimQueueCreatePaidCsv = (data, successMessage) =>
    apiUpload({
        url: getParticipantURL() + `rest/csv/claim-queue/create-paid`,
        method: 'post',
        data,
        onSuccess: documentAdded.type,
        successMessage,
        onError: addDocumentFailure.type
    });

export const addClaimQueueCreateCsv = (data, successMessage) =>
    apiUpload({
        url: getParticipantURL() + `rest/csv/claim-queue/create`,
        method: 'post',
        data,
        onSuccess: documentAdded.type,
        successMessage,
        onError: addDocumentFailure.type
    });

export const addParticipantCsv = (data, successMessage) =>
    apiUpload({
        url: getParticipantURL() + `rest/csv/participant`,
        method: 'post',
        data,
        onSuccess: documentAdded.type,
        successMessage,
        onError: addBatchDocumentFailure.type
    });

export const addPostcodeCsv = (contractId, data, successMessage) =>
    apiUpload({
        url: getParticipantURL() + `rest/csv/postcode-service-team/${contractId}`,
        method: 'post',
        data,
        onSuccess: documentAdded.type,
        successMessage,
        onError: addDocumentFailure.type
    });

export const deletePostcodeCsvData = (contractId, successMessage) =>
    apiUpload({
        url: getParticipantURL() + `rest/csv/postcode-service-team/${contractId}`,
        method: 'delete',
        successMessage,
        onError: errorOccurred.type
    });

export const addParticipantDocuments = (participantId, data, successMessage) =>
    apiUpload({
        url: getDocumentURL() + `rest/document/participant/${participantId}`,
        method: 'post',
        data,
        onSuccess: participantDocumentAdded.type,
        successMessage,
        onError: errorOccurred.type
    });

export const updateParticipantDocument = (filename, participantId, data) =>
    apiCallBegan({
        url: getDocumentURL() + `rest/document/participant/${participantId}/${filename}/meta`,
        method: 'put',
        data,
        onSuccess: participantDocumentDetailsUpdated.type,
        successMessage: `${filename} has been updated`,
        onError: errorOccurred.type
    });

export const loadParticipantDocumentDetails = (
    participantId,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'dateCreated',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getDocumentURL() +
            `rest/document/participant/${participantId}?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        onStart: participantDocumentDetailsLoading.type,
        onSuccess: participantDocumentDetailsLoaded.type,
        onError: participantDocumentDetailsLoadingFailed.type
    });

export const loadNewParticipantDocuments = (
    ids,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'filename',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getDocumentURL() +
            `rest/document/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data: { ids: ids },
        onSuccess: participantDocumentDetailsAdded.type,
        onError: errorOccurred.type
    });

export const downloadParticipantDocument = (participantId, filename) =>
    apiDownload({
        url: getDocumentURL() + `rest/document/participant/${participantId}/${filename}`,
        filename,
        successMessage: `${filename} has been downloaded`,
        onError: errorOccurred.type
    });

export const deleteParticipantDocument = (participantId, filename) =>
    apiCallBegan({
        url: getDocumentURL() + `rest/document/participant/${participantId}/${filename}`,
        method: 'delete',

        successMessage: `${filename} has been deleted`,
        onError: errorOccurred.type
    });

export const onClearUploadErrors = () => clearUploadErrors();
export const setErrorMessage = (message) => errorMessageSet(message);
export const deleteFileUploadErrorEntry = (filename) => fileUploadErrorEntryDeleted(filename);
export const { deleteFileUploadErrorEntries, clearParticipantDocuments } = slice.actions;
export const onDeleteParticipantDocument = (id) => participantDocumentDeleted(id);

const {
    addDocumentFailure,
    addBatchDocumentFailure,
    clearUploadErrors,
    documentAdded,
    errorMessageSet,
    errorOccurred,
    fileUploadErrorEntryDeleted,
    participantDocumentAdded,
    participantDocumentDeleted,
    participantDocumentDetailsLoading,
    participantDocumentDetailsLoadingFailed,
    participantDocumentDetailsLoaded,
    participantDocumentDetailsAdded,
    participantDocumentDetailsUpdated
} = slice.actions;

export default slice.reducer;
