import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadCommunicationOptOutTypes,
    loadGenderDetails,
    loadParticipantStatusDetails,
    loadParticipantStatusReasonDetails,
    loadPreferredPronounDetails
} from '../../../store/directusService';
import {
    loadContractConstants,
    searchEligibilityGroups,
    searchParticipantTimelineEvent,
    updateParticipant
} from '../../../store/participantService';
import { selectUserRoles, selectUsersById, selectUserTypes } from '../../../store/userSelectors';
import {
    clearTeamsForService,
    clearUsersBySearch,
    loadTeamsForService,
    loadUserById,
    loadUsersBySearch,
    loadUserTypes
} from '../../../store/userService';
import { getConfiguredItems, getNameFromId } from '../../../utils/directusFunctions';
import { getEmptyErrorState, validate } from '../../../utils/formValidation/validator';
import { addFullNameToArray } from '../../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    LOCAL_ADMIN,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import SingleSelect from '../../formElements/SingleSelect';
import TextInputField from '../../formElements/TextInputField';
import LoadingSpinner from '../../ui/LoadingSpinner';
import AuditModal from '../../ui/modals/AuditModal';

import { primaryDetailsValidationFields } from './PrimaryDetailsValidationFields';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const PrimaryDetails = ({ isAuditable }) => {
    // HOOKS
    const dispatch = useDispatch();
    const validationFields = { ...primaryDetailsValidationFields };

    // LOCAL STATE
    const initialState = {
        id: null,
        firstName: '',
        middleName: '',
        lastName: '',
        preferredName: '',
        preferredPronounsId: '',
        preferredPronouns: '',
        genderId: '',
        userId: '',
        statusId: '',
        statusReasonId: '',
        dateOfBirth: '',
        uniqueIdentifier: '',
        repeatUniqueIdentifier: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        postcode: '',
        primaryPhoneNumber: '',
        secondaryPhoneNumber: '',
        emailAddress: '',
        preferredMethodOfContact: '',
        communicationTypeOptOutIds: [],
        careOfAddress: 'No',
        startDate: '',
        eligibilityGroupId: '',
        referralDate: '',
        goodNewsStory: 'No',
        contractId: '',
        serviceId: '',
        teamId: '',
        uniqueIdentifierType: '',
        orionMembershipId: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER, PRAP, QUALITY, SUPERUSER];
    const uniqueIdRoles = [LOCAL_ADMIN, MANAGER, QUALITY, SUPERUSER];
    const changeTeamRoles = [ADVISER, RECRUITMENT_MANAGER, MANAGER, QUALITY, SUPERUSER];
    const qualityAndUpRoles = [QUALITY, SUPERUSER];
    const prapAndUpRoles = [...qualityAndUpRoles, PRAP];
    const bmAndUpRoles = [...prapAndUpRoles, MANAGER];
    const changeStartDateRoles = [QUALITY, SUPERUSER];
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [newEntry, setNewEntry] = useState(initialState);
    const [initialStateOnEntry, setInitialStateOnEntry] = useState(initialState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [arrayGenders, setArrayGenders] = useState([]);
    const [displayPreferredPronounsOther, setDisplayPreferredPronounsOther] = useState(true);
    const [statusDetails, setStatusDetails] = useState([]);
    const [statusReasons, setStatusReasons] = useState([]);

    const [uniqueIdentifierType, setUniqueIdentifierType] = useState('');
    const [eligibilityGroups, setEligibilityGroups] = useState([]);
    const [arrayAdvisers, setArrayAdvisers] = useState([]);
    const [preSelectedCommunicationOptOutTypeIds, setPreSelectedCommunicationOptOutTypeIds] =
        useState([]);
    const [preSelectedCommunicationOptOutTypes, setPreSelectedCommunicationOptOutTypes] = useState(
        []
    );
    const [users, setUsers] = useState([]);
    const [showAuditModal, setShowAuditModal] = useState(false);
    const [auditLabel, setAuditLabel] = useState('');

    // STORE STATE
    const roles = useSelector(selectUserRoles);
    const { errorMessage, successMessage } = useSelector((state) => state.entities.formsState);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const eligibilityGroupsForContractId = useSelector(
        (state) => state.entities.participantService.eligibilityGroupSearch
    );
    const {
        communicationOptOutTypes,
        genderDetails,
        participantStatusDetails,
        participantStatusReasonDetails,
        preferredPronounDetails
    } = useSelector((state) => state.entities.directusService);
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );
    const paginatedUsers = useSelector((state) => state.entities.userService.usersBySearch);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersBySearchMetaData
    );
    const usersById = useSelector(selectUsersById);
    const userTypes = useSelector(selectUserTypes);
    const teamsForService = useSelector((state) => state.entities.userService.teamsForService);

    // USE EFFECTS
    useEffect(() => {
        validationFields.button.checkDiff = initialStateOnEntry;
    }, [initialStateOnEntry]);

    useEffect(() => {
        dispatch(clearUsersBySearch());
        dispatch(clearTeamsForService());
        if (!userTypes.length) dispatch(loadUserTypes());
        if (!communicationOptOutTypes.length) dispatch(loadCommunicationOptOutTypes());
    }, []);

    useEffect(() => {
        setUsers(paginatedUsers.flat());
    }, [paginatedUsers]);

    useEffect(() => {
        if (!currentParticipant?.id || !userTypes.length) return;
        setErrors([]);
        if (currentParticipant.userId) {
            const initialAdviser = usersById[currentParticipant.userId];
            if (!initialAdviser) {
                dispatch(loadUserById(currentParticipant.userId));
            } else {
                setNewArrayAdvisers([initialAdviser]);
            }
        } else {
            setArrayAdvisers([]);
        }

        setUniqueIdentifierType(currentParticipant.uniqueIdentifierType);
        setArrayGenders([]);
        dispatch(loadContractConstants(currentParticipant.contractId));
        dispatch(loadGenderDetails());
        dispatch(loadParticipantStatusDetails());
        dispatch(loadParticipantStatusReasonDetails());
        dispatch(loadPreferredPronounDetails());
        setEligibilityGroups([]);
        dispatch(searchEligibilityGroups({ contractId: currentParticipant.contractId }));
        dispatch(loadTeamsForService({ serviceIds: [currentParticipant.serviceId] }));
    }, [currentParticipant.id, userTypes]);

    useEffect(() => {
        if (currentParticipant.userId && usersById[currentParticipant.userId]) {
            setNewArrayAdvisers([usersById[currentParticipant.userId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (!participantStatusDetails.length) return;

        const createdStatus = participantStatusDetails.find((el) => el.name === 'Created');
        const tempStatus = currentParticipant.statusId || createdStatus.id;
        if (
            !contractConstants.participantStatuses ||
            contractConstants.participantStatuses.length === 0 ||
            !contractConstants.participantStatuses.find(
                (el) => el.participantStatusId === tempStatus
            )
        ) {
            setStatusDetails([createdStatus]);
            updateInitialEntry({ ...currentParticipant, statusId: createdStatus.id });
            return;
        }
        const currentStatus = contractConstants.participantStatuses.find(
            (el) => el.participantStatusId === tempStatus
        );
        const activeStatuses = currentStatus.toParticipantStatuses.filter((el) => {
            const status = contractConstants.participantStatuses.find(
                (entry) => entry.participantStatusId === el.participantStatusId
            );

            if (hasRole(qualityAndUpRoles, roles)) return status.active;
            switch (el.selectableBy) {
                case 'prap_and_up':
                    if (hasRole(prapAndUpRoles, roles)) return status.active;
                    return false;
                case 'bm_and_up':
                    if (hasRole(bmAndUpRoles, roles)) return status.active;
                    return false;
                default:
                    return false;
            }
        });
        const newStatusDetails = activeStatuses.map((el) =>
            participantStatusDetails.find((entry) => entry.id === el.participantStatusId)
        );
        const currentStatusOfPS = participantStatusDetails.find(
            (el) => el.id === currentStatus.participantStatusId
        );
        setStatusDetails([...newStatusDetails, currentStatusOfPS]);
        updateInitialEntry({ ...currentParticipant, statusId: currentStatus.participantStatusId });
    }, [contractConstants, participantStatusDetails]);

    useEffect(() => {
        if (initialStateOnEntry.statusId.length === 0) return;
        if (newEntry.statusId === initialStateOnEntry.statusId) {
            const staticReason = participantStatusReasonDetails?.find(
                (el) => el.id === initialStateOnEntry.statusReasonId
            );
            setNewEntry((prev) => ({
                ...prev,
                statusReasonId: initialStateOnEntry.statusReasonId
            }));
            setStatusReasons(staticReason ? [staticReason] : []);
            return;
        }
        if (!contractConstants.participantStatuses) return;
        const newStatus = contractConstants.participantStatuses.find(
            (el) => el.participantStatusId === initialStateOnEntry.statusId
        );
        const newStatusReason = newStatus?.toParticipantStatuses.find(
            (el) => el.participantStatusId === newEntry.statusId
        );
        if (!newStatusReason) return;

        const newStatusReasons = participantStatusReasonDetails.filter((el) =>
            newStatusReason.participantStatusReasonIds.includes(el.id)
        );
        setStatusReasons(newStatusReasons);
        setNewEntry((prev) => ({ ...prev, statusReasonId: newStatusReasons[0].id }));
    }, [statusDetails, newEntry.statusId, initialStateOnEntry.statusId]);

    useEffect(() => {
        if (genderDetails?.length && arrayGenders?.length === 0) {
            setArrayGenders(getConfiguredItems(genderDetails, currentParticipant.contractId));
        }
    }, [genderDetails]);

    useEffect(() => {
        if (!eligibilityGroupsForContractId || eligibilityGroupsForContractId?.length < 1) return;
        setEligibilityGroups(eligibilityGroupsForContractId);
    }, [eligibilityGroupsForContractId]);

    useEffect(() => {
        if (communicationOptOutTypes?.length) {
            if (newEntry?.communicationTypeOptOutIds) {
                const preSelectedCommunicationOptOutTypes = communicationOptOutTypes.filter((el) =>
                    newEntry.communicationTypeOptOutIds.includes(el.id)
                );
                setPreSelectedCommunicationOptOutTypes(preSelectedCommunicationOptOutTypes);
                setPreSelectedCommunicationOptOutTypeIds(
                    preSelectedCommunicationOptOutTypes.map((el) => el.id)
                );
            }
        }
    }, [communicationOptOutTypes, initialStateOnEntry.communicationTypeOptOutIds]);

    useEffect(() => {
        if (!isUpdating) {
            setIsDisabled(Object.values(errors).some((el) => el.error));
        }
    }, [errors]);

    useEffect(() => {
        if (users.length < 1) return;
        let updatedAdvisers = users;
        if (newEntry.userId && !updatedAdvisers.some((el) => el.id === newEntry.userId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = arrayAdvisers.find((el) => el.id === newEntry.userId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setNewArrayAdvisers(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (currentParticipant.teamId) {
            dispatch(clearUsersBySearch());
        }
    }, [currentParticipant.teamId]);

    useEffect(() => {
        if (successMessage === `Participant primary details have been updated`) {
            setInitialStateOnEntry(newEntry);
            setIsUpdating(false);
            setIsDisabled(false);
            updateInitialEntry();
        }
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) setIsDisabled(false);
    }, [errorMessage]);

    // HELPER FNS
    const setNewArrayAdvisers = (newAdvisers) => {
        const updatedAdvisers = addFullNameToArray(newAdvisers);
        setArrayAdvisers(updatedAdvisers);
    };

    const getPreferredPronouns = (arr, id) => {
        if (!arr) return;
        const name = getNameFromId(arr, id);
        setDisplayPreferredPronounsOther(name === 'Other');
        return name;
    };

    const getAdviserTypeIds = () =>
        userTypes.filter((el) => el.role === MANAGER || el.role === ADVISER).map((el) => el.id);

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const updateInitialEntry = (currentEntry = currentParticipant) => {
        setNewEntry({
            ...currentEntry,
            repeatUniqueIdentifier: currentEntry.uniqueIdentifier
        });
        setInitialStateOnEntry({
            ...currentEntry,
            repeatUniqueIdentifier: currentEntry.uniqueIdentifier
        });
    };

    const loadingError = () => {
        if (genderDetails?.length < 1) return 'No gender details available';
        if (participantStatusDetails?.length < 1) return 'No participant status details available';
        if (participantStatusReasonDetails?.length < 1)
            return 'No participant status reason details available';
        if (eligibilityGroupsForContractId?.length < 1)
            return 'No eligibility groups for contract available';
        if (Object.keys(currentParticipant)?.length < 1) return 'No current participant';
        if (arrayGenders?.length < 1)
            return "No genders available. Probable cause: there are no gender details contracts matching the participant's contract";
        if (communicationOptOutTypes?.length < 1) return 'No communication opt out types available';
        if (currentParticipant.userId && arrayAdvisers.length < 1) return 'No advisers available';
    };

    // EVENT HANDLERS
    const onUpdate = (key, value) => {
        if (errors[key]) clearError(key);
        if (value == null) value = '';
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                loadUsersBySearch(
                    {
                        teamIds: [currentParticipant?.teamId],
                        active: true,
                        userTypeIds: getAdviserTypeIds()
                    },
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validation = validate(newEntry, validationFields, {
            statusId: initialStateOnEntry.statusId
        });
        setErrors(validation.errors);
        if (!validation.isValid) return;
        const updatedPronouns = getPreferredPronouns(
            preferredPronounDetails,
            newEntry.preferredPronounsId
        );
        const payload = {
            ...newEntry,
            preferredPronouns: updatedPronouns !== 'Other' ? '' : newEntry.preferredPronouns
        };
        setIsUpdating(true);
        setIsDisabled(true);
        dispatch(updateParticipant(payload));
    };

    // EVENT HANDLER CALLBACKS
    const onSearchAuditItems = (label) => {
        dispatch(
            searchParticipantTimelineEvent({
                participantIds: [newEntry.id],
                field: label
            })
        );
        setShowAuditModal(true);
        setAuditLabel(label);
    };

    const onModalClose = (e, reason) => {
        if (reason === 'backdropClick') return;
        setShowAuditModal(false);
        setAuditLabel('');
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h2 className={app.sectionHeading}>General Details</h2>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'firstName'}
                            label={'First Name'}
                            placeholder={'Enter first name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.firstName || ''}
                            error={errors.firstName}
                            onChange={(e) => onUpdate('firstName', e.target.value)}
                        />
                        <TextInputField
                            id={'middleName'}
                            label={'Middle Name'}
                            placeholder={'Enter middle name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.middleName || ''}
                            error={errors.middleName}
                            onChange={(e) => onUpdate('middleName', e.target.value)}
                        />
                        <TextInputField
                            id={'lastName'}
                            label={'Last Name'}
                            placeholder={'Enter last name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.lastName || ''}
                            error={errors.lastName}
                            onChange={(e) => onUpdate('lastName', e.target.value)}
                        />
                        <TextInputField
                            id={'preferredName'}
                            label={'Preferred Name'}
                            placeholder={'Enter preferred name'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            value={newEntry.preferredName || ''}
                            error={errors.preferredName}
                            onChange={(e) => onUpdate('preferredName', e.target.value)}
                        />
                        <SingleSelect
                            id={'genderId'}
                            label={'Gender'}
                            placeholder={'Select gender...'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={arrayGenders || []}
                            selectedId={newEntry.genderId || ''}
                            selected={arrayGenders?.find((el) => el.id === newEntry.genderId) || {}}
                            error={errors.genderId}
                            onChange={(chosenId) => onUpdate('genderId', chosenId)}
                        />
                        <SingleSelect
                            id={'preferredPronouns'}
                            label={'Preferred Pronouns'}
                            placeholder="Select preferred pronoun..."
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={preferredPronounDetails || []}
                            selectedId={newEntry.preferredPronounsId || ''}
                            selected={
                                preferredPronounDetails?.find(
                                    (el) => el.id === newEntry.preferredPronounsId
                                ) || {}
                            }
                            onChange={(chosenId) => {
                                onUpdate('preferredPronounsId', chosenId);
                                getPreferredPronouns(preferredPronounDetails, chosenId);
                            }}
                        />
                        <div hidden={!displayPreferredPronounsOther}>
                            <TextInputField
                                id={'preferredPronounsOther'}
                                label={'Preferred Pronouns Other Name'}
                                placeholder={'Enter preferred pronouns name'}
                                disabled={!hasRole(acceptedRoles, roles)}
                                value={newEntry.preferredPronouns || ''}
                                error={errors.preferredPronouns}
                                onChange={(e) => onUpdate('preferredPronouns', e.target.value)}
                            />
                        </div>
                    </div>

                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'adviserId'}
                            label={'Adviser'}
                            placeholder="Select adviser..."
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            menuItems={arrayAdvisers || []}
                            selectedId={newEntry.userId || ''}
                            selected={arrayAdvisers?.find((el) => el.id === newEntry.userId) || {}}
                            onChange={(chosenId) => onUpdate('userId', chosenId)}
                            onLoadMoreItems={onLoadMoreAdvisers}
                            moreItemsToLoad={!totalUsers || users.length < totalUsers}
                        />
                        <SingleSelect
                            id={'statusId'}
                            label={'Status'}
                            placeholder="Select status..."
                            disabled={!hasRole(bmAndUpRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            mandatory={true}
                            menuItems={statusDetails || []}
                            selectedId={newEntry.statusId || ''}
                            selected={
                                participantStatusDetails?.find(
                                    (el) => el.id === newEntry.statusId
                                ) || {}
                            }
                            error={errors.statusId}
                            onChange={(chosenId) => onUpdate('statusId', chosenId)}
                        />
                        <SingleSelect
                            id={'statusReason'}
                            label={'Status Reason'}
                            placeholder="Select status reason..."
                            disabled={
                                !hasRole(bmAndUpRoles, roles) ||
                                initialStateOnEntry.statusId === newEntry.statusId
                            }
                            mandatory={newEntry.statusId !== initialStateOnEntry.statusId}
                            menuItems={statusReasons || []}
                            selectedId={newEntry.statusReasonId || ''}
                            selected={
                                participantStatusReasonDetails?.find(
                                    (el) => el.id === newEntry.statusReasonId
                                ) || {}
                            }
                            error={errors.statusReasonId}
                            onChange={(chosenId) => onUpdate('statusReasonId', chosenId)}
                        />

                        <DateSelect
                            id="dateOfBirth"
                            label="Date of Birth"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.dateOfBirth}
                            error={errors.dateOfBirth}
                            onDateChange={(date) => onUpdate('dateOfBirth', date)}
                        />

                        <TextInputField
                            id={'uniqueId'}
                            label={
                                uniqueIdentifierType == null
                                    ? ''
                                    : uniqueIdentifierType
                                          .toString()
                                          .replace('_', ' ')
                                          .replace('NUMBER', 'Number')
                                          .concat(' Unique ID')
                            }
                            placeholder={'Enter unique identifier'}
                            disabled={!hasRole(uniqueIdRoles, roles)}
                            mandatory={hasRole(uniqueIdRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.uniqueIdentifier || ''}
                            error={errors.uniqueIdentifier}
                            onPaste={(e) => e.preventDefault()}
                            onChange={(e) => onUpdate('uniqueIdentifier', e.target.value)}
                        />
                        <TextInputField
                            id={'repeatUniqueId'}
                            label={
                                uniqueIdentifierType == null
                                    ? ''
                                    : uniqueIdentifierType
                                          .toString()
                                          .replace('_', ' ')
                                          .replace('NUMBER', 'Number')
                                          .concat(' Repeat Unique ID')
                            }
                            placeholder={'Repeat unique identifier'}
                            disabled={!hasRole(uniqueIdRoles, roles)}
                            mandatory={hasRole(uniqueIdRoles, roles)}
                            value={newEntry.repeatUniqueIdentifier || ''}
                            error={errors.repeatUniqueIdentifier}
                            onPaste={(e) => e.preventDefault()}
                            onChange={(e) => onUpdate('repeatUniqueIdentifier', e.target.value)}
                        />
                    </div>
                </div>

                <h2 className={app.sectionHeading}>Contact Details</h2>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'address1'}
                            label={'Address Line 1'}
                            placeholder={'Enter address line 1'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.address1 || ''}
                            error={errors.address1}
                            onChange={(e) => onUpdate('address1', e.target.value)}
                        />
                        <TextInputField
                            id={'address2'}
                            label={'Address Line 2'}
                            placeholder={'Enter address line 2'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.address2 || ''}
                            error={errors.address2}
                            onChange={(e) => onUpdate('address2', e.target.value)}
                        />
                        <TextInputField
                            id={'address3'}
                            label={'Address Line 3'}
                            placeholder={'Enter address line 3'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.address3 || ''}
                            error={errors.address3}
                            onChange={(e) => onUpdate('address3', e.target.value)}
                        />
                        <TextInputField
                            id={'city'}
                            label={'City'}
                            placeholder={'Enter city'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.city || ''}
                            error={errors.city}
                            onChange={(e) => onUpdate('city', e.target.value)}
                        />
                        <TextInputField
                            id={'postcode'}
                            label={'Postcode'}
                            placeholder={'Enter Postcode'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.postcode || ''}
                            error={errors.postcode}
                            onChange={(e) => onUpdate('postcode', e.target.value)}
                        />
                    </div>

                    <div className={form.formColumn}>
                        <TextInputField
                            id="primaryPhoneNumber"
                            label="Primary Phone Number"
                            placeholder={'Enter primary phone number'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.primaryPhoneNumber || ''}
                            error={errors.primaryPhoneNumber}
                            onChange={(e) => onUpdate('primaryPhoneNumber', e.target.value)}
                        />
                        <TextInputField
                            id="secondaryPhoneNumber"
                            label="Secondary Phone Number"
                            placeholder={'Enter secondary phone number'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.secondaryPhoneNumber || ''}
                            error={errors.secondaryPhoneNumber}
                            onChange={(e) => onUpdate('secondaryPhoneNumber', e.target.value)}
                        />
                        <TextInputField
                            id={'emailAddress'}
                            label={'Email Address'}
                            placeholder={'Enter email address'}
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.emailAddress || ''}
                            error={errors.emailAddress}
                            onChange={(e) => onUpdate('emailAddress', e.target.value)}
                        />
                        <RadioButtons
                            id="preferredMethodOfContact"
                            label="Preferred Method of Contact"
                            disabled={!hasRole(acceptedRoles, roles)}
                            radioButtons={[
                                { id: 'Email', label: 'Email', onChangeVal: 'email' },
                                { id: 'Phone', label: 'Phone', onChangeVal: 'phone' },
                                { id: 'Post', label: 'Post', onChangeVal: 'post' }
                            ]}
                            value={newEntry.preferredMethodOfContact}
                            onChange={(option) => onUpdate('preferredMethodOfContact', option)}
                        />
                        <MultiSelect
                            id="communicationOptOut"
                            label="Communication Opt Out"
                            placeholder="Select communication type(s)"
                            disabled={!hasRole(acceptedRoles, roles)}
                            menuItems={communicationOptOutTypes || []}
                            preSelectedIds={preSelectedCommunicationOptOutTypeIds || []}
                            preSelects={preSelectedCommunicationOptOutTypes}
                            onChange={(chosenIds) =>
                                onUpdate('communicationTypeOptOutIds', chosenIds)
                            }
                        />
                        <RadioButtons
                            id="careOfAddress"
                            label="Care of Address"
                            disabled={!hasRole(acceptedRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.careOfAddress ?? false}
                            onChange={(option) => onUpdate('careOfAddress', option)}
                        />
                    </div>
                </div>

                <h2 className={app.sectionHeading}>Contract Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <DateSelect
                            label="Start Date"
                            disabled={!hasRole(changeStartDateRoles, roles)}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.startDate}
                            onDateChange={(date) => onUpdate('startDate', date)}
                        />

                        <SingleSelect
                            id={'teamId'}
                            label={'Team'}
                            placeholder="Select team..."
                            disabled={!hasRole(changeTeamRoles, roles)}
                            menuItems={teamsForService || []}
                            selectedId={newEntry.teamId || ''}
                            selected={
                                teamsForService?.find((el) => el.id === newEntry.teamId) || {}
                            }
                            onChange={(chosenId) => onUpdate('teamId', chosenId)}
                        />
                    </div>

                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'eligibilityGroupId'}
                            label={'Eligibility Group'}
                            placeholder="Select eligibility group..."
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={eligibilityGroups || []}
                            selectedId={newEntry.eligibilityGroupId || ''}
                            selected={
                                eligibilityGroups?.find(
                                    (el) => el.id === newEntry.eligibilityGroupId
                                ) || {}
                            }
                            error={errors.eligibilityGroupId}
                            onChange={(chosenId) => onUpdate('eligibilityGroupId', chosenId)}
                        />
                        <DateSelect
                            id="referralDate"
                            label="Referral Date"
                            disabled={!hasRole(prapAndUpRoles, roles)}
                            mandatory={true}
                            isAuditable={isAuditable}
                            onSearchAuditItems={onSearchAuditItems}
                            value={newEntry.referralDate}
                            error={errors.referralDate}
                            onDateChange={(date) => onUpdate('referralDate', date)}
                        />
                    </div>
                </div>

                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <RadioButtons
                            id="goodNewsStory"
                            disabled={!hasRole(acceptedRoles, roles)}
                            label="Possible Good News Story?"
                            value={newEntry.goodNewsStory ?? false}
                            onChange={(option) => onUpdate('goodNewsStory', option)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        {newEntry.orionMembershipId && (
                            <div className={form.staticField}>
                                <label className={form.formLabel}>Orion ID</label>
                                <div className={form.staticText}>{newEntry.orionMembershipId}</div>
                            </div>
                        )}
                    </div>
                </div>
                {showAuditModal && (
                    <AuditModal isOpen={showAuditModal} label={auditLabel} onClose={onModalClose} />
                )}

                <Button
                    id="createContract"
                    content="Update Primary Details"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </div>
    );
};

PrimaryDetails.propTypes = {
    id: PropTypes.string,
    isAuditable: PropTypes.bool
};

export default PrimaryDetails;
