import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import { loadVacancyStageDetails } from '../../../../store/directusService';
import {
    clearVacancies,
    loadVacanciesByBusinessRecordId,
    loadVacancySubmissionsByVacancyIdForBusiness,
    searchBusinessesByCodeOrName,
    searchBusinessesByLoggedInUser
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { stableSort } from '../../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../../utils/uiConstants';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import Button from '../../../formElements/Button';
import SearchOnEnter from '../../../search/SearchOnEnter';
import ResultsTable from '../../../table/ResultsTable';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import BusinessTableRow from './BusinessTableRow';

import classes from '../../recruitmentStyles/businessToolbar.module.css';

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const sortOrder = 'asc';
const headCells = [
    { id: 'code', numeric: false, label: 'ID', sortable: true },
    { id: 'name', numeric: false, label: 'Business Name', sortable: true },
    { id: 'vacancies', numeric: false, label: 'Active Vacancies', align: 'center' },
    { id: 'positions', numeric: false, label: 'No. Positions', align: 'center' },
    { id: 'postcode', numeric: false, label: 'Postcode', sortable: true }
];
const BusinessManagement = ({ isOpen }) => {
    // HOOKS
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [activeBusinesses, setActiveBusinesses] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsForTiming, setRowsForTiming] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [jobsOfferedForVacancies, setJobsOfferedForVacancies] = useState([]);
    const [vacancyCount, setVacancyCount] = useState(0);
    const [vacancyProcessedCount, setVacancyProcessedCount] = useState(0);
    const [vacancyProcessed, setVacancyProcessed] = useState(false);
    const [vacanciesLoaded, setVacanciesLoaded] = useState(false);
    const [businessesLoaded, setBusinessesLoaded] = useState(false);
    const [totalResults, setTotalResults] = useState(0);
    const [rowMetaData, setRowMetaData] = useState({
        order: sortOrder,
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });
    const [openId, setOpenId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const {
        businessesByLoggedInUser,
        businessesByLoggedInUserMetaData,
        businessesByCodeOrName,
        vacancies,
        vacancySubmissionsForBusiness
    } = useSelector((state) => state.entities.recruitmentService);
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );

    // USE EFFECTS
    useEffect(() => {
        setSearchTerm('');
    }, [isOpen]);

    useEffect(() => {
        if (!loggedInUser || !('id' in loggedInUser)) return;
        dispatch(clearVacancies());
        businessesByLoggedInUser?.length < 1
            ? dispatch(searchBusinessesByLoggedInUser({ ownerId: loggedInUser.id }))
            : setBusinessesLoaded(true);
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, [loggedInUser]);

    useEffect(() => {
        if (!businessesLoaded) return;
        const businesses = businessesByLoggedInUser.filter(
            (el) => !el.inactive && el.ownerId === loggedInUser.id
        );
        if (businesses.length < rowMetaData.rowsPerPage && !businessesByLoggedInUserMetaData.last) {
            dispatch(
                searchBusinessesByLoggedInUser(
                    { ownerId: loggedInUser.id },
                    businessesByLoggedInUserMetaData.number + 1
                )
            );
        } else {
            configVacancies(businesses);
            setBusinessesLoaded(false);
        }
    }, [businessesByLoggedInUser, businessesLoaded]);

    useEffect(() => {
        setTotalResults(businessesByLoggedInUserMetaData.totalElements);
    }, [businessesByLoggedInUserMetaData]);

    useEffect(() => {
        if (!businessesLoaded || !searchTerm) return;
        if (businessesByCodeOrName.length < 1) setRows([]);
        else configVacancies(businessesByCodeOrName);
        setBusinessesLoaded(false);
    }, [businessesByCodeOrName, businessesLoaded]);

    useEffect(() => {
        if (!vacanciesLoaded) return;
        const businesses = searchTerm ? businessesByCodeOrName : businessesByLoggedInUser;
        if (searchTerm) {
            setRowsForTiming(businessesByCodeOrName);
            setTotalResults(1);
        } else {
            setRowsForTiming(businessesByLoggedInUser);
            setTotalResults(businessesByLoggedInUserMetaData.totalElements);
        }
        setRowsForTiming(businesses);
        setVacanciesLoaded(false);
    }, [vacancies, vacanciesLoaded]);

    useEffect(() => {
        if (rowsForTiming.length < 1) return;
        let count = 0;
        const updatedBusinesses = rowsForTiming
            .filter((el) => !el.inactive)
            .map((el) => {
                const activeVacancies = vacancies[el.id]?.filter((entry) => !entry.inactive) || [];
                if (activeVacancies.length > 0) {
                    count++;
                    activeVacancies.map((entry) => {
                        dispatch(loadVacancySubmissionsByVacancyIdForBusiness(entry.id, el.id));
                        setVacancyCount((vacancyCount) => vacancyCount + 1);
                    });
                }
                return {
                    ...el,
                    vacancies: activeVacancies,
                    positions:
                        activeVacancies.length > 0
                            ? activeVacancies.reduce((acc, cur) => acc + cur.numberOfPositions, 0)
                            : 0,
                    jobsOffered: 0
                };
            });
        if (updatedBusinesses.length < 1 || count === 0) setVacancyProcessed(true);
        if (
            !searchTerm &&
            updatedBusinesses.length < businessesByLoggedInUserMetaData.size &&
            !businessesByLoggedInUserMetaData.last
        ) {
            dispatch(
                searchBusinessesByLoggedInUser(
                    { ownerId: loggedInUser.id },
                    businessesByLoggedInUserMetaData.number + 1
                )
            );
        } else {
            setRows(updatedBusinesses);
            !searchTerm && setActiveBusinesses(updatedBusinesses);
        }
    }, [rowsForTiming]);

    useEffect(() => {
        if (
            successMessage === 'Business records by logged in user have been loaded' ||
            successMessage === 'Business search record has been loaded' ||
            successMessage === 'Business record has been deleted' ||
            successMessage === 'Business record has been updated'
        ) {
            setBusinessesLoaded(true);
        } else if (successMessage === 'Vacancies have been loaded for business records') {
            setVacanciesLoaded(true);
        } else if (successMessage.includes(' and business id: ')) {
            const vacancyId = successMessage.substring(53, 89);
            const businessId = successMessage.substring(107, 143);

            const jobsOffered = Array.isArray(vacancySubmissionsForBusiness)
                ? vacancySubmissionsForBusiness.reduce(
                      (acc, cur) =>
                          acc +
                          (getNameFromId(vacancyStageDetails, cur.statusId) === 'Job offered'
                              ? 1
                              : 0),
                      0
                  )
                : 0;

            rows.map((el) => {
                let vacancyFound;
                if (el.id === businessId) {
                    vacancyFound = el.vacancies.find((entry) => entry.id === vacancyId);
                }

                if (vacancyFound) {
                    const jobOfferedCount = {
                        businessId,
                        vacancyId,
                        jobsOffered
                    };
                    const vacancyAlreadyExists = jobsOfferedForVacancies.find(
                        (entry) => entry.businessId === businessId && entry.vacancyId === vacancyId
                    );
                    if (!vacancyAlreadyExists) {
                        setJobsOfferedForVacancies((prev) => [...prev, jobOfferedCount]);
                    }
                    setVacancyProcessedCount((vacancyProcessedCount) => vacancyProcessedCount + 1);
                }
            });
        }
    }, [successMessage]);

    useEffect(() => {
        if (vacancyCount === 0 || vacancyCount !== vacancyProcessedCount || vacancyProcessed)
            return;

        const uniqueJobOffers = [...new Set(jobsOfferedForVacancies.map((el) => el))];
        const jobOffersMerged = rows.map((el) => {
            let count = 0;
            uniqueJobOffers.map((entry) =>
                entry.businessId === el.id ? (count = count + entry.jobsOffered) : ''
            );
            return {
                ...el,
                jobsOffered: count
            };
        });

        setRows(jobOffersMerged);
        !searchTerm && setActiveBusinesses(jobOffersMerged);
        setVacancyProcessed(true);
    }, [vacancyProcessedCount]);

    useEffect(() => {
        if (!searchTerm) {
            setRows(activeBusinesses);
            setTotalResults(businessesByLoggedInUserMetaData.totalElements);
        }
    }, [searchTerm]);

    // HELPER FNS

    const configVacancies = (businesses) => {
        if (businesses.length > 0) {
            vacancyProcessed && setVacancyProcessed(false);
            dispatch(loadVacanciesByBusinessRecordId(businesses.map((el) => el.id)));
        } else setVacancyProcessed(true);
    };

    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <BusinessTableRow
                key={el.id}
                row={el}
                roles={acceptedRoles}
                toggleDropdown={onToggleDropdown}
                openId={openId}
                jobsOfferedForVacancies={jobsOfferedForVacancies}
            />
        ));
    };

    // EVENT HANDLERS

    const onTermChange = (value) => setSearchTerm(value);

    const onSearch = () => {
        if (!searchTerm) {
            setTotalResults(businessesByLoggedInUserMetaData.totalElements);
            return;
        }
        setTotalResults(1);
        if (activeBusinesses.length) {
            const filtered = activeBusinesses.filter(
                (el) =>
                    el.name.toLowerCase() === searchTerm.toLowerCase() ||
                    el.code.toLowerCase() === searchTerm.toLowerCase()
            );
            filtered.length
                ? setRows(filtered)
                : dispatch(
                      searchBusinessesByCodeOrName({
                          ownerId: loggedInUser.id,
                          term: searchTerm
                      })
                  );
        }
    };

    const onCreateBusiness = () => navigate('/create_business');

    const onToggleDropdown = (id) => setOpenId(id);

    // RENDER
    return (
        <>
            <div className={classes.businessToolBar}>
                <SearchOnEnter
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                    search={onSearch}
                    onChange={onTermChange}
                />
                <Button
                    id="CreateBusinessButton"
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    content="Create Business"
                    icon={true}
                    onClick={onCreateBusiness}
                />
            </div>

            {rows.length < 1 ? (
                <LoadingSpinner content="No businesses found" />
            ) : (
                <ResultsTable
                    defaultOrderBy={'name'}
                    sortOrder={rowMetaData.order}
                    headCells={headCells}
                    loadResults={searchBusinessesByLoggedInUser}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={{ ownerId: loggedInUser.id }}
                    tableRows={createRows()}
                    totalResults={totalResults}
                    metaData={businessesByLoggedInUserMetaData}
                    loadPageSize={loadPageSize}
                />
            )}
        </>
    );
};

export default BusinessManagement;

BusinessManagement.propTypes = {
    isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
