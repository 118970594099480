import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    clearAssetRequestAssetDetails,
    clearAssetRequestOrderTypeDetails,
    clearAssetRequestRequestTypeDetails,
    loadAssetRequestAssetDetails,
    loadAssetRequestOrderTypeDetails,
    loadAssetRequestRequestTypeDetails
} from '../../../store/directusService';
import { compareUnorderedArrays } from '../../../utils/arrayUtils';
import { updatePreSelects, updateSelectedItems } from '../../../utils/directusFunctions';
import { clearKeys } from '../../../utils/objectUtils';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { BUTTON_ERROR, initialErrorState, validate } from './validateAssetRequestsAdmin';

import form from '../../../commonStyles/formStyles.module.css';

const AssetRequestsAdmin = ({ contractId }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const viewRoles = [LOCAL_ADMIN, SUPERUSER];
    const acceptedRoles = [SUPERUSER];
    const collectionNames = ['request type', 'asset type', 'order type'];
    const urlNames = [
        'asset_request_request_type',
        'asset_request_asset_type',
        'request_order_type'
    ];
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);
    const [keys, setKeys] = useState({ requestType: '0', assetType: '1', orderType: '2' });

    const [selectedRequestTypeIds, setSelectedRequestTypeIds] = useState([]);
    const [preSelectedRequestTypeIds, setPreSelectedRequestTypeIds] = useState([]);
    const [preSelectedRequestTypes, setPreSelectedRequestTypes] = useState([]);

    const [selectedAssetTypeIds, setSelectedAssetTypeIds] = useState([]);
    const [preSelectedAssetTypes, setPreSelectedAssetTypes] = useState([]);
    const [preSelectedAssetTypeIds, setPreSelectedAssetTypeIds] = useState([]);
    const [arrayAssetTypes, setArrayAssetTypes] = useState([]);

    const [selectedOrderTypeIds, setSelectedOrderTypeIds] = useState([]);
    const [preSelectedOrderTypeIds, setPreSelectedOrderTypeIds] = useState([]);
    const [preSelectedOrderTypes, setPreSelectedOrderTypes] = useState([]);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const {
        assetRequestRequestTypeDetails,
        assetRequestAssetDetails,
        assetRequestOrderTypeDetails
    } = useSelector((state) => state.entities.directusService);

    // USE EFFECTS
    useEffect(() => {
        contractSetup();
    }, [contractId]);

    useEffect(() => {
        if (assetRequestRequestTypeDetails?.length < 1) return;
        const { preSelects, preSelectIds } = updatePreSelects(
            urlNames[0],
            assetRequestRequestTypeDetails,
            contractId
        );
        setPreSelectedRequestTypes(preSelects);
        setPreSelectedRequestTypeIds(preSelectIds);
        setSelectedRequestTypeIds(preSelectIds);
    }, [assetRequestRequestTypeDetails]);

    useEffect(() => {
        if (assetRequestAssetDetails?.length < 1) return;
        const { preSelects, preSelectIds } = updatePreSelects(
            urlNames[1],
            assetRequestAssetDetails,
            contractId
        );
        setPreSelectedAssetTypes(preSelects);
        setPreSelectedAssetTypeIds(preSelectIds);
        setSelectedAssetTypeIds(preSelectIds);
    }, [assetRequestAssetDetails]);

    useEffect(() => {
        const assetTypeMenuItems = getAssetTypeMenuItems(preSelectedRequestTypeIds);
        setArrayAssetTypes(assetTypeMenuItems);
    }, [preSelectedRequestTypeIds]);

    useEffect(() => {
        if (assetRequestOrderTypeDetails?.length) {
            const { preSelects, preSelectIds } = updatePreSelects(
                urlNames[2],
                assetRequestOrderTypeDetails,
                contractId
            );
            setPreSelectedOrderTypes(preSelects);
            setPreSelectedOrderTypeIds(preSelectIds);
            setSelectedOrderTypeIds(preSelectIds);
        }
    }, [assetRequestOrderTypeDetails]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (
            successMessage.startsWith('Updating request type') ||
            successMessage.startsWith('Updating asset type') ||
            successMessage.startsWith('Updating order type')
        ) {
            contractSetup();
        }
    }, [successMessage]);

    // HELPER FNS
    const contractSetup = () => {
        setIsDisabled(false);

        setSelectedRequestTypeIds([]);
        setSelectedAssetTypeIds([]);
        setSelectedOrderTypeIds([]);

        setPreSelectedRequestTypes([]);
        setPreSelectedAssetTypes([]);
        setPreSelectedOrderTypes([]);

        setErrors(initialErrorState);
        setKeys(clearKeys(keys));

        dispatch(clearAssetRequestRequestTypeDetails());
        dispatch(clearAssetRequestAssetDetails());
        dispatch(clearAssetRequestOrderTypeDetails());

        dispatch(loadAssetRequestRequestTypeDetails(contractId));
        dispatch(loadAssetRequestAssetDetails(contractId));
        dispatch(loadAssetRequestOrderTypeDetails(contractId));
    };

    const getAssetTypeMenuItems = (arr) => {
        return assetRequestAssetDetails?.filter((el) =>
            arr.find(
                (entry) =>
                    entry === el.asset_request_request_type[0]?.asset_request_request_type_id?.id
            )
        );
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const loadingError = () => {
        if (!contractId) return 'No contract';
        if (assetRequestRequestTypeDetails?.length < 1) return 'No asset request type details';
        if (assetRequestOrderTypeDetails?.length < 1) return 'No order type details';
        if (assetRequestAssetDetails?.length < 1) return 'No asset details';
    };

    // EVENT HANDLERS
    const onRequestTypeChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        if (compareUnorderedArrays(chosenIds, selectedRequestTypeIds)) return;
        clearError('assetRequestRequestTypeDetails');
        const assetTypeMenuItems = getAssetTypeMenuItems(chosenIds);
        setSelectedRequestTypeIds(chosenIds);
        if (chosenIds.length < selectedRequestTypeIds.length) {
            const updatedSelectedAssetTypeIds = selectedAssetTypeIds.filter((el) =>
                assetTypeMenuItems.find((entry) => entry.id === el)
            );
            setSelectedAssetTypeIds(updatedSelectedAssetTypeIds);
            setPreSelectedAssetTypeIds(updatedSelectedAssetTypeIds);
            setPreSelectedAssetTypes(
                assetRequestAssetDetails.filter((el) => updatedSelectedAssetTypeIds.includes(el.id))
            );
        }
        setArrayAssetTypes(assetTypeMenuItems);
    };

    const onAssetChange = (chosenIds) => {
        clearError('assetRequestAssetDetails');
        if (!chosenIds) chosenIds = [];
        setSelectedAssetTypeIds(chosenIds);
    };

    const onOrderTypeChange = (chosenIds) => {
        clearError('assetRequestOrderTypeDetails');
        if (!chosenIds) chosenIds = [];
        setSelectedOrderTypeIds(chosenIds);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const { newErrors } = validate(
            selectedRequestTypeIds,
            selectedAssetTypeIds,
            selectedOrderTypeIds
        );

        setErrors(newErrors);
        setIsDisabled(true);
        if (Object.keys(newErrors).length > 0) return;

        const requestTypeLength = updateSelectedItems(
            urlNames[0],
            assetRequestRequestTypeDetails,
            selectedRequestTypeIds,
            contractId,
            collectionNames[0],
            dispatch
        );

        const assetTypeLength = updateSelectedItems(
            urlNames[1],
            assetRequestAssetDetails,
            selectedAssetTypeIds,
            contractId,
            collectionNames[1],
            dispatch
        );

        const orderTypeLength = updateSelectedItems(
            urlNames[2],
            assetRequestOrderTypeDetails,
            selectedOrderTypeIds,
            contractId,
            collectionNames[2],
            dispatch
        );
        if (requestTypeLength + assetTypeLength + orderTypeLength === 0) {
            setErrors({ button: { error: true, message: BUTTON_ERROR } });
        }
    };

    // RENDER

    if (!hasRole(viewRoles, roles)) return <NotFound />;
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start">
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="requestTypeDetails"
                            key={keys.assetRequestRequestTypeDetails}
                            label="Asset Request Type"
                            placeholder="Select request type"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={assetRequestRequestTypeDetails || []}
                            preSelectedIds={preSelectedRequestTypeIds}
                            preSelects={preSelectedRequestTypes}
                            error={errors.assetRequestRequestTypeDetails}
                            onChange={(chosenIds) => onRequestTypeChange(chosenIds)}
                        />
                        <MultiSelect
                            id="assetTypeDetails"
                            key={keys.assetRequestAssetDetails}
                            label="Asset"
                            placeholder="Select asset"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={arrayAssetTypes || []}
                            preSelectedIds={preSelectedAssetTypeIds}
                            preSelects={preSelectedAssetTypes}
                            error={errors.assetRequestAssetDetails}
                            onChange={(chosenIds) => onAssetChange(chosenIds)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="orderTypeDetails"
                            key={keys.assetRequestOrderTypeDetails}
                            label="Order Type"
                            placeholder="Select order type"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            menuItems={assetRequestOrderTypeDetails || []}
                            preSelectedIds={preSelectedOrderTypeIds}
                            preSelects={preSelectedOrderTypes}
                            error={errors.assetRequestOrderTypeDetails}
                            onChange={(chosenIds) => onOrderTypeChange(chosenIds)}
                        />
                    </div>
                </div>

                <Button
                    id="assetRequestAdminButton"
                    content="UPDATE ASSET REQUEST"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </div>
    );
};

AssetRequestsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default AssetRequestsAdmin;
