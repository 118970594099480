import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../api/pagination';
import OnDeleteIcon from '../../icons/OnDeleteIcon';
import {
    appointmentSlotDeleted,
    deleteAppointmentSlot,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointmentSlot
} from '../../store/calendarService';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadAttendanceDetails,
    loadLocationsForServices,
    loadServiceDetailsByService
} from '../../store/directusService';
import {
    createAppointmentAttendanceDetail,
    searchAppointmentAttendanceDetails,
    searchParticipants
} from '../../store/participantService';
import { clearUsersBySearch, loadUserById, loadUsersBySearch } from '../../store/userService';
import { reverseFormatDate } from '../../utils/dateFunctions';
import { UPDATE } from '../../utils/uiConstants';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import DateSelect from '../formElements/DateSelect';
import SingleSelect from '../formElements/SingleSelect';
import AppointmentLocationSelect from '../scheduling/components/AppointmentLocationSelect';
import AppointmentTitleSelect from '../scheduling/components/AppointmentTitleSelect';
import ParticipantsSelector from '../scheduling/components/ParticipantsSelector';
import StartSlotButtons from '../scheduling/components/StartSlotButtons';
import TimeSelect from '../scheduling/components/TimeSelect';
import { updateLocations, validateUpdates } from '../scheduling/utils/appointmentSchedulerUtils';
import Panel from '../ui/panel/Panel';

import '../scheduling/styles/appointmentSchedulerPanel.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;

const UserAppointmentSlotsEdit = ({
    usersCurrentAppointment,
    currentCalendarOwner,
    currentLocation
}) => {
    const dispatch = useDispatch();
    // LOCAL STATE

    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantIds: [],
        numberOfSlots: 1,
        appointmentType: '',
        service: '',
        location: ''
    };

    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const editRestrictedRoles = [QUALITY, SUPERUSER];
    const deleteRestrictedRoles = [SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [participants, setParticipants] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [advisers, setAdvisers] = useState([]);
    const [addedParticipants, setAddedParticipants] = useState([]);
    const [isDisabledSlotAppointmentButtons, setIsDisabledSlotAppointmentButtons] = useState(false);
    const [statuses, setStatuses] = useState({});
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);
    const [isEditRestrictedByAttendanceStatus, setIsEditRestrictedByAttendanceStatus] =
        useState(false);

    // STORE STATE
    const {
        usersBySearch: users,
        usersById,
        usersBySearchMetaData,
        loggedInUser: { roles }
    } = useSelector((state) => state.entities.userService);
    const { participantsSearch, participantsSearchMetaData, appointmentAttendanceDetails } =
        useSelector((state) => state.entities.participantService);
    const {
        locationsForServices,
        serviceDetails: services,
        appointmentTypeDetailsForContracts: appointmentTypes,
        attendanceDetails
    } = useSelector((state) => state.entities.directusService);
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
    };

    const updateAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS

    useEffect(() => {
        dispatch(clearUsersBySearch());
        if (!currentCalendarOwner || Object.keys(currentCalendarOwner).length < 1) return;

        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        if (usersCurrentAppointment?.id)
            dispatch(
                searchAppointmentAttendanceDetails({
                    calendarServiceAppointmentIds: [usersCurrentAppointment.id]
                })
            );
        const { serviceId } = usersCurrentAppointment;

        if (appointmentTypes?.length < 1) {
            dispatch(loadAppointmentTypeDetailsByContractIds(currentCalendarOwner?.contractIds));
        }
        if (locationsForServices?.length < 1) dispatch(loadLocationsForServices([serviceId]));
        if (services?.length < 1 && serviceId) dispatch(loadServiceDetailsByService([serviceId]));
        if (participantsSearch?.length < 1 && serviceId) {
            dispatch(searchParticipants({ serviceId }, 0, PT_LOAD_SIZE));
        } else setParticipants(participantsSearch);

        const currentAppointmentType =
            appointmentTypes.find((el) => el.id === usersCurrentAppointment.typeId)?.name || '';
        const currentServiceName = services?.find((el) => el.id === serviceId)?.name || '';

        setNewEntry({
            ...usersCurrentAppointment,
            startTime: usersCurrentAppointment.startTime?.slice(0, 5),
            endTime: usersCurrentAppointment.endTime?.slice(0, 5),
            date: usersCurrentAppointment.date?.split('T')[0] || '',
            service: currentServiceName,
            location: currentLocation,
            appointmentType: currentAppointmentType
        });
        if (usersCurrentAppointment.userId) {
            const selectedAdviser = usersById[usersCurrentAppointment.userId];
            if (!selectedAdviser) {
                dispatch(loadUserById(usersCurrentAppointment.userId));
            } else {
                updateAdvisersArray([selectedAdviser]);
            }
        }

        const canEdit = hasRole(acceptedRoles, roles);
        setIsDisabledSlotAppointmentButtons(!canEdit);
    }, []);

    useEffect(() => {
        if (participantsSearch?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { serviceIds: [usersCurrentAppointment.serviceId] },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsSearch);
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (participants.length < 1) return;
        if (locationsForServices?.length > 0) {
            setFilteredLocations(updateLocations(locationsForServices, newEntry.serviceId));
        }
        setFilteredParticipants(
            participants.filter((el) => el.serviceId === usersCurrentAppointment.serviceId)
        );
        if (usersCurrentAppointment.participantIds?.length) {
            const entries = usersCurrentAppointment.participantIds.map((el) =>
                participants.find((entry) => entry.id === el)
            );
            setAddedParticipants(entries.filter((el) => el));
        }
    }, [participants, locationsForServices]);

    useEffect(() => {
        if (appointmentAttendanceDetails?.length < 1 || participants.length < 1) return;
        const statuses = participants.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.id]:
                    attendanceDetails.find(
                        (row) =>
                            row.id ===
                            appointmentAttendanceDetails.find(
                                (entry) => entry.participantId === cur.id
                            )?.attendanceId
                    )?.name || 'Not known'
            }),
            {}
        );
        setStatuses(statuses);
    }, [appointmentAttendanceDetails, participants]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter(
            (el) =>
                el.active && el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < usersBySearchMetaData.totalElements) {
            onLoadMoreAdvisers();
            return;
        }
        if (!updatedAdvisers.some((el) => el.id === newEntry.userId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === newEntry.userId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        updateAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (newEntry.userId && usersById[newEntry.userId]) {
            updateAdvisersArray([usersById[newEntry.userId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (addedParticipants.length < 1 || statuses.length < 1) return;
        setIsEditRestrictedByAttendanceStatus(
            addedParticipants.some((el) => statuses[el.id] !== 'Not known')
        );
    }, [addedParticipants, statuses]);

    useEffect(() => {
        if (successMessage === `Appointment slot ${newEntry.appointmentType} has been updated`) {
            setIsUpdateButtonDisabled(false);
            if (addedParticipants.length) {
                setIsDisabledSlotAppointmentButtons(false);
            } else onPanelClose();
        }

        if (
            successMessage.includes(`Appointment slot ${newEntry.appointmentType} has been deleted`)
        ) {
            setIsUpdateButtonDisabled(false);
            dispatch(appointmentSlotDeleted(newEntry.id));
            onPanelClose();
        }
    }, [successMessage]);

    // HELPER FNS
    const onPanelClose = () => {
        clearForm();
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        setCurrentAppointment({});
    };

    const onUpdate = (update) => {
        setNewEntry((prev) => ({ ...prev, ...update }));
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onInputUpdate = (key, value) => {
        setNewEntry((prev) => ({ ...prev, [key]: value }));
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onUpdateAdviser = (chosenId) => {
        setNewEntry((prev) => ({
            ...prev,
            userId: chosenId
        }));
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onDelete = () => {
        dispatch(deleteAppointmentSlot(newEntry.userId, newEntry.appointmentType, newEntry.id));
    };

    const onAddParticipant = (entry) => {
        setAddedParticipants([...addedParticipants, entry].filter((el) => el));
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onRemoveParticipant = (entries) => {
        setAddedParticipants(entries.filter((el) => el));
        setIsDisabledSlotAppointmentButtons(true);
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < usersBySearchMetaData.totalElements) {
            dispatch(
                loadUsersBySearch(
                    { serviceIds: [usersCurrentAppointment.serviceId] },
                    !users.length ? 0 : usersBySearchMetaData.number + 1
                )
            );
        }
    };

    const configSelectedAdviserName = () => {
        const selectedAdviser = advisers.find((el) => el.id === newEntry.userId);
        return `${selectedAdviser?.firstName} ${selectedAdviser?.lastName}`;
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const valid = validateUpdates(newEntry, dispatch);
        if (!valid) return;
        setIsUpdateButtonDisabled(true);
        if (valid) {
            const {
                /* eslint-disable no-unused-vars */
                appointmentType,
                service,
                location,
                ...rest
            } = newEntry;

            const participantIds = addedParticipants.map((el) => el.id);
            const payload = { ...rest, participantIds };
            dispatch(
                updateAppointmentSlot(
                    usersCurrentAppointment.userId,
                    newEntry.appointmentType,
                    payload
                )
            );
            const newParticipantIds = participantIds.filter(
                (el) => !newEntry.participantIds.includes(el)
            );
            newParticipantIds.forEach((el) => {
                const payload = {
                    participantId: el,
                    calendarServiceAppointmentId: newEntry.id
                };
                dispatch(createAppointmentAttendanceDetail(payload));
            });
            setNewEntry((prev) => ({
                ...prev,
                participantIds: [...newEntry.participantIds, ...newParticipantIds]
            }));
        }
    };

    // RENDER

    return filteredParticipants.length < 1 ? null : (
        <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
            <div className="delete-appointment">
                <OnDeleteIcon
                    onDelete={onDelete}
                    roles={roles}
                    acceptedRoles={deleteRestrictedRoles}
                    active={!!newEntry.id}
                />
            </div>
            <div className="appointment-scheduler">
                <form onSubmit={onSubmit}>
                    {hasRole(editRestrictedRoles, roles) && !isEditRestrictedByAttendanceStatus ? (
                        <div className="appointment-title-elements">
                            <AppointmentTitleSelect
                                appointmentTypes={appointmentTypes}
                                appointmentType={newEntry.appointmentType}
                                onTitleChange={onUpdate}
                            />
                            <span className="static">
                                {addedParticipants.length} / {newEntry.numberOfSlots}
                            </span>
                        </div>
                    ) : (
                        <div className="appointment-title static appointment-title-static">
                            {newEntry.appointmentType}
                            <span>
                                {addedParticipants.length} / {newEntry.numberOfSlots}
                            </span>
                        </div>
                    )}

                    <div className="input-set">
                        <label htmlFor="service">Service</label>
                        <div className="static">{newEntry.service}</div>
                    </div>
                    {hasRole(editRestrictedRoles, roles) || !isEditRestrictedByAttendanceStatus ? (
                        <>
                            <div className="input-set">
                                <SingleSelect
                                    id={'adviserId'}
                                    label={'Adviser'}
                                    placeholder="Select Adviser..."
                                    disabled={!newEntry.service}
                                    menuItems={advisers}
                                    selectedId={newEntry.userId || ''}
                                    selected={
                                        newEntry.userId
                                            ? advisers?.find((el) => el.id === newEntry.userId) ||
                                              {}
                                            : {}
                                    }
                                    onChange={onUpdateAdviser}
                                    onLoadMoreItems={onLoadMoreAdvisers}
                                    moreItemsToLoad={
                                        !usersBySearchMetaData?.totalElements ||
                                        users.length < usersBySearchMetaData?.totalElements
                                    }
                                />
                            </div>
                            <AppointmentLocationSelect
                                locations={filteredLocations}
                                locationName={newEntry.location}
                                onLocationChange={onUpdate}
                                disabled={!newEntry.service}
                            />
                            <TimeSelect
                                startTime={newEntry.startTime}
                                endTime={newEntry.endTime}
                                onTimeChange={onUpdate}
                            />
                        </>
                    ) : (
                        <>
                            <div className="input-set">
                                <label htmlFor="adviser">Adviser</label>
                                <div className="static">{configSelectedAdviserName()}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="location">Location</label>
                                <div className="static">{newEntry.location}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="startTime">Start Time</label>
                                <div className="static">{newEntry.startTime}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="endTime">End Time</label>
                                <div className="static">{newEntry.endTime}</div>
                            </div>
                        </>
                    )}

                    {hasRole(editRestrictedRoles, roles) ? (
                        <>
                            <br />
                            <DateSelect
                                value={newEntry.date}
                                onDateChange={(date) => onInputUpdate('date', date)}
                            />
                        </>
                    ) : (
                        <div className="input-set">
                            <label htmlFor="date">Date</label>
                            <div className="static">{reverseFormatDate(newEntry.date)}</div>
                        </div>
                    )}

                    <div className="last-group">
                        <ParticipantsSelector
                            participants={filteredParticipants}
                            add={(entry) => onAddParticipant(entry)}
                            remove={(entries) => onRemoveParticipant(entries)}
                            added={addedParticipants}
                            numOfSlots={newEntry.numberOfSlots}
                            statuses={statuses}
                            isEditRestrictedByAttendanceStatus={isEditRestrictedByAttendanceStatus}
                        />

                        <div className="appointmentSlotSaveBtn">
                            <Button
                                type="submit"
                                disabled={
                                    isUpdateButtonDisabled ||
                                    (isEditRestrictedByAttendanceStatus &&
                                        !hasRole(editRestrictedRoles, roles))
                                }
                                color="primary"
                                variant="contained"
                                data-testid="testIdSubmitButton">
                                {UPDATE}
                            </Button>
                        </div>
                    </div>
                </form>

                <StartSlotButtons
                    participants={addedParticipants}
                    appointment={newEntry}
                    disabled={isDisabledSlotAppointmentButtons}
                    onPanelClose={onPanelClose}
                    statuses={statuses}
                />
            </div>
        </Panel>
    );
};

export default UserAppointmentSlotsEdit;

UserAppointmentSlotsEdit.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object,
    currentLocation: PropTypes.string
};
