import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../api/pagination';
import { selectUsersAppointments } from '../../store/calendarSelectors';
import {
    loadUserAppointments,
    loadUserAppointmentSlots,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointment,
    updateAppointmentSlot
} from '../../store/calendarService';
import { selectCourseIds } from '../../store/courseSelectors';
import { loadCourseModulesByUserId, searchCoursesByCourseIds } from '../../store/courseService';
import {
    loadAttendanceDetails,
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import {
    searchAppointmentAttendanceDetails,
    searchParticipantsByLoggedInUserServiceIds,
    setCurrentlySelectedParticipant
} from '../../store/participantService';
import { searchUsersByLoggedInUserServiceIds } from '../../store/userService';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import SingleSelect from '../formElements/SingleSelect';
import Calendar from '../ui/calendar/Calendar';
import {
    configCourseEventContent,
    configParticipantEventContent,
    fetchLoggedInUserData,
    formatFullCalendarTimes,
    getBusinessHours,
    mapColors,
    mapCommonParams
} from '../ui/calendar/calendarUtils';

import UserAppointmentCreate from './UserAppointmentCreate';
import UserAppointmentEdit from './UserAppointmentEdit';
import UserAppointmenSlotsEdit from './UserAppointmentSlotsEdit';

import classes from '../../app.module.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;
let count = 0;

const UserCalendar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSelectUsersAppointments = useMemo(selectUsersAppointments, []);

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const [courseEvents, setCourseEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [appointments, setAppointments] = useState([]);
    const [currentCalendarOwner, setCurrentCalendarOwner] = useState({});
    const [eventOwnerId, setEventOwnerId] = useState('');
    const [advisers, setAdvisers] = useState([]);
    const [participants, setParticipants] = useState([]);

    // STORE STATE
    const usersAppointments = useSelector((state) =>
        onSelectUsersAppointments(state, currentCalendarOwner?.id)
    );
    const {
        usersByLoggedInUserServiceIds: users,
        usersByLoggedInUserServiceIdsMetaData,
        loggedInUser
    } = useSelector((state) => state.entities.userService);
    const {
        coursesByCourseIds: courses,
        coursesByCourseIdsMetaData,
        courseModules
    } = useSelector((state) => state.entities.courseService);
    const {
        locationIdsAndNames: locations,
        appointmentTypes,
        attendanceDetails
    } = useSelector((state) => state.entities.directusService);
    const courseIds = useSelector(selectCourseIds);
    const {
        participantsByLoggedInUserServiceIds,
        participantsByLoggedInUserServiceIdsMetaData: ptMetaData
    } = useSelector((state) => state.entities.participantService);
    const { usersCurrentAppointment, allAppointments, openAppointmentSchedulerPanel } = useSelector(
        (state) => state.entities.calendarService
    );

    // CONFIG FNS
    const configEvents = () => {
        const businessHours = getBusinessHours();
        let entries;
        if (usersAppointments?.length < 1) entries = [{}];
        else
            entries = usersAppointments?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, participantEntry, participant, slots, filledSlots } =
                    configParticipantEventContent(el, appointmentTypes, participants);
                const { color, textColor } = mapColors(el.id, usersCurrentAppointment?.id);
                const editable = el.id === usersCurrentAppointment?.id;
                return {
                    id: el.id,
                    eventType: 'user',
                    hasLink: true,
                    className:
                        !hasRole(acceptedRoles, loggedInUser.roles) &&
                        loggedInUser.id !== currentCalendarOwner.id
                            ? 'non-click-event'
                            : '',
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    slots,
                    filledSlots,
                    location,
                    participantEntry,
                    participant,
                    appointmentType,
                    color,
                    textColor,
                    date,
                    start,
                    end,
                    editable,
                    constraint: businessHours
                };
            });
        setEvents(entries);
        const cur = entries.find((el) => el.id === usersCurrentAppointment?.id);
        if (cur) setCurrentEvent(cur);
    };

    const configBackgroundEvents = () => {
        let events;
        if (courseModules?.length < 1) events = [];
        else
            events = courseModules?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, courses, location);
                return {
                    eventType: 'courseModules',
                    className: 'non-interactive-events',
                    content,
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    date,
                    start,
                    end,
                    editable: false
                };
            });
        setCourseEvents(events);
    };

    const fetchAdviserData = (adviserId) => {
        if (!allAppointments[adviserId]) {
            dispatch(loadUserAppointments(adviserId));
            dispatch(loadUserAppointmentSlots(adviserId));
        }
        dispatch(loadCourseModulesByUserId(adviserId));
    };

    // HELPER FNS
    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS
    useEffect(() => {
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || !loggedInUser?.id) return;
        setCurrentCalendarOwner(loggedInUser);
        setAdvisersArray([loggedInUser]);
        fetchLoggedInUserData(loggedInUser, dispatch);
        dispatch(
            searchParticipantsByLoggedInUserServiceIds(loggedInUser.serviceIds, 0, PT_LOAD_SIZE)
        );
        fetchAdviserData(loggedInUser.id);
    }, [loggedInUser]);

    useEffect(() => {
        if (participantsByLoggedInUserServiceIds?.length < 1) return;
        else if (!ptMetaData.last) {
            dispatch(
                searchParticipantsByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    ptMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsByLoggedInUserServiceIds);
    }, [participantsByLoggedInUserServiceIds, ptMetaData]);

    useEffect(() => {
        setAppointments(usersAppointments);
        if (locations?.length > 0 && participants && appointmentTypes?.length > 0) {
            configEvents();
        }
    }, [usersAppointments, locations, participants, appointmentTypes]);

    useEffect(() => {
        if (!usersCurrentAppointment?.id) {
            setNewEvent({});
            setCurrentEvent({});
        } else {
            dispatch(
                searchAppointmentAttendanceDetails({
                    calendarServiceAppointmentIds: [usersCurrentAppointment.id]
                })
            );
        }
        configEvents();
    }, [usersCurrentAppointment]);

    useEffect(() => {
        if (!courseModules?.length || coursesByCourseIdsMetaData.last) return;
        courseIds?.length > 0 &&
            dispatch(
                searchCoursesByCourseIds(
                    courseIds,
                    'number' in coursesByCourseIdsMetaData
                        ? coursesByCourseIdsMetaData.number + 1
                        : 0
                )
            );
    }, [courseModules, courseIds, coursesByCourseIdsMetaData]);

    useEffect(() => {
        configBackgroundEvents();
    }, [courses, courseModules]);

    useEffect(() => {
        if (!currentCalendarOwner?.id) return;
        fetchAdviserData(currentCalendarOwner.id);
    }, [currentCalendarOwner.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter(
            (el) =>
                el.active && el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
        );
        if (
            !updatedAdvisers.length &&
            users.length < usersByLoggedInUserServiceIdsMetaData.totalElements
        ) {
            onLoadMoreAdvisers();
            return;
        }
        if (
            currentCalendarOwner.id &&
            !updatedAdvisers.some((el) => el.id === currentCalendarOwner.id)
        ) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === currentCalendarOwner.id);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    // EVENT HANDLERS
    const onEditTimes = (time, update) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            const updatedAppointment = { ...usersCurrentAppointment, date, startTime, endTime };
            currentEvent.slots
                ? dispatch(
                      updateAppointmentSlot(
                          eventOwnerId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  )
                : dispatch(
                      updateAppointment(
                          eventOwnerId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  );
        }
        dispatch(setCurrentAppointment({}));
    };

    const onTogglePanel = (open) => {
        dispatch(setOpenAppointmentSchedulerPanel(open));
    };

    const onEventSelect = (id) => {
        setNewEvent({});
        if (id !== usersCurrentAppointment?.id) {
            const selectedAppointment = appointments.find((el) => el.id === id);
            selectedAppointment && dispatch(setCurrentAppointment(selectedAppointment));
            setEventOwnerId(currentCalendarOwner.id);
        }
        if (id === currentEvent.id) {
            count++;
            setTimeout(() => (count = 0), 300);
            if (count > 1) dispatch(setOpenAppointmentSchedulerPanel(true));
        }
    };

    const onSlotSelect = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        setNewEvent({ date, startTime, endTime });
        onTogglePanel(true);
    };

    const onUpdateAdviser = (chosenId) => {
        if (!chosenId) {
            if (currentCalendarOwner.id !== loggedInUser.id) {
                setCurrentCalendarOwner(loggedInUser);
            }
        } else if (chosenId && chosenId !== currentCalendarOwner.id) {
            const chosenAdviser = advisers.find((el) => el.id === chosenId);
            setCurrentCalendarOwner({
                ...chosenAdviser,
                contractIds: chosenAdviser.contracts,
                serviceIds: chosenAdviser.serviceIds || [
                    chosenAdviser.primaryService,
                    ...chosenAdviser.otherServices
                ]
            });
        }
    };

    const onNav = (selectedEvent) => {
        dispatch(setCurrentlySelectedParticipant(selectedEvent.participantEntry));
        dispatch(loadContractDetailsForContractId(selectedEvent.participantEntry.contractId));
        dispatch(loadServiceDetailsForContractId(selectedEvent.participantEntry.contractId));
        navigate('/edit_participant');
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < usersByLoggedInUserServiceIdsMetaData.totalElements) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : usersByLoggedInUserServiceIdsMetaData.number + 1
                )
            );
        }
    };

    // RENDER
    return (
        <div className={classes.container}>
            <div className="calendar-wrapper">
                <div className="toolbar-search">
                    <SingleSelect
                        id={'adviserId'}
                        label={'Adviser/Coach'}
                        placeholder="Select Adviser/Coach..."
                        menuItems={advisers}
                        selectedId={currentCalendarOwner.id || ''}
                        selected={
                            currentCalendarOwner.id
                                ? advisers?.find((el) => el.id === currentCalendarOwner.id) || {}
                                : {}
                        }
                        onChange={onUpdateAdviser}
                        onLoadMoreItems={onLoadMoreAdvisers}
                        moreItemsToLoad={
                            !usersByLoggedInUserServiceIdsMetaData?.totalElements ||
                            users.length < usersByLoggedInUserServiceIdsMetaData?.totalElements
                        }
                    />
                </div>

                <Calendar
                    events={[...events, ...courseEvents]}
                    currentEvent={currentEvent}
                    onEditTimes={onEditTimes}
                    selectSlot={onSlotSelect}
                    selectEvent={onEventSelect}
                    allowSlotSelect={true}
                    returnButton={false}
                    initialView="timeGridDay"
                    onNav={onNav}
                    eventEditPermission={
                        hasRole(acceptedRoles, loggedInUser.roles) ||
                        loggedInUser?.id === currentCalendarOwner?.id
                    }
                />

                {openAppointmentSchedulerPanel &&
                    currentCalendarOwner &&
                    (Object.keys(newEvent).length ? (
                        <UserAppointmentCreate
                            newAppointment={newEvent}
                            currentCalendarOwner={currentCalendarOwner}
                        />
                    ) : currentEvent.slots ? (
                        <UserAppointmenSlotsEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentCalendarOwner}
                            currentLocation={currentEvent.location}
                        />
                    ) : (
                        <UserAppointmentEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentCalendarOwner}
                            currentLocation={currentEvent.location}
                            currentAppointmentType={currentEvent.appointmentType}
                            currentParticipantEntry={currentEvent.participantEntry}
                        />
                    ))}
            </div>
        </div>
    );
};

export default UserCalendar;
