export const names = {
    ADDRESS: 'Address line',
    BUSINESS_NAME: 'Business name',
    BUSINESS_OWNER_ID: 'Business owner name',
    BUSINESS_SECTOR_ID: 'Business sector name',
    BUSINESS_SIZE: 'Business size',
    CITY: 'City',
    CONTRACT_ID: `Contract name`,
    COUNTY: 'County',
    ELIGIBILITY_GROUP_ID: 'Eligibility group',
    EMAIL: 'Email',
    FIRST_NAME: 'First name',
    GENDER: 'Gender',
    LAST_NAME: 'Last name',
    MIDDLE_NAME: 'Middle name',
    NHS_NUMBER: 'NHS Number',
    NINO: 'NINO',
    PHONE: 'Phone number',
    PREFERRED_NAME: 'Preferred name',
    PREFERRED_PRONOUNS_OTHER: 'Preferred pronouns other',
    SERVICE_ID: 'Service name',
    STATUS_ID: 'Status',
    STATUS_REASON_ID: 'Status reason',
    TEAM_ID: 'Team name',
    POSTCODE: 'Postcode',
    WEBSITE_URL: 'Website'
};

export const charVal = {
    MAX_ADDRESS: 100,
    MIN_BUSINESS_NAME: 1,
    MAX_BUSINESS_NAME: 100,
    MAX_CITY: 30,
    MAX_COUNTY: 30,
    MIN_EMAIL: 7,
    MAX_EMAIL: 50,
    MIN_NAME: 1,
    MAX_NAME: 100,
    MIN_PHONE: 11,
    MAX_PHONE: 15,
    MAX_PRONOUNS_OTHER: 50,
    MAX_POSITIONS: 50,
    NHS_LENGTH: 10,
    NHS_MOD: 11
};

export const regExps = {
    EMAIL_R: /^[\w-'.]+@(\[?)[a-zA-Z\d\-.]+\.([a-zA-Z]{2,}|\d{1,3})(]?)$/,
    NAME_R: /^[A-Za-z\s]*$/i,
    NINO_R: /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s*\d\s*){6}([A-D]|\s)$/i,
    LAST_NAME_R: /^([A-Za-z'\s-]+)?$/,
    NUMBERS_R: /^\d+$/,
    PHONE_FORMAT_R: /^(\+?[0-9()]*)?$/,
    PHONE_R: /^[\\+]?[(]?[0-9]{2,3}[)]?[-\s\\.]?[0-9]{3,4}[-\s\\.]?[0-9]{4,8}$/,
    POSTCODE_R: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i,
    WEBSITE_R: /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)$/i
};
