import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TableCell, TableRow } from '@mui/material';

import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import {
    loadParticipantByPtCode,
    loadRecentParticipants,
    saveCurrentParticipantId
} from '../../store/participantService';
import { countDays, reverseFormatDate } from '../../utils/dateFunctions';

import classes from '../../commonStyles/tables.module.css';

const ReferralsCreatedTableRow = ({ row }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [loaded, setLoaded] = useState(false);

    // STORE STATE
    const { currentParticipant } = useSelector((state) => state.entities.participantService);

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipant.ptCode === row.ptCode && loaded) {
            dispatch(loadContractDetailsForContractId(currentParticipant.contractId));
            dispatch(loadServiceDetailsForContractId(currentParticipant.contractId));
            setLoaded(false);
            navigate('/edit_participant');
            saveCurrentParticipantId(currentParticipant.id);
            dispatch(loadRecentParticipants());
        }
    }, [currentParticipant]);

    // HELPER FUNCTIONS
    const onNav = () => {
        dispatch(loadParticipantByPtCode(row.ptCode));
        setLoaded(true);
    };

    // RENDER
    return (
        <TableRow hover className={classes.basicRowStripes}>
            <TableCell>{row.contractName}</TableCell>
            <TableCell>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName}` : ''}
            </TableCell>
            <TableCell className={classes.rowLink} onClick={onNav}>
                {row.ptCode}
            </TableCell>
            <TableCell>{row.poNumber || ''}</TableCell>
            <TableCell>{row.eligibilityGroupName}</TableCell>
            <TableCell>{row.participantStatus}</TableCell>
            <TableCell>{row.participantStatusReason}</TableCell>
            <TableCell>{reverseFormatDate(row.referralDate) || ''}</TableCell>
            <TableCell>{reverseFormatDate(row.dateCreated) || ''}</TableCell>
            <TableCell>{Math.floor(countDays(row.referralDate, row.dateCreated))}</TableCell>
            <TableCell>
                {row.initialAppointment ? reverseFormatDate(row.initialAppointment.date) : '-'}
            </TableCell>
        </TableRow>
    );
};

ReferralsCreatedTableRow.propTypes = {
    row: PropTypes.object.isRequired
};

export default ReferralsCreatedTableRow;
