import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    createServiceConstants,
    loadServiceConstants,
    updateServiceConstants
} from '../../../store/participantService';
import { selectUserRoles } from '../../../store/userSelectors';
import { charVal } from '../../../utils/formValidation/validationPatterns';
import {
    getEmptyErrorState,
    isErrorsValid,
    validate
} from '../../../utils/formValidation/validator';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import TextInputField from '../../formElements/TextInputField';

import { addressAndContactInfoValidationFields } from './addressAndContactInfoValidationFields';

import form from '../../../commonStyles/formStyles.module.css';

const AddressAndContactInfo = ({ serviceId }) => {
    const dispatch = useDispatch();
    const roles = useSelector(selectUserRoles);
    const validationFields = { ...addressAndContactInfoValidationFields };

    const initialContactInfo = {
        formAddressLine1: null,
        formAddressLine2: null,
        formAddressLine3: null,
        formAddressLine4: null,
        formCity: null,
        formCounty: null,
        formPostcode: null,
        formPhoneNumber: null,
        exitFormEmailAddress: null,
        sanctionFormFromEmailAddress: null,
        serviceAddressLine1: null,
        serviceAddressLine2: null,
        serviceAddressLine3: null,
        serviceAddressLine4: null,
        serviceCity: null,
        serviceCounty: null,
        servicePostcode: null
    };

    const [newEntry, setNewEntry] = useState(initialContactInfo);
    const [isUpdating, setIsUpdating] = useState(false);
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [isValid, setIsValid] = useState(true);

    const serviceConstants = useSelector(
        (state) => state.entities.participantService.serviceConstants
    );

    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    const clearError = (key) => {
        const newErrors = { ...errors, [key]: { error: false, message: '' } };
        setIsValid(isErrorsValid(newErrors));
        setErrors(newErrors);
    };

    useEffect(() => {
        if (serviceConstants[serviceId]) {
            setNewEntry(serviceConstants[serviceId]);
            validationFields.button.checkDiff = serviceConstants[serviceId];
        } else dispatch(loadServiceConstants(serviceId));
    }, [serviceId, serviceConstants]);

    useEffect(() => {
        if (successMessage.includes('Service constants has been updated') && isUpdating) {
            validationFields.button.checkDiff = serviceConstants[serviceId];
            setIsUpdating(false);
        }
    }, [successMessage]);

    const onUpdate = (key, value) => {
        setNewEntry({ ...newEntry, [key]: value });
        clearError(key);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const validation = validate(newEntry, addressAndContactInfoValidationFields);
        setErrors(validation.errors);
        setIsValid(validation.isValid);
        if (!validation.isValid) return;
        setIsUpdating(true);
        newEntry.id
            ? dispatch(updateServiceConstants(newEntry))
            : dispatch(createServiceConstants({ ...newEntry, serviceId }));
    };

    if (!hasRole([SUPERUSER], roles)) return;

    return (
        <div>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.formWrapper}>
                <h3>Form Details</h3>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={`${serviceId}-formAddressLine1`}
                            label={'Form Address Line 1'}
                            placeholder={'Enter form address line 1'}
                            value={newEntry.formAddressLine1 || ''}
                            error={errors.formAddressLine1}
                            onChange={(e) =>
                                onUpdate(
                                    'formAddressLine1',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-formAddressLine2`}
                            label={'Form Address Line 2'}
                            placeholder={'Enter form address line 2'}
                            value={newEntry.formAddressLine2 || ''}
                            error={errors.formAddressLine2}
                            onChange={(e) =>
                                onUpdate(
                                    'formAddressLine2',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-formAddressLine3`}
                            label={'Form Address Line 3'}
                            placeholder={'Enter form address line 3'}
                            value={newEntry.formAddressLine3 || ''}
                            error={errors.formAddressLine3}
                            onChange={(e) =>
                                onUpdate(
                                    'formAddressLine3',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-formAddressLine4`}
                            label={'Form Address Line 4'}
                            placeholder={'Enter form address line 4'}
                            value={newEntry.formAddressLine4 || ''}
                            error={errors.formAddressLine4}
                            onChange={(e) =>
                                onUpdate(
                                    'formAddressLine4',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-formCity`}
                            label={'Form City'}
                            placeholder={'Enter form city'}
                            value={newEntry.formCity || ''}
                            error={errors.formCity}
                            onChange={(e) =>
                                onUpdate('formCity', e.target.value.slice(0, charVal.MAX_CITY))
                            }
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={`${serviceId}-formCounty`}
                            label={'Form County'}
                            placeholder={'Enter form county'}
                            value={newEntry.formCounty || ''}
                            error={errors.formCounty}
                            onChange={(e) =>
                                onUpdate('formCounty', e.target.value.slice(0, charVal.MAX_COUNTY))
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-formPostcode`}
                            label={'Form Postcode'}
                            placeholder={'Enter form postcode'}
                            value={newEntry.formPostcode || ''}
                            error={errors.formPostcode}
                            onChange={(e) => onUpdate('formPostcode', e.target.value)}
                        />
                        <TextInputField
                            id={`${serviceId}-formPhoneNumber`}
                            label={'Form Phone Number'}
                            placeholder={'Enter form phone number'}
                            value={newEntry.formPhoneNumber || ''}
                            error={errors.formPhoneNumber}
                            onChange={(e) =>
                                onUpdate(
                                    'formPhoneNumber',
                                    e.target.value.slice(0, charVal.MAX_PHONE)
                                )
                            }
                            type="tel"
                        />
                        <TextInputField
                            id={`${serviceId}-exitFormEmailAddress`}
                            label={'Exit Form From Email Address'}
                            placeholder={'Enter exit form from email address'}
                            value={newEntry.exitFormEmailAddress || ''}
                            error={errors.exitFormEmailAddress}
                            onChange={(e) =>
                                onUpdate(
                                    'exitFormEmailAddress',
                                    e.target.value.slice(0, charVal.MAX_EMAIL)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-sanctionFormFromEmailAddress`}
                            label={'Sanction Form From Email Address'}
                            placeholder={'Enter sanction form from email address'}
                            value={newEntry.sanctionFormFromEmailAddress || ''}
                            error={errors.sanctionFormFromEmailAddress}
                            onChange={(e) =>
                                onUpdate(
                                    'sanctionFormFromEmailAddress',
                                    e.target.value.slice(0, charVal.MAX_EMAIL)
                                )
                            }
                        />
                    </div>
                </div>
                <h3>Service Details</h3>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={`${serviceId}-serviceAddressLine1`}
                            label={'Service Address Line 1'}
                            placeholder={'Enter service address line 1'}
                            value={newEntry.serviceAddressLine1 || ''}
                            error={errors.serviceAddressLine1}
                            onChange={(e) =>
                                onUpdate(
                                    'serviceAddressLine1',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-serviceAddressLine2`}
                            label={'Service Address Line 2'}
                            placeholder={'Enter service address line 2'}
                            value={newEntry.serviceAddressLine2 || ''}
                            error={errors.serviceAddressLine2}
                            onChange={(e) =>
                                onUpdate(
                                    'serviceAddressLine2',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-serviceAddressLine3`}
                            label={'Service Address Line 3'}
                            placeholder={'Enter service address line 3'}
                            value={newEntry.serviceAddressLine3 || ''}
                            error={errors.serviceAddressLine3}
                            onChange={(e) =>
                                onUpdate(
                                    'serviceAddressLine3',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-serviceAddressLine4`}
                            label={'Service Address Line 4'}
                            placeholder={'Enter service address line 4'}
                            value={newEntry.serviceAddressLine4 || ''}
                            error={errors.serviceAddressLine4}
                            onChange={(e) =>
                                onUpdate(
                                    'serviceAddressLine4',
                                    e.target.value.slice(0, charVal.MAX_ADDRESS)
                                )
                            }
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={`${serviceId}-serviceCity`}
                            label={'Service City'}
                            placeholder={'Enter service city'}
                            value={newEntry.serviceCity || ''}
                            error={errors.serviceCity}
                            onChange={(e) =>
                                onUpdate('serviceCity', e.target.value.slice(0, charVal.MAX_CITY))
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-serviceCounty`}
                            label={'Service County'}
                            placeholder={'Enter service county'}
                            value={newEntry.serviceCounty || ''}
                            error={errors.serviceCounty}
                            onChange={(e) =>
                                onUpdate(
                                    'serviceCounty',
                                    e.target.value.slice(0, charVal.MAX_COUNTY)
                                )
                            }
                        />
                        <TextInputField
                            id={`${serviceId}-servicePostcode`}
                            label={'Service Postcode'}
                            placeholder={'Enter service postcode'}
                            value={newEntry.servicePostcode || ''}
                            error={errors.servicePostcode}
                            onChange={(e) => onUpdate('servicePostcode', e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    id="updateAddressAndContactInfoButton"
                    content="UPDATE"
                    disabled={!isValid}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </div>
    );
};

AddressAndContactInfo.propTypes = {
    serviceId: PropTypes.string
};

export default AddressAndContactInfo;
