import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchBaseAppointments } from '../../store/calendarService';
import { selectContracts } from '../../store/dataSelectors';
import { loadAppointmentTypeDetails } from '../../store/directusService';
import {
    loadParticipantsAdvancedSearch,
    searchEligibilityGroups,
    searchParticipantTimelineEvent
} from '../../store/participantService';
import { selectUsers } from '../../store/userSelectors';
import { loadUsersByUserIds } from '../../store/userService';
import { getNameFromId } from '../../utils/directusFunctions';
import { stableSort } from '../../utils/sortFunctions';
import ResultsTable from '../table/ResultsTable';

import ReferralsCreatedTableRow from './ReferralsCreatedTableRow';

import app from '../../app.module.css';

const headCells = [
    { id: 'contractId', label: 'Contract', sortable: false },
    { id: 'createdBy', label: 'Created By', sortable: true },
    { id: 'ptCode', label: 'Participant ID', sortable: true },
    { id: 'poNumber', label: 'PO Number', sortable: true },
    { id: 'eligibilityGroupId', label: 'Eligibility Group', sortable: true },
    { id: 'status', label: 'Status', sortable: true },
    { id: 'statusReason', label: 'Status Reason', sortable: true },
    { id: 'referralDate', label: 'Referral Date', sortable: true },
    { id: 'dateCreated', label: 'Participant Created Date', sortable: true },
    {
        id: 'daysBetweenReferralAndCreated',
        label: 'Days between referral date and created date',
        sortable: true
    },
    { id: 'initialAppointmentDate', label: 'Initial Appointment Date', sortable: true }
];

const initialRowMetaData = {
    order: 'asc',
    orderBy: 'firstName',
    page: 0,
    rowsPerPage: 100
};

const ReferralsCreated = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [userIdsForRowsToLoad, setUserIdsForRowsToLoad] = useState([]);
    const [participantIdsForRows, setParticipantIdsForRows] = useState([]);
    const [initialTimeLineEntryByParticipantId, setInitialTimeLineEntryByParticipantId] = useState(
        {}
    );
    const [rowMetaData, setRowMetaData] = useState(initialRowMetaData);

    // STORE STATE
    const {
        eligibilityGroupSearch,
        loadingParticipantsSearch,
        participantSearchData: { data: searchData },
        participantsSearch,
        participantsSearchMetaData,
        participantTimelineSearchData,
        participantTimelineSearchDataLoaded,
        participantTimelineSearchDataLoading
    } = useSelector((state) => state.entities.participantService);
    const { appointmentsBaseSearch, loadingAppointmentsBaseSearch } = useSelector(
        (state) => state.entities.calendarService
    );
    const contracts = useSelector(selectContracts);
    const users = useSelector(selectUsers);
    const {
        usersMetaData: { number: lastPageOfUsersLoaded, last: allUsersLoaded },
        usersByLoggedInUserServiceIds
    } = useSelector((state) => state.entities.userService);
    const { appointmentTypeDetails, participantStatusDetails, participantStatusReasonDetails } =
        useSelector((state) => state.entities.directusService);

    // USE EFFECTS
    useEffect(() => {
        appointmentTypeDetails?.length < 1 && dispatch(loadAppointmentTypeDetails());
    }, []);

    useEffect(() => {
        if (participantsSearch?.length < 1 || appointmentTypeDetails?.length < 1) {
            setRowMetaData(initialRowMetaData);
            return;
        }
        const newParticipantIdsForRows = participantsSearch.map((el) => el.id);
        setParticipantIdsForRows(newParticipantIdsForRows);

        const eligibilityGroupIdsForRows = [
            ...new Set(participantsSearch.map((el) => el.eligibilityGroupId))
        ];
        dispatch(searchEligibilityGroups({ ids: eligibilityGroupIdsForRows }));

        const initialAppointmentType = appointmentTypeDetails.find(
            (el) => el.name.toLowerCase() === 'initial'
        );
        dispatch(
            searchBaseAppointments({
                participantIds: newParticipantIdsForRows,
                typeIds: [initialAppointmentType.id]
            })
        );
        dispatch(
            searchParticipantTimelineEvent({
                participantIds: newParticipantIdsForRows,
                eventType: 'PARTICIPANT'
            })
        );
    }, [participantsSearch, appointmentTypeDetails]);

    useEffect(() => {
        if (
            participantTimelineSearchData?.length < 1 ||
            participantIdsForRows.length !== participantsSearch.length
        )
            return;
        const newInitialTimeLineEntryByParticipantId = participantIdsForRows.reduce(
            (acc, participantId) => {
                const initialTimeLineEntry = [...participantTimelineSearchData]
                    .reverse()
                    .find((entry) => entry.participantId === participantId);
                if (initialTimeLineEntry) {
                    acc[participantId] = initialTimeLineEntry;
                }
                return acc;
            },
            {}
        );
        setInitialTimeLineEntryByParticipantId(newInitialTimeLineEntryByParticipantId);

        const newUserIdsForRowsToLoad = [
            ...new Set(Object.values(newInitialTimeLineEntryByParticipantId).map((el) => el.userId))
        ].filter((el) => !usersByLoggedInUserServiceIds.some((entry) => entry.id === el));
        if (newUserIdsForRowsToLoad.length > 0) {
            setUserIdsForRowsToLoad(newUserIdsForRowsToLoad);
            dispatch(loadUsersByUserIds(newUserIdsForRowsToLoad));
        }
    }, [participantTimelineSearchData, participantIdsForRows]);

    useEffect(() => {
        if (users?.length > 0 && !allUsersLoaded) {
            dispatch(loadUsersByUserIds(userIdsForRowsToLoad, lastPageOfUsersLoaded + 1));
        }
    }, [users, allUsersLoaded]);

    useEffect(() => {
        if (
            !contracts.length > 0 ||
            loadingAppointmentsBaseSearch ||
            (userIdsForRowsToLoad.length > 0 && !allUsersLoaded) ||
            !eligibilityGroupSearch.length > 0 ||
            !participantStatusDetails.length > 0 ||
            participantTimelineSearchDataLoading ||
            !participantTimelineSearchDataLoaded
        )
            return;
        const newRows = participantsSearch.map((el) => {
            const contract = contracts.find((entry) => entry.id === el.contractId);
            const initialAppointment = appointmentsBaseSearch.find(
                (entry) => entry.participantId === el.id
            );
            const user = initialTimeLineEntryByParticipantId[el.id]
                ? [...usersByLoggedInUserServiceIds, ...users].find(
                      (entry) => entry.id === initialTimeLineEntryByParticipantId[el.id].userId
                  )
                : '';
            const eligibilityGroupName = getNameFromId(
                eligibilityGroupSearch,
                el.eligibilityGroupId
            );
            const participantStatus = getNameFromId(participantStatusDetails, el.statusId);
            const participantStatusReason = getNameFromId(
                participantStatusReasonDetails,
                el.statusReasonId
            );
            return {
                ...el,
                contractName: contract.name,
                createdBy: user,
                eligibilityGroupName,
                participantStatus,
                participantStatusReason,
                initialAppointment
            };
        });
        setRows(newRows);
    }, [
        contracts,
        loadingAppointmentsBaseSearch,
        userIdsForRowsToLoad,
        allUsersLoaded,
        eligibilityGroupSearch,
        participantStatusDetails,
        initialTimeLineEntryByParticipantId
    ]);

    // HELPER FNS
    const createRows = () =>
        stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <ReferralsCreatedTableRow key={el.ptCode} row={el} />
        ));

    // RENDER
    return (
        <div className={app.container}>
            <h2 className={app.mainHeading}>Data Sets Results Screen</h2>
            {rows?.length < 1 ? (
                <div>No referrals found</div>
            ) : (
                <ResultsTable
                    defaultOrderBy={'firstName'}
                    headCells={headCells}
                    loadingValues={loadingParticipantsSearch || loadingAppointmentsBaseSearch}
                    loadResults={loadParticipantsAdvancedSearch}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={searchData}
                    tableRows={createRows()}
                    totalResults={participantsSearchMetaData.totalElements}
                    metaData={participantsSearchMetaData}
                />
            )}
        </div>
    );
};

export default ReferralsCreated;
