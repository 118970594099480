import React from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';

import LoadingSpinner from '../../ui/LoadingSpinner';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import dropdown from '../claimStyles/rowDropDown.module.css';

const ReversionDetails = ({ row, onClose }) => {
    return !row ? (
        <LoadingSpinner content="No reversion information found" />
    ) : (
        <div className={dropdown.rowDropdown}>
            <div className={app.sectionHeading}>Reversion Feedback</div>
            <div className={form.staticField}>
                <div className={form.staticText}>{row?.notes || ''}</div>
            </div>
            <div className={dropdown.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

export default ReversionDetails;

ReversionDetails.propTypes = {
    row: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};
