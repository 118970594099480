import {
    getCharMinMessage,
    getSelectValueMessage
} from '../../../utils/formValidation/errorMessageFunctions';

export const NOTE_MIN_CHAR = 1;
export const NOTE_CHAR_LIMIT = 750;

export const initialErrorState = {};

export const validate = (newEntry) => {
    let newErrors = {};

    if (newEntry.actionNotes.trim().length < NOTE_MIN_CHAR) {
        newErrors = {
            ...newErrors,
            actionNotes: { error: true, message: getCharMinMessage('Notes', 1) }
        };
    }

    if (!newEntry.actionStatusId) {
        newErrors = {
            ...newErrors,
            actionStatusId: { error: true, message: getSelectValueMessage() }
        };
    }

    return newErrors;
};
