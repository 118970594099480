import React, { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@mui/material';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { updateCourseModuleParticipants } from '../../../store/courseService';
import { selectAttendedStatusId, selectBarrierDetails } from '../../../store/dataSelectors';
import { loadBarrierNameDetails } from '../../../store/directusService';
import { clearParticipantsSearch, searchParticipants } from '../../../store/participantService';
import { createActionPlan } from '../../../store/participantService';
import LoadingSpinner from '../../ui/LoadingSpinner';

import FullCourseAttendanceTable from './FullCourseAttendanceTable';

import classes from '../../../app.module.css';
import local from '../courseStyles/fullCourseAttendanceStyles.module.css';

const FullCourseAttendanceDialog = ({ module, isOpen }) => {
    const dispatch = useDispatch();

    const msg = `Module details have been updated for module ${module.title}`;
    const [open, setOpen] = useState(true);
    const [rows, setRows] = useState([]);
    const [updatedParticipants, setUpdatedParticipants] = useState([]);
    const [actionPlanData, setActionPlanData] = useState([]);
    const [currentParticipantId, setCurrentParticipantId] = useState('');

    const participants = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const attendedId = useSelector(selectAttendedStatusId);
    const barrierNameDetails = useSelector(selectBarrierDetails);

    useEffect(() => {
        if (barrierNameDetails?.length < 1) dispatch(loadBarrierNameDetails());
        const ids = module?.participants?.map((el) => el.participantId);
        ids.length > 0
            ? dispatch(searchParticipants({ ids }, 0, DEFAULT_PAGE_LOAD_SIZE))
            : dispatch(clearParticipantsSearch());
        setUpdatedParticipants(module.participants);
    }, [module]);

    useEffect(() => {
        if (!participants) return;
        const rows = participants.map((el) => ({
            id: el.id,
            contractId: el.contractId,
            ptCode: el.ptCode,
            firstName: el.firstName,
            lastName: el.lastName,
            notes: module?.participants?.find((entry) => entry.participantId === el.id)?.notes || ''
        }));
        setRows(rows);
    }, [participants]);

    useEffect(() => {
        if (!currentParticipantId) return;
        if (actionPlanData.some((el) => el.participantId === currentParticipantId)) return;
        const actionPlan = {
            participantId: currentParticipantId,
            mandatory: '',
            actionTitle: module.smartTarget.name,
            action: module.smartTarget.action,
            actionStartDate: '',
            actionDueDate: '',
            actionOutcomeDate: '',
            outcomeId: '',
            moduleId: module.id,
            comments: ''
        };
        setActionPlanData((prev) => [...prev, actionPlan]);
    }, [currentParticipantId]);

    useEffect(() => {
        if (successMessage === msg) {
            if (currentParticipantId) {
                actionPlanData.length > 0 &&
                    actionPlanData.forEach((el) => dispatch(createActionPlan(el)));
                setCurrentParticipantId('');
                setActionPlanData([]);
                onClose();
            } else onClose();
        }
    }, [successMessage, actionPlanData]);

    // EVENT HANDLERS

    const onClose = () => {
        setOpen(false);
        isOpen(false);
    };

    const onUpdateParticipantDetail = (statusId, participantId) => {
        const modified = updatedParticipants.map((el) =>
            el.participantId === participantId ? { ...el, statusId } : el
        );
        setUpdatedParticipants(modified);

        if (
            module?.smartTarget &&
            attendedId &&
            statusId === attendedId &&
            barrierNameDetails.length > 0
        ) {
            setCurrentParticipantId(participantId);
        }
    };

    const onUpdate = () => {
        const payload = { participants: updatedParticipants };
        dispatch(updateCourseModuleParticipants(module.id, payload, msg));
    };

    if (participants?.length < 1) {
        return <LoadingSpinner content="No participants" />;
    }

    return (
        <div>
            <Dialog open={open} maxWidth="md" fullWidth>
                <DialogTitle
                    id="alert-dialog-title"
                    className={classes.center}>{`Course Booking Confirmation`}</DialogTitle>
                <DialogContent style={{ height: '725px' }}>
                    <Typography variant="h6" className={classes.center} component="div">
                        The following participants are currently booked on this course
                    </Typography>
                    {rows.length > 0 && (
                        <FullCourseAttendanceTable
                            rows={rows}
                            module={module}
                            onUpdateParticipantDetail={onUpdateParticipantDetail}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <div className={local.dialogActions}>
                        <Button variant={'contained'} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant={'contained'} onClick={onUpdate}>
                            Update
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FullCourseAttendanceDialog;

FullCourseAttendanceDialog.propTypes = {
    module: PropTypes.object,
    isOpen: PropTypes.func
};
