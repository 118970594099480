import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ClearIcon from '@mui/icons-material/Clear';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { loadUserAppointments, loadUserAppointmentSlots } from '../../../store/calendarService';
import { loadCourseModulesByUserId } from '../../../store/courseService';
import {
    loadLocationsByServices,
    loadServiceDetailsByService
} from '../../../store/directusService';
import { searchParticipants } from '../../../store/participantService';
import MultiSelect from '../../formElements/MultiSelect';
import { getServiceIds } from '../../ui/calendar/calendarUtils';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;

const SchedulerAdviserPanel = ({
    advisers,
    selectedAdviserIds,
    setSelectedAdviserIds,
    activeAdviserId,
    setActiveAdviserId,
    onLoadMoreAdvisers,
    moreAdvisersToLoad,
    disabled = false
}) => {
    const dispatch = useDispatch();

    const [existingServiceIds, setExistingServiceIds] = useState([]);
    const [calendarHeight, setCalendarHeight] = useState(1000);
    const [listItemHeight, setListItemHeight] = useState(30);

    // STORE STATE
    const { participantsSearch, participantsSearchMetaData } = useSelector(
        (state) => state.entities.participantService
    );

    useEffect(() => {
        const el = document.querySelector('.timeview-content-container');
        setCalendarHeight(el.getBoundingClientRect().height - 170);
    });

    useEffect(() => {
        setListItemHeight(~~(calendarHeight / selectedAdviserIds.length) - 3);
    }, [selectedAdviserIds]);

    useEffect(() => {
        if (activeAdviserId) {
            dispatch(loadUserAppointments(activeAdviserId));
            dispatch(loadUserAppointmentSlots(activeAdviserId));
            dispatch(loadCourseModulesByUserId(activeAdviserId));

            const activeAdviser = advisers.find((el) => el.id === activeAdviserId);
            const serviceIds = getServiceIds(activeAdviser);
            const newServiceIds = serviceIds.filter((el) => !existingServiceIds.includes(el));

            if (newServiceIds.length) {
                dispatch(loadServiceDetailsByService([...existingServiceIds, ...newServiceIds]));
                dispatch(loadLocationsByServices([...existingServiceIds, ...newServiceIds]));
                dispatch(
                    searchParticipants(
                        { serviceIds: [...existingServiceIds, ...newServiceIds] },
                        0,
                        PT_LOAD_SIZE
                    )
                );
            }
            setExistingServiceIds([...existingServiceIds, ...newServiceIds]);
        }
    }, [activeAdviserId]);

    useEffect(() => {
        if (participantsSearch?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { serviceIds: [existingServiceIds] },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        }
    }, [participantsSearch, participantsSearchMetaData]);

    const onToggleActiveAdviser = (id) => {
        if (id === activeAdviserId) {
            setActiveAdviserId('');
        } else {
            setActiveAdviserId(id);
        }
    };

    const onRemove = (id) => {
        const newSelectedAdviserIds = selectedAdviserIds.filter((el) => el !== id);
        setSelectedAdviserIds(newSelectedAdviserIds);
    };

    return (
        <div className="input-set adviser-panel">
            <div>
                <MultiSelect
                    id="adviserIds"
                    label="Advisers"
                    placeholder="Select advisers..."
                    disabled={disabled}
                    menuItems={advisers || []}
                    preSelectedIds={selectedAdviserIds}
                    preSelects={advisers.filter((el) => selectedAdviserIds.includes(el.id))}
                    onChange={setSelectedAdviserIds}
                    onLoadMoreItems={onLoadMoreAdvisers}
                    moreItemsToLoad={moreAdvisersToLoad}
                />
            </div>
            <div className="added-advisers">
                {selectedAdviserIds.map((el) => {
                    const adviser = advisers.find((entry) => entry.id === el);
                    return adviser ? (
                        <div
                            key={adviser.id}
                            onClick={() => onToggleActiveAdviser(adviser.id)}
                            className={`adviser-list-item ${activeAdviserId === adviser.id ? 'selected' : ''}`}
                            style={{ height: `${listItemHeight}px` }}>
                            <span className="clear-icon" onClick={() => onRemove(adviser.id)}>
                                <ClearIcon />
                            </span>
                            <span className="title">
                                {' '}
                                {adviser.firstName} {adviser.lastName}{' '}
                            </span>
                        </div>
                    ) : null;
                })}
            </div>
        </div>
    );
};

export default SchedulerAdviserPanel;

SchedulerAdviserPanel.propTypes = {
    advisers: PropTypes.arrayOf(PropTypes.object),
    add: PropTypes.func,
    remove: PropTypes.func,
    selectedAdviserIds: PropTypes.arrayOf(PropTypes.string),
    setSelectedAdviserIds: PropTypes.func,
    activeAdviserId: PropTypes.string,
    setActiveAdviserId: PropTypes.func,
    onLoadMoreAdvisers: PropTypes.func,
    moreAdvisersToLoad: PropTypes.bool,
    disabled: PropTypes.bool
};
