import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogTitle } from '@mui/material';

import { loadClaimQueueActionStatusDetails } from '../../../store/directusService';
import { updateClaimQueueActionStatus } from '../../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';
import TextAreaField from '../../formElements/TextAreaField';

import { initialErrorState, NOTE_CHAR_LIMIT, validate } from './validateActionEditor';

import form from '../../../commonStyles/formStyles.module.css';
import local from '../claimStyles/validationModal.module.css';

const ActionEditor = ({ roles, isOpen, onCancel, formType, onUpdate, selectedRow }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const catCarEscalatedRoles = [MANAGER];
    const catOnlyRoles = [ADVISER, RECRUITMENT_MANAGER];
    const acceptedRoles = [PRAP, QUALITY, SUPERUSER];
    const allRoles = [...catOnlyRoles, ...catCarEscalatedRoles, ...acceptedRoles];
    const initialState = {
        id: '',
        actionStatusId: '',
        actionNotes: ''
    };
    const [actionStatuses, setActionStatuses] = useState([]);
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);

    // STORE STATE
    const claimQueueActionStatusDetails = useSelector(
        (state) => state.entities.directusService.claimQueueActionStatusDetails
    );

    // USE EFFECTS
    useEffect(() => {
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (roles.length < 1 || claimQueueActionStatusDetails?.length < 1) return;

        const catStatus = claimQueueActionStatusDetails.find(
            (el) => el.name === 'Corrective Action Taken'
        );
        const carStatus = claimQueueActionStatusDetails.find(
            (el) => el.name === 'Corrective Action Required'
        );
        const carEscalatedStatus = claimQueueActionStatusDetails.find(
            (el) => el.name === 'Escalate Corrective Action Required'
        );

        setNewEntry((prev) => ({
            ...prev,
            id: selectedRow.id,
            actionStatusId: formType === 'create' ? carStatus.id : selectedRow.actionStatusId || ''
        }));

        if (formType === 'edit') {
            if (roles.some((el) => acceptedRoles.includes(el)))
                setActionStatuses(claimQueueActionStatusDetails);
            if (roles.some((el) => catOnlyRoles.includes(el))) setActionStatuses([catStatus]);
            if (roles.some((el) => catCarEscalatedRoles.includes(el)))
                setActionStatuses([carEscalatedStatus, catStatus]);
        }
    }, [roles, claimQueueActionStatusDetails]);

    // HELPER FNS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onInputUpdate = (key, value) => {
        clearError(key);
        setNewEntry((prev) => ({
            ...prev,
            [key]: value
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(newEntry);
        setErrors(newErrors);
        if (Object.keys(newErrors).length > 0) return;
        dispatch(updateClaimQueueActionStatus(newEntry));
        onUpdate();
    };

    // RENDER
    return (
        <div>
            <Dialog onClose={onCancel} open={isOpen} fullWidth={true}>
                <DialogTitle>{formType === 'edit' ? 'Update Action' : 'Create Action'}</DialogTitle>

                <div className={local.modalContent}>
                    <form
                        onSubmit={onSubmit}
                        className={form.formWrapper}
                        data-testid="update_action_form_start">
                        {formType === 'create' ? (
                            <div className={form.staticField}>
                                <h3>Corrective Activity Status</h3>
                                Corrective Activity Required
                            </div>
                        ) : (
                            <SingleSelect
                                id={'actionStatusId'}
                                label={'Corrective Activity Status'}
                                placeholder={'Select an Action Status'}
                                disabled={!hasRole(allRoles, roles)}
                                mandatory={true}
                                menuItems={actionStatuses || []}
                                selectedId={
                                    newEntry.actionStatusId || selectedRow?.actionStatusId || ''
                                }
                                selected={actionStatuses.find(
                                    (el) => el.id === newEntry.actionStatusId
                                )}
                                error={errors.actionStatusId}
                                onChange={(chosenId) => onInputUpdate('actionStatusId', chosenId)}
                            />
                        )}

                        <TextAreaField
                            id="actionNotes"
                            label="Notes"
                            placeholder={'Notes'}
                            disabled={!hasRole(allRoles, roles)}
                            mandatory={true}
                            maxLength={NOTE_CHAR_LIMIT}
                            rows={8}
                            value={newEntry.actionNotes || ''}
                            count={`${newEntry.actionNotes.length}/${NOTE_CHAR_LIMIT}`}
                            error={errors.actionNotes}
                            onChange={(e) => onInputUpdate('actionNotes', e.target.value)}
                        />

                        <div className={local.actionButtons}>
                            <Button
                                id="actionEditorSubmitButton"
                                disabled={!hasRole(allRoles, roles)}
                                content={formType === 'create' ? 'Create' : 'Update'}
                            />

                            <Button
                                id="actionEditorCancelButton"
                                type="button"
                                disabled={!hasRole(allRoles, roles)}
                                content="Cancel"
                                onClick={onCancel}
                            />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default ActionEditor;

ActionEditor.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    formType: PropTypes.string,
    onUpdate: PropTypes.func,
    selectedRow: PropTypes.object
};
