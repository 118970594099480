import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { checkExists, getParticipantURL } from '../../../api/commonHTTP';
import { selectDocumentTypes } from '../../../store/dataSelectors';
import {
    clearAppointmentType,
    loadAppointmentTypeDetails,
    loadAppointmentTypeDetailsByContractIds,
    loadDocumentTypes
} from '../../../store/directusService';
import {
    createParticipantSectionHeadings,
    loadContractConstants,
    loadParticipantSectionHeadings,
    updateContractConstants,
    updateParticipantSectionHeadings
} from '../../../store/participantService';
import {
    addDirectusContractSelections,
    updateDirectusContractSelections,
    updatePreSelects
} from '../../../utils/directusFunctions';
import { hasRole, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import MultiSelect from '../../formElements/MultiSelect';
import RadioButtons from '../../formElements/RadioButtons';
import Switch from '../../formElements/Switch';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { BUTTON_ERROR, checkDiff, initialErrorState, validate } from './validateAppointmentsAdmin';

import form from '../../../commonStyles/formStyles.module.css';
import local from './appointmentsAdmin.module.css';

const AppointmentsAdmin = ({ contractId }) => {
    const dispatch = useDispatch();
    // LOCAL STATE
    const initialHeadings = {
        questionnaire: false,
        jobReadiness: false,
        actionPlan: false,
        barriers: false,
        courses: false,
        request: false,
        vacancy: false
    };

    const acceptedRoles = [SUPERUSER];
    const submitMessage = 'Appointment types have been updated';
    const documentTypeName = 'document_type';
    const appointmentTypeName = 'calendar_appointment_type';
    const [sectionHeadings, setSectionHeadings] = useState(initialHeadings);
    const [sectionHeadingsOnEntry, setSectionHeadingsOnEntry] = useState({});
    const [displayESignature, setDisplayESignature] = useState(false);
    const [requirePoNumber, setRequirePoNumber] = useState(false);
    const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState([]);
    const [preSelectedAppointmentTypes, setPreSelectedAppointmentTypes] = useState([]);
    const [preSelectedAppointmentTypeIds, setPreSelectedAppointmentTypeIds] = useState([]);
    const [arrayAppointmentTypes, setArrayAppointmentTypes] = useState([]);
    const [isClearSelectedAppointmentTypes, setIsClearSelectedAppointmentTypes] = useState('0');
    const [preSelectedDocumentTypes, setPreSelectedDocumentTypes] = useState([]);
    const [preSelectedDocumentTypeIds, setPreSelectedDocumentTypeIds] = useState([]);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    const [isUpdatedAppointmentTypes, setIsUpdatedAppointmentTypes] = useState(false);

    // STORE STATE
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const appointmentTypeDetails = useSelector(
        (state) => state.entities.directusService.appointmentTypeDetails
    );
    const documentTypes = useSelector(selectDocumentTypes);
    const participantSectionHeadings = useSelector(
        (state) => state.entities.participantService.participantSectionHeadings
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // USEEFFECTS
    const contractSetup = () => {
        setSelectedAppointmentTypes([]);
        setPreSelectedAppointmentTypeIds([]);
        setArrayAppointmentTypes([]);
        setPreSelectedDocumentTypes([]);
        setPreSelectedDocumentTypeIds([]);
        setIsClearSelectedAppointmentTypes(Math.random());
        dispatch(clearAppointmentType());
        dispatch(loadAppointmentTypeDetails());
        dispatch(loadAppointmentTypeDetailsByContractIds([contractId]));
        dispatch(loadDocumentTypes());
        setIsUpdatedAppointmentTypes(false);
        setDisplayESignature(false);
        setRequirePoNumber(false);
    };

    useEffect(() => {
        if (contractId) {
            contractSetup();
            dispatch(loadParticipantSectionHeadings(contractId));
            dispatch(loadContractConstants(contractId));
        }
    }, [contractId]);

    useEffect(() => {
        if (Object.keys(participantSectionHeadings)?.length > 0) {
            // eslint-disable-next-line
            const { id, contractId, ...rest } = participantSectionHeadings;
            setSectionHeadings((prev) => ({ ...prev, ...rest }));
            setSectionHeadingsOnEntry((prev) => ({ ...prev, ...rest }));
        }
    }, [participantSectionHeadings]);

    useEffect(() => {
        if ('displayESignature' in contractConstants)
            setDisplayESignature(contractConstants.displayESignature);
        setRequirePoNumber(
            'requirePoNumber' in contractConstants ? contractConstants.requirePoNumber : false
        );
    }, [contractConstants]);

    useEffect(() => {
        if (appointmentTypeDetails?.length > 0 && arrayAppointmentTypes?.length === 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                appointmentTypeName,
                appointmentTypeDetails,
                contractId
            );
            setPreSelectedAppointmentTypes(preSelects);
            setPreSelectedAppointmentTypeIds(preSelectIds);
            setArrayAppointmentTypes(appointmentTypeDetails);
        }
    }, [appointmentTypeDetails]);

    useEffect(() => {
        if (documentTypes?.length > 0) {
            const { preSelects, preSelectIds } = updatePreSelects(
                documentTypeName,
                documentTypes,
                contractId
            );
            setPreSelectedDocumentTypes(preSelects);
            setPreSelectedDocumentTypeIds(preSelectIds);
        }
    }, [documentTypes]);

    useEffect(() => {
        if (successMessage.includes(`${submitMessage}`)) {
            contractSetup();
            setIsDisabled(false);
        }
    }, [successMessage]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    //  HELPER FNS

    const loadingError = () => {
        if (!contractId) return 'No contract';
        if (appointmentTypeDetails?.length < 1) return 'No appointment type details';
    };
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS

    const onAppointmentTypeChange = (chosenIds) => {
        if (!chosenIds) chosenIds = [];
        clearError('appointmentTypes');
        setSelectedAppointmentTypes(chosenIds);
        setIsUpdatedAppointmentTypes(true);
    };

    const onUpdateSectionHeadings = (key, e) => {
        setSectionHeadings((prev) => ({
            ...prev,
            [key]: e.target.checked
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let isUpdated = false;
        const selections = {
            appointmentTypes: selectedAppointmentTypes
        };
        const { newErrors } = validate(selections);
        setErrors(newErrors);
        setIsDisabled(true);
        if (Object.keys(newErrors)?.length > 0) return;
        if (isUpdatedAppointmentTypes) {
            const selectedDocumentTypes = selectedAppointmentTypes
                .map((el) => appointmentTypeDetails.find((entry) => entry.id === el))
                .map(
                    (el) =>
                        documentTypes.find(
                            (entry) => entry.name.toLowerCase() === el.name.toLowerCase()
                        )?.id
                );
            const isUpdatedDocumentTypes = addDirectusContractSelections(
                documentTypeName,
                preSelectedDocumentTypeIds,
                preSelectedDocumentTypes,
                selectedDocumentTypes,
                contractId,
                submitMessage,
                dispatch
            );
            const isUpdatedAppointments = updateDirectusContractSelections(
                appointmentTypeName,
                preSelectedAppointmentTypeIds,
                preSelectedAppointmentTypes,
                selectedAppointmentTypes,
                contractId,
                submitMessage,
                dispatch
            );
            if (isUpdatedDocumentTypes || isUpdatedAppointments) isUpdated = true;
        }

        const { isSame } = checkDiff(sectionHeadings, sectionHeadingsOnEntry);
        if (!isSame) {
            let data;
            if (
                await checkExists(
                    getParticipantURL() + 'rest/participant-section-heading/exists/' + contractId
                )
            ) {
                data = {
                    id: participantSectionHeadings.id,
                    contractId,
                    ...sectionHeadings
                };
                dispatch(updateParticipantSectionHeadings(data));
            } else {
                data = { id: null, contractId, ...sectionHeadings };
                dispatch(createParticipantSectionHeadings(data));
            }
            isUpdated = true;
        }

        if (
            displayESignature !== contractConstants.displayESignature ||
            requirePoNumber !== contractConstants.requirePoNumber
        ) {
            dispatch(
                updateContractConstants({
                    ...contractConstants,
                    displayESignature,
                    requirePoNumber
                })
            );
            isUpdated = true;
        }
        if (!isUpdated)
            setErrors((prev) => ({ ...prev, button: { error: true, message: BUTTON_ERROR } }));
    };

    // RENDER
    if (!hasRole(acceptedRoles, roles)) return <NotFound />;
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <div className={local.appointmentsWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start">
                <MultiSelect
                    id="appointmentTypes"
                    key={isClearSelectedAppointmentTypes}
                    disabled={!hasRole(acceptedRoles, roles)}
                    label={'Appointment Types'}
                    mandatory={true}
                    placeholder="Appointment Types"
                    menuItems={appointmentTypeDetails || []}
                    preSelectedIds={preSelectedAppointmentTypeIds}
                    preSelects={preSelectedAppointmentTypes}
                    error={errors.appointmentTypes}
                    onChange={(chosenIds) => onAppointmentTypeChange(chosenIds)}
                />

                <div className={local.appointmentToggles}>
                    <h3>
                        Select below what Accordions and fields will be displayed for all
                        appointments selected above
                    </h3>
                    <div>
                        <div className={local.sectionHeadingRow}>
                            <span>Questionnaire</span>
                            <Switch
                                id="isQuestionnaire"
                                checked={sectionHeadings.questionnaire}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('questionnaire', e)}
                            />
                        </div>
                        <div className={local.sectionHeadingRow}>
                            <span>Job Readiness</span>
                            <Switch
                                id="isJobReadiness"
                                checked={sectionHeadings.jobReadiness}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('jobReadiness', e)}
                            />
                        </div>
                        <div className={local.sectionHeadingRow}>
                            <span>Action Plan</span>
                            <Switch
                                id="isActionPlan"
                                checked={sectionHeadings.actionPlan}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('actionPlan', e)}
                            />
                        </div>
                        <div className={local.sectionHeadingRow}>
                            <span>Barriers</span>
                            <Switch
                                id="isBarriers"
                                checked={sectionHeadings.barriers}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('barriers', e)}
                            />
                        </div>
                        <div className={local.sectionHeadingRow}>
                            <span>Courses</span>
                            <Switch
                                id="isCourses"
                                checked={sectionHeadings.courses}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('courses', e)}
                            />
                        </div>
                        <div className={local.sectionHeadingRow}>
                            <span>Request</span>
                            <Switch
                                id="isRequest"
                                checked={sectionHeadings.request}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('request', e)}
                            />
                        </div>
                        <div className={local.sectionHeadingRow}>
                            <span>Vacancy</span>
                            <Switch
                                id="isVacancy"
                                checked={sectionHeadings.vacancy || false}
                                disabled={!hasRole(acceptedRoles, roles)}
                                onOff={true}
                                inline={true}
                                onChange={(e) => onUpdateSectionHeadings('vacancy', e)}
                            />
                        </div>

                        <div className={form.formSection}>
                            <div className={form.formColumn}>
                                <h3>E-sign will be used</h3>
                                <div className={local.sectionHeadingRow}>
                                    <span>Display E-sign</span>
                                    <Switch
                                        id="displayESignature"
                                        checked={displayESignature || false}
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        onOff={true}
                                        inline={true}
                                        onChange={(e) => setDisplayESignature(e.target.checked)}
                                    />
                                </div>
                            </div>
                            <div className={form.formColumn}>
                                <div className={local.radioButtons}>
                                    <RadioButtons
                                        id="requirePoNumber"
                                        label="PO Number Required for Initial Appointment Attendance"
                                        disabled={!hasRole(acceptedRoles, roles)}
                                        value={requirePoNumber || false}
                                        onChange={(option) => setRequirePoNumber(option)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button
                    id="appointmentsAdminButton"
                    content="UPDATE APPOINTMENTS"
                    disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                    error={errors.button}
                    clearError={() => clearError('button')}
                />
            </form>
        </div>
    );
};

AppointmentsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default AppointmentsAdmin;
