import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { TableCell, TableRow } from '@mui/material';

import { selectUsers } from '../../store/userSelectors';
import { loadUser } from '../../store/userService';
import { formatJustTime, reverseFormatDate } from '../../utils/dateFunctions';

import classes from '../../commonStyles/tables.module.css';

const AuditEventHistoryTableRow = ({ row, label }) => {
    //HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [emailAddress, setEmailAddress] = useState('');
    const [auditFieldHistory, setAuditFieldHistory] = useState([]);

    // STORE STATE
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const users = useSelector(selectUsers);

    // USE EFFECTS
    useEffect(() => {
        if (row.userId) {
            if (users && users.length) {
                const user = users.find((el) => el.id === row.userId);
                user ? setEmailAddress(user.emailAddress) : dispatch(loadUser(row.userId));
            } else dispatch(loadUser(row.userId));
        }
        setAuditFieldHistory(row.history.find((entry) => entry.field === label));
    }, []);

    useEffect(() => {
        if (successMessage === `Users loaded`) {
            const user = users.find((el) => el.id === row.userId);
            user ? setEmailAddress(user.emailAddress) : setEmailAddress('');
        }
    }, [successMessage]);

    return (
        <>
            <TableRow hover className={`${classes.basicRowStripes}`}>
                <TableCell>{auditFieldHistory?.oldValue}</TableCell>
                <TableCell>{auditFieldHistory?.newValue}</TableCell>
                <TableCell>{emailAddress}</TableCell>
                <TableCell>{reverseFormatDate(row.loggedDate)}</TableCell>
                <TableCell>{formatJustTime(row.loggedTime)}</TableCell>
            </TableRow>
        </>
    );
};

export default AuditEventHistoryTableRow;

AuditEventHistoryTableRow.propTypes = {
    row: PropTypes.object,
    label: PropTypes.string
};
