import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import {
    loadBusinessSectorDetails,
    loadJobSectorDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import {
    loadBusinessRecords,
    loadVacanciesByOwners,
    loadVacancySubmissionsByParticipantId
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersForVacancies } from '../../../../store/userService';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import CurrentVacanciesTableManagement from './currentVacancies/CurrentVacanciesTableManagement';
import RejectedVacanciesTableManagement from './rejectedVacancies/RejectedVacanciesTableManagement';
import VacancySearchTableManagement from './vacancySearch/VacancySearchTableManagement';

const BUS_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;
const USERS_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;
const Vacancies = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [businessesForVacancies, setBusinessesForVacancies] = useState([]);
    const [missingUserIds, setMissingUserIds] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { businessSectorDetails, jobSectorDetails, vacancyStageDetails, vacancySubmissions } =
        useSelector((state) => state.entities.directusService);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const { businesses, businessesMetaData, vacanciesForOwners } = useSelector(
        (state) => state.entities.recruitmentService
    );
    const { usersForVacancies, usersForVacanciesMetaData } = useSelector(
        (state) => state.entities.userService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadVacancySubmissionsByParticipantId(currentParticipant.id));
        businesses?.length < 1
            ? dispatch(loadBusinessRecords(0, BUS_LOAD_SIZE))
            : setBusinessesForVacancies(businesses);
        businessSectorDetails?.length < 1 && dispatch(loadBusinessSectorDetails());
        jobSectorDetails?.length < 1 && dispatch(loadJobSectorDetails());
        vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        usersForVacancies?.length < 1 &&
            loggedInUser?.serviceIds?.length > 0 &&
            dispatch(
                searchUsersForVacancies({ serviceIds: loggedInUser.serviceIds }, 0, USERS_LOAD_SIZE)
            );
    }, [loggedInUser.id]);

    useEffect(() => {
        if (businessesForVacancies?.length < 1) return;
        else if (!businessesMetaData.last) {
            dispatch(loadBusinessRecords(businessesMetaData.number + 1, BUS_LOAD_SIZE));
        } else setBusinessesForVacancies(businesses);
    }, [businesses, businessesMetaData]);

    useEffect(() => {
        if (vacanciesForOwners?.length < 1 || usersForVacancies?.length < 1) return;
        const ids = [
            ...new Set(
                vacanciesForOwners
                    .map((el) => el.ownerId)
                    .filter((el) => !usersForVacancies.find((entry) => entry.id === el))
            )
        ];
        setMissingUserIds(ids);
    }, [vacanciesForOwners]);

    useEffect(() => {
        if ('last' in usersForVacanciesMetaData && usersForVacanciesMetaData.last) return;
        if (missingUserIds.length > 0) {
            dispatch(
                searchUsersForVacancies(
                    { userIds: missingUserIds },
                    'number' in usersForVacanciesMetaData
                        ? usersForVacanciesMetaData.number + 1
                        : 0,
                    USERS_LOAD_SIZE
                )
            );
        }
    }, [missingUserIds, usersForVacanciesMetaData]);

    // RENDER
    let content = '';
    if (businesses?.length < 1) content = 'No businesses found';
    if (businessSectorDetails?.length < 1) content = 'No business sector details';
    if (jobSectorDetails?.length < 1) content = 'No job sector details';
    if (vacanciesForOwners?.length < 1) content = 'No vacancy fow owner details';
    if (vacancyStageDetails?.length < 1) content = 'No vacancy stage details';
    if (vacancySubmissions?.length < 1) content = 'No vacancy submissions';
    if (
        businesses?.length < 1 ||
        businessSectorDetails?.length < 1 ||
        jobSectorDetails?.length < 1 ||
        vacanciesForOwners?.length < 1 ||
        vacancyStageDetails?.length < 1 ||
        vacancySubmissions?.length < 1
    )
        return <LoadingSpinner content={content} />;

    return (
        <div>
            <br />
            <Typography variant="h5">Vacancy Search</Typography>
            <VacancySearchTableManagement />
            <br />
            <br />
            <Typography variant="h5">Current Vacancies</Typography>
            <CurrentVacanciesTableManagement />
            <br />
            <br />
            <Typography variant="h5">Rejected Vacancies</Typography>
            <RejectedVacanciesTableManagement />
        </div>
    );
};

export default Vacancies;
