import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Collapse, TableCell, TableRow } from '@mui/material';

import { loadParticipantByPtCode } from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { reverseFormatDate } from '../../../utils/dateFunctions';
import { hasRole } from '../../../utils/userRoles';
import RadioButtons from '../../formElements/RadioButtons';

import ReversionDetails from './ReversionDetails';

import table from '../../../commonStyles/tables.module.css';

const ClaimTableRow = ({ row, roles, toggleDropdown, openId, onRowChange }) => {
    // HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [open, setOpen] = useState(null);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);

    // USE EFFECTS
    useEffect(() => {
        if (openId !== row.id) setOpen(null);
    }, [openId]);

    // EVENT HANDLERS
    const onEditParticipant = () => {
        if (row?.ptCode) {
            dispatch(loadParticipantByPtCode(row.ptCode));
            navigate('/edit_participant');
        }
    };

    const onStatusChange = (selection) => {
        const updatedRow = { ...row, selection };
        onRowChange(updatedRow);
    };

    const onToggleDropdown = () => {
        toggleDropdown(row.id);
        setOpen(open === row.id ? null : row.id);
    };

    // RENDER
    return (
        <>
            <TableRow
                key={row.id}
                hover
                className={`${table.tableRowStripes} ${table.oldLace} ${table.canOpen}`}>
                <TableCell
                    className={table.rowLink}
                    onClick={onEditParticipant}
                    disabled={!hasRole(roles, loggedInUser.roles)}>
                    {row?.ptCode}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row?.poNumber}</TableCell>
                <TableCell onClick={onToggleDropdown}>{row.eventName}</TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.eventDate)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>
                    {reverseFormatDate(row?.lastStatusUpdated)}
                </TableCell>
                <TableCell onClick={onToggleDropdown}>{row.statusName}</TableCell>
                <TableCell>
                    <RadioButtons
                        id={`statusUpdate-${row.id}`}
                        radioButtons={[
                            {
                                id: 'proceed',
                                label: '',
                                onChangeVal: 'Proceed',
                                buttonDisabled: row.statusName === 'Paid'
                            },
                            { id: 'revert', onChangeVal: 'Revert' },
                            { id: 'cannotClaim', onChangeVal: 'CannotClaim' },
                            { id: 'skip', onChangeVal: 'Skip' }
                        ]}
                        customClass="claimsTableRadioWrapper"
                        value={row.selection}
                        onChange={(value) => onStatusChange(value)}
                    />
                </TableCell>
            </TableRow>
            {row?.selection !== 'Revert' ? (
                ''
            ) : (
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            paddingLeft: 0,
                            paddingRight: 0,
                            borderTop: 'none',
                            borderBottom: 'none'
                        }}
                        colSpan={7}>
                        <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                            <ReversionDetails row={row} onClose={() => setOpen(null)} />
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default ClaimTableRow;

ClaimTableRow.propTypes = {
    row: PropTypes.object,
    roles: PropTypes.arrayOf(PropTypes.string),
    openId: PropTypes.string,
    toggleDropdown: PropTypes.func,
    onRowChange: PropTypes.func
};
