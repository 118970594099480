import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import {
    loadClaimEventStatuses,
    loadClaimEventTypes,
    loadClaimQueueActionStatusDetails
} from '../../../store/directusService';
import {
    clearClaimQueues,
    searchClaimQueue,
    searchParticipants
} from '../../../store/participantService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../store/userService';
import { getNameFromId } from '../../../utils/directusFunctions';
import { stableSort } from '../../../utils/sortFunctions';
import { addFullNameToArray } from '../../../utils/userArrayUtils';
import { ADVISER, MANAGER } from '../../../utils/userRoles';
import ActionEditor from '../../claims/validation/ActionEditor';
import SingleSelect from '../../formElements/SingleSelect';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ClaimTableRow from './ClaimTableRow';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';
import local from '../homeStyles/claims.module.css';

const PT_LOAD_SIZE = 65;
const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const headCells = [
    { id: 'ptCode', numeric: false, label: 'Participant ID', sortable: true },
    { id: 'firstName', numeric: false, label: 'First Name', sortable: true },
    { id: 'lastName', numeric: false, label: 'Last Name', sortable: true },
    { id: 'eventType', numeric: false, label: 'Event Type', sortable: true },
    { id: 'statusName', numeric: false, label: 'Status', sortable: true },
    { id: 'actionStatus', numeric: false, label: 'Action Status', sortable: true, align: 'center' }
];

const ClaimManagement = () => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedUserTypes = [ADVISER, MANAGER];
    const [adviserId, setAdviserId] = useState('');
    const [advisers, setAdvisers] = useState([]);
    const [rows, setRows] = useState([]);
    const [isOpenActionModal, setIsOpenActionModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [apiData, setApiData] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'eventType',
        page: 0,
        rowsPerPage: 100
    });
    const [openId, setOpenId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );
    const { claimEventStatuses, claimQueueActionStatusDetails, claimEventTypes } = useSelector(
        (state) => state.entities.directusService
    );
    const { claimQueues, claimQueuesMetaData, loadingClaimQueuesSearch } = useSelector(
        (state) => state.entities.participantService
    );
    const participants = useSelector(
        (state) => state.entities.participantService.participantsSearch
    );

    // HELPER FNS
    const onAddAdvisers = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearClaimQueues());
        setRows([]);
        claimEventTypes?.length < 1 && dispatch(loadClaimEventTypes());
        claimEventStatuses?.length < 1 && dispatch(loadClaimEventStatuses());
        claimQueueActionStatusDetails?.length < 1 && dispatch(loadClaimQueueActionStatusDetails());
    }, []);

    useEffect(() => {
        if (loggedInUser.roles.some((el) => acceptedUserTypes.includes(el))) {
            setAdviserId(loggedInUser.id);
            onAddAdvisers([loggedInUser]);
        }
    }, [loggedInUser.id]);

    useEffect(() => {
        setTotalResults(claimQueuesMetaData.totalElements);
    }, [claimQueuesMetaData]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter((el) =>
            el.userTypes.some((entry) => acceptedUserTypes.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < totalUsers) {
            onLoadMoreAdvisers();
            return;
        }
        if (adviserId && !updatedAdvisers.some((el) => el.id === adviserId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === adviserId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        onAddAdvisers(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        adviserId && dispatch(searchParticipants({ userId: adviserId }, 0, PT_LOAD_SIZE));
    }, [adviserId]);

    useEffect(() => {
        if (participants?.length < 1) {
            setRows([]);
            return;
        }
        const ptCodes = participants.map((el) => el.ptCode);
        setApiData(ptCodes);
        ptCodes.length && dispatch(searchClaimQueue({ ptCodes }, 0, loadPageSize, 'eventType'));
    }, [participants]);

    useEffect(() => {
        if (
            claimEventStatuses?.length < 1 ||
            claimQueueActionStatusDetails?.length < 1 ||
            !claimQueues ||
            claimQueues.length < 1 ||
            !adviserId
        ) {
            setRows([]);
            return;
        }
        const failStatusId = claimEventStatuses.find((el) => el.name === 'Fail')?.id || '';
        const carActionStatusId =
            claimQueueActionStatusDetails.find((el) => el.name === 'Corrective Action Required')
                ?.id || '';

        const updatedClaims = claimQueues
            .filter((el) => el.statusId === failStatusId || el.actionStatusId === carActionStatusId)
            .map((el) => {
                const pt = participants.find((entry) => entry.ptCode === el.ptCode);
                return {
                    ...el,
                    eventName: getNameFromId(claimEventTypes, el.eventTypeId) || 'zz',
                    statusName: getNameFromId(claimEventStatuses, el.statusId) || 'zz',
                    firstName: pt?.firstName || 'zz',
                    lastName: pt?.lastName || 'zz',
                    actionStatus:
                        getNameFromId(claimQueueActionStatusDetails, el.actionStatusId) || 'zz'
                };
            });
        setRows(updatedClaims);
    }, [claimQueues]);

    // EVENT HANDLERS

    const onEditAction = (row) => {
        setSelectedRow(row);
        setIsOpenActionModal(true);
    };

    const onActionModalCancel = () => {
        setIsOpenActionModal(false);
        setSelectedRow({});
    };

    const onUpdate = () => {
        setIsOpenActionModal(false);
        setSelectedRow({});
    };

    const onLoadMoreAdvisers = () => {
        dispatch(
            searchUsersByLoggedInUserServiceIds(
                loggedInUser.serviceIds,
                !users.length ? 0 : lastPageOfUsersLoaded + 1
            )
        );
    };

    const onToggleDropdown = (id) => setOpenId(id);

    // RENDER
    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <ClaimTableRow
                key={el.id}
                row={el}
                roles={loggedInUser.roles}
                toggleDropdown={onToggleDropdown}
                openId={openId}
                onEditAction={onEditAction}
            />
        ));
    };

    return (
        <div className={app.accordionContainer}>
            <div className={local.topBar}>
                <div className={local.iconContainer}>
                    <AccountBalanceIcon />
                </div>

                <div className={form.minWidthField}>
                    <SingleSelect
                        id={'adviserId'}
                        label={'Adviser'}
                        placeholder="Select adviser..."
                        menuItems={advisers || []}
                        selectedId={adviserId || ''}
                        selected={advisers?.find((el) => el.id === adviserId) || {}}
                        onChange={(chosenId) => setAdviserId(chosenId)}
                        onLoadMoreItems={onLoadMoreAdvisers}
                        moreItemsToLoad={!totalUsers || users.length < totalUsers}
                    />
                </div>
            </div>

            <div>
                {rows.length < 1 ? (
                    <LoadingSpinner content="No claims found" />
                ) : (
                    <ResultsTable
                        defaultOrderBy={rowMetaData.orderBy}
                        sortOrder={rowMetaData.order}
                        headCells={headCells}
                        loadResults={searchClaimQueue}
                        apiParams={{ apiData }}
                        passRowMetaDataUp={setRowMetaData}
                        tableRows={createRows()}
                        totalResults={totalResults}
                        metaData={claimQueuesMetaData}
                        loadingValues={loadingClaimQueuesSearch}
                        loadPageSize={loadPageSize}
                    />
                )}
            </div>
            {isOpenActionModal && (
                <ActionEditor
                    roles={loggedInUser.roles}
                    formType="edit"
                    isOpen={isOpenActionModal}
                    selectedRow={selectedRow}
                    onCancel={onActionModalCancel}
                    onUpdate={onUpdate}
                />
            )}
        </div>
    );
};

export default ClaimManagement;
