import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import { loadVacancyStageDetails } from '../../../../store/directusService';
import {
    loadVacanciesByBusinessRecordId,
    loadVacancySubmissionsByVacancyIdForOtherBusiness,
    searchBusinessesByCodeOrName,
    searchBusinessesByOwner
} from '../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../store/userSelectors';
import { searchUsersByLoggedInUserServiceIds } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import { stableSort } from '../../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../../utils/uiConstants';
import { addEmailAddressAsNameToArray } from '../../../../utils/userArrayUtils';
import {
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import SingleSelect from '../../../formElements/SingleSelect';
import SearchOnEnter from '../../../search/SearchOnEnter';
import ResultsTable from '../../../table/ResultsTable';
import LoadingSpinner from '../../../ui/LoadingSpinner';

import OtherBusinessTableRow from './OtherBusinessTableRow';

import classes from '../../recruitmentStyles/businessToolbar.module.css';

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const headCells = [
    { id: 'code', numeric: false, label: 'ID', sortable: true },
    { id: 'name', numeric: false, label: 'Business Name', sortable: true },
    { id: 'vacancies', numeric: false, label: 'Active Vacancies', align: 'center' },
    { id: 'positions', numeric: false, label: 'No. Positions', align: 'center' },
    { id: 'postcode', numeric: false, label: 'Postcode', sortable: true }
];

const OtherBusinessManagement = ({ isOpen }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const businessOwnerRoles = [MANAGER, RECRUITMENT_MANAGER];
    const [otherBusinesses, setOtherBusinesses] = useState([]);
    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [otherJobsOfferedForVacancies, setOtherJobsOfferedForVacancies] = useState([]);
    const [otherVacancyCount, setOtherVacancyCount] = useState(0);
    const [otherVacancyProcessedCount, setOtherVacancyProcessedCount] = useState(0);
    const [otherVacancyProcessed, setOtherVacancyProcessed] = useState(false);
    const [businessOwners, setBusinessOwners] = useState([]);
    const [selectedBusinessRecordOwner, setSelectedBusinessRecordOwner] = useState('');
    const [totalResults, setTotalResults] = useState(0);
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'code',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });
    const [openId, setOpenId] = useState('');

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const { roles } = loggedInUser;
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const {
        businessesByOwner,
        loadingBusinessesByOwner,
        businessesByOwnerMetaData,
        businessesByCodeOrName,
        vacancies,
        vacancySubmissionsForOtherBusiness
    } = useSelector((state) => state.entities.recruitmentService);
    const vacancyStageDetails = useSelector(
        (state) => state.entities.directusService.vacancyStageDetails
    );
    const users = useSelector((state) => state.entities.userService.usersByLoggedInUserServiceIds);
    const { number: lastPageOfUsersLoaded, totalElements: totalUsers } = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIdsMetaData
    );

    // USE EFFECTS
    useEffect(() => {
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
    }, []);

    useEffect(() => {
        setSelectedBusinessRecordOwner('');
        setOtherBusinesses([]);
        setRows([]);
        setSearchTerm('');
    }, [isOpen]);

    useEffect(() => {
        if (!users.length) return;
        let updatedBusinessOwners = users.filter((el) =>
            el.userTypes?.find(
                (entry) => businessOwnerRoles.includes(entry.role) && el.id !== loggedInUser.id
            )
        );
        if (!updatedBusinessOwners.length && users.length < totalUsers) {
            onLoadMoreBusinessOwners();
            return;
        }
        setBusinessOwners(addEmailAddressAsNameToArray(updatedBusinessOwners));
    }, [users]);

    useEffect(() => {
        if (!selectedBusinessRecordOwner) return;
        setSearchTerm('');
        dispatch(searchBusinessesByOwner({ ownerId: selectedBusinessRecordOwner }));
    }, [selectedBusinessRecordOwner]);

    useEffect(() => {
        if (!selectedBusinessRecordOwner) return;
        const filteredBusinesses =
            businessesByOwner?.length > 0 ? businessesByOwner.filter((el) => !el.inactive) : [];
        if (filteredBusinesses.length < 1 && !businessesByOwnerMetaData.last) {
            dispatch(
                searchBusinessesByOwner(
                    { ownerId: selectedBusinessRecordOwner },
                    businessesByOwnerMetaData.number + 1
                )
            );
        } else configVacancies(filteredBusinesses);
    }, [businessesByOwner, users]);

    useEffect(() => {
        setTotalResults(businessesByOwnerMetaData.totalElements);
    }, [businessesByOwnerMetaData]);

    useEffect(() => {
        if (!searchTerm) return;
        if (businessesByCodeOrName.length < 1) {
            setRows([]);
            setOtherBusinesses([]);
        } else configVacancies(businessesByCodeOrName);
    }, [businessesByCodeOrName]);

    useEffect(() => {
        if (otherVacancyProcessed) return;
        const businesses = searchTerm ? businessesByCodeOrName : businessesByOwner;
        const updatedOtherBusinesses = businesses?.length
            ? businesses
                  .filter((el) => !el.inactive)
                  .map((el) => {
                      const activeOtherVacancies =
                          vacancies[el.id]?.filter((entry) => !entry.inactive) || [];
                      if (Array.isArray(activeOtherVacancies)) {
                          activeOtherVacancies.map((entry) => {
                              dispatch(
                                  loadVacancySubmissionsByVacancyIdForOtherBusiness(entry.id, el.id)
                              );
                              setOtherVacancyCount((vacancyCount) => vacancyCount + 1);
                          });
                      }
                      return {
                          ...el,
                          vacancies: activeOtherVacancies,
                          positions: Array.isArray(activeOtherVacancies)
                              ? activeOtherVacancies.reduce(
                                    (acc, cur) => acc + cur.numberOfPositions,
                                    0
                                )
                              : 0,
                          jobsOffered: 0
                      };
                  })
            : [];
        setRows(updatedOtherBusinesses);
        setOtherBusinesses(updatedOtherBusinesses);
    }, [vacancies, vacancyStageDetails]);

    useEffect(() => {
        if (successMessage.includes(' and other business id: ')) {
            const vacancyId = successMessage.substring(53, 89);
            const businessId = successMessage.substring(113, 150);

            const jobsOffered = Array.isArray(vacancySubmissionsForOtherBusiness)
                ? vacancySubmissionsForOtherBusiness.reduce(
                      (acc, cur) =>
                          acc +
                          (getNameFromId(vacancyStageDetails, cur.statusId) === 'Job offered'
                              ? 1
                              : 0),
                      0
                  )
                : 0;

            rows.map((el) => {
                let vacancyFound;
                if (vacancies && vacancies.length > 0 && el.id === businessId) {
                    vacancyFound = el.vacancies.find((entry) => entry.id === vacancyId);
                }

                if (vacancyFound) {
                    const jobOfferedCount = {
                        businessId,
                        vacancyId,
                        jobsOffered
                    };
                    const otherVacancyAlreadyExists = otherJobsOfferedForVacancies.find(
                        (entry) => entry.businessId === businessId && entry.vacancyId === vacancyId
                    );
                    if (!otherVacancyAlreadyExists)
                        setOtherJobsOfferedForVacancies((prev) => [...prev, jobOfferedCount]);
                    setOtherVacancyProcessedCount(
                        (otherVacancyProcessedCount) => otherVacancyProcessedCount + 1
                    );
                }
            });
        }
    }, [successMessage]);

    useEffect(() => {
        if (
            otherVacancyCount === 0 ||
            otherVacancyCount !== otherVacancyProcessedCount ||
            otherVacancyProcessed
        )
            return;

        const uniqueJobOffers = [...new Set(otherJobsOfferedForVacancies.map((el) => el))];
        const jobOffersMerged = rows.map((el) => {
            let count = 0;
            uniqueJobOffers.map((entry) =>
                entry.businessId === el.id ? (count = count + entry.jobsOffered) : ''
            );
            return {
                ...el,
                jobsOffered: count
            };
        });

        setRows(jobOffersMerged);
        !searchTerm && setOtherBusinesses(jobOffersMerged);
        setOtherVacancyProcessed(true);
    }, [otherVacancyProcessedCount]);

    useEffect(() => {
        if (!searchTerm) {
            setRows([]);
            setOtherBusinesses([]);
            setTotalResults(businessesByOwnerMetaData.totalElements);
        }
    }, [searchTerm]);

    // HELPER FNS
    const getPreSelect = (arr, id) => arr.find((el) => el.id === id) || {};

    const configVacancies = (businesses) => {
        if (businesses.length < 1) return;
        dispatch(loadVacanciesByBusinessRecordId(businesses.map((el) => el.id)));
        setRows(businesses);
        setOtherBusinesses(businesses);
    };

    const createRows = () => {
        return stableSort(rows, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <OtherBusinessTableRow
                key={el.id}
                row={el}
                roles={acceptedRoles}
                toggleDropdown={onToggleDropdown}
                openId={openId}
                jobsOfferedForVacancies={otherJobsOfferedForVacancies}
            />
        ));
    };

    // EVENT HANDLERS

    const onTermChange = (value) => {
        setSearchTerm(value);
        setTotalResults(!value ? businessesByOwnerMetaData.totalElements : 1);
    };
    const onSearch = () => {
        setSelectedBusinessRecordOwner('');
        setRows([]);
        if (!searchTerm) {
            setTotalResults(businessesByOwnerMetaData.totalElements);
            return;
        }
        setTotalResults(1);

        const filtered = otherBusinesses.filter(
            (el) =>
                el.name.toLowerCase() === searchTerm.toLowerCase() ||
                el.code.toLowerCase() === searchTerm.toLowerCase()
        );

        filtered.length > 0
            ? setRows(filtered)
            : dispatch(
                  searchBusinessesByCodeOrName({
                      withLoggedInUserServiceIds: true,
                      term: searchTerm
                  })
              );
        setOtherBusinesses([]);
    };

    const onBusinessOwnerChange = (chosenId) => {
        if (!chosenId || Object.keys(chosenId).length === 0) {
            chosenId = '';
            setRows([]);
            setOtherBusinesses([]);
        }
        setSelectedBusinessRecordOwner(chosenId);
    };

    const onLoadMoreBusinessOwners = () => {
        if (!users.length || users.length < totalUsers) {
            dispatch(
                searchUsersByLoggedInUserServiceIds(
                    loggedInUser.serviceIds,
                    !users.length ? 0 : lastPageOfUsersLoaded + 1
                )
            );
        }
    };

    const onToggleDropdown = (id) => setOpenId(id);

    // RENDER
    return (
        <>
            <div className={classes.businessToolBar}>
                <div className={classes.searchWrapper}>
                    <SearchOnEnter
                        searchTerm={searchTerm}
                        placeholder="Enter Search Term"
                        search={onSearch}
                        onChange={onTermChange}
                    />
                </div>
                <div className={classes.singleSelectorWrapper}>
                    <SingleSelect
                        id={'ownerId'}
                        label={'Business Record Owner'}
                        placeholder="Select Business Record Owner..."
                        disabled={!hasRole(acceptedRoles, roles)}
                        menuItems={businessOwners}
                        selectedId={selectedBusinessRecordOwner}
                        selected={getPreSelect(businessOwners, selectedBusinessRecordOwner)}
                        onChange={(e) => {
                            if (e !== null && e !== undefined) {
                                onBusinessOwnerChange(e);
                            }
                        }}
                        onLoadMoreItems={onLoadMoreBusinessOwners}
                        moreItemsToLoad={!totalUsers || users.length < totalUsers}
                    />
                </div>
            </div>

            {rows.length < 1 ? (
                <LoadingSpinner content="No businesses found" />
            ) : (
                <ResultsTable
                    defaultOrderBy={'code'}
                    loadingValues={loadingBusinessesByOwner}
                    headCells={headCells}
                    loadResults={searchBusinessesByOwner}
                    passRowMetaDataUp={setRowMetaData}
                    apiParams={{ ownerId: selectedBusinessRecordOwner }}
                    tableRows={createRows()}
                    totalResults={totalResults}
                    metaData={businessesByOwnerMetaData}
                    loadPageSize={loadPageSize}
                />
            )}
        </>
    );
};

export default OtherBusinessManagement;

OtherBusinessManagement.propTypes = {
    isOpen: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
