export const DEFAULT_PAGE_LOAD_SIZE = 100;

export const applyPagination = (
    url,
    term,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    dir = 'asc',
    sortBy
) => {
    return `${url}${term ? term : ''}?page=${page}&size=${size}&dir=${dir}${sortBy ? `&sort=${sortBy}` : ''}`;
};
