import { getCharMinMessage } from '../../../utils/formValidation/errorMessageFunctions';

const NOTE_MIN_CHAR = 5;
export const NOTE_CHAR_LIMIT = 750;

export const initialErrorState = {};

export const validate = (notes) => {
    let newErrors = {};

    if (notes.trim().length < NOTE_MIN_CHAR) {
        newErrors = {
            ...newErrors,
            notes: { error: true, message: getCharMinMessage('Reversion notes', 5) }
        };
    }

    return newErrors;
};
