import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ClearIcon from '@mui/icons-material/Clear';

import AutoCompleteNameSearch from '../../search/AutoCompleteNameSearch';

import '../../../commonStyles/pills-selection-list.css';

const ParticipantsSelector = ({
    participants,
    add,
    remove,
    added,
    numOfSlots,
    statuses,
    isEditRestrictedByAttendanceStatus
}) => {
    const RESULT_LENGTH = 10;
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        setEntries(participants);
    }, []);

    const onAdd = (_, value) => {
        if (!value || added.find((el) => el.id === value.id)) return;
        add(value);
        setEntries(entries.filter((el) => el.id !== value.id));
    };

    const onRemove = (id) => {
        remove(added.filter((el) => el.id !== id));
        const isEntry = entries.some((el) => el.id === id);
        !isEntry && setEntries([...entries, participants.find((el) => el.id === id)]);
    };

    return (
        <div className="input-set add-participants">
            {!isEditRestrictedByAttendanceStatus && (
                <div>
                    <label htmlFor="participantOptions">Participant</label>
                    <AutoCompleteNameSearch
                        id="participantOptions"
                        list={entries}
                        resultLength={RESULT_LENGTH}
                        placeholder="Search participants..."
                        onSearch={onAdd}
                        disabled={added.length === numOfSlots}
                        customStyle="schedule-panel-search"
                    />
                    {added.length === numOfSlots && (
                        <div className="all-filled-notice">All slots are filled</div>
                    )}
                </div>
            )}
            <div className="selection-list participant-selections">
                {added.map((el) => (
                    <span key={el.id} className="selected-list-item">
                        {(statuses[el.id] === 'Not known' || !statuses[el.id]) &&
                            !isEditRestrictedByAttendanceStatus && (
                                <span className="clear-icon" onClick={() => onRemove(el.id)}>
                                    <ClearIcon />
                                </span>
                            )}
                        <span>
                            {' '}
                            {el.firstName} {el.lastName}{' '}
                        </span>
                    </span>
                ))}
            </div>
        </div>
    );
};

export default ParticipantsSelector;

ParticipantsSelector.propTypes = {
    participants: PropTypes.arrayOf(PropTypes.object),
    add: PropTypes.func,
    remove: PropTypes.func,
    added: PropTypes.arrayOf(PropTypes.object),
    numOfSlots: PropTypes.number,
    statuses: PropTypes.object,
    isEditRestrictedByAttendanceStatus: PropTypes.bool
};
