import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadContractDetails,
    loadPreferredPronounDetails,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import {
    createParticipant,
    loadContractConstants,
    searchEligibilityGroups
} from '../../../store/participantService';
import { loadTeamsForService } from '../../../store/userService';
import { commonFieldValidations } from '../../../utils/formValidation/commonFieldValidations';
import { names, regExps } from '../../../utils/formValidation/validationPatterns';
import {
    getEmptyErrorState,
    validate,
    validateSingleField
} from '../../../utils/formValidation/validator';
import { generateUniqueId } from '../../../utils/stringUtils';
import Button from '../../formElements/Button';
import SingleSelect from '../../formElements/SingleSelect';
import TextInputField from '../../formElements/TextInputField';
import LoadingSpinner from '../../ui/LoadingSpinner';

import app from '../../../app.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const validationFields = {
    contractId: { required: 'select', ...commonFieldValidations.contractId },
    serviceId: { required: 'select', ...commonFieldValidations.serviceId },
    teamId: { required: 'select', ...commonFieldValidations.teamId },
    firstName: { required: 'text', ...commonFieldValidations.firstName },
    middleName: commonFieldValidations.middleName,
    lastName: commonFieldValidations.lastName,
    preferredName: commonFieldValidations.preferredName,
    address1: commonFieldValidations.address1,
    address2: commonFieldValidations.address2,
    address3: commonFieldValidations.address3,
    city: commonFieldValidations.city,
    primaryPhoneNumber: commonFieldValidations.primaryPhoneNumber,
    postcode: commonFieldValidations.postcode,
    uniqueIdentifier: commonFieldValidations.uniqueIdentifier,
    repeatUniqueIdentifier: commonFieldValidations.repeatUniqueIdentifier,
    emailAddress: commonFieldValidations.emailAddress
};

const CreateParticipant = ({ instantValidation = false }) => {
    const initialState = {
        id: null,
        firstName: '',
        middleName: '',
        lastName: '',
        preferredName: '',
        preferredPronounsId: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        postcode: '',
        primaryPhoneNumber: '',
        emailAddress: '',
        uniqueIdentifier: '',
        repeatUniqueIdentifier: '',
        uniqueIdentifierType: null,
        eligibilityGroupId: '',
        referralDate: '',
        contractId: '',
        serviceId: '',
        teamId: ''
    };

    const [resetKey, setResetKey] = useState(generateUniqueId);
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(getEmptyErrorState(validationFields));
    const [isValid, setIsValid] = useState(true);
    const [createDisabled, setCreateDisabled] = useState(false);
    const [disableFields, setDisableFields] = useState(false);

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { errorMessage } = useSelector((state) => state.entities.formsState);
    const { teamsForService } = useSelector((state) => state.entities.userService);
    const { preferredPronounDetails, serviceDetailsForContractId, contractDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const eligibilityGroupsForContractId = useSelector(
        (state) => state.entities.participantService.eligibilityGroupSearch
    );
    const { contractConstants } = useSelector((state) => state.entities.participantService);
    const dispatch = useDispatch();

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadContractDetails());
        dispatch(loadPreferredPronounDetails());
    }, []);

    useEffect(() => {
        setCreateDisabled(!isValid);
    }, [isValid]);

    useEffect(() => {
        if (successMessage === 'New participant created') {
            clearParticipant();
        }
        setDisableFields(false);
        setCreateDisabled(false);
    }, [successMessage]);

    useEffect(() => {
        if (errorMessage) {
            setDisableFields(false);
            setCreateDisabled(false);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (!createDisabled) setDisableFields(false);
    }, [createDisabled]);

    useEffect(() => {
        if (newEntry.contractId) {
            const contract = contractDetails.find((el) => el.id === newEntry.contractId);
            onUpdate('uniqueIdentifierType', contract?.unique_identifier_type.name);
            dispatch(loadServiceDetailsForContractId(newEntry.contractId));
            dispatch(searchEligibilityGroups({ contractId: newEntry.contractId }));
            dispatch(loadContractConstants(newEntry.contractId));
        }
    }, [newEntry.contractId]);

    useEffect(() => {
        if (
            teamsForService.length &&
            !newEntry.teamId &&
            teamsForService.some((team) => team.id === contractConstants.defaultTeamId)
        ) {
            onUpdate('teamId', contractConstants.defaultTeamId);
        }
    }, [teamsForService]);

    useEffect(() => {
        if (newEntry.serviceId) {
            dispatch(loadTeamsForService({ serviceIds: [newEntry.serviceId] }));
        }
    }, [newEntry.serviceId]);

    useEffect(() => {
        const entryToSet = {
            ...newEntry,
            uniqueIdentifier: initialState.uniqueIdentifier,
            repeatUniqueIdentifier: initialState.repeatUniqueIdentifier
        };
        setNewEntry(entryToSet);

        const tempErrors = getEmptyErrorState(validationFields);
        setErrors({
            ...errors,
            uniqueIdentifier: tempErrors.uniqueIdentifier,
            repeatUniqueIdentifier: tempErrors.repeatUniqueIdentifier
        });
    }, [newEntry.uniqueIdentifierType]);

    // HELPER FNS
    const clearParticipant = () => {
        setNewEntry(initialState);
        setErrors(getEmptyErrorState(validationFields));
        setIsValid(true);
        setResetKey(generateUniqueId);
    };

    const getPreSelect = (arr, id) => {
        return arr.find((el) => el.id === id) || {};
    };

    const getUniqueString = (extension) => {
        if (!newEntry.uniqueIdentifierType) return extension;
        return `${names[newEntry.uniqueIdentifierType]} ${extension}`;
    };

    // EVENT HANDLERS
    const onUpdate = (key, value, clearKeys = []) => {
        if (disableFields) return;
        const entryToSet = { ...newEntry, [key]: value };
        const emptyErrorState = getEmptyErrorState(validationFields);
        let errorsTemp = errors;
        let isValidTemp = isValid;
        if (instantValidation) {
            const validation = validateSingleField(
                entryToSet,
                key,
                errorsTemp,
                validationFields,
                isValid
            );
            errorsTemp = validation.errors;
            isValidTemp = validation.isValid;
        } else if (emptyErrorState[key]) {
            const validation = validateSingleField(
                entryToSet,
                key,
                errorsTemp,
                validationFields,
                isValid
            );
            errorsTemp[key] = emptyErrorState[key];
            isValidTemp = validation.isValid;
        }
        for (const clearKey of clearKeys) {
            entryToSet[clearKey] = initialState[clearKey];
            if (instantValidation && emptyErrorState[clearKey])
                errorsTemp[clearKey] = emptyErrorState[clearKey];
        }
        if (instantValidation && key === 'uniqueIdentifier') {
            const validation = validateSingleField(
                entryToSet,
                'repeatUniqueIdentifier',
                errorsTemp,
                validationFields,
                isValid
            );
            errorsTemp = validation.errors;
            isValidTemp = validation.isValid;
        } else if (key === 'uniqueIdentifier')
            errorsTemp.repeatUniqueIdentifier = emptyErrorState.repeatUniqueIdentifier;
        setErrors(errorsTemp);
        setIsValid(isValidTemp);
        setNewEntry(entryToSet);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry, validationFields);
        if (!newErrors.isValid) {
            setErrors(newErrors.errors);
            setIsValid(newErrors.isValid);
            return;
        }
        setDisableFields(true);
        setCreateDisabled(true);

        const payload = {
            ...newEntry,
            referralDate: new Date().toISOString().slice(0, 10)
        };
        dispatch(createParticipant(payload));
    };

    // RENDER
    if (contractDetails?.length < 1) return <LoadingSpinner content="No Contracts found" />;

    return (
        <div key={resetKey} className={form.formWrapper}>
            <form onSubmit={onSubmit} className={form.form}>
                <h1 className={app.mainHeading}>Create Participant</h1>
                <h2 className={app.sectionHeading}>Contract Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'contractId'}
                            label={'Contract'}
                            placeholder="Select Contract..."
                            disabled={disableFields}
                            mandatory={true}
                            menuItems={contractDetails}
                            selectedId={newEntry.contractId}
                            selected={getPreSelect(contractDetails, newEntry.contractId)}
                            error={errors.contractId}
                            onChange={(chosenId) =>
                                onUpdate('contractId', chosenId, [
                                    'serviceId',
                                    'teamId',
                                    'eligibilityGroupId'
                                ])
                            }
                        />
                        <SingleSelect
                            id={'serviceId'}
                            label={'Service'}
                            placeholder="Select Service..."
                            disabled={!newEntry.contractId || disableFields}
                            mandatory={true}
                            menuItems={serviceDetailsForContractId}
                            selectedId={newEntry.serviceId}
                            selected={getPreSelect(serviceDetailsForContractId, newEntry.serviceId)}
                            error={errors.serviceId}
                            onChange={(chosenId) => onUpdate('serviceId', chosenId, ['teamId'])}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <SingleSelect
                            id={'teamId'}
                            label={'Team'}
                            placeholder="Select Team..."
                            disabled={!newEntry.serviceId || disableFields}
                            mandatory={true}
                            menuItems={teamsForService}
                            selectedId={newEntry.teamId}
                            selected={getPreSelect(teamsForService, newEntry.teamId)}
                            error={errors.teamId}
                            onChange={(chosenId) => onUpdate('teamId', chosenId)}
                        />
                        <SingleSelect
                            id={'eligibilityGroupId'}
                            label={'Eligibility Group'}
                            placeholder="Select Eligibility Group..."
                            disabled={!newEntry.contractId || disableFields}
                            menuItems={eligibilityGroupsForContractId}
                            selectedId={newEntry.eligibilityGroupId}
                            selected={getPreSelect(
                                eligibilityGroupsForContractId,
                                newEntry.eligibilityGroupId
                            )}
                            onChange={(chosenId) => onUpdate('eligibilityGroupId', chosenId)}
                        />
                    </div>
                </div>

                <h2 className={app.sectionHeading}>General Details</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'firstName'}
                            label={'First Name'}
                            placeholder={'Enter first name'}
                            disabled={disableFields}
                            mandatory={true}
                            value={newEntry.firstName || ''}
                            error={errors.firstName}
                            onChange={(e) => onUpdate('firstName', e.target.value)}
                        />
                        <TextInputField
                            id={'middleName'}
                            label={'Middle Name'}
                            placeholder={'Enter middle name'}
                            disabled={disableFields}
                            value={newEntry.middleName || ''}
                            error={errors.middleName}
                            onChange={(e) => onUpdate('middleName', e.target.value)}
                        />
                        <TextInputField
                            id={'lastName'}
                            label={'Last Name'}
                            placeholder={'Enter last name'}
                            disabled={disableFields}
                            value={newEntry.lastName || ''}
                            error={errors.lastName}
                            onChange={(e) => onUpdate('lastName', e.target.value)}
                        />
                        <TextInputField
                            id={'preferredName'}
                            label={'Preferred Name'}
                            placeholder={'Enter preferred name'}
                            disabled={disableFields}
                            value={newEntry.preferredName || ''}
                            error={errors.preferredName}
                            onChange={(e) => onUpdate('preferredName', e.target.value)}
                        />

                        <SingleSelect
                            id={'preferredPronouns'}
                            label={'Preferred Pronouns'}
                            placeholder="Select preferred pronoun..."
                            disabled={disableFields}
                            menuItems={preferredPronounDetails}
                            selectedId={newEntry.preferredPronounsId}
                            selected={getPreSelect(
                                preferredPronounDetails,
                                newEntry.preferredPronounsId
                            )}
                            onChange={(chosenId) => onUpdate('preferredPronounsId', chosenId)}
                        />

                        <TextInputField
                            id={'uniqueId'}
                            label={getUniqueString('Unique ID')}
                            placeholder={'Enter unique identifier'}
                            disabled={disableFields}
                            mandatory={true}
                            value={newEntry.uniqueIdentifier || ''}
                            error={errors.uniqueIdentifier}
                            onPaste={(e) => e.preventDefault()}
                            onChange={(e) => onUpdate('uniqueIdentifier', e.target.value)}
                        />
                        <TextInputField
                            id={'repeatUniqueId'}
                            label={getUniqueString('Repeat Unique ID')}
                            placeholder={'Repeat unique identifier'}
                            disabled={disableFields}
                            mandatory={true}
                            value={newEntry.repeatUniqueIdentifier || ''}
                            error={errors.repeatUniqueIdentifier}
                            onPaste={(e) => e.preventDefault()}
                            onChange={(e) => onUpdate('repeatUniqueIdentifier', e.target.value)}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'address1'}
                            label={'Address Line 1'}
                            placeholder={'Enter address line 1'}
                            disabled={disableFields}
                            value={newEntry.address1 || ''}
                            error={errors.address1}
                            onChange={(e) => onUpdate('address1', e.target.value)}
                        />
                        <TextInputField
                            id={'address2'}
                            label={'Address Line 2'}
                            placeholder={'Enter address line 2'}
                            disabled={disableFields}
                            value={newEntry.address2 || ''}
                            error={errors.address2}
                            onChange={(e) => onUpdate('address2', e.target.value)}
                        />
                        <TextInputField
                            id={'address3'}
                            label={'Address Line 3'}
                            placeholder={'Enter address line 3'}
                            disabled={disableFields}
                            value={newEntry.address3 || ''}
                            error={errors.address3}
                            onChange={(e) => onUpdate('address3', e.target.value)}
                        />
                        <TextInputField
                            id={'city'}
                            label={'City'}
                            placeholder={'Enter city'}
                            disabled={disableFields}
                            value={newEntry.city || ''}
                            error={errors.city}
                            onChange={(e) => onUpdate('city', e.target.value)}
                        />
                        <TextInputField
                            id={'postcode'}
                            label={'Postcode'}
                            placeholder={'Enter postcode'}
                            disabled={disableFields}
                            value={newEntry.postcode || ''}
                            error={errors.postcode}
                            onChange={(e) => onUpdate('postcode', e.target.value)}
                        />
                        <TextInputField
                            id="primaryPhoneNumber"
                            type="tel"
                            label="Phone Number"
                            placeholder={'Enter phone number'}
                            disabled={disableFields}
                            value={newEntry.primaryPhoneNumber || ''}
                            error={errors.primaryPhoneNumber}
                            onChange={(e) => {
                                if (regExps.PHONE_FORMAT_R.test(e.target.value))
                                    onUpdate('primaryPhoneNumber', e.target.value);
                            }}
                        />
                        <TextInputField
                            id={'emailAddress'}
                            label={'Email Address'}
                            placeholder={'Enter email address'}
                            disabled={disableFields}
                            value={newEntry.emailAddress || ''}
                            error={errors.emailAddress}
                            onChange={(e) => onUpdate('emailAddress', e.target.value)}
                        />
                    </div>
                </div>
                <Button
                    id="createParticipant"
                    content="Create"
                    disabled={createDisabled || disableFields}
                />
            </form>
        </div>
    );
};

CreateParticipant.propTypes = {
    instantValidation: PropTypes.bool
};

export default CreateParticipant;
