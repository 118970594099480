import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

import { loadBusinessSectorDetails } from '../../../../store/directusService';
import { loadBusinessRecord } from '../../../../store/recruitmentService';
import { loadUser } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import BusinessContactCard from '../cards/BusinessContactCard';
import VacancyCard from '../cards/VacancyCard';
import BusinessNotes from '../shared/BusinessNotes';

import app from '../../../../app.module.css';
import cards from '../../../../commonStyles/cardContainer.module.css';
import dropdown from '../../recruitmentStyles/rowDropDown.module.css';

const BusinessDetails = ({ row, onClose, jobsOfferedForVacancies, accordionPanelFrom }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const [owner, setOwner] = useState('');

    // STORE STATE

    const businessSectorDetails = useSelector(
        (state) => state.entities.directusService.businessSectorDetails
    );
    const currentBusiness = useSelector(
        (state) => state.entities.recruitmentService.currentBusiness
    );
    const usersByLoggedInUserServiceIds = useSelector(
        (state) => state.entities.userService.usersByLoggedInUserServiceIds
    );
    const advisers = usersByLoggedInUserServiceIds.flat();

    // USE EFFECTS
    useEffect(() => {
        if (!row?.ownerId) return;
        let user;
        if (advisers && advisers.length > 0) user = advisers.find((el) => el.id === row.ownerId);
        user ? addAdviser(user) : dispatch(loadUser(row.ownerId));
    }, [advisers]);

    useEffect(() => {
        if (!row || !businessSectorDetails) return;
        dispatch(loadBusinessRecord(row.id));
        businessSectorDetails.length < 1 && dispatch(loadBusinessSectorDetails());
    }, [row, businessSectorDetails]);

    // HELPER FNS
    const addAdviser = (adviser) => {
        const { firstName, lastName } = adviser;
        setOwner(`${firstName || ''} ${lastName || ''}`);
    };

    const loadingError = () => {
        if (businessSectorDetails?.length < 1) return 'No business sector found';
        if (Object.keys(currentBusiness)?.length < 1) return 'No business information found';
    };

    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    return (
        <div className={dropdown.rowDropdown}>
            <div className={app.sectionHeading}>
                {row.name} ({row.code})
            </div>
            <div className={dropdown.line}>
                <div>{row.addressLine1}</div>
                <div>{row.addressLine2}</div>
                <div>{row.addressLine3}</div>
                <div>{row.city}</div>
                <div>{row.postcode}</div>
            </div>

            <div className={dropdown.keyValues}>
                <div className={dropdown.keyVal}>
                    <span>Business Record Owner: </span>
                    <span>{owner || ''}</span>
                </div>
                <div className={dropdown.keyVal}>
                    <span>Sector: </span>
                    <span>{getNameFromId(businessSectorDetails, row.sectorId)}</span>
                </div>
                <div className={dropdown.keyVal}>
                    <span>Website: </span>
                    <span>{row.website || '-'}</span>
                </div>
                <div className={dropdown.keyVal}>
                    <span>No. Employees:</span>
                    <span>{row.size || '-'}</span>
                </div>
            </div>

            <div className={cards.cardContainer}>
                <div className={app.sectionHeading}>Business Contacts</div>
                <div className={cards.cards}>
                    {row.contactCards.length < 1 ? (
                        <div> None </div>
                    ) : (
                        row.contactCards.map((el) => (
                            <div key={el.id}>
                                <BusinessContactCard entry={el} />
                            </div>
                        ))
                    )}
                </div>
            </div>

            <div className={cards.cardContainer}>
                <div className={app.sectionHeading}>Vacancies</div>
                <div className={cards.cards}>
                    {row.vacancies?.length < 1 ? (
                        <div> None </div>
                    ) : (
                        row.vacancies?.map((el) => (
                            <div key={el.id}>
                                <VacancyCard
                                    entry={el}
                                    accordionPanelFrom={accordionPanelFrom}
                                    jobsOfferedForVacancies={jobsOfferedForVacancies}
                                />
                            </div>
                        ))
                    )}
                </div>
            </div>
            <BusinessNotes />
            <div className={dropdown.exit} onClick={onClose}>
                Close details
                <CloseIcon />
            </div>
        </div>
    );
};

BusinessDetails.propTypes = {
    accordionPanelFrom: PropTypes.string,
    row: PropTypes.shape({
        id: PropTypes.string,
        businessId: PropTypes.string,
        participantId: PropTypes.string,
        ownerId: PropTypes.string,
        sectorId: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        addressLine3: PropTypes.string,
        city: PropTypes.string,
        postcode: PropTypes.string,
        website: PropTypes.string,
        size: PropTypes.number,
        contactCards: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string
            })
        ),
        vacancies: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string
            })
        ),
        notes: PropTypes.arrayOf(PropTypes.object)
    }),
    onClose: PropTypes.func.isRequired,
    jobsOfferedForVacancies: PropTypes.array
};

export default BusinessDetails;
