import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import PropTypes from 'prop-types';

import { reverseFormatDate } from '../../utils/dateFunctions';

import classes from '../../commonStyles/pdf.module.css';

const GenerateWHPER1ExitFormPDF = ({
    surveyModel,
    form,
    row,
    currentParticipant,
    currentUser,
    onPdfGenerated
}) => {
    const doc = new jsPDF();

    doc.setFontSize(12);

    const createHeader = (surveyModel, form, row, currentParticipant, currentUser) => {
        const dateCompleted = !row.lastUpdated
            ? format(new Date(), `dd /MM/yyyy`)
            : reverseFormatDate(row.lastUpdated);

        const blankRow = '<tr><td><td/></tr>';

        return `<table class="${classes.formHeader}">
            <tr>
                <td><b>Form Title</b></td>
                <td>${surveyModel.title || form.name}</td>
            </tr>
            <tr>
                <td><b>Form ID</b></td>
                <td>${form.code}</td>
            </tr>
            <tr>
                <td><b>Participant Name</b></td>
                <td>${currentParticipant.firstName} ${currentParticipant.lastName}</td>
            </tr>
            <tr>
                <td><b>Participant ID</b></td>
                <td>${currentParticipant.ptCode}</td>
            </tr>
            <tr>
                <td><b>Advisor Name</b></td>
                <td>${currentUser.firstName} ${currentUser.lastName}</td>
            </tr>
            <tr>
                <td><b>Date Complete</b></td>
                <td>${dateCompleted}</td>
            </tr>
            ${blankRow.repeat(20)}
        </table>`;
    };

    const createBody = (doc, surveyModel, form, header) => {
        const blankRow = '<tr><td><td/></tr>';

        const pages = form.data.pages;
        // calculate what page each question is on
        const formData = surveyModel.getPlainData().map((data) => {
            const page = pages.find((page) =>
                page.elements.some((entry) => entry.name === data.name)
            );
            return {
                ...data,
                page: page.title || page.name
            };
        });

        const groupedByPage = formData.reduce((acc, item) => {
            acc[item.page] = [...(acc[item.page] || []), item];
            return acc;
        }, {});

        let body = `<table class="${classes.formBody}">`;
        let oldRowCount = header.match(/<tr>/g).length;

        Object.entries(groupedByPage).forEach(([pageTitle, questions]) => {
            body += `<tr><td><h2>${pageTitle}</h2></td></tr>`;
            questions.forEach((question) => {
                body += `<tr class="${classes.formQuestion}"><td><b>${
                    question.title || 'No question title'
                }</b></td><td class="${classes.formAnswer}">${
                    typeof question.displayValue === 'string'
                        ? !question.displayValue.includes('data:image/png')
                            ? question.displayValue
                            : '[Question Type is an Image]'
                        : '[Question Type is not Text]'
                }</td></tr>`;
            });
            body += blankRow.repeat(20);
            // calculate page sizing based on table rows
            const rowCount = body.match(/<tr>/g).length;
            if (rowCount - oldRowCount > 250) {
                oldRowCount = rowCount;
                doc.addPage();
            }
        });

        body += '</table>';

        return body;
    };

    const header = createHeader(surveyModel, form, row, currentParticipant, currentUser);

    const body = createBody(doc, surveyModel, form, header);

    const createFooter = (doc, form) => {
        const pageCount = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;

        doc.setFontSize(8);

        Array.from({ length: pageCount }, (_, i) => i + 1).forEach((pageNumber) => {
            doc.setPage(pageNumber);
            const footerText =
                `    Page ${pageNumber} of ${pageCount} ` +
                ' '.repeat(110) +
                ` Printed on ${format(new Date(), 'EEEE, MMMM dd, yyyy HH:mm')} Document ID : ${
                    form.code
                }`;

            doc.text(footerText, 1, pageSize.height - 5, { align: 'left' });
        });
    };

    doc.html(`<div>${header}<br/>${body}</div>`, {
        async callback(doc) {
            createFooter(doc, form);
            doc.save(form.name);
            onPdfGenerated(true);
        },
        autoPaging: 'text',
        margin: [10, 5, 10, 5],
        width: 210,
        windowWidth: 1050
    });
};

GenerateWHPER1ExitFormPDF.propTypes = {
    surveyModel: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    currentParticipant: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    onPdfGenerated: PropTypes.func.isRequired
};

export default GenerateWHPER1ExitFormPDF;
