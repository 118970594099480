import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';

import ConfirmPrompt from '../components/ui/notices/confirmPrompt/ConfirmPrompt';
import { POLARIS_DARKER_GREY, POLARIS_ROYAL_BLUE } from '../themes/theme';
import { hasRole } from '../utils/userRoles';

import local from './iconStyles.module.css';

const OnDeleteIcon = ({ onDelete, roles, acceptedRoles, active = true, confirmTwice = false }) => {
    const [showConfirmPrompt, setShowConfirmPrompt] = useState(false);
    const [showSecondConfirmPrompt, setShowSecondConfirmPrompt] = useState(false);

    acceptedRoles = acceptedRoles?.length > 0 ? acceptedRoles : [];

    const firstConfirmDeleteModalContent = <div>Are you sure you want to delete?</div>;

    const secondConfirmDeleteModalContent = (
        <div className={local.deleteIconSecondModalContent}>
            <div>DETAILS DELETED CANNOT BE RETRIEVED! </div>
            <div>DO YOU CONFIRM DELETION?</div>
        </div>
    );

    const onConfirmDelete = (_, doDelete) => {
        if (doDelete) {
            confirmTwice ? setShowSecondConfirmPrompt(true) : onDelete();
        }
        setShowConfirmPrompt(false);
    };

    const onSecondConfirmDelete = (_, doDelete) => {
        if (doDelete) onDelete();
        setShowSecondConfirmPrompt(false);
    };

    if (hasRole(roles, acceptedRoles) && active) {
        // Can delete
        return (
            <>
                {showConfirmPrompt && (
                    <ConfirmPrompt
                        isOpen={showConfirmPrompt}
                        onCancel={(e) => onConfirmDelete(e, false)}
                        onConfirm={(e) => onConfirmDelete(e, true)}
                        content={firstConfirmDeleteModalContent}
                    />
                )}
                {showSecondConfirmPrompt && (
                    <ConfirmPrompt
                        isOpen={showSecondConfirmPrompt}
                        onCancel={(e) => onSecondConfirmDelete(e, false)}
                        onConfirm={(e) => onSecondConfirmDelete(e, true)}
                        content={secondConfirmDeleteModalContent}
                    />
                )}

                <Tooltip title="Delete" placement="top">
                    <DeleteIcon
                        sx={{ color: POLARIS_ROYAL_BLUE, cursor: 'pointer' }}
                        aria-label="delete-icon"
                        data-testid="delete-icon"
                        onClick={() => setShowConfirmPrompt(true)}></DeleteIcon>
                </Tooltip>
            </>
        );
    }
    // Can't do any deleting...
    return (
        <DeleteIcon
            sx={{ color: POLARIS_DARKER_GREY, cursor: 'not-allowed' }}
            aria-label="delete-icon"></DeleteIcon>
    );
};

export default OnDeleteIcon;

OnDeleteIcon.propTypes = {
    onDelete: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    acceptedRoles: PropTypes.arrayOf(PropTypes.string),
    active: PropTypes.bool,
    confirmTwice: PropTypes.bool
};
