import { createSlice } from '@reduxjs/toolkit';

import { getCourseURL } from '../api/commonHTTP';
import { DEFAULT_PAGE_LOAD_SIZE } from '../api/pagination';
import { getEmptyPaginationState } from '../utils/objectUtils';

import { apiCallBegan } from './api';

const slice = createSlice({
    name: 'courseService',
    initialState: {
        allCourseModules: [],
        courseModules: [],
        courseProvisions: [],
        courseProvisionsMetaData: {},
        courses: [],
        coursesMetaData: getEmptyPaginationState(),
        coursesByCourseIds: [],
        coursesByCourseIdsMetaData: {},
        courseTemplates: [],

        activeCourses: [],
        activeCoursesMetaData: {},
        currentCourse: {},
        currentModule: {},
        currentProvision: {},
        currentTemplate: {},

        loadingCourse: false,
        loadingCourses: false,
        loadingCoursesByCourseIds: false,
        loadingUsersCoursesByCodeOrName: false,
        loadingModule: false,
        loadingModules: false,
        loadingParticipantTimeline: false,
        loadingProvision: false,
        loadingProvisions: false,
        loadingTemplate: false,
        loadingTemplates: false,
        loadingUserId: '',
        participantsCourses: [],
        participantsCoursesMetaData: getEmptyPaginationState(),
        participantsModules: [],
        participantModulesAttendedCount: null,
        usersCourses: [],
        usersCoursesMetaData: getEmptyPaginationState(),
        usersCoursesByCodeOrName: [],
        usersCoursesByCodeOrNameMetaData: getEmptyPaginationState(),
        usersCourseTemplates: [],

        openModuleScheduler: false,
        recruitmentParticipantTimelineEvents: [],

        successMessage: '',
        errorMessage: ''
    },
    reducers: {
        // These update the state. They can use mutable code and Immer is used
        // to convert this to immutable code

        courseLoaded: (state, action) => {
            state.loadingCourse = false;
            state.currentCourse = action.payload;
            state.courses = state.courses.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            state.coursesByCourseIds = state.coursesByCourseIds.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            state.usersCourses = state.usersCourses.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        courseLoading: (state) => {
            state.currentCourse = {};
            state.loadingCourse = true;
        },

        courseLoadingFailed: (state, action) => {
            state.loadingCourse = false;
            state.errorMessage = action.payload;
        },

        courseUpdated: (state, action) => {
            state.loadingCourse = false;
            state.currentCourse = action.payload;
            state.courses = state.courses.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            state.usersCourses = state.usersCourses.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            state.coursesByCourseIds = state.coursesByCourseIds.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
        },

        courseModulesLoading: (state) => {
            state.courseModules = [];
            state.loadingModules = true;
        },

        courseModulesLoaded: (state, action) => {
            state.loadingModules = false;
            state.courseModules = action.payload;
            const filtered = state.allCourseModules.filter(
                (el) => !action.payload.find((entry) => entry.id === el.id)
            );
            state.allCourseModules = [...filtered, ...action.payload];
        },

        courseModulesLoadingFailed: (state, action) => {
            state.loadingModules = false;
            state.errorMessage = action.payload;
        },

        courseModuleLoading: (state) => {
            state.currentModule = {};
            state.loadingModule = true;
        },

        courseModuleLoadingFailed: (state, action) => {
            state.loadingModule = false;
            state.errorMessage = action.payload;
        },

        courseModuleUpdated: (state, action) => {
            state.loadingModule = false;
            // update modules and allModules in state
            const existingInModules = state.courseModules.some((el) => el.id === action.payload.id);

            state.courseModules = existingInModules
                ? state.courseModules.map((el) =>
                      el.id === action.payload.id ? action.payload : el
                  )
                : [...state.courseModules, action.payload];

            const existingInAllModules = state.allCourseModules.some(
                (el) => el.id === action.payload.id
            );

            state.allCourseModules = existingInAllModules
                ? state.allCourseModules.map((el) =>
                      el.id === action.payload.id ? action.payload : el
                  )
                : [...state.allCourseModules, action.payload];

            // update courses, participantsCourses and usersCourses
            let course = state.courses.find((el) => el.id === action.payload.courseId);

            if (!course)
                course = state.activeCourses.find((el) => el.id === action.payload.courseId);
            if (!course)
                course = state.usersCourses.find((el) => el.id === action.payload.courseId);
            if (!course)
                course = state.participantsCourses.find((el) => el.id === action.payload.courseId);
            if (!course) return;

            const modules = course.modules.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            const updatedCourse = { ...course, modules };
            state.currentCourse = updatedCourse;
            state.courses = state.courses.map((el) =>
                el.id === updatedCourse.id ? updatedCourse : el
            );
            state.activeCourses = state.activeCourses.map((el) =>
                el.id === updatedCourse.id ? updatedCourse : el
            );
            state.usersCourses = state.usersCourses.map((el) =>
                el.id === updatedCourse.id ? updatedCourse : el
            );
            state.participantsCourses = state.participantsCourses.map((el) =>
                el.id === updatedCourse.id ? updatedCourse : el
            );
            // update current module and course
            state.currentCourse = updatedCourse;
            state.currentModule = action.payload;
        },

        courseProvisionLoaded: (state, action) => {
            state.loadingProvision = false;
            state.currentProvision = action.payload;
        },

        courseProvisionLoading: (state) => {
            state.loadingProvision = true;
            state.courseProvision = {};
        },

        courseProvisionLoadingFailed: (state, action) => {
            state.loadingProvision = false;
            state.errorMessage = action.payload;
        },

        courseProvisionsLoaded: (state, action) => {
            state.loadingProvisions = false;
            const { content, ...rest } = action.payload;
            if (rest.first || state.courseProvisionsMetaData.number !== rest.number) {
                state.courseProvisions = rest.first
                    ? content
                    : [...state.courseProvisions, ...content];
                state.courseProvisionsMetaData = rest;
            }
        },

        courseProvisionsLoading: (state) => {
            state.loadingProvisions = true;
        },

        courseProvisionsLoadingFailed: (state, action) => {
            state.loadingProvisions = false;
            state.errorMessage = action.payload;
        },

        courseProvisionUpdated: (state, action) => {
            state.loadingProvision = false;
            const provisions = state.courseProvisions.filter((el) => el.id !== action.payload.id);
            state.courseProvisions = [...provisions, action.payload];
        },

        coursesLoading: (state) => {
            state.loadingCourses = true;
        },

        coursesLoaded: (state, action) => {
            state.loadingCourses = false;
            const { content, ...rest } = action.payload;
            state.courses = rest.first ? content : [...state.courses, ...content];
            state.coursesMetaData = rest;
        },

        coursesLoadingFailed: (state, action) => {
            state.loadingCourses = false;
            state.errorMessage = action.payload;
        },

        coursesByCourseIdsLoading: (state) => {
            state.loadingCoursesByCourseIds = true;
        },

        coursesByCourseIdsLoaded: (state, action) => {
            state.loadingCoursesByCourseIds = false;
            const { content, ...rest } = action.payload;
            state.coursesByCourseIds = rest.first
                ? content
                : [...state.coursesByCourseIds, ...content];
            state.coursesByCourseIdsMetaData = rest;
        },

        coursesByCourseIdsLoadingFailed: (state, action) => {
            state.loadingCoursesByCourseIds = false;
            state.errorMessage = action.payload;
        },

        courseTemplateLoaded: (state, action) => {
            state.loadingTemplate = false;
            const templates = state.courseTemplates.filter((el) => el.id !== action.payload.id);
            state.courseTemplates = [...templates, action.payload];
            state.currentTemplate = action.payload;
        },

        courseTemplateLoading: (state) => {
            state.loadingTemplate = true;
            state.currentTemplate = {};
        },

        courseTemplateLoadingFailed: (state, action) => {
            state.loadingTemplate = false;
            state.errorMessage = action.payload;
        },

        courseTemplatesLoaded: (state, action) => {
            state.loadingTemplates = false;
            state.courseTemplates = action.payload;
        },

        courseTemplatesLoading: (state) => {
            state.loadingTemplates = true;
            state.courseTemplates = [];
        },

        courseTemplatesLoadingFailed: (state, action) => {
            state.loadingTemplates = false;
            state.errorMessage = action.payload;
        },

        courseTemplateUpdated: (state, action) => {
            state.loadingTemplate = false;
            const templates = state.courseTemplates.filter((el) => el.id !== action.payload.id);
            state.courseTemplates = [...templates, action.payload];
            state.usersCourseTemplates = state.usersCourseTemplates.map((el) =>
                el.id === action.payload.id ? action.payload : el
            );
            state.currentTemplate = action.payload;
        },

        activeCoursesLoading: (state) => {
            state.loadingActiveCourses = true;
        },

        activeCoursesLoaded: (state, action) => {
            state.loadingActiveCourses = false;
            const { content, ...rest } = action.payload;
            if (rest.first || state.activeCoursesMetaData.number !== rest.number) {
                state.activeCourses = rest.first ? content : [...state.activeCourses, ...content];
                state.activeCoursesMetaData = rest;
            }
        },

        activeCoursesLoadingFailed: (state, action) => {
            state.loadingActiveCourses = false;
            state.errorMessage = action.payload;
        },

        participantsCoursesLoading: (state) => {
            state.loadingParticipantsCourses = true;
        },

        participantsCoursesLoaded: (state, action) => {
            state.loadingParticipantsCourses = false;
            const { content, ...rest } = action.payload;
            state.participantsCourses = rest.first
                ? content
                : [...state.participantsCourses, ...content];
            state.participantsCoursesMetaData = rest;
        },

        participantsCoursesLoadingFailed: (state, action) => {
            state.loadingParticipantsCourses = false;
            state.errorMessage = action.payload;
        },

        participantModulesAttendedCountLoaded: (state, action) => {
            state.participantModulesAttendedCount = action.payload;
        },

        setCurrentCourse: (state, action) => {
            state.currentCourse = action.payload;
        },

        setCurrentModule: (state, action) => {
            state.currentModule = action.payload;
        },

        setCurrentProvision: (state, action) => {
            state.currentProvision = action.payload;
        },

        setCurrentTemplate: (state, action) => {
            state.currentTemplate = action.payload;
        },

        usersCourseTemplatesLoaded: (state, action) => {
            state.loadingTemplates = false;
            state.usersCourseTemplates = action.payload;
        },

        usersCourseTemplatesLoading: (state) => {
            state.loadingTemplates = true;
            state.usersCourseTemplates = [];
        },

        usersCourseTemplatesLoadingFailed: (state, action) => {
            state.loadingTemplates = false;
            state.errorMessage = action.payload;
        },

        usersCoursesByCodeOrNameLoading: (state) => {
            state.loadingUsersCoursesByCodeOrName = true;
        },

        usersCoursesByCodeOrNameLoaded: (state, action) => {
            state.loadingUsersCoursesByCodeOrName = false;
            const { content, ...rest } = action.payload;
            state.usersCoursesByCodeOrName = rest.first
                ? content
                : [...state.usersCoursesByCodeOrName, ...content];
            state.usersCoursesByCodeOrNameMetaData = rest;
        },

        usersCoursesByCodeOrNameLoadingFailed: (state, action) => {
            state.loadingUsersCoursesByCodeOrName = false;
            state.errorMessage = action.payload;
        },

        // used by My Courses
        usersCoursesLoading: (state) => {
            state.loadingCourses = true;
        },

        usersCoursesLoaded: (state, action) => {
            state.loadingCourses = false;
            const { content, ...rest } = action.payload;
            state.usersCourses = rest.first ? content : [...state.usersCourses, ...content];
            state.usersCoursesMetaData = rest;
        },

        usersCoursesLoadingFailed: (state, action) => {
            state.loadingCourses = false;
            state.errorMessage = action.payload;
        },

        setOpenModuleSchedulerPanel: (state, action) => {
            state.openModuleScheduler = action.payload;
        },

        // TIMELINE
        courseParticipantTimelineEventsLoaded: (state, action) => {
            state.loadingParticipantTimeline = false;
            state.courseParticipantTimelineEvents = action.payload;
        },

        courseParticipantTimelineEventsLoading: (state) => {
            state.loadingParticipantTimeline = true;
            state.courseParticipantTimelineEvents = [];
        },

        courseParticipantTimelineEventsLoadingFailed: (state, action) => {
            state.loadingParticipantTimeline = false;
            state.errorMessage = action.payload;
        },

        errorMessageSet: (state, action) => {
            state.errorMessage = action.payload;
            state.successMessage = '';
        },

        successMessageSet: (state, action) => {
            state.successMessage = action.payload;
            state.errorMessage = '';
        }
    }
});

// ActionCreators. These are commands rather
// than events that have happened

export const createCourse = (data) =>
    apiCallBegan({
        url: getCourseURL() + 'rest/course',
        method: 'post',
        data,
        onStart: courseLoading.type,
        onSuccess: courseLoaded.type,
        successMessage: `Course details for ${data.name} have been added`,
        onError: courseLoadingFailed.type
    });

export const createCourseProvision = (title, data) =>
    apiCallBegan({
        url: getCourseURL() + 'rest/provision',
        method: 'post',
        data,
        onStart: courseProvisionLoading.type,
        onSuccess: courseProvisionUpdated.type, // new,
        successMessage: `Course Provision details for ${title} have been added`,
        onError: courseProvisionLoadingFailed.type
    });

export const createCourseTemplate = (name, data) =>
    apiCallBegan({
        url: getCourseURL() + 'rest/course-template',
        method: 'post',
        data,
        onStart: courseTemplateLoading.type,
        onSuccess: courseTemplateLoaded.type, // new
        successMessage: `Course template details for ${name} have been added`,
        onError: courseTemplateLoadingFailed.type
    });

export const loadCourseModulesByUserId = (userId) =>
    apiCallBegan({
        url: getCourseURL() + `rest/module/user/${userId}`,
        onStart: courseModulesLoading.type,
        onSuccess: courseModulesLoaded.type,
        successMessage: `Modules have been loaded`,
        onError: courseModulesLoadingFailed.type
    });

export const loadCourseProvision = (id) =>
    apiCallBegan({
        url: getCourseURL() + `rest/provision/${id}`,
        onStart: courseProvisionLoading.type,
        onSuccess: courseProvisionLoaded.type,
        onError: courseProvisionLoadingFailed.type
    });

export const loadCourseProvisions = (
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'name',
    dir = 'asc'
) =>
    apiCallBegan({
        url: getCourseURL() + `rest/provision?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        onStart: courseProvisionsLoading.type,
        onSuccess: courseProvisionsLoaded.type,
        onError: courseProvisionsLoadingFailed.type
    });

// this now appears to fetch ALL courses - not just for the loggedInUser
export const loadCourses = (page = 0, size = DEFAULT_PAGE_LOAD_SIZE, sort = 'name', dir = 'asc') =>
    apiCallBegan({
        url: getCourseURL() + `rest/course?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        onStart: coursesLoading.type,
        onSuccess: coursesLoaded.type,
        onError: coursesLoadingFailed.type
    });

export const loadCourse = (id) =>
    apiCallBegan({
        url: getCourseURL() + `rest/course/${id}`,
        onSuccess: courseLoaded.type,
        onError: courseLoadingFailed.type
    });

export const searchCoursesByCourseIds = (
    ids,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'name',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getCourseURL() + `rest/course/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data: { ids },
        onStart: coursesByCourseIdsLoading.type,
        onSuccess: coursesByCourseIdsLoaded.type,
        onError: coursesByCourseIdsLoadingFailed.type
    });

export const loadCoursesByParticipantId = (
    participantIds,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'name',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getCourseURL() + `rest/course/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data: { participantIds: [participantIds] },
        onStart: participantsCoursesLoading.type,
        onSuccess: participantsCoursesLoaded.type,
        onError: participantsCoursesLoadingFailed.type
    });

export const searchActiveCourses = (
    data,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'name',
    dir = 'asc'
) =>
    apiCallBegan({
        url:
            getCourseURL() + `rest/course/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: activeCoursesLoading.type,
        onSuccess: activeCoursesLoaded.type,
        onError: activeCoursesLoadingFailed.type
    });

export const searchCoursesByCodeOrName = (
    data,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'endDate',
    dir = 'desc'
) =>
    apiCallBegan({
        url:
            getCourseURL() + `rest/course/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: usersCoursesByCodeOrNameLoading.type,
        onSuccess: usersCoursesByCodeOrNameLoaded.type,
        successMessage: `Course search record has been loaded`,
        onError: usersCoursesByCodeOrNameLoadingFailed.type
    });

export const searchCoursesByLoggedInUser = (
    data,
    page = 0,
    size = DEFAULT_PAGE_LOAD_SIZE,
    sort = 'endDate',
    dir = 'desc'
) =>
    apiCallBegan({
        url:
            getCourseURL() + `rest/course/search?page=${page}&size=${size}&sort=${sort}&dir=${dir}`,
        method: 'post',
        data,
        onStart: usersCoursesLoading.type,
        onSuccess: usersCoursesLoaded.type,
        successMessage: `Course records by logged in user have been loaded`,
        onError: usersCoursesLoadingFailed.type
    });

export const loadCourseTemplate = (id) =>
    apiCallBegan({
        url: getCourseURL() + `rest/course-template/${id}`,
        onStart: courseTemplateLoading.type,
        onSuccess: courseTemplateLoaded.type,
        onError: courseTemplateLoadingFailed.type
    });

// this loads all now - not just loggedInUser's
export const loadCourseTemplates = () =>
    apiCallBegan({
        url: getCourseURL() + 'rest/course-template',
        onStart: courseTemplatesLoading.type,
        onSuccess: courseTemplatesLoaded.type,
        onError: courseTemplatesLoadingFailed.type
    });

export const loadCourseTemplatesByUserId = (userId) =>
    apiCallBegan({
        url: getCourseURL() + 'rest/course-template/search',
        method: 'post',
        data: { userIds: [userId] },
        onStart: usersCourseTemplatesLoading.type,
        onSuccess: usersCourseTemplatesLoaded.type,
        onError: usersCourseTemplatesLoadingFailed.type
    });

export const loadCourseTemplatesByContractIds = (contractIds) =>
    apiCallBegan({
        url: getCourseURL() + 'rest/course-template/search',
        method: 'post',
        data: { contractIds },
        onStart: courseTemplatesLoading.type,
        onSuccess: courseTemplatesLoaded.type,
        onError: usersCourseTemplatesLoadingFailed.type
    });

export const updateCourse = (data) =>
    apiCallBegan({
        url: getCourseURL() + `rest/course/${data.id}`,
        method: 'put',
        data,
        onStart: courseLoading.type,
        onSuccess: courseUpdated.type,
        successMessage: `Course details for ${data.name} have been updated`,
        onError: courseLoadingFailed.type
    });

export const updateCourseModule = (data, message) =>
    apiCallBegan({
        url: getCourseURL() + `rest/module/${data.id}`,
        method: 'put',
        data,
        onStart: courseModuleLoading.type,
        onSuccess: courseModuleUpdated.type,
        successMessage: message,
        onError: courseModuleLoadingFailed.type
    });

export const updateCourseModuleParticipants = (id, data, msg) =>
    apiCallBegan({
        url: getCourseURL() + `rest/module/${id}/participants`,
        method: 'put',
        data,
        onStart: courseModuleLoading.type,
        onSuccess: courseModuleUpdated.type,
        successMessage: msg,
        onError: courseModuleLoadingFailed.type
    });

export const loadParticipantModulesAttendedCount = (participantId) =>
    apiCallBegan({
        url: getCourseURL() + `rest/module/attended/participant/${participantId}/count`,
        onSuccess: participantModulesAttendedCountLoaded.type,
        onError: courseModulesLoadingFailed.type
    });

export const updateCourseProvision = (msg, data) =>
    apiCallBegan({
        url: getCourseURL() + `rest/provision/${data.id}`,
        method: 'put',
        data,
        onStart: courseProvisionLoading.type,
        onSuccess: courseProvisionUpdated.type,
        successMessage: msg,
        onError: courseProvisionLoadingFailed.type
    });

export const updateCourseProvisionParticipants = (id, data, msg) =>
    apiCallBegan({
        url: getCourseURL() + `rest/provision/${id}/participants`,
        method: 'put',
        data,
        onStart: courseProvisionLoading.type,
        onSuccess: courseProvisionUpdated.type,
        successMessage: msg,
        onError: courseProvisionLoadingFailed.type
    });

export const updateCourseTemplate = (data) =>
    apiCallBegan({
        url: getCourseURL() + `rest/course-template/${data.id}`,
        method: 'put',
        data,
        onStart: courseTemplateLoading.type,
        onSuccess: courseTemplateUpdated.type,
        successMessage: `Course template details for ${data.name} have been updated`,
        onError: courseTemplateLoadingFailed.type
    });

// TIMELINE
export const loadCourseParticipantTimelineEvent = (participantId) =>
    apiCallBegan({
        url: getCourseURL() + `/rest/course-module-timeline/participant/${participantId}`,
        onStart: courseParticipantTimelineEventsLoading.type,
        onSuccess: courseParticipantTimelineEventsLoaded.type,
        successMessage: `Course Participant TimelineEvents have been loaded`,
        onError: courseParticipantTimelineEventsLoadingFailed.type
    });

// Put selectors for getting data from state here
// Selectors take state and return computed state
export const setCurrentlySelectedCourseTemplate = (courseTemplate) =>
    courseTemplateUpdated(courseTemplate);

export const setCurrentlySelectedCourse = (course) => courseUpdated(course);

// Action reducers called directly without http
export const {
    setCurrentCourse,
    setCurrentModule,
    setCurrentProvision,
    setCurrentTemplate,
    setOpenModuleSchedulerPanel
} = slice.actions;

// Used internally
const {
    activeCoursesLoading,
    activeCoursesLoadingFailed,
    activeCoursesLoaded,
    courseLoaded,
    courseLoading,
    courseLoadingFailed,
    courseModuleUpdated,
    courseModuleLoading,
    courseModuleLoadingFailed,
    courseModulesLoaded,
    courseModulesLoading,
    courseModulesLoadingFailed,
    coursesLoaded,
    coursesLoading,
    coursesByCourseIdsLoading,
    coursesByCourseIdsLoaded,
    coursesByCourseIdsLoadingFailed,
    coursesLoadingFailed,
    courseUpdated,
    courseParticipantTimelineEventsLoading,
    courseParticipantTimelineEventsLoaded,
    courseParticipantTimelineEventsLoadingFailed,
    courseProvisionLoaded,
    courseProvisionLoading,
    courseProvisionLoadingFailed,
    courseProvisionsLoaded,
    courseProvisionsLoading,
    courseProvisionsLoadingFailed,
    courseProvisionUpdated,
    courseTemplateLoaded,
    courseTemplateLoading,
    courseTemplateLoadingFailed,
    courseTemplatesLoaded,
    courseTemplatesLoading,
    courseTemplatesLoadingFailed,
    courseTemplateUpdated,
    participantsCoursesLoading,
    participantsCoursesLoaded,
    participantsCoursesLoadingFailed,
    participantModulesAttendedCountLoaded,
    usersCoursesByCodeOrNameLoaded,
    usersCoursesByCodeOrNameLoading,
    usersCoursesByCodeOrNameLoadingFailed,
    usersCoursesLoading,
    usersCoursesLoaded,
    usersCoursesLoadingFailed,
    usersCourseTemplatesLoading,
    usersCourseTemplatesLoaded,
    usersCourseTemplatesLoadingFailed
} = slice.actions;

export default slice.reducer;
