import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@mui/material';

import OnDeleteIcon from '../../icons/OnDeleteIcon';
import {
    appointmentDeleted,
    deleteAppointment,
    updateAppointment
} from '../../store/calendarService';
import {
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../store/calendarService';
import { selectNotKnownStatusId } from '../../store/dataSelectors';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadAttendanceDetails,
    loadLocationsForServices,
    loadServiceDetailsByService
} from '../../store/directusService';
import {
    loadParticipantEmployability,
    searchAppointmentAttendanceDetails
} from '../../store/participantService';
import { clearUsersBySearch, loadUserById, loadUsersBySearch } from '../../store/userService';
import { reverseFormatDate } from '../../utils/dateFunctions';
import { SAVE } from '../../utils/uiConstants';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import DateSelect from '../formElements/DateSelect';
import SingleSelect from '../formElements/SingleSelect';
import AppointmentLocationSelect from '../scheduling/components/AppointmentLocationSelect';
import AppointmentTitleSelect from '../scheduling/components/AppointmentTitleSelect';
import MandatoryActivitiesSwitch from '../scheduling/components/MandatoryActivitiesSwitch';
import StartAppointmentButton from '../scheduling/components/StartAppointmentButton';
import StaticParticipant from '../scheduling/components/StaticParticipant';
import TimeSelect from '../scheduling/components/TimeSelect';
import { updateLocations, validateUpdates } from '../scheduling/utils/appointmentSchedulerUtils';
import Panel from '../ui/panel/Panel';

import '../scheduling/styles/appointmentSchedulerPanel.css';

const ParticipantAppointmentEdit = ({
    usersCurrentAppointment,
    currentLocation,
    currentAppointmentType,
    currentParticipantEntry
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE

    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        mandatory: false,
        appointmentType: '',
        service: '',
        location: '',
        participant: ''
    };

    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const editRestrictedRoles = [QUALITY, SUPERUSER];
    const deleteRestrictedRoles = [SUPERUSER];
    const [editable, setEditable] = useState(false);
    const [advisers, setAdvisers] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [newEntry, setNewEntry] = useState(initialState);
    const [addedParticipants, setAddedParticipants] = useState([]);
    const showStartAppointment = true;
    const [disableStartAppointmentButton, setDisableStartAppointmentButton] = useState(false);
    const [isAppointmentCompleted, setIsAppointmentCompleted] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    // STORE STATE
    const {
        loggedInUser: { roles },
        usersBySearch: users,
        usersById,
        usersBySearchMetaData
    } = useSelector((state) => state.entities.userService);
    const { participants, appointmentAttendanceDetails } = useSelector(
        (state) => state.entities.participantService
    );
    const {
        locationsForServices,
        attendanceDetails,
        serviceDetails: services,
        appointmentTypeDetailsForContracts: appointmentTypes
    } = useSelector((state) => state.entities.directusService);
    const notKnownStatusId = useSelector(selectNotKnownStatusId);
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
    };

    const updateAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    const getSelectedAdviserName = () => {
        const selectedAdviser = advisers.find((el) => el.id === newEntry.userId);
        return `${selectedAdviser?.firstName} ${selectedAdviser?.lastName}`;
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(clearUsersBySearch());
        if (!currentParticipantEntry?.id) return;
        const { serviceId } = currentParticipantEntry;
        if (!serviceId) return;
        if (appointmentTypes?.length < 1)
            dispatch(loadAppointmentTypeDetailsByContractIds([currentParticipantEntry.contractId]));
        if (!services?.find((el) => el.id === serviceId))
            dispatch(loadServiceDetailsByService([serviceId]));
        if (locationsForServices?.length < 1) dispatch(loadLocationsForServices([serviceId]));
        dispatch(loadParticipantEmployability(currentParticipantEntry?.id));
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
    }, []);

    useEffect(() => {
        if (usersCurrentAppointment?.id)
            dispatch(
                searchAppointmentAttendanceDetails({
                    calendarServiceAppointmentIds: [usersCurrentAppointment.id]
                })
            );
    }, [usersCurrentAppointment]);

    useEffect(() => {
        if (
            !usersCurrentAppointment ||
            !currentParticipantEntry ||
            appointmentTypes?.length < 1 ||
            services?.length < 1 ||
            locationsForServices?.length < 1
        )
            return;
        const { serviceId } = usersCurrentAppointment;
        const currentServiceName = services?.find((el) => el.id === serviceId)?.name || '';
        setFilteredLocations(updateLocations(locationsForServices, serviceId));
        const currentParticipant = currentParticipantEntry
            ? `${currentParticipantEntry?.firstName} ${currentParticipantEntry?.lastName} ${currentParticipantEntry?.id}`
            : '';

        setNewEntry({
            ...usersCurrentAppointment,
            userId: usersCurrentAppointment.userId,
            startTime: usersCurrentAppointment.startTime?.slice(0, 5),
            endTime: usersCurrentAppointment.endTime?.slice(0, 5),
            date: usersCurrentAppointment.date?.split('T')[0] || '',
            mandatory: usersCurrentAppointment?.mandatory || false,
            service: currentServiceName,
            participant: currentParticipant,
            location: currentLocation,
            appointmentType: currentAppointmentType
        });
        if (usersCurrentAppointment.userId) {
            const selectedAdviser = usersById[usersCurrentAppointment.userId];
            if (!selectedAdviser) {
                dispatch(loadUserById(usersCurrentAppointment.userId));
            } else {
                updateAdvisersArray([selectedAdviser]);
            }
        }

        if (usersCurrentAppointment.participantIds?.length) {
            const entries = usersCurrentAppointment.participantIds.map((el) =>
                participants.find((entry) => entry.id === el)
            );
            setAddedParticipants(entries);
        }
        const canEdit = hasRole(acceptedRoles, roles);
        setEditable(canEdit);
        setDisableStartAppointmentButton(!canEdit);
    }, [appointmentTypes, locationsForServices, services]);

    useEffect(() => {
        if (
            appointmentAttendanceDetails?.length < 1 ||
            attendanceDetails?.length < 1 ||
            !notKnownStatusId
        )
            return;
        const ptApp = appointmentAttendanceDetails.find(
            (el) => el.participantId === currentParticipantEntry?.id
        );
        setIsAppointmentCompleted(notKnownStatusId !== ptApp?.attendanceId);
    }, [appointmentAttendanceDetails, attendanceDetails, notKnownStatusId]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter(
            (el) =>
                el.active && el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < usersBySearchMetaData.totalElements) {
            onLoadMoreAdvisers();
            return;
        }
        if (!updatedAdvisers.some((el) => el.id === newEntry.userId)) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === newEntry.userId);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        updateAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (newEntry.userId && usersById[newEntry.userId]) {
            updateAdvisersArray([usersById[newEntry.userId]]);
        }
    }, [usersById]);

    useEffect(() => {
        if (successMessage === `Appointment ${newEntry.appointmentType} has been updated`) {
            setIsSaveButtonDisabled(false);
            newEntry.participantId && !newEntry.appointmentSlots
                ? setDisableStartAppointmentButton(false)
                : onPanelClose();
        }
        if (successMessage.includes(`Appointment ${newEntry.appointmentType} has been deleted`)) {
            setIsSaveButtonDisabled(false);
            dispatch(appointmentDeleted(newEntry.id));
            onPanelClose();
        }
    }, [successMessage]);

    // HELPER FNS
    const onPanelClose = () => {
        clearForm();
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
    };

    const onUpdateAdviser = (chosenId) => {
        setNewEntry((prev) => ({
            ...prev,
            userId: chosenId
        }));
        setDisableStartAppointmentButton(true);
    };

    const onUpdate = (update) => {
        setNewEntry((prev) => ({ ...prev, ...update }));
        setDisableStartAppointmentButton(true);
    };

    const onInputUpdate = (key, value) => {
        setNewEntry((prev) => ({ ...prev, [key]: value }));
        setDisableStartAppointmentButton(true);
    };

    const onDelete = () =>
        dispatch(deleteAppointment(newEntry.userId, newEntry.appointmentType, newEntry.id));

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const valid = validateUpdates(newEntry, dispatch);
        if (!valid) return;
        setIsSaveButtonDisabled(true);
        if (valid) {
            const {
                /* eslint-disable no-unused-vars */
                appointmentType,
                service,
                location,
                participant,
                ...rest
            } = newEntry;
            dispatch(updateAppointment(newEntry.userId, newEntry.appointmentType, rest));
        }
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < usersBySearchMetaData.totalElements) {
            dispatch(
                loadUsersBySearch(
                    { serviceIds: [currentParticipantEntry.serviceId] },
                    !users.length ? 0 : usersBySearchMetaData.number + 1
                )
            );
        }
    };

    // RENDER

    return (
        <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
            <div className="delete-appointment">
                <OnDeleteIcon
                    onDelete={onDelete}
                    roles={roles}
                    acceptedRoles={deleteRestrictedRoles}
                    active={!!newEntry.id}
                />
            </div>
            <div className="appointment-scheduler">
                <form onSubmit={onSubmit}>
                    {isAppointmentCompleted && !hasRole(editRestrictedRoles, roles) ? (
                        <div className="appointment-title static appointment-title-static">
                            {newEntry.appointmentType}
                        </div>
                    ) : (
                        <AppointmentTitleSelect
                            appointmentTypes={appointmentTypes}
                            appointmentType={newEntry.appointmentType}
                            onTitleChange={onUpdate}
                        />
                    )}

                    {newEntry.appointmentSlots && (
                        <div className="appointment-title static appointment-title-static">
                            <span>
                                {addedParticipants.length} / {newEntry.numberOfSlots}
                            </span>
                        </div>
                    )}

                    <div className="input-set">
                        <label htmlFor="service">Service</label>
                        <div className="static">{newEntry.service}</div>
                    </div>
                    {isAppointmentCompleted && !hasRole(editRestrictedRoles, roles) ? (
                        <>
                            <div className="input-set">
                                <label htmlFor="adviser">Adviser</label>
                                <div className="static">{getSelectedAdviserName()}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="location">Location</label>
                                <div className="static">{newEntry.location}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="startTime">Start Time</label>
                                <div className="static">{newEntry.startTime}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="endTime">End Time</label>
                                <div className="static">{newEntry.endTime}</div>
                            </div>

                            <div className="input-set">
                                <label htmlFor="date">Date</label>
                                <div className="static">{reverseFormatDate(newEntry.date)}</div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="input-set">
                                <SingleSelect
                                    id={'adviserId'}
                                    label={'Adviser'}
                                    placeholder="Select Adviser..."
                                    disabled={!newEntry.service || !editable}
                                    menuItems={advisers}
                                    selectedId={newEntry.userId || ''}
                                    selected={
                                        newEntry.userId
                                            ? advisers?.find((el) => el.id === newEntry.userId) ||
                                              {}
                                            : {}
                                    }
                                    onChange={onUpdateAdviser}
                                    onLoadMoreItems={onLoadMoreAdvisers}
                                    moreItemsToLoad={
                                        !usersBySearchMetaData?.totalElements ||
                                        users.length < usersBySearchMetaData?.totalElements
                                    }
                                />
                            </div>
                            <AppointmentLocationSelect
                                locations={filteredLocations}
                                locationName={newEntry.location}
                                onLocationChange={onUpdate}
                                disabled={!newEntry.service}
                            />

                            <TimeSelect
                                startTime={newEntry.startTime}
                                endTime={newEntry.endTime}
                                onTimeChange={onUpdate}
                            />
                            {!hasRole(editRestrictedRoles, roles) ? (
                                <div className="input-set">
                                    <label htmlFor="date">Date</label>
                                    <div className="static">{newEntry.date}</div>
                                </div>
                            ) : (
                                <>
                                    <br />
                                    <DateSelect
                                        value={newEntry.date}
                                        onDateChange={(date) => onInputUpdate('date', date)}
                                    />
                                </>
                            )}
                        </>
                    )}

                    <div className="last-group">
                        <>
                            <StaticParticipant
                                participant={currentParticipantEntry}
                                onNav={onPanelClose}
                            />

                            <MandatoryActivitiesSwitch
                                checked={newEntry.mandatory}
                                onSwitch={onUpdate}
                                disabled={true}
                            />
                        </>

                        <div className="appointmentSaveBtn">
                            <Button
                                type="submit"
                                disabled={
                                    (!hasRole(editRestrictedRoles, roles) &&
                                        isAppointmentCompleted) ||
                                    isSaveButtonDisabled
                                }
                                color="primary"
                                variant="contained"
                                data-testid="testIdSubmitButton">
                                {SAVE}
                            </Button>
                        </div>
                        <StartAppointmentButton
                            show={showStartAppointment && !newEntry.appointmentSlots}
                            onClick={onPanelClose}
                            disabled={disableStartAppointmentButton}
                            appointment={newEntry}
                            participant={currentParticipantEntry}
                        />
                    </div>
                </form>
            </div>
        </Panel>
    );
};

export default ParticipantAppointmentEdit;

ParticipantAppointmentEdit.propTypes = {
    usersCurrentAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object,
    currentLocation: PropTypes.string,
    currentAppointmentType: PropTypes.string,
    currentParticipantEntry: PropTypes.object
};
