import { commonFieldValidations } from '../../../utils/formValidation/commonFieldValidations';
import { charVal, names, regExps } from '../../../utils/formValidation/validationPatterns';

export const primaryDetailsValidationFields = {
    firstName: { required: 'text', ...commonFieldValidations.firstName },
    middleName: commonFieldValidations.middleName,
    lastName: commonFieldValidations.lastName,
    preferredName: commonFieldValidations.preferredName,

    genderId: {
        subject: names.GENDER,
        indefiniteArticle: 'a',
        required: 'select'
    },
    preferredPronouns: {
        subject: names.PREFERRED_PRONOUNS_OTHER,
        charMax: charVal.MAX_PRONOUNS_OTHER,
        format: regExps.NAME_R
    },
    statusId: {
        subject: names.STATUS_ID,
        indefiniteArticle: 'a',
        required: 'select'
    },
    statusReasonId: {
        subject: names.STATUS_REASON_ID,
        conditional: [
            {
                condition: (entry, resolutionData) => entry.statusId !== resolutionData.statusId,
                indefiniteArticle: 'a',
                required: 'select'
            }
        ]
    },
    dateOfBirth: {
        subject: 'Date of Birth',
        required: 'text',
        ageMin: '0016-00-00T00:00:00.000z'
    },

    uniqueIdentifier: commonFieldValidations.uniqueIdentifier,
    repeatUniqueIdentifier: commonFieldValidations.repeatUniqueIdentifier,
    address1: commonFieldValidations.address1,
    address2: commonFieldValidations.address2,
    address3: commonFieldValidations.address3,
    city: commonFieldValidations.city,
    primaryPhoneNumber: commonFieldValidations.primaryPhoneNumber,
    postcode: commonFieldValidations.postcode,
    secondaryPhoneNumber: commonFieldValidations.primaryPhoneNumber,
    emailAddress: commonFieldValidations.emailAddress,
    referralDate: {
        subject: 'Referral Date',
        required: 'text'
    },

    eligibilityGroupId: {
        subject: names.ELIGIBILITY_GROUP_ID,
        indefiniteArticle: 'an',
        required: 'select'
    },
    button: {
        global: true,
        checkDiff: {}
    }
};
