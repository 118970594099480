import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectAllAppointments } from '../../../store/calendarSelectors';
import {
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel,
    updateAppointment,
    updateAppointmentSlot
} from '../../../store/calendarService';
import { selectAllCourseIds, selectAllCourseModules } from '../../../store/courseSelectors';
import { searchCoursesByCourseIds } from '../../../store/courseService';
import { selectAppointmentTypes, selectLocationsForServices } from '../../../store/dataSelectors';
import {
    loadContractDetailsForContractId,
    loadServiceDetailsForContractId
} from '../../../store/directusService';
import { selectParticipants } from '../../../store/participantSelectors';
import { setCurrentlySelectedParticipant } from '../../../store/participantService';
import { selectLoggedInUser, selectUserRoles } from '../../../store/userSelectors';
import { hasRole, MANAGER, SUPERUSER } from '../../../utils/userRoles';
import Calendar from '../../ui/calendar/Calendar';
import {
    configCourseEventContent,
    configParticipantEventContent,
    formatFullCalendarTimes,
    getBusinessHours,
    mapColors,
    mapCommonParams
} from '../../ui/calendar/calendarUtils';
import UserAppointmentCreate from '../../user/UserAppointmentCreate';
import UserAppointmentEdit from '../../user/UserAppointmentEdit';
import UserAppointmentSlotsEdit from '../../user/UserAppointmentSlotsEdit';

import classes from '../../../app.module.css';

let count = 0;

const TimeView = ({ selectedAdvisers, selectedAdviserId, isAppointmentSlots }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [courseEvents, setCourseEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [currentAdviser, setCurrentAdviser] = useState({});
    const [currentResource, setCurrentResource] = useState('');

    const [advisers, setAdvisers] = useState([]);
    const [resources, setResources] = useState([]);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const roles = useSelector(selectUserRoles);
    const locations = useSelector(selectLocationsForServices);
    const appointmentTypes = useSelector(selectAppointmentTypes);
    const participants = useSelector(selectParticipants);
    const courseModules = useSelector(selectAllCourseModules);
    const courseIds = useSelector(selectAllCourseIds);
    const { coursesByCourseIds: courses, coursesByCourseIdsMetaData } = useSelector(
        (state) => state.entities.courseService
    );
    const allAppointments = useSelector(selectAllAppointments);
    const usersCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );
    const openAppointmentSchedulerPanel = useSelector(
        (state) => state.entities.calendarService.openAppointmentSchedulerPanel
    );

    // CONFIG FNS

    const convertTimeToNumber = (time) => {
        if (!time) return;
        const [hr, min] = time.split(':');
        return +hr + +min / 60;
    };

    const sortEvents = (events) => {
        const resourceIds = [...new Set(events.map((el) => el.resourceId))];
        const resourceSorted = resourceIds.map((el) =>
            events.filter((entry) => entry.resourceId === el)
        );
        return resourceSorted
            .map((el) => {
                const uniqueDates = [...new Set(el.map((entry) => entry.date))];
                const dateSorted = uniqueDates.map((ele) => el.filter((item) => item.date === ele));
                const timeToNumbers = dateSorted.map((dArr) => {
                    return dArr
                        .map((t) => ({
                            ...t,
                            startNumber: convertTimeToNumber(t.displayStart),
                            endNumber: convertTimeToNumber(t.displayEnd)
                        }))
                        .sort((a, b) => (a.startNumber > b.startNumber ? 1 : -1));
                });
                return timeToNumbers
                    .map((r) =>
                        r.map((q, i, arr) => {
                            let hasOverlap = false;
                            if (arr[i].endNumber > arr[i + 1]?.startNumber) hasOverlap = true;
                            if (arr[i - 1]?.endNumber > arr[i].startNumber) hasOverlap = true;
                            return { ...q, hasOverlap };
                        })
                    )
                    .flat();
            })
            .flat();
    };

    const configEvents = () => {
        const businessHours = getBusinessHours();
        const appointments = allAppointments && Object.values(allAppointments)?.flat();
        let userEvents;
        if (!appointments || appointments.length < 1) userEvents = [{}];
        else
            userEvents = appointments?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, participantEntry, participant, slots, filledSlots } =
                    configParticipantEventContent(el, appointmentTypes, participants);
                const { color, textColor } = mapColors(el.id, usersCurrentAppointment?.id);
                const editable =
                    el.userId === currentResource &&
                    (hasRole([SUPERUSER, MANAGER], roles) || loggedInUser.id === currentAdviser.id);
                const resourceEditable = el.userId === currentResource;
                const orderId = resources.find((entry) => entry.id === el.userId)?.orderId;
                const classes = `timeview-event ${
                    !isAppointmentSlots ? 'non-click-event' : !slots ? 'hide-event' : ''
                } ${isAppointmentSlots && !editable ? 'non-click-event' : ''}
                `;
                return {
                    id: el.id,
                    eventType: 'user',
                    viewType: 'timeView',
                    className: classes,
                    hasLink: true,
                    slots,
                    filledSlots,
                    location,
                    participantEntry,
                    participant,
                    appointmentType,
                    displayStart: el.startTime,
                    displayEnd: el.endTime,
                    orderId,
                    color,
                    textColor,
                    date,
                    start,
                    end,
                    constraint: businessHours,
                    resourceId: el.userId,
                    resourceEditable,
                    editable,
                    draggable: isAppointmentSlots
                };
            });
        const updatedEvents = events?.filter((el) => el.resourceId !== currentResource);
        const newEvents = sortEvents(userEvents);
        setEvents([...updatedEvents, ...newEvents]);

        if (userEvents.length > 0) {
            const cur = events?.find((el) => el.id === usersCurrentAppointment?.id);
            if (cur && cur.resourceId === currentResource) setCurrentEvent(cur);
        }
    };

    const configModuleEvents = () => {
        const modules = courseModules?.filter((el) => el.date)?.filter((el) => el.userId);
        let events;
        if (modules?.length < 1) events = [];
        else
            events = modules?.map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, courses, location);
                const orderId = resources?.find((entry) => entry.id === el.userId)?.orderId;
                const classes = `timeview-event non-click-event ${
                    isAppointmentSlots ? 'hide-event' : ''
                }`;
                return {
                    id: el.id,
                    eventType: 'courseModules',
                    className: classes,
                    viewType: 'timeView',
                    content,
                    date,
                    start,
                    end,
                    displayStart: el.startTime,
                    displayEnd: el.endTime,
                    orderId,
                    editable: false,
                    draggable: false,
                    resourceId: el.userId
                };
            });

        setCourseEvents([...courseEvents, ...events]);
    };

    const configResources = () => {
        const businessHours = getBusinessHours();
        let resources;
        if (!advisers.length) resources = [];
        else
            resources = advisers
                .map((el, i) => {
                    return el
                        ? {
                              id: el.id,
                              title: `${el.firstName} ${el.lastName}`,
                              orderId: i,
                              active: el.id === selectedAdviserId,
                              businessHours: businessHours
                          }
                        : null;
                })
                .filter((el) => el);
        setResources(resources);
    };

    const sidePanel = document.querySelector('.scheduling-sidebar .adviser-panel .added-advisers');
    const table = document.querySelector('.fc .fc-timeline .fc-scroller-liquid-absolute');
    sidePanel?.addEventListener('scroll', () => {
        table.scrollTop = sidePanel.scrollTop;
        table.style.top = `${sidePanel.scrollTop}px`;
    });

    // USEEFFECTS

    useEffect(() => {
        setAdvisers(selectedAdvisers);
        setCurrentAdviser(selectedAdvisers[selectedAdvisers.length - 1]);
        setCurrentResource(selectedAdvisers[selectedAdvisers.length - 1]?.id);
        configResources();
    }, [selectedAdvisers]);

    useEffect(() => {
        setCurrentResource(selectedAdviserId);
        const adviser = advisers.find((el) => el.id === selectedAdviserId);
        adviser &&
            setCurrentAdviser(
                !('contractIds' in adviser)
                    ? { ...adviser, contractIds: adviser.contracts || [] }
                    : adviser
            );

        configResources();
    }, [selectedAdviserId]);

    useEffect(() => {
        if (
            allAppointments &&
            Object.values(allAppointments)?.flat().length &&
            currentResource &&
            locations?.length &&
            participants?.length &&
            appointmentTypes?.length
        ) {
            configEvents();
        }
    }, [currentResource, allAppointments, locations, participants, appointmentTypes]);

    useEffect(() => {
        const calendarTable = document.querySelector('.fc-timeline-body');
        if (!calendarTable) return;
        calendarTable && !advisers.length
            ? (calendarTable.style.visibility = 'hidden')
            : (calendarTable.style.visibility = 'visible');
        configResources();
    }, [advisers]);

    useEffect(() => {
        configEvents();
    }, [usersCurrentAppointment]);

    useEffect(() => {
        if (!courseModules || coursesByCourseIdsMetaData.last) return;
        courseIds.length > 0 &&
            dispatch(
                searchCoursesByCourseIds(
                    courseIds,
                    'number' in coursesByCourseIdsMetaData
                        ? coursesByCourseIdsMetaData.number + 1
                        : 0
                )
            );
    }, [courseModules, courseIds, coursesByCourseIdsMetaData]);

    useEffect(() => {
        if (courseModules?.length && courses?.length && locations?.length) configModuleEvents();
    }, [courses]);

    useEffect(() => {
        configEvents();
        configModuleEvents();
    }, [isAppointmentSlots]);

    // EVENT HANDLERS
    const onEditTimes = (time, update, id) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            let currentAppointment =
                usersCurrentAppointment?.id === id
                    ? usersCurrentAppointment
                    : allAppointments[currentResource]?.find((el) => el.id === id);

            const updatedAppointment = { ...currentAppointment, date, startTime, endTime };
            currentEvent.slots
                ? dispatch(
                      updateAppointmentSlot(
                          currentAdviser.id,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  )
                : dispatch(
                      updateAppointment(
                          currentAdviser.id,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  );
        }
        dispatch(setCurrentAppointment({}));
    };

    const onTogglePanel = (open) => {
        dispatch(setOpenAppointmentSchedulerPanel(open));
    };

    const onEventSelect = (id) => {
        if (!currentResource || !isAppointmentSlots) return;

        setNewEvent({});
        if (id !== usersCurrentAppointment?.id) {
            const selectedAppointment = allAppointments[currentResource]?.find(
                (el) => el.id === id
            );
            selectedAppointment && dispatch(setCurrentAppointment(selectedAppointment));
        }
        if (id === currentEvent.id) {
            count++;
            setTimeout(() => (count = 0), 300);
            if (count > 1) dispatch(setOpenAppointmentSchedulerPanel(true));
        }
    };

    const onSlotSelect = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        setNewEvent({ date, startTime, endTime });
        onTogglePanel(true);
    };

    const onChangeResource = () => {
        configEvents();
    };

    const onNav = (selectedEvent) => {
        dispatch(setCurrentlySelectedParticipant(selectedEvent.participantEntry));
        dispatch(loadContractDetailsForContractId(selectedEvent.participantEntry.contractId));
        dispatch(loadServiceDetailsForContractId(selectedEvent.participantEntry.contractId));
        navigate('/edit_participant');
    };

    // RENDER

    return (
        <div className={classes.container}>
            <div className="calendar-wrapper timeview">
                <Calendar
                    resources={resources}
                    resourceLabelText="Advisers"
                    /* eslint-disable-next-line no-constant-binary-expression */
                    events={[...events, ...courseEvents] || []}
                    currentEvent={currentEvent}
                    onEditTimes={onEditTimes}
                    selectSlot={onSlotSelect}
                    allowSlotSelect={!isAppointmentSlots}
                    selectEvent={onEventSelect}
                    returnButton={false}
                    initialView="resourceTimelineDay"
                    slotMinTime="07:00:00"
                    slotMaxTime="19:00:00"
                    onChangeResource={onChangeResource}
                    onNav={onNav}
                    eventEditPermission={
                        hasRole([SUPERUSER, MANAGER], roles) ||
                        loggedInUser?.id === currentAdviser?.id
                    }
                />

                {openAppointmentSchedulerPanel &&
                    currentAdviser &&
                    (Object.keys(newEvent).length ? (
                        <UserAppointmentCreate
                            newAppointment={newEvent}
                            currentCalendarOwner={currentAdviser}
                        />
                    ) : currentEvent.slots ? (
                        <UserAppointmentSlotsEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentAdviser}
                            currentLocation={currentEvent.location}
                        />
                    ) : (
                        <UserAppointmentEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentCalendarOwner={currentAdviser}
                            currentLocation={currentEvent.location}
                            currentAppointmentType={currentEvent.appointmentType}
                            currentParticipantEntry={currentEvent.participantEntry}
                        />
                    ))}
            </div>
        </div>
    );
};

export default TimeView;

TimeView.propTypes = {
    selectedAdvisers: PropTypes.arrayOf(PropTypes.object),
    selectedAdviserId: PropTypes.string,
    isAppointmentSlots: PropTypes.bool
};
