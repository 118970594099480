import { createSelector } from '@reduxjs/toolkit';

const selectAllAppointmentEntries = (state) => state.entities.calendarService.allAppointments;

const selectAppointmentSearchEntries = (state) => state.entities.calendarService.appointmentSearch;

const selectAppointmentSlotSearchEntries = (state) =>
    state.entities.calendarService.appointmentSlotSearch;

const selectParticipantAppointmentEntries = (state) =>
    state.entities.calendarService.participantAppointments;

export const selectAllAppointments = createSelector(
    selectAllAppointmentEntries,
    (allAppointments) => allAppointments
);

export const selectUsersAppointments = () =>
    createSelector(
        selectAllAppointments,
        (_, id) => id,
        (allAppointments, id) => allAppointments[id] || []
    );

export const selectParticipantAppointments = createSelector(
    selectParticipantAppointmentEntries,
    (participantAppointments) => participantAppointments
);

export const selectAppointmentAndSlotSearch = createSelector(
    selectAppointmentSearchEntries,
    selectAppointmentSlotSearchEntries,
    (appointmentSearch, appointmentSlotSearch) => [appointmentSearch, appointmentSlotSearch]
);
