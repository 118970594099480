import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { selectActionPlanStatus } from '../../../store/dataSelectors';
import { loadActionPlanStatusDetails } from '../../../store/directusService';
import {
    selectCurrentParticipant,
    selectCurrentParticipantBarriers,
    selectCurrentParticipantEmployability
} from '../../../store/participantSelectors';
import { createActionPlan } from '../../../store/participantService';
import { getNameFromId } from '../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../utils/userRoles';
import DateSelect from '../../formElements/DateSelect';
import LabelledTextField from '../../ui/editors/LabelledTextField';
import FormActions from '../../ui/formActions/FormActions';
import SmartDefs from '../../ui/notices/smartDefs/SmartDefs';
import DDLOptionPicker from '../../ui/pickers/DDLOptionPicker';
import NoYesRadioPicker from '../../ui/pickers/NoYesRadioPicker';

import { validate } from './actionPlanValidations';
import { calcDays } from './calcDays';
import { configDisabled } from './disabledStatus';

import classes from './actionPlan.module.css';

const ACTION_DESCRIPTION_LIMIT = 750;
const ACTION_COMMENTS_LIMIT = 500;
const CreateActionPlan = ({ onClose, roles }) => {
    const {
        register,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({});

    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const restrictedRoles = [QUALITY, SUPERUSER];
    const initialState = {
        id: '',
        participantId: '',
        mandatory: false,
        actionTitle: '',
        action: '',
        barrierId: '',
        actionStartDate: new Date().toISOString().slice(0, 10),
        actionDueDate: '',
        actionOutcomeDate: '',
        totalDays: null,
        outcomeId: '',
        outcomeName: '',
        moduleId: null,
        comments: ''
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const [allowMandatoryActivities, setAllowMandatoryActivities] = useState(true);
    const [isClearBarrier, setIsClearBarrier] = useState('0');
    const [isClearOutcome, setIsClearOutcome] = useState('1');
    const [outcomes, setOutcomes] = useState([]);

    const initialDisabledState = {
        barrierId: false,
        mandatory: false,
        actionTitle: false,
        action: false,
        actionStartDate: true,
        actionDueDate: false,
        actionOutcomeDate: false,
        outcomeId: false,
        comments: false
    };
    const [disabled, setDisabled] = useState(initialDisabledState);
    const [currentParticipantBarriersWithNames, setCurrentParticipantBarriersWithNames] = useState(
        []
    );
    // STORE STATE
    const currentParticipant = useSelector(selectCurrentParticipant);
    const currentParticipantEmployability = useSelector(selectCurrentParticipantEmployability);
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const actionPlanStatus = useSelector(selectActionPlanStatus);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const barrierNameDetails = useSelector(
        (state) => state.entities.directusService.barrierNameDetails
    );

    // HELPER FNS
    const clearForm = () => {
        setNewEntry(initialState);
        setIsClearBarrier(Math.random());
        setIsClearOutcome(Math.random());
    };

    // USE EFFECTS
    useEffect(() => {
        if (currentParticipantBarriers.length < 1) return;

        setCurrentParticipantBarriersWithNames(
            currentParticipantBarriers.map((el) => ({
                ...el,
                name: getNameFromId(barrierNameDetails, el.barrierId)
            }))
        );
    }, [currentParticipantBarriers]);

    useEffect(() => {
        setDisabled(configDisabled(newEntry, roles, allowMandatoryActivities));
    }, [roles, newEntry]);

    useEffect(() => {
        if (!currentParticipant?.id) return;
        reset();
        setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
        if (!actionPlanStatus?.length) dispatch(loadActionPlanStatusDetails());
    }, [currentParticipant]);

    useEffect(() => {
        if (Object.keys(currentParticipantEmployability).length > 1) {
            setAllowMandatoryActivities(currentParticipantEmployability?.allowMandatoryActivities);
        }
    }, [currentParticipantEmployability]);

    useEffect(() => {
        if (!actionPlanStatus.length) return;
        setOutcomes(
            actionPlanStatus.filter((el) =>
                el.contract.some((entry) => entry.contract_id.id === currentParticipant.contractId)
            )
        );
    }, [actionPlanStatus]);

    useEffect(() => {
        if (newEntry.outcomeId)
            setNewEntry((prev) => ({
                ...prev,
                actionOutcomeDate: new Date().toISOString().slice(0, 10)
            }));
    }, [newEntry.outcomeId]);

    useEffect(() => {
        if (successMessage === 'Participant action plan has been added') clearForm();
    }, [successMessage]);

    // EVENT HANDLERS
    const onFormExit = () => {
        clearForm();
        onClose();
    };

    const onSubmit = (e) => {
        e.preventDefault();
        clearErrors();
        const { valid, errors } = validate(disabled, newEntry);
        errors.forEach((el) => setError(el.error, el.detail));
        if (!valid) return;
        // eslint-disable-next-line
        const { id, outcomeName, totalDays, ...rest } = newEntry;
        dispatch(createActionPlan(rest));
        onFormExit();
    };

    // RENDER
    return (
        <div className={classes.actionFormSection}>
            <h3>Create an action</h3>
            <form className={classes.actionForm} onSubmit={handleSubmit(onSubmit)}>
                <NoYesRadioPicker
                    id="mandatory"
                    disabled={!hasRole(acceptedRoles, roles) || !allowMandatoryActivities}
                    radioButtonPick={newEntry.mandatory}
                    label={'Is this a mandatory action?'}
                    onChange={(val) =>
                        setNewEntry((prev) => ({ ...prev, mandatory: val }))
                    }></NoYesRadioPicker>

                <LabelledTextField
                    label={'What shall we call this action?'}
                    id={'actionTitle'}
                    value={newEntry.actionTitle || ''}
                    placeholder={'Enter name'}
                    mandatory={true}
                    disabled={!hasRole(acceptedRoles, roles)}
                    error={errors.actionTitle}
                    {...register('actionTitle')}
                    onChange={(e) => {
                        clearErrors('actionTitle');
                        setNewEntry((prev) => ({ ...prev, actionTitle: e.target.value }));
                    }}
                />

                <DDLOptionPicker
                    label={'Which barrier does this action align to?'}
                    id={'barrierId'}
                    key={isClearBarrier}
                    disabled={!hasRole(acceptedRoles, roles)}
                    menuItems={currentParticipantBarriersWithNames || []}
                    chosenName={getNameFromId(barrierNameDetails, newEntry?.barrierId)}
                    chosenId={newEntry.barrierId}
                    onChange={(chosenId) => {
                        clearErrors('barrierId');
                        setNewEntry((prev) => ({ ...prev, barrierId: chosenId }));
                    }}></DDLOptionPicker>

                <div className={classes.smartDefsWrapper}>
                    <div className={classes.heading}>
                        What is the action?<sup>*</sup>
                    </div>
                    <SmartDefs />
                </div>

                <LabelledTextField
                    label={'Action'}
                    id={'action'}
                    mandatory={true}
                    disabled={!hasRole(acceptedRoles, roles)}
                    multiline
                    rows={5}
                    value={newEntry.action}
                    placeholder={'Enter action description'}
                    counter={'true'}
                    helperText={`${newEntry.action.length}/${ACTION_DESCRIPTION_LIMIT}`}
                    inputProps={{ maxLength: ACTION_DESCRIPTION_LIMIT }}
                    error={errors.action}
                    {...register('action')}
                    onChange={(e) => {
                        clearErrors('action');
                        setNewEntry((prev) => ({ ...prev, action: e.target.value }));
                    }}
                />

                <div className={classes.twoCol}>
                    <div>
                        <DateSelect
                            value={newEntry.actionStartDate}
                            label="Action start date"
                            disabled={true}
                        />
                    </div>

                    <div>
                        <DateSelect
                            value={newEntry.actionDueDate}
                            label="Action due date"
                            disabled={!hasRole(acceptedRoles, roles)}
                            error={errors.actionDueDate}
                            onDateChange={(res) => {
                                clearErrors('actionDueDate');
                                setNewEntry((prev) => ({ ...prev, actionDueDate: res }));
                            }}
                        />
                    </div>
                </div>

                <div className={classes.completion}>
                    <h3>Action completion information</h3>

                    <div className={classes.twoCol}>
                        <div>
                            <DateSelect
                                value={newEntry.actionOutcomeDate}
                                label="Action outcome date"
                                disabled={!hasRole(restrictedRoles, roles) || !newEntry.outcomeId}
                                isDefault={false}
                                error={errors.actionOutcomeDate}
                                onDateChange={(res) => {
                                    clearErrors('actionOutcomeDate');
                                    setNewEntry((prev) => ({
                                        ...prev,
                                        actionOutcomeDate: res
                                    }));
                                }}
                            />
                        </div>
                        <div className={classes.inputSet}>
                            <label>Total days to complete</label>
                            <div className={classes.total}>
                                {' '}
                                {calcDays(actionPlanStatus, newEntry)}{' '}
                            </div>
                        </div>
                    </div>
                    <DDLOptionPicker
                        label={'Outcome'}
                        id={'outcome'}
                        key={isClearOutcome}
                        disabled={!hasRole(acceptedRoles, roles)}
                        menuItems={outcomes || []}
                        chosenName={newEntry.outcomeName}
                        chosenId={newEntry.outcomeId}
                        onChange={(chosenId) =>
                            setNewEntry((prev) => ({ ...prev, outcomeId: chosenId }))
                        }></DDLOptionPicker>

                    <div className={classes.comments}>
                        <LabelledTextField
                            label="Comments"
                            subLabel="Use this area for comments surrounding this action"
                            id="comments"
                            disabled={!hasRole(acceptedRoles, roles)}
                            multiline
                            rows={5}
                            value={newEntry.comments}
                            placeholder="Enter here"
                            counter={'true'}
                            helperText={`${newEntry.comments.length}` + '/' + ACTION_COMMENTS_LIMIT}
                            inputProps={{ maxLength: ACTION_COMMENTS_LIMIT }}
                            error={errors.comments}
                            {...register('comments')}
                            onChange={(e) => {
                                clearErrors('comments');
                                setNewEntry((prev) => ({ ...prev, comments: e.target.value }));
                            }}
                        />
                    </div>
                </div>
                <FormActions onClose={onSubmit} onCancel={onFormExit} btnText="Add action" />
            </form>
        </div>
    );
};

export default CreateActionPlan;

CreateActionPlan.propTypes = {
    row: PropTypes.object,
    onClose: PropTypes.func,
    roles: PropTypes.arrayOf(PropTypes.string),
    formType: PropTypes.string
};
