import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { loadParticipantStatusReasonDetails } from '../../store/directusService';
import { loadParticipantsAdvancedSearch } from '../../store/participantService';
import {
    ADVISER,
    hasRole,
    MANAGER,
    PRAP,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import DateSelect from '../formElements/DateSelect';
import DDLOptionPicker from '../ui/pickers/DDLOptionPicker';
import MultiSelect from '../ui/pickers/MultiSelect';

import { initialErrorState, validate } from './validateDataSets';

import '../../commonStyles/muiStyles.css';
import form from '../../commonStyles/formStyles.module.css';
import modal from '../../commonStyles/modal.module.css';

const DataSetsModal = ({
    dataSets,
    contracts,
    attendanceStatuses,
    onGenerate,
    onCancel,
    isOpen
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialSelectState = {
        attendanceStatuses: [],
        contract: {},
        date: String(new Date().toISOString().split('T')[0])
    };
    const presentReferralsCreatedRoles = [
        ADVISER,
        MANAGER,
        PRAP,
        QUALITY,
        RECRUITMENT_MANAGER,
        SUPERUSER
    ];
    const [errors, setErrors] = useState(initialErrorState);
    const [selects, setSelects] = useState(initialSelectState);
    const [selectedDataSet, setSelectedDataSet] = useState({});
    const [type, setType] = useState('');
    const [preSelectedAttendanceStatuses, setPreSelectedAttendanceStatuses] = useState([]);
    const [preSelectedAttendanceStatusIds, setPreSelectedAttendanceStatusIds] = useState([]);
    const [isClearSelectedAttendanceIds, setIsClearSelectedAttendanceIds] = useState('0');
    const [isDisabled, setIsDisabled] = useState(true);

    // STORE STATE
    const participantStatusReasonDetails = useSelector(
        (state) => state.entities.directusService.participantStatusReasonDetails
    );
    const roles = useSelector((state) => state.entities.userService.loggedInUser.roles);

    // HELPER FNS
    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    const clearFields = () => {
        setSelects(initialSelectState);
        setErrors(initialErrorState);
        setSelectedDataSet({});
        setType('');
        setPreSelectedAttendanceStatuses([]);
        setPreSelectedAttendanceStatusIds([]);
        setIsClearSelectedAttendanceIds(Math.random());
    };

    // USE EFFECTS
    useEffect(() => {
        const isValid = validate(
            selectedDataSet,
            selects.attendanceStatuses,
            selects.contract?.id,
            errors
        );
        setIsDisabled(!isValid);
        if (selectedDataSet && 'name' in selectedDataSet) {
            setType(
                selectedDataSet?.name.toLowerCase() === 'attendance outcomes'
                    ? 'attendanceOutcomes'
                    : 'referralsCreated'
            );
        }
    }, [selectedDataSet, selects.attendanceStatuses, selects.contract]);

    useEffect(() => {
        if (!type) return;
        if (type === 'referralsCreated') {
            participantStatusReasonDetails?.length < 1 &&
                dispatch(loadParticipantStatusReasonDetails());
        }
    }, [type]);

    // EVENT HANDLERS
    const onClose = () => {
        clearFields();
        onCancel();
    };

    const onPresent = () => {
        onClose();
        dispatch(loadParticipantsAdvancedSearch({ contractIds: [selects.contract.id] }));
        navigate('/referrals_created');
    };

    // RENDER

    return (
        <Dialog open={isOpen} fullWidth={true} className="muiDialogWrapper">
            <div className={modal.modalHeader}>
                <DialogTitle>Data Set Modal</DialogTitle>
            </div>
            <DialogContent>
                <>
                    <form className={form.formWrapper} onSubmit={(e) => onGenerate(e, selects)}>
                        <div className={form.formSection}>
                            <div className={form.formColumn}>
                                <DDLOptionPicker
                                    label="Select Data Set"
                                    id="dataSetId"
                                    mandatory={true}
                                    menuItems={dataSets || []}
                                    chosenName={selectedDataSet?.name || ''}
                                    chosenId={selectedDataSet?.id || ''}
                                    onChange={(chosenId) => {
                                        clearError('dataSetId');
                                        const dataSet = dataSets.find((el) => el.id === chosenId);
                                        setSelectedDataSet(dataSet);
                                    }}></DDLOptionPicker>
                                {type === 'attendanceOutcomes' && (
                                    <MultiSelect
                                        id={'attendanceId'}
                                        key={isClearSelectedAttendanceIds}
                                        disabled={false}
                                        label="Attendance Statuses *"
                                        placeholder="Attendance Statuses"
                                        menuItems={attendanceStatuses || []}
                                        preSelects={preSelectedAttendanceStatuses || []}
                                        preSelectedIds={preSelectedAttendanceStatusIds || []}
                                        onChange={(chosenIds) => {
                                            clearError('attendanceStatuses');
                                            const res = attendanceStatuses.filter((el) =>
                                                chosenIds.includes(el.id)
                                            );
                                            setSelects((prev) => ({
                                                ...prev,
                                                attendanceStatuses: res
                                            }));
                                        }}
                                    />
                                )}
                            </div>
                            <div className={form.formColumn}>
                                {selectedDataSet && Object.keys(selectedDataSet).length > 0 && (
                                    <DDLOptionPicker
                                        label="Contract *"
                                        id="contractId"
                                        menuItems={contracts || []}
                                        chosenName={selects.contract?.name || ''}
                                        chosenId={selects.contract?.id || ''}
                                        onChange={(chosenId) => {
                                            clearError('contractId');
                                            const contract = contracts.find(
                                                (el) => el.id === chosenId
                                            );
                                            setSelects((prev) => ({ ...prev, contract }));
                                        }}></DDLOptionPicker>
                                )}

                                {type === 'attendanceOutcomes' && (
                                    <div className={form.alignDown}>
                                        <DateSelect
                                            label={'Date Range'}
                                            value={selects.date}
                                            isDefault={true}
                                            onDateChange={(res) =>
                                                setSelects((prev) => ({
                                                    ...prev,
                                                    date: res
                                                }))
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>

                    <div className={modal.actionButtonsContainer}>
                        <div>
                            {selectedDataSet?.name === 'Referrals Created' && (
                                <Button
                                    onClick={onPresent}
                                    disabled={
                                        !selects.contract ||
                                        !hasRole(presentReferralsCreatedRoles, roles)
                                    }
                                    color="primary"
                                    variant="contained">
                                    Present
                                </Button>
                            )}
                        </div>
                        <div className={modal.rightActionButtons}>
                            <Button
                                onClick={(e) => onGenerate(e, selects, type)}
                                disabled={!hasRole([PRAP, QUALITY, SUPERUSER], roles) || isDisabled}
                                color="primary"
                                variant="contained">
                                Generate
                            </Button>

                            <div className={modal.cancelButton}>
                                <Button variant={'contained'} onClick={onClose}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    );
};

export default DataSetsModal;

DataSetsModal.propTypes = {
    dataSets: PropTypes.arrayOf(PropTypes.object),
    contracts: PropTypes.arrayOf(PropTypes.object),
    attendanceStatuses: PropTypes.arrayOf(PropTypes.object),
    onGenerate: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
};
