import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Button, TextField } from '@mui/material';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../api/pagination';
import {
    createAppointment,
    createAppointmentSlot,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../store/calendarService';
import {
    loadAppointmentTypeDetailsByContractIds,
    loadLocationsForServices,
    loadServiceDetailsByService
} from '../../store/directusService';
import {
    createAppointmentAttendanceDetail,
    loadParticipant,
    loadParticipantEmployability,
    searchParticipants,
    setCurrentlySelectedParticipant
} from '../../store/participantService';
import { clearUsersBySearch, loadUsersBySearch } from '../../store/userService';
import { SAVE } from '../../utils/uiConstants';
import { addEmailAddressAsNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import DateSelect from '../formElements/DateSelect';
import SingleSelect from '../formElements/SingleSelect';
import AppointmentLocationSelect from '../scheduling/components/AppointmentLocationSelect';
import AppointmentParticipantSelect from '../scheduling/components/AppointmentParticipantSelect';
import AppointmentServiceSelect from '../scheduling/components/AppointmentServiceSelect';
import AppointmentSlotSwitch from '../scheduling/components/AppointmentSlotSwitch';
import AppointmentTitleSelect from '../scheduling/components/AppointmentTitleSelect';
import MandatoryActivitiesSwitch from '../scheduling/components/MandatoryActivitiesSwitch';
import StartAppointmentButton from '../scheduling/components/StartAppointmentButton';
import TimeSelect from '../scheduling/components/TimeSelect';
import {
    updateAdvisers,
    updateLocations,
    updateParticipants,
    validateUpdates
} from '../scheduling/utils/appointmentSchedulerUtils';
import { getServiceIds } from '../ui/calendar/calendarUtils';
import Panel from '../ui/panel/Panel';

import '../scheduling/styles/appointmentSchedulerPanel.css';

const PT_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;

const UserAppointmentCreate = ({ newAppointment, currentCalendarOwner }) => {
    const dispatch = useDispatch();

    // LOCAL STATE

    const initialState = {
        id: '',
        typeId: '',
        userId: '',
        locationId: '',
        startTime: '08:00',
        endTime: '09:00',
        date: String(new Date().toISOString().split('T')[0]),
        serviceId: '',
        participantId: '',
        participantIds: [],
        mandatory: false,
        numberOfSlots: 1,
        appointmentSlots: false,
        appointmentType: '',
        service: '',
        location: '',
        participant: ''
    };

    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const [editable, setEditable] = useState(false);
    const [advisers, setAdvisers] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [newEntry, setNewEntry] = useState(initialState);
    const [serviceIds, setServiceIds] = useState([]);
    const [allowParticipantMandatoryActivities, setAllowParticipantMandatoryActivities] =
        useState(true);
    const [addedParticipants] = useState([]);
    const [showStartAppointment, setShowStartAppointment] = useState(false);
    const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(false);
    const [currentParticipant, setCurrentParticipant] = useState({});
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

    // STORE STATE
    const {
        loggedInUser: { roles },
        usersBySearch: users,
        usersBySearchMetaData
    } = useSelector((state) => state.entities.userService);
    const { participantsSearch, participantsSearchMetaData, currentParticipantEmployability } =
        useSelector((state) => state.entities.participantService);
    const {
        locationsForServices,
        appointmentTypeDetailsForContracts,
        serviceDetails: services
    } = useSelector((state) => state.entities.directusService);
    const { openAppointmentSchedulerPanel, newAppointment: newCreatedAppointment } = useSelector(
        (state) => state.entities.calendarService
    );
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);

    // HELPER FNS

    const clearForm = () => {
        setNewEntry(initialState);
    };

    const updateAdvisersArray = (advisers) => {
        const updatedAdvisers = addEmailAddressAsNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS

    useEffect(() => {
        dispatch(clearUsersBySearch());
        if (!currentCalendarOwner || Object.keys(currentCalendarOwner).length < 1) return;
        const serviceIds = getServiceIds(currentCalendarOwner);
        if (serviceIds?.length < 1) return;
        setServiceIds(serviceIds);
        if (currentCalendarOwner?.contractIds?.length > 0)
            dispatch(loadAppointmentTypeDetailsByContractIds(currentCalendarOwner?.contractIds));
        dispatch(loadLocationsForServices(serviceIds));
        if (services?.length < 1) dispatch(loadServiceDetailsByService(serviceIds));
        participants?.length < 1
            ? dispatch(searchParticipants({ serviceIds }, 0, PT_LOAD_SIZE))
            : setParticipants(participantsSearch);
        setEditable(hasRole([ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER], roles));
    }, []);

    useEffect(() => {
        if (participantsSearch?.length < 1) return;
        else if (!participantsSearchMetaData.last) {
            dispatch(
                searchParticipants(
                    { serviceIds },
                    participantsSearchMetaData.number + 1,
                    PT_LOAD_SIZE
                )
            );
        } else setParticipants(participantsSearch);
    }, [participantsSearch, participantsSearchMetaData]);

    useEffect(() => {
        if (services?.length < 1) return;
        const currentService =
            services?.find((el) => el.id === currentCalendarOwner?.primaryService)?.name || '';
        setNewEntry({
            ...newEntry,
            serviceId: currentCalendarOwner.primaryService,
            userId: currentCalendarOwner.id,
            startTime: newAppointment.startTime,
            endTime: newAppointment.endTime,
            date: newAppointment.date,
            service: currentService
        });
        updateAdvisersArray([currentCalendarOwner]);
    }, [services]);

    useEffect(() => {
        if (Object.keys(currentParticipantEmployability).length > 1) {
            setAllowParticipantMandatoryActivities(
                currentParticipantEmployability?.allowMandatoryActivities
            );
        }
    }, [currentParticipantEmployability]);

    useEffect(() => {
        if (!newEntry.serviceId) return;
        if (locationsForServices?.length > 0) {
            setFilteredLocations(updateLocations(locationsForServices, newEntry.serviceId));
        }
        if (participants?.length > 0)
            setFilteredParticipants(
                updateParticipants(participants, newEntry.serviceId, newEntry.date)
            );
    }, [newEntry.serviceId, newEntry.date, participants, locationsForServices]);

    useEffect(() => {
        if (!filteredParticipants.some((el) => el.id === newEntry.participantId))
            setNewEntry((prev) => ({
                ...prev,
                participantId: '',
                participant: ''
            }));
    }, [filteredParticipants]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = updateAdvisers(users, newEntry.serviceId, allowedAdviserRoles);
        if (!updatedAdvisers.length && users.length < usersBySearchMetaData.totalElements) {
            onLoadMoreAdvisers();
            return;
        }
        if (!updatedAdvisers.some((el) => el.id === currentCalendarOwner.id)) {
            // Put current calendar owner at the top of dropdown if it's not in the updated advisers array
            updatedAdvisers = [currentCalendarOwner, ...updatedAdvisers];
        }
        updateAdvisersArray(updatedAdvisers);
    }, [users]);

    useEffect(() => {
        if (
            successMessage === `Appointment ${newEntry.appointmentType} has been created` ||
            successMessage === `Appointment slot ${newEntry.appointmentType} has been created`
        ) {
            setIsSaveButtonDisabled(false);
            if (newEntry.participantId && !newEntry.appointmentSlots) {
                setShowStartAppointment(true);
                setIsStartButtonDisabled(false);
                if (newCreatedAppointment?.id) {
                    const payload = {
                        participantId: newCreatedAppointment.participantId,
                        calendarServiceAppointmentId: newCreatedAppointment.id
                    };
                    dispatch(createAppointmentAttendanceDetail(payload));
                }
            } else onPanelClose();
        }
    }, [successMessage, newCreatedAppointment]);

    // HELPER FNS
    const onPanelClose = () => {
        clearForm();
        dispatch(setCurrentAppointment({}));
        dispatch(setOpenAppointmentSchedulerPanel(false));
        setCurrentAppointment({});
    };

    const onServiceChange = (update) => {
        setIsStartButtonDisabled(true);
        setNewEntry((prev) => ({
            ...prev,
            ...update,
            userId: '',
            locationId: '',
            participantId: '',
            location: '',
            participant: ''
        }));
    };

    const onUpdateAdviser = (chosenId) => {
        setIsStartButtonDisabled(true);
        setNewEntry((prev) => ({
            ...prev,
            userId: chosenId,
            participantId: '',
            participant: '',
            location: '',
            locationId: ''
        }));
    };

    const onUpdate = (update) => {
        setIsStartButtonDisabled(true);
        setNewEntry((prev) => ({ ...prev, ...update }));
    };

    const onInputUpdate = (key, value) => {
        setIsStartButtonDisabled(true);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onUpdateParticipant = (update) => {
        if (!('participantId' in update)) return;
        onUpdate(update);
        dispatch(loadParticipantEmployability(update.participantId));
        const entry = participants.find((el) => el.id === update.participantId);
        if (entry) {
            dispatch(setCurrentlySelectedParticipant(entry));
            setCurrentParticipant(entry);
        } else {
            dispatch(loadParticipant(update.participantId));
        }
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < usersBySearchMetaData.totalElements) {
            dispatch(
                loadUsersBySearch(
                    { serviceIds },
                    !users.length ? 0 : usersBySearchMetaData.number + 1
                )
            );
        }
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();
        const valid = validateUpdates(newEntry, dispatch);
        if (!valid) return;
        if (valid) {
            setIsSaveButtonDisabled(true);
            const {
                /* eslint-disable no-unused-vars */
                id,
                appointmentType,
                appointmentSlots,
                service,
                location,
                participant,
                participantId,
                participantIds,
                numberOfSlots,
                mandatory,
                ...rest
            } = newEntry;

            if (newEntry.appointmentSlots) {
                const participantIds = addedParticipants.map((el) => el.id);
                const payload = { ...rest, numberOfSlots, participantIds };
                dispatch(createAppointmentSlot(newEntry.appointmentType, payload));
            }
            if (!newEntry.appointmentSlots) {
                const payload = { ...rest, participantId, mandatory };
                dispatch(createAppointment(newEntry.appointmentType, payload));
            }
        }
    };

    // RENDER

    return (
        <Panel width="500px" open={openAppointmentSchedulerPanel} onToggle={onPanelClose}>
            <div className="appointment-scheduler">
                <form onSubmit={onSubmit}>
                    <>
                        <AppointmentTitleSelect
                            appointmentTypes={appointmentTypeDetailsForContracts}
                            appointmentType={newEntry.appointmentType}
                            onTitleChange={onUpdate}
                        />
                        <AppointmentSlotSwitch
                            checked={newEntry.appointmentSlots}
                            onChange={onUpdate}
                        />
                    </>

                    <AppointmentServiceSelect
                        serviceDetails={services}
                        serviceName={newEntry.service}
                        currentServiceId={newEntry.serviceId}
                        onServiceChange={onServiceChange}
                        disabled={!editable}
                    />
                    <div className="input-set">
                        <SingleSelect
                            id={'adviserId'}
                            label={'Adviser'}
                            placeholder="Select Adviser..."
                            disabled={!newEntry.service || !editable}
                            menuItems={advisers}
                            selectedId={newEntry.userId || ''}
                            selected={
                                newEntry.userId
                                    ? advisers?.find((el) => el.id === newEntry.userId) || {}
                                    : {}
                            }
                            onChange={onUpdateAdviser}
                            onLoadMoreItems={onLoadMoreAdvisers}
                            moreItemsToLoad={
                                !usersBySearchMetaData?.totalElements ||
                                users.length < usersBySearchMetaData?.totalElements
                            }
                        />
                    </div>
                    <AppointmentLocationSelect
                        locations={filteredLocations}
                        locationName={newEntry.location}
                        onLocationChange={onUpdate}
                        disabled={!newEntry.service}
                    />

                    <TimeSelect
                        startTime={newEntry.startTime}
                        endTime={newEntry.endTime}
                        onTimeChange={onUpdate}
                    />
                    <DateSelect
                        value={newEntry.date}
                        onDateChange={(date) => onInputUpdate('date', date)}
                    />

                    <div className="last-group">
                        {!newEntry.appointmentSlots ? (
                            <>
                                <AppointmentParticipantSelect
                                    participants={filteredParticipants}
                                    participant={newEntry.participant}
                                    onParticipantChange={onUpdateParticipant}
                                    disabled={!newEntry.service}
                                />

                                <MandatoryActivitiesSwitch
                                    checked={newEntry.mandatory}
                                    onSwitch={onUpdate}
                                    disabled={
                                        !allowParticipantMandatoryActivities && !newEntry.mandatory
                                    }
                                />
                            </>
                        ) : (
                            <div className="input-set capacity-input">
                                <label htmlFor="numberOfSlots"> Number of slots </label>
                                <TextField
                                    type="number"
                                    InputProps={{ inputProps: { min: 1, max: 99 } }}
                                    id="numberOfSlots"
                                    value={newEntry.numberOfSlots || 1}
                                    onChange={(e) =>
                                        setNewEntry((prev) => ({
                                            ...prev,
                                            numberOfSlots: e.target.value
                                        }))
                                    }
                                />
                            </div>
                        )}

                        <div className="appointmentSaveBtn">
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={isSaveButtonDisabled}
                                data-testid="testIdSubmitButton">
                                {SAVE}
                            </Button>
                        </div>

                        <StartAppointmentButton
                            show={showStartAppointment}
                            onClick={onPanelClose}
                            disabled={!editable || isStartButtonDisabled}
                            appointment={{
                                ...newCreatedAppointment,
                                appointmentType: newEntry.appointmentType
                            }}
                            participant={currentParticipant}
                        />
                    </div>
                </form>
            </div>
        </Panel>
    );
};

export default UserAppointmentCreate;

UserAppointmentCreate.propTypes = {
    newAppointment: PropTypes.object,
    currentCalendarOwner: PropTypes.object
};
