import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@mui/material';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../../api/pagination';
import {
    loadHoursPerWeekDetails,
    loadPlacementEmploymentTypeDetails,
    loadVacancyStageDetails
} from '../../../../store/directusService';
import {
    loadBusinessRecords,
    loadPlacementsForParticipant,
    loadVacanciesByOwners,
    loadVacancySubmissionsByParticipantId
} from '../../../../store/recruitmentService';
import { selectLoggedInUser, selectUsersForVacancies } from '../../../../store/userSelectors';
import { searchUsersForVacancies } from '../../../../store/userService';
import { getNameFromId } from '../../../../utils/directusFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../utils/userRoles';
import DynamicSearch from '../../../search/DynamicSearch';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import AddPlacementEditor from '../placements/placement/AddPlacementEditor';

import JobOffersTable from './JobOffersTable';

import classes from '../submissionStyles/joboffer.module.css';

const BUS_LOAD_SIZE = DEFAULT_PAGE_LOAD_SIZE;
const JobOffersTableManagement = () => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const participantClosedStatusRoles = [MANAGER, QUALITY, SUPERUSER];
    const [rows, setRows] = useState([]);
    const [jobOfferedParticipantVacancies, setJobOfferedParticipantVacancies] = useState([]);
    const [jobOfferedVacancies, setJobOfferedVacancies] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [placementEditor, setPlacementEditor] = useState(false);
    const [jobPlacementsLoaded, setJobPlacementsLoaded] = useState(false);
    const [businessesForJobsOffered, setBusinessesForJobsOffered] = useState([]);

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const usersForVacancies = useSelector(selectUsersForVacancies);
    const loggedInUser = useSelector(selectLoggedInUser);
    const { businesses, businessesMetaData, placements, vacanciesForOwners, vacancySubmissions } =
        useSelector((state) => state.entities.recruitmentService);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const {
        vacancyStageDetails,
        hoursPerWeekDetails,
        participantStatusDetails,
        placementEmploymentTypeDetails
    } = useSelector((state) => state.entities.directusService);

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // CALLBACKS
    const onPlacementEditor = (setOpen) => {
        setPlacementEditor(setOpen);
    };

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadVacancySubmissionsByParticipantId(currentParticipant.id));
        businesses?.length < 1
            ? dispatch(loadBusinessRecords(0, BUS_LOAD_SIZE))
            : setBusinessesForJobsOffered(businesses);
        hoursPerWeekDetails?.length < 1 && dispatch(loadHoursPerWeekDetails());
        placementEmploymentTypeDetails?.length < 1 &&
            dispatch(loadPlacementEmploymentTypeDetails());
        vacanciesForOwners?.length < 1 && dispatch(loadVacanciesByOwners());
        vacancyStageDetails?.length < 1 && dispatch(loadVacancyStageDetails());
        dispatch(loadPlacementsForParticipant(currentParticipant.id));
    }, []);

    useEffect(() => {
        if (!loggedInUser || Object.keys(loggedInUser).length < 1) return;
        usersForVacancies?.length < 1 &&
            loggedInUser?.serviceIds?.length > 0 &&
            dispatch(searchUsersForVacancies({ serviceIds: loggedInUser.serviceIds }));
    }, [loggedInUser.id]);

    useEffect(() => {
        if (businessesForJobsOffered?.length < 1) return;
        else if (!businessesMetaData.last) {
            dispatch(loadBusinessRecords(businessesMetaData.number + 1, BUS_LOAD_SIZE));
        } else setBusinessesForJobsOffered(businesses);
    }, [businesses, businessesMetaData]);

    useEffect(() => {
        if (
            vacanciesForOwners?.length < 1 ||
            !usersForVacancies ||
            usersForVacancies?.length < 1 ||
            !jobPlacementsLoaded
        ) {
            setRows([]);
            setJobOfferedParticipantVacancies([]);
            setJobOfferedVacancies([]);
            return;
        }

        const activeOwnerVacancies = vacanciesForOwners.map((el) => ({
            ...el,
            businessName:
                businessesForJobsOffered?.find((entry) => entry.id === el.businessRecordId)?.name ||
                '',
            adviser:
                usersForVacancies.find((entry) => entry.id === el?.ownerId)?.firstName +
                ' ' +
                usersForVacancies.find((entry) => entry.id === el?.ownerId)?.lastName,
            placementFound: !!placements?.find((entry) => entry.vacancyId === el.id)
        }));
        setJobOfferedParticipantVacancies(activeOwnerVacancies);
    }, [vacanciesForOwners, usersForVacancies, jobPlacementsLoaded]);

    useEffect(() => {
        if (jobOfferedParticipantVacancies.length < 1 || vacancySubmissions.length < 1) {
            return;
        }
        const jobOfferedStatusId =
            vacancyStageDetails.find((el) => el.name.toLowerCase() === 'job offered')?.id || '';

        const JobOfferedVacancy = jobOfferedParticipantVacancies
            .map((el) => {
                const foundVacancySubmission = vacancySubmissions.find(
                    (entry) =>
                        entry.participantId === currentParticipant.id &&
                        entry.vacancyId === el.id &&
                        entry.statusId === jobOfferedStatusId
                );
                if (foundVacancySubmission) {
                    return {
                        ...el,
                        vacancySubmissionStatusId: jobOfferedStatusId
                    };
                }
            })
            .filter((el) => el);

        if (JobOfferedVacancy.length > 0) {
            setRows([...rows, ...JobOfferedVacancy]);
            setJobOfferedVacancies([...jobOfferedVacancies, ...JobOfferedVacancy]);
        }
    }, [jobOfferedParticipantVacancies, vacancySubmissions]);

    useEffect(() => {
        if (successMessage === `Job placements have been loaded`) {
            setJobPlacementsLoaded(true);
        }
    }, [successMessage]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.title.toLowerCase().includes(searchTerm.toLowerCase()));
        setJobOfferedVacancies(filteredRows);
    }, [searchTerm]);

    // RENDER
    let content = '';
    if (businesses?.length < 1) content = 'No businesses found';
    if (hoursPerWeekDetails?.length < 1) content = 'No hours per week details';
    if (placementEmploymentTypeDetails?.length < 1)
        content = 'No placement employment type details';
    if (vacanciesForOwners?.length < 1) content = 'No vacancy fow owner details';
    if (vacancyStageDetails?.length < 1) content = 'No vacancy stage details';
    if (vacancySubmissions?.length < 1) content = 'No vacancy submissions';
    if (
        businesses?.length < 1 ||
        hoursPerWeekDetails?.length < 1 ||
        placementEmploymentTypeDetails?.length < 1 ||
        vacanciesForOwners?.length < 1 ||
        vacancyStageDetails?.length < 1 ||
        vacancySubmissions?.length < 1 ||
        !jobPlacementsLoaded
    )
        return <LoadingSpinner content={content} />;

    return (
        <>
            <Typography className={classes.jobOfferTitle} variant={'h5'}>
                Job Offers
            </Typography>
            <div className={classes.jobOfferToolBar}>
                <DynamicSearch
                    disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter Search Term"
                />
            </div>
            <AddPlacementEditor
                open={placementEditor}
                roles={
                    loggedInUser.roles ||
                    (getNameFromId(participantStatusDetails, currentParticipant.statusId) ===
                        'Closed' &&
                        !hasRole(participantClosedStatusRoles, loggedInUser.roles))
                }
                onPlacementEditor={onPlacementEditor}
                placementEmploymentTypeDetails={placementEmploymentTypeDetails}
                hoursPerWeekDetails={hoursPerWeekDetails}
            />

            {jobOfferedVacancies.length < 1 ? (
                <LoadingSpinner content="No job offers found" />
            ) : (
                <JobOffersTable
                    rows={jobOfferedVacancies}
                    roles={loggedInUser.roles}
                    searchTerm={searchTerm}
                    onPlacementEditor={onPlacementEditor}
                />
            )}
        </>
    );
};

export default JobOffersTableManagement;
