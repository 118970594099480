import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { searchActiveCourses } from '../../../store/courseService';
import { selectBarrierDetails } from '../../../store/dataSelectors';
import { loadBarrierNameDetails } from '../../../store/directusService';
import { selectCurrentParticipantBarriers } from '../../../store/participantSelectors';
import { loadParticipantBarriers } from '../../../store/participantService';
import { POLARIS_WHITE } from '../../../themes/theme';
import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CurrentCourseTableRow from './CurrentCourseTableRow';

const headCells = [
    { id: 'id', numeric: false, label: 'ID' },
    { id: 'name', numeric: false, label: 'Name' },
    { id: 'startDate', numeric: false, label: 'Start Date' },
    { id: 'endDate', numeric: false, label: 'End Date' },
    { id: 'modules', numeric: false, label: 'Modules' }
];

const CurrentCourseManagement = ({
    activeCourses,
    activeCoursesSearchData,
    activeCoursesMetaData,
    loadingActiveCourses
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [filteredActiveCourses, setFilteredActiveCourses] = useState([]);
    const [openId, setOpenId] = useState('');
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const { currentParticipant } = useSelector((state) => state.entities.participantService);
    const currentParticipantBarriers = useSelector(selectCurrentParticipantBarriers);
    const barrierNameDetails = useSelector(selectBarrierDetails);

    // USE EFFECTS
    useEffect(() => {
        if (
            !currentParticipantBarriers?.length ||
            currentParticipantBarriers?.participantId !== currentParticipant.id
        )
            dispatch(loadParticipantBarriers(currentParticipant.id));

        if (barrierNameDetails?.length < 1) dispatch(loadBarrierNameDetails());
    }, []);

    useEffect(() => {
        if (activeCourses.length < 1) return;
        const filteredActiveCourses = activeCourses.filter((el) =>
            el.modules.some((entry) =>
                entry.participants.some((pt) => pt.participantId === currentParticipant.id)
            )
        );
        setFilteredActiveCourses(filteredActiveCourses);
    }, [activeCourses]);

    // HELPER FNS
    const createRows = () =>
        stableSort(filteredActiveCourses, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <CurrentCourseTableRow
                key={el.id}
                row={el}
                toggleDropdown={(id) => setOpenId(id)}
                openId={openId}
            />
        ));

    // RENDER
    return (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Current Courses'}></FormHeader>
                    {activeCoursesMetaData.last && filteredActiveCourses.length < 1 ? (
                        <LoadingSpinner content="No current courses found" />
                    ) : (
                        <ResultsTable
                            defaultOrderBy={'name'}
                            defaultRowsPerPage={rowMetaData.rowsPerPage}
                            apiParams={activeCoursesSearchData}
                            headCells={headCells}
                            loadingValues={loadingActiveCourses}
                            loadResults={searchActiveCourses}
                            passRowMetaDataUp={setRowMetaData}
                            tableRows={createRows()}
                            totalResults={activeCoursesMetaData.totalElements}
                            metaData={activeCoursesMetaData}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

CurrentCourseManagement.propTypes = {
    activeCourses: PropTypes.array,
    activeCoursesSearchData: PropTypes.object,
    activeCoursesMetaData: PropTypes.object,
    loadingActiveCourses: PropTypes.bool
};

export default CurrentCourseManagement;
