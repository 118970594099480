import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardContent } from '@mui/material';

import { loadCourseProvisions } from '../../../store/courseService';
import { loadAllExternalCourseProviderDetails } from '../../../store/directusService';
import { POLARIS_WHITE } from '../../../themes/theme';
import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import FormHeader from '../../layout/FormHeader';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import CurrentProvisionTableRow from './CurrentProvisionTableRow';

const headCells = [
    { id: 'id', numeric: false, label: 'ID' },
    { id: 'provisionName', numeric: false, label: 'Provision Name' },
    { id: 'providerName', numeric: false, label: 'Provider Name' },
    { id: 'status', numeric: false, label: 'Status' }
];

const CurrentProvisionManagement = ({
    courseProvisions,
    courseProvisionsMetaData,
    loadingProvisions
}) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [filteredCourseProvisions, setFilteredCourseProvisions] = useState([]);
    const [openId, setOpenId] = useState('');
    const [rowMetaData, setRowMetaData] = useState({
        order: 'asc',
        orderBy: 'name',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const { externalCourseProviderDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const { currentParticipant } = useSelector((state) => state.entities.participantService);

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadAllExternalCourseProviderDetails());
    }, []);

    useEffect(() => {
        if (courseProvisions.length < 1 || !externalCourseProviderDetails.length < 1) return;
        const filteredCourseProvisions = courseProvisions
            .filter((el) =>
                el.participants.some((pt) => pt.participantId === currentParticipant.id)
            )
            .map((el) => ({
                ...el,
                provisionName: el.title,
                providerName:
                    externalCourseProviderDetails.find((entry) => entry.id === el.nameId)?.name ||
                    '',
                statusId:
                    el.participants.find((entry) => entry.participantId === currentParticipant.id)
                        ?.statusId || ''
            }));
        setFilteredCourseProvisions(filteredCourseProvisions);
    }, [courseProvisions, externalCourseProviderDetails]);

    // HELPER FNS
    const createRows = () =>
        stableSort(filteredCourseProvisions, rowMetaData.orderBy, rowMetaData.order).map((el) => (
            <CurrentProvisionTableRow
                key={el.id}
                row={el}
                toggleDropdown={(id) => setOpenId(id)}
                openId={openId}
            />
        ));

    // RENDER
    return (
        <Card sx={{ backgroundColor: POLARIS_WHITE }} data-testid="form_start">
            <CardContent>
                <div>
                    <FormHeader text={'Current Provisions'}></FormHeader>
                    {courseProvisionsMetaData.last && filteredCourseProvisions.length < 1 ? (
                        <LoadingSpinner content="No current provisions found" />
                    ) : (
                        <ResultsTable
                            defaultOrderBy={'name'}
                            defaultRowsPerPage={rowMetaData.rowsPerPage}
                            headCells={headCells}
                            loadingValues={loadingProvisions}
                            loadResults={loadCourseProvisions}
                            passRowMetaDataUp={setRowMetaData}
                            tableRows={createRows()}
                            totalResults={courseProvisionsMetaData.totalElements}
                            metaData={courseProvisionsMetaData}
                        />
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

CurrentProvisionManagement.propTypes = {
    courseProvisions: PropTypes.array,
    courseProvisionsMetaData: PropTypes.object,
    loadingProvisions: PropTypes.bool
};

export default CurrentProvisionManagement;
