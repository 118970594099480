import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUsersAppointments } from '../../store/calendarSelectors';
import {
    loadParticipantAppointments,
    loadParticipantAppointmentSlots,
    updateAppointment,
    updateAppointmentSlot
} from '../../store/calendarService';
import {
    loadUserAppointments,
    loadUserAppointmentSlots,
    setCurrentAppointment,
    setOpenAppointmentSchedulerPanel
} from '../../store/calendarService';
import { loadCourseModulesByUserId, loadCoursesByParticipantId } from '../../store/courseService';
import {
    loadAppointmentTypeDetails,
    loadAttendanceDetails,
    loadContractDetailsForContractId,
    loadLocationIdsAndNames,
    loadServiceDetailsByService,
    loadServiceDetailsForContractId
} from '../../store/directusService';
import { selectCurrentParticipantsName } from '../../store/participantSelectors';
import {
    searchAppointmentAttendanceDetails,
    setCurrentlySelectedParticipant
} from '../../store/participantService';
import { clearUsersBySearch, loadUsersBySearch } from '../../store/userService';
import { POLARIS_BLACK, POLARIS_GREY, POLARIS_ROYAL_BLUE, POLARIS_WHITE } from '../../themes/theme';
import { addFullNameToArray } from '../../utils/userArrayUtils';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../utils/userRoles';
import SingleSelect from '../formElements/SingleSelect';
import Calendar from '../ui/calendar/Calendar';
import {
    configCourseEventContent,
    configParticipantEventContent,
    formatFullCalendarTimes,
    getBusinessHours,
    mapColors,
    mapCommonParams
} from '../ui/calendar/calendarUtils';

import ParticipantAppointmentCreate from './ParticipantAppointmentCreate';
import ParticipantAppointmentEdit from './ParticipantAppointmentEdit';

import classes from '../../app.module.css';

let count = 0;

const ParticipantCalendar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSelectUsersAppointments = useMemo(selectUsersAppointments, []);
    // LOCAL STATE
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const allowedAdviserRoles = [ADVISER, MANAGER, RECRUITMENT_MANAGER];
    const [currentCalendarOwner, setCurrentCalendarOwner] = useState({});
    const [hiddenEvents, setHiddenEvents] = useState([]);
    const [events, setEvents] = useState([]);
    const [newEvent, setNewEvent] = useState({});
    const [currentEvent, setCurrentEvent] = useState({});
    const [advisers, setAdvisers] = useState([]);

    // STORE STATE
    const usersAppointments = useSelector((state) =>
        onSelectUsersAppointments(state, currentCalendarOwner?.id)
    );
    const {
        usersBySearch: users,
        usersBySearchMetaData,
        loggedInUser
    } = useSelector((state) => state.entities.userService);
    const currentParticipant = useSelector(
        (state) => state.entities.participantService.currentParticipant
    );
    const participantsName = useSelector(selectCurrentParticipantsName);
    const {
        usersCurrentAppointment,
        allAppointments,
        participantAppointments,
        participantAppointmentSlots,
        openAppointmentSchedulerPanel
    } = useSelector((state) => state.entities.calendarService);
    const { courseModules, participantsCourses } = useSelector(
        (state) => state.entities.courseService
    );
    const {
        locationIdsAndNames: locations,
        appointmentTypes,
        attendanceDetails
    } = useSelector((state) => state.entities.directusService);

    // CONFIG FNS
    const configParticipantEvents = () => {
        const businessHours = getBusinessHours();
        let appEvents;
        if (participantAppointments?.length < 1 && participantAppointmentSlots?.length < 1)
            appEvents = [{}];
        else
            appEvents = [...participantAppointments, ...participantAppointmentSlots].map((el) => {
                const { date, start, end, location } = mapCommonParams(el, locations);
                const { appointmentType, slots, filledSlots } = configParticipantEventContent(
                    el,
                    appointmentTypes
                );
                const { color, textColor } = mapColors(el.id, usersCurrentAppointment?.id);
                const editable = el.id === usersCurrentAppointment?.id;
                const hasPermission =
                    !slots &&
                    (hasRole(acceptedRoles, loggedInUser.roles) || loggedInUser.id === el.userId);
                return {
                    id: el.id,
                    eventType: 'user',
                    hasLink: true,
                    className: !hasPermission ? 'non-click-event' : '',
                    slots,
                    filledSlots,
                    location,
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    participantEntry: currentParticipant,
                    participant: participantsName,
                    appointmentType,
                    hasPermission,
                    date,
                    start,
                    end,
                    color,
                    textColor,
                    constraint: businessHours,
                    editable
                };
            });

        const modules = participantsCourses?.map((el) => el.modules).flat();
        let moduleEvents;
        if (modules?.length < 1) moduleEvents = [];
        else {
            moduleEvents = modules?.map((el) => {
                // can't interact with modules - only appointments.
                const { date, start, end, location } = mapCommonParams(el, locations);
                const content = configCourseEventContent(el, participantsCourses, location);
                return {
                    eventType: 'courseModules',
                    className: 'non-interactive-events',
                    content,
                    timeContent: `${start.slice(-5)} - ${end.slice(-5)}`,
                    editable: false,
                    date,
                    start,
                    end,
                    color: POLARIS_ROYAL_BLUE,
                    textColor: POLARIS_WHITE
                };
            });
        }
        setEvents([...appEvents, ...moduleEvents]);
        if (appEvents?.length > 0) {
            const cur = appEvents?.find((el) => el.id === usersCurrentAppointment?.id);
            if (cur) setCurrentEvent(cur);
        }
    };

    const configUserHiddenEvents = () => {
        const filteredModules = courseModules?.filter(
            (el) => !el.participants.some((entry) => entry.participantId === currentParticipant.id)
        );
        let filteredAppointments = usersAppointments
            ?.filter((el) => el.participantId !== currentParticipant.id)
            ?.filter((el) => !el.participantIds?.includes(currentParticipant.id));
        const allUserAppointments = [...filteredAppointments, ...filteredModules];
        let events;
        if (allUserAppointments?.length < 1) events = [];
        else
            events = allUserAppointments?.map((el) => {
                const { date, start, end } = mapCommonParams(el);
                return {
                    eventType: 'hidden',
                    className: 'busy-events',
                    content: `${start.slice(-5)} - ${end.slice(-5)} Busy`,
                    editable: false,
                    color: POLARIS_GREY,
                    textColor: POLARIS_BLACK,
                    date,
                    start,
                    end
                };
            });
        setHiddenEvents(events);
    };

    const fetchAdviserData = () => {
        if (currentCalendarOwner?.id) {
            if (!allAppointments[currentCalendarOwner.id]) {
                dispatch(loadUserAppointments(currentCalendarOwner.id));
                dispatch(loadUserAppointmentSlots(currentCalendarOwner.id));
            }
            dispatch(loadCourseModulesByUserId(currentCalendarOwner.id));
        }
    };

    // HELPER FNS
    const setAdvisersArray = (advisers) => {
        const updatedAdvisers = addFullNameToArray(advisers);
        setAdvisers(updatedAdvisers);
    };

    // USE EFFECTS
    useEffect(() => {
        appointmentTypes?.length < 1 && dispatch(loadAppointmentTypeDetails());
        locations?.length < 1 && dispatch(loadLocationIdsAndNames());
        if (attendanceDetails?.length < 1) dispatch(loadAttendanceDetails());
        fetchAdviserData();
        dispatch(clearUsersBySearch());
    }, []);

    useEffect(() => {
        setCurrentCalendarOwner(loggedInUser);
        setAdvisersArray([loggedInUser]);
    }, [loggedInUser.id]);

    useEffect(() => {
        if (Object.keys(currentParticipant)?.length < 1) return;
        dispatch(loadServiceDetailsByService([currentParticipant.serviceId]));
        dispatch(loadCoursesByParticipantId(currentParticipant.id));
        dispatch(loadParticipantAppointments(currentParticipant.id));
        dispatch(loadParticipantAppointmentSlots(currentParticipant.id));
    }, [currentParticipant]);

    useEffect(() => {
        configUserHiddenEvents();
        configParticipantEvents();
    }, [
        usersAppointments,
        courseModules,
        participantsCourses,
        participantAppointments,
        participantAppointmentSlots,
        locations,
        appointmentTypes
    ]);

    useEffect(() => {
        if (!usersCurrentAppointment?.id) {
            setNewEvent({});
            setCurrentEvent({});
        } else {
            dispatch(
                searchAppointmentAttendanceDetails({
                    calendarServiceAppointmentIds: [usersCurrentAppointment.id]
                })
            );
        }
        configParticipantEvents();
    }, [usersCurrentAppointment]);

    useEffect(() => {
        fetchAdviserData();
    }, [currentCalendarOwner.id]);

    useEffect(() => {
        if (!users.length) return;
        let updatedAdvisers = users.filter(
            (el) =>
                el.active && el.userTypes.find((entry) => allowedAdviserRoles.includes(entry.role))
        );
        if (!updatedAdvisers.length && users.length < usersBySearchMetaData.totalElements) {
            onLoadMoreAdvisers();
            return;
        }
        if (
            currentCalendarOwner.id &&
            !updatedAdvisers.some((el) => el.id === currentCalendarOwner.id)
        ) {
            // Put selected adviser at the top of dropdown if it's not in the updated advisers array
            const selectedAdvisor = advisers.find((el) => el.id === currentCalendarOwner.id);
            updatedAdvisers = [selectedAdvisor, ...updatedAdvisers];
        }
        setAdvisersArray(updatedAdvisers);
    }, [users]);

    // EVENT HANDLERS
    const onEditTimes = (time, update) => {
        if (update) {
            const { date, startTime, endTime } = formatFullCalendarTimes(time);
            const updatedAppointment = { ...usersCurrentAppointment, date, startTime, endTime };
            currentEvent.slots
                ? dispatch(
                      updateAppointmentSlot(
                          updatedAppointment.userId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  )
                : dispatch(
                      updateAppointment(
                          updatedAppointment.userId,
                          currentEvent.appointmentType,
                          updatedAppointment
                      )
                  );
        }
        dispatch(setCurrentAppointment({}));
    };

    const onTogglePanel = (open) => {
        dispatch(setOpenAppointmentSchedulerPanel(open));
    };

    const onEventSelect = (id) => {
        setNewEvent({});
        if (id !== usersCurrentAppointment?.id) {
            const selectedAppointment = participantAppointments.find((el) => el.id === id);
            selectedAppointment && dispatch(setCurrentAppointment(selectedAppointment));
        }
        if (id === currentEvent.id) {
            count++;
            setTimeout(() => (count = 0), 300);
            if (count > 1) dispatch(setOpenAppointmentSchedulerPanel(true));
        }
    };

    const onSlotSelect = (start, end) => {
        const { date, startTime, endTime } = formatFullCalendarTimes({ start, end });
        setNewEvent({ date, startTime, endTime });
        onTogglePanel(true);
    };

    const onUpdateAdviser = (chosenId) => {
        if (!chosenId) {
            if (currentCalendarOwner.id !== loggedInUser.id) {
                setCurrentCalendarOwner(loggedInUser);
            }
        } else if (chosenId && chosenId !== currentCalendarOwner.id) {
            const chosenAdviser = advisers.find((el) => el.id === chosenId);
            setCurrentCalendarOwner({
                ...chosenAdviser,
                contractIds: chosenAdviser.contracts,
                serviceIds: chosenAdviser.serviceIds || [
                    chosenAdviser.primaryService,
                    ...chosenAdviser.otherServices
                ]
            });
        }
    };

    const onNav = (selectedEvent) => {
        dispatch(setCurrentlySelectedParticipant(selectedEvent.participantEntry));
        dispatch(loadContractDetailsForContractId(selectedEvent.participantEntry.contractId));
        dispatch(loadServiceDetailsForContractId(selectedEvent.participantEntry.contractId));
        navigate('/edit_participant');
    };

    const onLoadMoreAdvisers = () => {
        if (!users.length || users.length < usersBySearchMetaData.totalElements) {
            dispatch(
                loadUsersBySearch(
                    { serviceIds: [currentParticipant.serviceId] },
                    !users.length ? 0 : usersBySearchMetaData.number + 1
                )
            );
        }
    };

    // RENDER

    return (
        <div className={classes.container}>
            <div className="calendar-wrapper">
                <div className="toolbar-search">
                    <SingleSelect
                        id={'adviserId'}
                        label={'Adviser/Coach'}
                        placeholder="Select Adviser/Coach..."
                        menuItems={advisers}
                        selectedId={currentCalendarOwner.id || ''}
                        selected={
                            currentCalendarOwner.id
                                ? advisers?.find((el) => el.id === currentCalendarOwner.id) || {}
                                : {}
                        }
                        onChange={onUpdateAdviser}
                        onLoadMoreItems={onLoadMoreAdvisers}
                        moreItemsToLoad={
                            !usersBySearchMetaData?.totalElements ||
                            users.length < usersBySearchMetaData?.totalElements
                        }
                    />
                </div>

                <Calendar
                    /* eslint-disable-next-line no-constant-binary-expression */
                    events={[...events, ...hiddenEvents] || []}
                    currentEvent={currentEvent}
                    onEditTimes={onEditTimes}
                    selectSlot={onSlotSelect}
                    selectEvent={onEventSelect}
                    allowSlotSelect={true}
                    returnButton={false}
                    initialView="timeGridDay"
                    onNav={onNav}
                />
                {openAppointmentSchedulerPanel &&
                    currentCalendarOwner &&
                    (Object.keys(newEvent).length ? (
                        <ParticipantAppointmentCreate
                            newAppointment={newEvent}
                            currentCalendarOwner={currentCalendarOwner}
                        />
                    ) : (
                        <ParticipantAppointmentEdit
                            usersCurrentAppointment={usersCurrentAppointment}
                            currentLocation={currentEvent.location}
                            currentAppointmentType={currentEvent.appointmentType}
                            currentParticipantEntry={currentEvent.participantEntry}
                        />
                    ))}
            </div>
        </div>
    );
};

export default ParticipantCalendar;
