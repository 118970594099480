import React from 'react';

import classes from './smartDefs.module.css';

const SmartDefs = () => (
    <div className={classes.smartDefs}>
        <p>Write an action below using the SMART system</p>
        <p>
            <span>S:</span> What is the action?
        </p>
        <p>
            <span>M:</span> What are the measures of success?
        </p>
        <p>
            <span>A:</span> Is the action achievable in complexity and time given?
        </p>
        <p>
            <span>R:</span> Is the action relevant to the barrier it aligns to?
        </p>
        <p>
            <span>T:</span> When is the action due?
        </p>
    </div>
);

export default SmartDefs;
