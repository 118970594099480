import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '@mui/material';

import { DEFAULT_PAGE_LOAD_SIZE } from '../../../api/pagination';
import { loadCourseProvisions } from '../../../store/courseService';
import { loadAllExternalCourseProviderDetails } from '../../../store/directusService';
import { stableSort } from '../../../utils/sortFunctions';
import { DEFAULT_TABLE_ROWS_PER_PAGE } from '../../../utils/uiConstants';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import DynamicSearch from '../../search/DynamicSearch';
import ResultsTable from '../../table/ResultsTable';
import LoadingSpinner from '../../ui/LoadingSpinner';

import ProvisionTableRow from './ProvisionTableRow';

import classes from '../courseStyles/courseToolbar.module.css';

/**
 * Manage provisions
 * See PPL-473 for details.
 * As far as possible all UI code is in CourseProvisionManagementUI
 * @returns {JSX.Element}
 */

const loadPageSize = DEFAULT_PAGE_LOAD_SIZE;
const headCells = [
    { id: 'title', label: 'Provision Name', sortable: true },
    { id: 'providerName', label: 'Provider Name', sortable: true },
    { id: 'providerContactName', label: 'Provider Contact', sortable: true },
    { id: 'costPerParticipant', label: 'Cost', sortable: true },
    { id: 'reviewDate', label: 'Review Date', sortable: true },
    { id: 'dateCreated', label: 'Date Added', sortable: true },
    { id: 'submissionList', label: '' },
    { id: 'edit', label: '' }
];

const CourseProvisionManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [provisionsRowMetaData, setProvisionsRowMetaData] = useState({
        order: 'desc',
        orderBy: 'reviewDate',
        page: 0,
        rowsPerPage: DEFAULT_TABLE_ROWS_PER_PAGE
    });

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { courseProvisions, courseProvisionsMetaData, loadingProvisions } = useSelector(
        (state) => state.entities.courseService
    );
    const { externalCourseProviderDetails } = useSelector(
        (state) => state.entities.directusService
    );

    // USE EFFECTS
    useEffect(() => {
        dispatch(loadCourseProvisions(0, loadPageSize));
        dispatch(loadAllExternalCourseProviderDetails());
    }, []);

    useEffect(() => {
        if (!courseProvisions?.length || !externalCourseProviderDetails?.length) return;
        const getProviderContactName = (nameId) => {
            const entry = externalCourseProviderDetails.find((el) => el.id === nameId);
            return entry
                ? `${entry.facilitator_contact_firstnames} ${entry.facilitator_contact_lastname}`
                : '';
        };

        if (courseProvisions?.length !== 0) {
            const rows = courseProvisions
                .filter((el) => el.active)
                .map((el) => ({
                    ...el,
                    providerName:
                        externalCourseProviderDetails.find((entry) => entry.id === el.nameId)
                            ?.name || '',
                    providerContactName: getProviderContactName(el.nameId)
                }));
            setRows(rows);
            setFilteredRows(rows);
        }
    }, [courseProvisions, externalCourseProviderDetails]);

    useEffect(() => {
        const filteredRows = !searchTerm
            ? rows
            : rows.filter((el) => el.title.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredRows(filteredRows);
    }, [searchTerm]);

    // EVENT HANDLERS
    const onCreateProvision = () => navigate('/create_provision');

    const onSearch = (searchRes) => setSearchTerm(searchRes);

    // HELPER FNS
    const createRows = () =>
        stableSort(filteredRows, provisionsRowMetaData.orderBy, provisionsRowMetaData.order).map(
            (el) => <ProvisionTableRow key={el.id} row={el} roles={roles} />
        );

    // RENDER
    return (
        <>
            <div className={classes.coursesToolBar}>
                <DynamicSearch
                    search={onSearch}
                    searchTerm={searchTerm}
                    placeholder="Enter provision name"
                />
                <Button
                    disabled={!hasRole([SUPERUSER, ADVISER, MANAGER, QUALITY], roles)}
                    color="primary"
                    variant="contained"
                    size={'small'}
                    onClick={onCreateProvision}>
                    Create Provision
                </Button>
            </div>

            {courseProvisionsMetaData.last && rows.length < 1 ? (
                <LoadingSpinner content="No provisions found" />
            ) : (
                <ResultsTable
                    defaultOrderBy={'reviewDate'}
                    sortOrder={'desc'}
                    loadPageSize={loadPageSize}
                    defaultRowsPerPage={provisionsRowMetaData.rowsPerPage}
                    headCells={headCells}
                    loadingValues={loadingProvisions}
                    loadResults={loadCourseProvisions}
                    passRowMetaDataUp={setProvisionsRowMetaData}
                    tableRows={createRows()}
                    totalResults={courseProvisionsMetaData.totalElements}
                    metaData={courseProvisionsMetaData}
                />
            )}
        </>
    );
};

export default CourseProvisionManagement;
