import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { createCourse } from '../../../store/courseService';
import { selectLocationsForServices } from '../../../store/dataSelectors';
import {
    loadLocationsForServices,
    loadServiceDetailsByContract
} from '../../../store/directusService';
import { selectLoggedInUser } from '../../../store/userSelectors';
import { clearKeys } from '../../../utils/objectUtils';
import { ADVISER, hasRole, MANAGER, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import MultiSelect from '../../formElements/MultiSelect';
import SingleSelect from '../../formElements/SingleSelect';
import TextAreaField from '../../formElements/TextAreaField';
import TextInputField from '../../formElements/TextInputField';
import HealthInfo from '../../healthInfo/HealthInfo';
import FormActions from '../../ui/formActions/FormActions';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ScheduleModuleAttendanceView from '../courseCards/ScheduleModuleAttendanceView';

import { initialErrorState, validate } from './validateCourseInstance';

import app from '../../../app.module.css';
import cardClasses from '../../../commonStyles/cardContainer.module.css';
import form from '../../../commonStyles/formStyles.module.css';

const CHARACTER_LIMIT = 250;

const CreateCourse = () => {
    // HOOKS
    const { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // LOCAL STATE
    const initialState = {
        id: null,
        name: '',
        description: '',
        courseProvider: 'internal',
        serviceIds: [],
        courseTemplateId: '',
        code: '',
        modules: [],
        locationId: ''
    };

    const acceptedRoles = [ADVISER, MANAGER, QUALITY, SUPERUSER];
    const [newEntry, setNewEntry] = useState(initialState);
    const [keys, setKeys] = useState({ serviceIds: '0' });
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const loggedInUser = useSelector(selectLoggedInUser);
    const successMessage = useSelector((state) => state.entities.formsState.successMessage);
    const serviceDetailsByContract = useSelector(
        (state) => state.entities.directusService.serviceDetails
    );
    const locationsForServices = useSelector(selectLocationsForServices);
    const { currentTemplate, currentCourse } = useSelector((state) => state.entities.courseService);

    // USE EFFECTS
    useEffect(() => {
        if (state?.id) setNewEntry((prev) => ({ ...prev, courseTemplateId: state.id }));
    }, []);

    useEffect(() => {
        if (currentTemplate && Object.keys(currentTemplate).length !== 0) {
            if (currentTemplate.contractIds?.length > 0) {
                dispatch(loadServiceDetailsByContract(currentTemplate.contractIds));
            }
            const modules = currentTemplate.moduleTemplates?.map((el) => ({
                title: el.name,
                description: el.description,
                courseId: currentCourse.id,
                completionOrder: el.completionOrder,
                startTime: null,
                endTime: null,
                locationId: newEntry.locationId,
                date: null,
                capacity: null,
                smartTarget: el.smartTarget || null
            }));
            setNewEntry((prev) => ({
                ...prev,
                name: currentTemplate.name,
                description: currentTemplate.description,
                modules
            }));
        }
    }, [currentTemplate]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === `Course details for ${newEntry.name} have been added`) {
            onNavigate();
        }
    }, [successMessage]);

    // HELPER FNS
    const loadingError = () => {
        if (serviceDetailsByContract?.length < 1) return `No service details available`;
        if (!currentTemplate) return `The current template hasn't loaded`;
        else if (Object.keys(currentTemplate)?.length < 1) return `The current template is empty`;
    };

    const onNavigate = () => {
        clearData();
        if (state?.accordionPanel)
            if (state.accordionPanel === 'allTemplates') navigate('/all_active_course_templates');
            else
                navigate('/course_management', { state: { accordionPanel: state.accordionPanel } });
        else navigate('/course_management');
    };

    const onCancel = () => {
        onNavigate();
    };

    const clearData = () => {
        setNewEntry(initialState);
        setKeys(clearKeys(keys));
    };

    const clearError = (key) => {
        setErrors((prev) => ({ ...prev, [key]: { ...errors[key], error: false } }));
    };

    // EVENT HANDLERS
    const onNameChange = (e) => {
        clearError('name');
        setNewEntry((prev) => ({ ...prev, name: e.target.value }));
    };
    const onDescriptionChange = (e) => {
        clearError('description');
        setNewEntry((prev) => ({ ...prev, description: e.target.value }));
    };
    const onServicesChange = (chosenIds) => {
        clearError('serviceIds');
        chosenIds?.length > 0 && dispatch(loadLocationsForServices(chosenIds));
        setNewEntry((prev) => ({ ...prev, serviceIds: chosenIds, locationId: '' }));
    };

    const onLocationChange = (chosenId) => {
        clearError('locationId');
        if (chosenId == null) return;
        setNewEntry((prev) => ({
            ...prev,
            locationId: chosenId || ''
        }));
    };

    // FORM SUBMIT
    const onSubmit = (e) => {
        e.preventDefault();

        const newErrors = validate(newEntry, errors);
        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }
        const { locationId, modules, ...rest } = newEntry;
        const payload = {
            ...rest,
            locationId,
            modules: modules.map((el) => ({ ...el, locationId }))
        };
        setIsDisabled(true);
        dispatch(createCourse(payload));
    };

    // AWAITING CONTENT
    const errorMsg = loadingError();
    if (errorMsg) return <LoadingSpinner content={errorMsg} />;

    // RENDER
    return (
        <div className={form.formWrapper}>
            <form onSubmit={onSubmit} data-testid="form_start" className={form.form}>
                <h1 className={app.mainHeading}>Create Course</h1>
                <h2 className={app.sectionHeading}>Course Details:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <TextInputField
                            id={'courseName'}
                            label={'Course Name'}
                            placeholder={'Enter course name'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            maxLength={100}
                            value={newEntry.name || ''}
                            error={errors.name}
                            onChange={onNameChange}
                        />
                        <TextAreaField
                            id={'courseDescription'}
                            label={'Course Description'}
                            placeholder={'Enter course description'}
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            value={newEntry.description || ''}
                            count={`${newEntry.description.length}/${CHARACTER_LIMIT}`}
                            maxLength={CHARACTER_LIMIT}
                            rows={6}
                            error={errors.description}
                            onChange={onDescriptionChange}
                        />
                    </div>
                    <div className={form.formColumn}>
                        <MultiSelect
                            id="serviceIds"
                            key={keys.serviceIds}
                            label="Search Services"
                            placeholder="Search service..."
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                            mandatory={true}
                            menuItems={serviceDetailsByContract || []}
                            preSelectedIds={newEntry.serviceIds || []}
                            preSelects={
                                serviceDetailsByContract.filter((el) =>
                                    newEntry.serviceIds.includes(el.id)
                                ) || []
                            }
                            error={errors.serviceIds}
                            onChange={(chosenIds) => onServicesChange(chosenIds)}
                        />
                        {currentTemplate.healthInformationCaptured &&
                            currentTemplate.healthInformationIds.length > 0 && (
                                <HealthInfo
                                    healthInformationCaptured={true}
                                    healthInformationIds={currentTemplate.healthInformationIds}
                                />
                            )}

                        <SingleSelect
                            id={'locationId'}
                            label={
                                'Course Location (Locations can be set per module once Course has been created)'
                            }
                            placeholder="Search locations..."
                            disabled={
                                !hasRole(acceptedRoles, loggedInUser.roles) ||
                                newEntry.serviceIds.length < 1
                            }
                            mandatory={true}
                            menuItems={locationsForServices || []}
                            selected={
                                locationsForServices.find((el) => el.id === newEntry.locationId) ||
                                {}
                            }
                            selectedId={newEntry.locationId || ''}
                            error={errors.locationId}
                            onChange={(chosenId) => onLocationChange(chosenId)}
                        />
                    </div>
                </div>
                <h2 className={app.sectionHeading}>Schedule Module:</h2>
                <div className={form.formSection}>
                    <div className={form.formColumn}>
                        <div className={cardClasses.cardContainer}>
                            <div className={cardClasses.cards}>
                                {currentTemplate.moduleTemplates.map((el) => (
                                    <div key={el.id}>
                                        <ScheduleModuleAttendanceView
                                            course={currentCourse}
                                            module={el}
                                            prevPage="createCourse"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <FormActions
                            id="publish"
                            btnText="Publish"
                            disabled={!hasRole(acceptedRoles, loggedInUser.roles) || isDisabled}
                            onClose={onSubmit}
                            onCancel={onCancel}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateCourse;
