import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';

import { setErrorMessage } from '../../../../../store/formsState';
import { createPoolApplication } from '../../../../../store/recruitmentService';
import { selectLoggedInUser } from '../../../../../store/userSelectors';
import { countDays } from '../../../../../utils/dateFunctions';
import { getNoUpdateMessage } from '../../../../../utils/formValidation/errorMessageFunctions';
import {
    ADVISER,
    hasRole,
    MANAGER,
    QUALITY,
    RECRUITMENT_MANAGER,
    SUPERUSER
} from '../../../../../utils/userRoles';
import DDLOptionPicker from '../../../../ui/pickers/DDLOptionPicker';

import SelectPoolsTableManagement from './SelectPoolsTableManagement';

import classes from '../../submissionStyles/pools.module.css';

const ApplyToPoolEditor = ({ open, onPoolEditor, participantDocuments, poolApplications }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    // State for create pool newEntry
    // This matches the payload sent to the backend
    const initialState = {
        id: null,
        goldStandardCVId: null,
        participantId: null,
        participantPoolIds: null
    };
    const [newEntry, setNewEntry] = useState(initialState);
    const acceptedRoles = [ADVISER, MANAGER, QUALITY, RECRUITMENT_MANAGER, SUPERUSER];
    const [isOpen, setIsOpen] = React.useState(false);
    const [rowsUpdated, setRowsUpdated] = useState([]);
    const [mockInterview, setMockInterview] = useState(false);
    const [clearSelectedParticipantDocuments, setClearSelectedParticipantDocuments] = useState('1');
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false);

    // STORE STATE
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const { appointmentAttendanceDetails, currentParticipant, currentParticipantEmployability } =
        useSelector((state) => state.entities.participantService);
    const { participantAppointments } = useSelector((state) => state.entities.calendarService);
    const { attendanceDetails, appointmentTypeDetails } = useSelector(
        (state) => state.entities.directusService
    );
    const loggedInUser = useSelector(selectLoggedInUser);

    // EVENT HANDLERS
    const handleParticipantDocumentChange = (chosenId) => {
        setNewEntry((prev) => ({ ...prev, goldStandardCVId: chosenId }));
    };

    const handleClickOutside = () => {
        // Do nothing. User cannot exit by clicking outside dialog.
    };

    const handleCancel = () => {
        clearData();
    };

    const clearData = () => {
        setRowsUpdated([]);
        setNewEntry(initialState);
        reset();
        setClearSelectedParticipantDocuments(Math.random());
        onPoolEditor(false);
    };

    const onCreated = () => {
        clearData();
    };

    const onRowsUpdated = (rowsUpdated) => {
        setRowsUpdated(rowsUpdated);
    };

    // USE EFFECTS
    useEffect(() => {
        setNewEntry((prev) => ({ ...prev, participantId: currentParticipant.id }));
    }, []);

    useEffect(() => {
        if (appointmentTypeDetails?.length < 1 || attendanceDetails?.length < 1) return;
        if (participantAppointments?.length < 1 || appointmentAttendanceDetails?.length < 1) {
            setMockInterview(false);
        } else {
            const appointmentTypeId = appointmentTypeDetails.find(
                (item) => item.name.toLowerCase() === 'mock interview'
            );
            if (!appointmentTypeId) return;

            const attendanceId = attendanceDetails.find(
                (item) => item.name.toLowerCase() === 'attended'
            );
            if (!attendanceId) return;

            const cAppts = participantAppointments.filter(
                (el) => el.typeId === appointmentTypeId.id
            );

            const pAppts = appointmentAttendanceDetails.filter(
                (el) => el.attendanceId === attendanceId.id
            );

            let count = 0;
            if (pAppts) {
                pAppts.map((el) => {
                    const found = cAppts.find(
                        (entry) => el.calendarServiceAppointmentId === entry.id
                    );
                    if (found) {
                        const numberOfDays = countDays(found?.date, new Date());
                        if (numberOfDays < 31) count++;
                        if (count > 0) setMockInterview(true);
                    }
                });
            }
        }
    }, [
        appointmentAttendanceDetails,
        appointmentTypeDetails,
        attendanceDetails,
        participantAppointments
    ]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        if (successMessage === `Pool application(s) have been added`) {
            setIsUpdateButtonDisabled(false);
            onCreated();
        }
    }, [successMessage]);

    const onSubmit = () => {
        const participantPoolIds = rowsUpdated
            .filter((el) => el.update === true)
            .map((el) => el.id);
        if (newEntry.goldStandardCVId?.length < 1) {
            dispatch(setErrorMessage(`Please select a CV.`));
        } else if (participantPoolIds?.length < 1) {
            dispatch(setErrorMessage(getNoUpdateMessage()));
        } else {
            setIsUpdateButtonDisabled(true);
            const payload = {
                ...newEntry,
                participantId: currentParticipant.id,
                participantPoolIds: participantPoolIds
            };
            dispatch(createPoolApplication(payload));
        }
    };

    // RENDER
    return (
        <div
            onSubmit={(e) => {
                //  This stops the Submit event from bubbling up to the form enclosing this component
                e.preventDefault();
                e.stopPropagation();
            }}>
            <div className={classes.poolDialogWindow}>
                <Dialog
                    sx={{ alignSelf: 'centre', maxWidth: '850px' }}
                    open={isOpen}
                    onClose={handleClickOutside}
                    aria-labelledby="form-dialog-title">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle id="form-dialog-title">
                            Select Pool(s) For Application
                        </DialogTitle>
                        <DialogContent data-testid="dialog-content">
                            <DDLOptionPicker
                                label={'Gold Standard CV'}
                                id={'goldStandardCVId'}
                                key={clearSelectedParticipantDocuments}
                                mandatory={true}
                                disabled={!hasRole(acceptedRoles, loggedInUser.roles)}
                                menuItems={participantDocuments}
                                error={errors.goldStandardCVId}
                                {...register('goldStandardCVId')}
                                onChange={(e) => {
                                    if (e !== null && e !== undefined) {
                                        handleParticipantDocumentChange(e);
                                    }
                                }}
                            />
                            <Typography variant="h6">Criteria For Pool Applications:</Typography>
                            <section>
                                {participantDocuments?.length < 1 ? (
                                    <CancelIcon className={classes.red} />
                                ) : (
                                    <CheckCircleIcon className={classes.green} />
                                )}{' '}
                                Have a Gold Standard CV attached to their record
                            </section>
                            <section>
                                {Object.keys(currentParticipantEmployability).length < 1 ||
                                currentParticipantEmployability?.rightToWorkDocumentTypeId ===
                                    null ? (
                                    <CancelIcon className={classes.red} />
                                ) : (
                                    <CheckCircleIcon className={classes.green} />
                                )}{' '}
                                Right to Work Documents available
                            </section>
                            <section>
                                {mockInterview === false ? (
                                    <CancelIcon className={classes.red} />
                                ) : (
                                    <CheckCircleIcon className={classes.green} />
                                )}{' '}
                                Participant has attended at least one mock interview within last
                                month
                            </section>
                            <p>Select a Pool to submit your Participant to for consideration</p>
                            <SelectPoolsTableManagement
                                onRowsUpdated={onRowsUpdated}
                                poolApplications={poolApplications}
                            />
                        </DialogContent>

                        <DialogActions>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                disabled={
                                    !hasRole(acceptedRoles, loggedInUser.roles) ||
                                    participantDocuments?.length < 1 ||
                                    currentParticipantEmployability?.rightToWorkDocumentTypeId ===
                                        null ||
                                    currentParticipantEmployability?.rightToWorkDocumentTypeId ===
                                        undefined ||
                                    mockInterview === false ||
                                    newEntry.participantPoolIds?.length < 1 ||
                                    isUpdateButtonDisabled
                                }>
                                Update
                            </Button>
                            <Button onClick={handleCancel} color="primary" variant="contained">
                                Cancel
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

const validationSchema = Yup.object().shape({
    goldStandardCVId: Yup.string().required('Please select a CV.')
});

ApplyToPoolEditor.propTypes = {
    onPoolEditor: PropTypes.func,
    open: PropTypes.bool.isRequired,
    participantDocuments: PropTypes.array.isRequired,
    poolApplications: PropTypes.array
};

export default ApplyToPoolEditor;
