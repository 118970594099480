import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { loadContractDetailsForContractId } from '../../../store/directusService';
import { loadContractConstants, updateContractConstants } from '../../../store/participantService';
import { hasRole, LOCAL_ADMIN, SUPERUSER } from '../../../utils/userRoles';
import Button from '../../formElements/Button';
import DateSelect from '../../formElements/DateSelect';
import RadioButtons from '../../formElements/RadioButtons';
import NotFound from '../../notFound/NotFound';
import LoadingSpinner from '../../ui/LoadingSpinner';

import { initialErrorState, validate } from './validateContractDetailsAdmin';

import form from '../../../commonStyles/formStyles.module.css';

const ContractDetailsAdmin = ({ contractId }) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const viewRoles = [LOCAL_ADMIN, SUPERUSER];
    const acceptedRoles = [SUPERUSER];
    const initialState = {
        active: true,
        startDate: '',
        endDate: ''
    };

    const submitMessage = 'Contract constants has been updated';
    const [newEntry, setNewEntry] = useState(initialState);
    const [errors, setErrors] = useState(initialErrorState);
    const [isDisabled, setIsDisabled] = useState(false);

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { successMessage } = useSelector((state) => state.entities.formsState);
    const contract = useSelector(
        (state) => state.entities.directusService.contractDetailsForContractId
    );
    const contractConstants = useSelector(
        (state) => state.entities.participantService.contractConstants
    );

    // USE EFFECTS
    useEffect(() => {
        if (!contractId) return;
        contractSetup();
        dispatch(loadContractDetailsForContractId(contractId));
        if (
            Object.keys(contractConstants)?.length < 1 ||
            ('contractId' in contractConstants && contractConstants.contractId !== contractId)
        )
            dispatch(loadContractConstants(contractId));
    }, [contractId]);

    useEffect(() => {
        if (Object.keys(contractConstants).length) {
            setNewEntry((prev) => ({
                ...prev,
                active: contractConstants.active ?? true,
                startDate: contractConstants.startDate,
                endDate: contractConstants.endDate
            }));
        }
    }, [contractConstants]);

    useEffect(() => {
        setIsDisabled(Object.values(errors).some((el) => el.error));
    }, [errors]);

    useEffect(() => {
        if (successMessage === submitMessage) setIsDisabled(false);
    }, [successMessage]);

    // HELPER FNS
    const contractSetup = () => {
        setIsDisabled(false);
        setNewEntry(initialState);
        setErrors(initialErrorState);
    };

    const clearError = (key) => {
        key !== 'button' &&
            setErrors((prev) => ({ ...prev, [key]: { error: false, message: '' } }));
    };

    const clearButtonError = () => setErrors(initialErrorState);

    // EVENT HANDLERS

    const onUpdate = (key, value) => {
        if (errors[key]) clearError(key);
        setNewEntry((prev) => ({ ...prev, [key]: value }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const newErrors = validate(newEntry, contractConstants);
        if (Object.keys(newErrors)?.length > 0) {
            setErrors(newErrors);
            return;
        }
        dispatch(updateContractConstants({ ...contractConstants, ...newEntry }));
        setIsDisabled(true);
    };

    // AWAITING CONTENT

    if (!hasRole(viewRoles, roles)) return <NotFound />;
    if (!contractId || !contract || !Object.keys(contract).length)
        return <LoadingSpinner content={'No contract'} />;

    // RENDER
    return (
        <form className={form.formWrapper} onSubmit={onSubmit} data-testid="form_start">
            <div className={form.formSection}>
                <div className={form.formColumn}>
                    <div className={form.staticField}>
                        <label className={form.formLabel}>Contract Name</label>
                        <div className={form.staticText}>{contract.name}</div>
                    </div>
                    <div className={form.staticField}>
                        <label className={form.formLabel}>Contract Type</label>
                        <div className={form.staticText}>{contract.contract_type.name}</div>
                    </div>
                    <div className={form.staticField}>
                        <label className={form.formLabel}>Unique ID Type</label>
                        <div className={form.staticText}>
                            {contract.unique_identifier_type.name}
                        </div>
                    </div>
                </div>
                <div className={form.formColumn}>
                    <RadioButtons
                        id="active"
                        disabled={true}
                        label="Active"
                        value={newEntry.active}
                        onChange={(option) => onUpdate('active', option)}
                    />
                    <div className={form.formColumnSplit}>
                        <DateSelect
                            id="startDate"
                            label="Start Date"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.startDate}
                            error={errors.startDate}
                            onDateChange={(date) => onUpdate('startDate', date)}
                        />
                        <DateSelect
                            id="endDate"
                            label="End Date"
                            disabled={!hasRole(acceptedRoles, roles)}
                            mandatory={true}
                            value={newEntry.endDate}
                            error={errors.endDate}
                            onDateChange={(date) => onUpdate('endDate', date)}
                        />
                    </div>
                </div>
            </div>

            <Button
                id="contractDetailsAdmin"
                content="UPDATE CONTRACT DETAILS"
                disabled={!hasRole(acceptedRoles, roles) || isDisabled}
                error={errors.button}
                clearError={clearButtonError}
            />
        </form>
    );
};

ContractDetailsAdmin.propTypes = {
    contractId: PropTypes.string
};

export default ContractDetailsAdmin;
