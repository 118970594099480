import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { searchUsersByLoggedInUserServiceIds } from '../../store/userService';
import LoadingSpinner from '../ui/LoadingSpinner';

import AuditEventHistoryTable from './AuditEventHistoryTable';

const AuditEventHistoryManagement = ({ auditEventHistory = [], label }) => {
    // HOOKS
    const dispatch = useDispatch();

    // LOCAL STATE
    const [rows, setRows] = useState([]);

    // STORE STATE
    const { usersByLoggedInUserServiceIds: users, loggedInUser } = useSelector(
        (state) => state.entities.userService
    );
    // USE EFFECTS
    useEffect(() => {
        if (!auditEventHistory?.length > 0) return;
        setRows(auditEventHistory);
    }, []);

    useEffect(() => {
        if (!loggedInUser || !('serviceIds' in loggedInUser) || loggedInUser.serviceIds.length < 1)
            return;
        if (!users || users.length < 1)
            dispatch(searchUsersByLoggedInUserServiceIds(loggedInUser.serviceIds));
    }, [loggedInUser]);

    // RENDER
    return rows.length < 1 ? (
        <LoadingSpinner content="No audit event history found" />
    ) : (
        <AuditEventHistoryTable rows={rows} label={label} />
    );
};

AuditEventHistoryManagement.propTypes = {
    auditEventHistory: PropTypes.array,
    label: PropTypes.string
};

export default AuditEventHistoryManagement;
