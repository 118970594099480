import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Button, Tooltip } from '@mui/material';

import { selectNotKnownStatusId } from '../../../store/dataSelectors';
import { getBankHolidayData } from '../../../store/dataService';
import { POLARIS_GREY, POLARIS_ROYAL_BLUE } from '../../../themes/theme';
import { RETURN } from '../../../utils/uiConstants';
import { hasRole, QUALITY, SUPERUSER } from '../../../utils/userRoles';
import ConfirmPrompt from '../notices/confirmPrompt/ConfirmPrompt';

import './calendar.css';

let eventGuid = 0;
function createEventId() {
    return String(eventGuid++);
}
/**
 *
 * @param events (array of objects)
 * @param currentEvent (object) - the id is needed and also the date and times to check for change
 * @param updateEvents (function)
 * @param selectSlot (function) - a callback on slot select - ie. to open a panel for editing
 * @param selectEvent (function) -  a callback on event select - ie. to open a panel / switch colors
 * @param onEditTimes (function) - a callback on a direct time/date drag edit in the calendar
 * @param onReturn (function) - to return to editCourse
 * @param returnButton (boolean) - if it requires a return button in the toolbar
 * @param initialView (string) - the calendar's initial view required (day, week etc)
 * @param onNav (function) - for use in render for the PT's name
 * @returns {JSX.Element}
 * @constructor
 */

const Calendar = ({
    events,
    currentEvent,
    onEditTimes,
    selectSlot,
    selectEvent,
    onReturn,
    initialView,
    returnButton,
    onNav,
    slotMinTime = '08:00:00',
    slotMaxTime = '18:00:00',
    resources,
    onChangeResource,
    allowSlotSelect,
    eventEditPermission = true
}) => {
    const dispatch = useDispatch();

    // LOCAL STATE
    const editRestrictedRoles = [QUALITY, SUPERUSER];
    const initialTimeState = { date: '', start: '', end: '' };
    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [time, setTime] = useState(initialTimeState);
    const [currentId, setCurrentId] = useState('');
    const [holidayEvents, setHolidayEvents] = useState([]);
    const [eventHeight, setEventHeight] = useState(0);
    const [locale, setLocale] = useState('');

    // STORE STATE
    const { roles } = useSelector((state) => state.entities.userService.loggedInUser);
    const { bankHolidayData } = useSelector((state) => state.entities.dataService);
    const notKnownStatusId = useSelector(selectNotKnownStatusId);
    const appointmentAttendanceDetails = useSelector(
        (state) => state.entities.participantService.appointmentAttendanceDetails
    );
    const usersCurrentAppointment = useSelector(
        (state) => state.entities.calendarService.usersCurrentAppointment
    );

    // USEEFFECTS
    useEffect(() => {
        dispatch(getBankHolidayData());
        if (initialView === 'resourceTimelineDay') setLocale('en-GB');
    }, []);

    useEffect(() => {
        // TODO
        // for demo purposes - division is hard-coded - there are 3 from the API
        // 'england-and-wales', 'northern-ireland' & 'scotland'
        // also noted that it's needed - how to determine the division
        const filtered = bankHolidayData
            .filter((el) => el.division === 'england-and-wales')
            .map((el) => ({
                content: el.name,
                date: el.date.split('T')[0],
                display: 'background',
                className: 'bank-holidays'
            }));
        setHolidayEvents(filtered);
    }, [bankHolidayData]);

    useEffect(() => {
        if (initialView === 'resourceTimelineDay') {
            const calendar = document.querySelector('.fc');
            const table = document.querySelector('.fc-timeline-body'); // needs to be connected to window height

            const calendarHeight = calendar.getBoundingClientRect().height;
            const eventHeight = table ? ~~((calendarHeight - 175) / resources.length) : 0;
            setEventHeight(eventHeight);

            let rows = Array.from(document.querySelectorAll('.fc-resource'));
            rows = rows.filter((el) => el.classList.contains('fc-timeline-lane'));
            rows.forEach((el, i) => {
                el.style.minHeight = `${eventHeight}px`;
                el.style.height = `${eventHeight}px`;
                el.style.maxHeight = `${eventHeight}px`;
                resources[i].active === true
                    ? el.classList.add('fc-resource-row-active')
                    : el.classList.remove('fc-resource-row-active');
            });

            onChangeResource();
        }
    }, [resources]);

    // RENDER FNS
    const renderInnerContent = (eventInfo) => {
        const {
            participant,
            location,
            appointmentType,
            content,
            timeContent,
            eventType,
            slots,
            filledSlots,
            hasLink,
            viewType,
            displayStart,
            displayEnd,
            hasOverlap
        } = eventInfo.event.extendedProps;

        const copy = (
            <>
                {eventInfo.timeText && eventType !== 'hidden' && (
                    <div className="fc-event-time">{timeContent}</div>
                )}
                <div className="fc-event-title-container">
                    <div className="fc-event-title">
                        {viewType === 'timeView' && (
                            <div>
                                {' '}
                                {displayStart} - {displayEnd}{' '}
                            </div>
                        )}
                        {eventType !== 'user' && <span className="content-text">{content}</span>}
                        {appointmentType && <span>{appointmentType}</span>}
                        {slots && <span> - {filledSlots} </span>}
                        {participant && !hasLink && <span> - {participant}</span>}
                        {participant && hasLink && (
                            <>
                                <span> - </span>
                                <span
                                    className="event-link"
                                    onClick={() => onNav(eventInfo.event.extendedProps)}>
                                    {participant}
                                </span>
                            </>
                        )}
                        {eventType === 'user' && location && <span> - {location} </span>}
                    </div>
                </div>
            </>
        );
        const eventContent = (
            <div
                className="fc-event-main-frame"
                style={
                    initialView === 'resourceTimelineDay'
                        ? {
                              minHeight: hasOverlap ? `${eventHeight / 2}px` : `${eventHeight}px`,
                              height: hasOverlap ? `${eventHeight / 2}px` : `${eventHeight}px`,
                              maxHeight: hasOverlap ? `${eventHeight / 2}px` : `${eventHeight}px`
                          }
                        : {}
                }>
                {copy}
            </div>
        );
        const toolTipContent = <div className="fc-event-main-frame">{copy}</div>;

        return [eventContent, toolTipContent];
    };
    const renderEventContent = (eventInfo) => {
        const [eventContent, toolTipContent] = renderInnerContent(eventInfo);
        if (eventInfo.event._def.ui.classNames.includes('busy-events')) return eventContent;
        return (
            <Tooltip title={toolTipContent} placement="top">
                {eventContent}
            </Tooltip>
        );
    };

    // EVENT HANDLERS

    const onSelectAllow = (selectInfo) => {
        if (!allowSlotSelect) return false;
        if (selectInfo.resource) {
            return !!selectInfo.resource._resource.extendedProps.active;
        }
        return true;
    };
    const onSlotSelect = (newEvent) => {
        if (!selectSlot) return;
        // const endTime = timeAdd(newEvent?.endStr, 15); // 15 (existing) + 15
        const title = 'Click to edit';
        const calendarApi = newEvent?.view.calendar;
        calendarApi.unselect();
        if (title) {
            calendarApi.addEvent({
                id: createEventId(),
                title,
                start: newEvent?.startStr,
                end: newEvent?.endStr, //endTime,
                allDay: newEvent.allDay,
                backgroundColor: currentEvent?.color || POLARIS_GREY,
                textColor: currentEvent?.textColor || POLARIS_ROYAL_BLUE,
                extendedProps: currentEvent?.detail
            });
        } else {
            calendarApi.unselect();
        }
        newEvent.view.type === 'dayGridMonth'
            ? selectSlot(`${newEvent.startStr}T08:00:00`, `${newEvent.startStr}T08:30:00`)
            : selectSlot(newEvent.startStr, newEvent.endStr);
    };

    const onEventSelect = (eventInfo) => {
        if (
            'hasPermission' in eventInfo.event.extendedProps &&
            !eventInfo.event.extendedProps?.hasPermission
        )
            return;
        if (eventEditPermission) {
            selectEvent(eventInfo.event._def.publicId);
        }
    };

    // when an event is dragged
    const onEventEdit = (changeInfo) => {
        const onNoEdit = () => {
            onEditTimes({ date, start, end });
            setTime(initialTimeState);
            setCurrentId('');
        };
        const id = changeInfo.event._def.publicId;
        const { startStr, endStr } = changeInfo.event;
        const start = startStr;
        const end = endStr;
        const date = start.split('T')[0];
        setTime({ date, start, end });
        setCurrentId(id);
        if (initialView === 'resourceTimelineDay' && !changeInfo.event.extendedProps.draggable) {
            onNoEdit();
            return;
        }
        if (
            appointmentAttendanceDetails?.length < 1 ||
            !notKnownStatusId ||
            Object.keys(usersCurrentAppointment).length < 1
        )
            return;
        let isAppointmentStarted = false;
        if ('participantId' in usersCurrentAppointment) {
            const ptApp = appointmentAttendanceDetails.find(
                (el) => el.participantId === usersCurrentAppointment.participantId
            );
            isAppointmentStarted = ptApp?.attendanceId !== notKnownStatusId;
        } else if ('participantIds' in usersCurrentAppointment) {
            const statuses = usersCurrentAppointment.participantIds.map(
                (el) =>
                    appointmentAttendanceDetails.find((entry) => entry.participantId === el)
                        ?.attendanceId || notKnownStatusId
            );
            isAppointmentStarted = statuses.some((el) => el !== notKnownStatusId);
        }

        if (
            !hasRole(editRestrictedRoles, roles) &&
            (date !== usersCurrentAppointment?.date || isAppointmentStarted)
        ) {
            onNoEdit();
            return;
        }

        if (
            date !== currentEvent.date ||
            start !== currentEvent.start ||
            end !== currentEvent.end
        ) {
            setShowVerifyModal(true);
        } else onNoEdit();
    };

    const onVerifyModalClose = (_, verify) => {
        let update = true;
        if (!verify || verify === 'backdropClick' || typeof verify === 'object') update = false;
        onEditTimes(time, update, currentId);
        setShowVerifyModal(false);
        setTime(initialTimeState);
        setCurrentId('');
    };

    // RENDER
    return (
        <div className="calendar-container">
            {showVerifyModal && (
                <ConfirmPrompt
                    isOpen={showVerifyModal}
                    onCancel={(e) => onVerifyModalClose(e, false)}
                    onConfirm={(e) => onVerifyModalClose(e, true)}
                    content="Do you want to keep this change?"
                />
            )}
            <div className={`${returnButton ? 'show-return-btn' : 'hide-return-btn'}`}>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={onReturn}
                    data-testid="testIdSubmitButton">
                    {RETURN}
                </Button>
            </div>
            <FullCalendar
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives" // https://fullcalendar.io/docs/premium
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                headerToolbar={{
                    left: 'prev title next today',
                    right:
                        initialView === 'resourceTimelineDay'
                            ? ''
                            : 'dayGridMonth timeGridWeek timeGridDay'
                }}
                views={{
                    timeGridWeek: {
                        weekends: false,
                        dayHeaderFormat: {
                            weekday: 'short',
                            day: 'numeric'
                        },
                        titleFormat: {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric'
                        }
                    },
                    dayGridMonth: {
                        eventTimeFormat: {
                            hour: '2-digit',
                            minute: '2-digit'
                        },
                        displayEventEnd: true
                    },
                    resourceTimelineDay: {
                        expandRows: true,
                        titleFormat: {
                            weekday: 'long',
                            day: '2-digit',
                            month: 'short'
                        }
                    }
                }}
                locale={locale} // use "en-GB" for 24hr y-axis labels - but title date will revert to M DD Y
                initialView={initialView}
                navLinks={true}
                eventDisplay="block" // month view text style errors without this
                selectable={!!selectSlot}
                selectAllow={onSelectAllow}
                select={onSlotSelect}
                selectMirror={true}
                weekends={true}
                allDaySlot={false}
                slotMinTime={slotMinTime}
                slotMaxTime={slotMaxTime}
                slotDuration="00:15:00"
                slotLabelInterval="01:00:00"
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: false
                }}
                resourceAreaHeaderContent=""
                resourceAreaWidth="0px"
                resourceOrder="orderId"
                resources={resources}
                resourcesInitiallyExpanded={false}
                events={[...events, ...holidayEvents]}
                eventContent={renderEventContent}
                eventClick={onEventSelect}
                eventChange={onEventEdit}
                editable={true}
            />
        </div>
    );
};

export default Calendar;

Calendar.propTypes = {
    events: PropTypes.arrayOf(PropTypes.object),
    currentEvent: PropTypes.object,
    onEditTimes: PropTypes.func,
    selectSlot: PropTypes.func,
    selectEvent: PropTypes.func,
    onReturn: PropTypes.func,
    initialView: PropTypes.string,
    returnButton: PropTypes.bool,
    onNav: PropTypes.func,
    slotMinTime: PropTypes.string,
    slotMaxTime: PropTypes.string,
    resources: PropTypes.arrayOf(PropTypes.object),
    onChangeResource: PropTypes.func,
    resourceLabelText: PropTypes.string,
    allowSlotSelect: PropTypes.bool,
    eventEditPermission: PropTypes.bool
};
